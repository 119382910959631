import React, { useEffect, useState } from "react";
import HomePageFooter from "./HomePageFooter";
import HomePageHeader from "./HomePageHeader";
import { StarIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import axios from "axios";

const incentives = [
  {
    name: "Free shipping",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
  },
  {
    name: "10-year warranty",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "If it breaks in the first 10 years we'll replace it. After that you're on your own though.",
  },
  {
    name: "Exchanges",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "If you don't like it, trade it to one of your friends for something of theirs. Don't send it here though.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PublicallyFacingProfile = () => {
  const { id } = useParams();
  const [currProfile, setCurrProfile] = useState(null);

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        // setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/resource/Company Profile/${id}`,
            config
          )
          .then((response) => {
            setCurrProfile(response.data.data);
            console.log("public_facing_profile", response.data.data);
            // setLoading(false);
          });
      } catch (e) {
        console.error(e);
      }
    };

    getProfileOwner();
  }, []);

  return (
    <>
      <HomePageHeader />

      <div className="bg-white ">
        <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
          <div className="rounded-2xl bg-gray-50 px-6 py-10 sm:p-10">
            <div className="mx-auto max-w-xl lg:max-w-none">
              <div className="mx-auto  grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
                <div className="sm:flex sm:text-left lg:block lg:text-center lg:border-r  border-r-0">
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <h1 className="text-4xl font-semibold capitalize">
                        {currProfile?.business_name &&
                          currProfile.business_name.charAt(0)}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="text-left sm:flex sm:text-left lg:block lg:text-left lg:border-r border-r-0">
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <h1 className="font-semibold text-2xl capitalize">
                        {currProfile?.business_name}
                      </h1>
                    </div>
                  </div>
                  <div className="mt-2 sm:ml-2 sm:mt-0 lg:ml-0 lg:mt-2 ">
                    <div className="flex items-center gap-x-1">
                      <h3 className="text-sm font-medium text-gray-900">5.0</h3>
                      <span className="flex text-yellow-400">
                        <StarIcon className="w-5 h-5" />
                        <StarIcon className="w-5 h-5" />
                        <StarIcon className="w-5 h-5" />
                        <StarIcon className="w-5 h-5" />
                        <StarIcon className="w-5 h-5" />
                      </span>
                      <p className="text-sm text-gray-500 hover:underline">
                        1(review)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-left sm:flex sm:text-left lg:block lg:text-left">
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root font-semibold ">
                      <h1>Verified Renter</h1>
                    </div>
                  </div>
                  <div className="mt-1 sm:ml-1 sm:mt-0 lg:ml-0 lg:mt-1">
                    <p className="mt-2 text-sm text-gray-500 ">
                      Our verified renters have been carefully selected to
                      provide you with the ultimate renting experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HomePageFooter />
    </>
  );
};

export default PublicallyFacingProfile;
