import React from "react";
import axios from "axios";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  TruckIcon,
  HomeIcon,
  CogIcon,
  LifebuoyIcon,
  XMarkIcon,
  MapPinIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import { CalendarIcon } from "@heroicons/react/24/solid";
import "./Dashboard.css";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { useEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, logout, reset } from "../../auth/authSlice";
import { reFreshToken, setLocalStorage } from "../../auth/authCheck";
import Vehicles from "../../Components/Owner/Vehicle/Vehicles";
import DashMain from "../../Components/Owner/DashMain/DashMain";
// import EditVehicle from "../../Components/Vehicle/EditVehicle";
import LocationList from "../../Components/Owner/Locations/LocationList";
import AddLocation from "../../Components/Owner/Locations/AddLocation";
import Editlocation from "../../Components/Owner/Locations/Editlocation";
import Profile from "../../Components/Global/Profile/Profile";
import EditProfileBusiness from "../../Components/Global/Profile/EditProfileBusiness";
import SidebarOwner from "../../Components/Global/SidebarOptions/Owner/SidebarOwnerMobile";
import SidebarOwnerDesktop from "../../Components/Global/SidebarOptions/Owner/SidebarOwneDesktop";
import CheckRole from "../../Components/Global/SidebarOptions/CheckRole";
import EditVehicleForm from "../../Components/Owner/Vehicle/EditVehicle";
import VehicleDetailPage from "../../Components/Owner/Vehicle/VehicleDetailPage";
import Drivers from "../../Components/Renter/Driver/Drivers";
import AddDriver from "../../Components/Renter/Driver/addDriver";
import EditDriver from "../../Components/Renter/Driver/editDriver";
import VehicleListing from "../../Components/Renter/VehicleListing/VehicleListing";
import VehicleDetail from "../../Components/Renter/VehicleListing/VehicleDetail";
import Logo from "../../img/Logo.png";
import ReservationList from "../../Components/Owner/Reservation/ReservationList";
import ReservationDetailPage from "../../Components/Owner/Reservation/ReservationDetailPage";
import TermsandConditions from "../../Components/Global/TermsandConditions/TermsandConditions";
import moment from "moment";
import LoadingIcons from "react-loading-icons";
import Reservation from "../../Components/Renter/Reservation/Reservation";
import ReservationDetails from "../../Components/Renter/Reservation/ReservationDetails";
import Chat from "../../Components/Renter/Chat/Chat";
import OwnerChat from "../../Components/Owner/Chat/OwnerChat";
import { CompanyProfile } from "../../Context/CompanyProfile";
import Setting from "../../Components/Global/Setting/Setting";
import ReturnStripe from "../../Components/Global/ReturnStripe/ReturnStripe";
import RefreshStripe from "../../Components/Global/RefreshStripe/RefreshStripe";
import VehicleAvailability from "../../Components/Owner/Vehicle/VehicleAvailability";
import userAvatar from "../../img/user.png";
import { toast, Toaster } from "sonner";
import SupportTicket from "../../Components/Renter/SupportTicket/SupportTicket";
import AddTicket from "../../Components/Renter/SupportTicket/AddTicket";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [currProfile, setCurrProfile] = useState(null);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const nevigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [roleLocal, setRoleLocal] = useState(localStorage.getItem("role"));
  const [roleToggle, setRoleToggle] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [allNotifications, setAllNotifications] = useState();
  const [newNotificationCheck, setNewNotificationCheck] = useState(0);
  const [loading, setLoading] = useState(true);
  const [intervalCheck, setIntervalCheck] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const navigate = useNavigate();

  const recivedData = (data) => {
    setUpdatedProfile(data);
  };

  useEffect(() => {
    const handleStorage = (e) => {
      if (e.key === "role") {
        setRoleLocal(e.newValue);
        // console.log(e.key);
      }
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [roleLocal, roleToggle]);

  const getNotificationsNew = async (e) => {
    try {
      await axios
        .get(
          `${window.$backendURL}/api/resource/Notifications?fields=["*"]&filters=[["creation",">","${e?.last_notification_seen}"]]`,
          config
        )
        .then((response) => {
          console.log(response.data.data);
          response.data.data.length > 0 && setNewNotificationCheck(1);
        });
    } catch (e) {
      console.error(e);
    }
  };

  setInterval(() => {
    setIntervalCheck(!intervalCheck);
  }, 1200000000);

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            // console.log(response.data.data);
            if (response.data._server_messages) {
              const serverMessages = JSON.parse(response.data._server_messages);
              if (JSON.parse(serverMessages[0])?.message === "User not found") {
                
                toast.error("User not found. Logging out...");

                setTimeout(() => {
                  onLogout()
                }, 2000);

                
              }
            }
            setCurrProfile(response?.data?.data);
            setProfilePicture(response?.data?.data?.user?.user_image);
            localStorage.setItem("userID", response?.data?.data?.name);
            setLoading(false);

            getNotificationsNew(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getProfileOwner();
  }, [updatedProfile, roleLocal, loggedIn, intervalCheck]);


  useEffect(() => {
    const getNotifications = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Notifications?fields=["*"]&filters=[["for_user","=","${userEmail}"]]&order_by=creation desc`,
            config
          )
          .then((response) => {
            console.log(">>>", response.data.data);
            setAllNotifications(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getNotifications();
  }, []);
  

  useEffect(() => {
    setTimeout(() => {
      setLoggedIn(!loggedIn);
    }, 1000);
  }, []);

  useEffect(() => {
    if (localStorage.user) {
      dispatch(login(localStorage.user));
      // window.location.reload(false)
    } else {
      nevigate("/signin");
    }
  }, []);

  useEffect(() => {
    // -------- Access Token Expiry Check Start ------
    if (localStorage.user) {
      var dateInInt = parseInt(localStorage.date);
      var dateInIntWithExpiry = dateInInt + 3600000;
      var timeNow = Date.now();
      if (timeNow < dateInIntWithExpiry) {
        dispatch(login(localStorage.user));
      } else {
        reFreshToken();

      }
      // -------- Access Token Expiry Check End ------
    }
  });


  function addZeroPrefix(value) {
    return value < 10 ? "0" + value : value;
  }
  
  const onLogout = () => {
    const logoutFromFrappe = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            "https://api.rentmytruck.co/api/method/truckrent.api.clear_all_sessions_of_user?user=" +
              userEmail,
            config
          )
          .then((response) => {
            window.location.reload();
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    logoutFromFrappe();
    dispatch(logout());
    dispatch(reset());
    nevigate("/signin");
  };

  const notificationsButtonClickHandle = () => {
    setNewNotificationCheck(0);
    var time = new Date().toLocaleString("en-US", { timeZone: "Europe/Paris" });
    var finaltime = new Date(time);
    var formattedTime =
      finaltime.getFullYear() +
      "-" +
      addZeroPrefix(finaltime.getMonth() + 1) +
      "-" +
      addZeroPrefix(finaltime.getDate()) +
      " " +
      addZeroPrefix(finaltime.getHours()) +
      ":" +
      addZeroPrefix(finaltime.getMinutes()) +
      ":" +
      addZeroPrefix(finaltime.getSeconds()) +
      "." +
      finaltime.getMilliseconds();
    var json = {
      last_notification_seen: formattedTime,
    };
    const updateNotificationSeenField = async () => {
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Company Profile/${localStorage.userID}`,
            json,
            config
          )
          .then((response) => {});
      } catch (e) {
        console.error(e);
      }
    };
    updateNotificationSeenField();
  };

  useEffect(() => {
    if (localStorage.user) {
      setLocalStorage();
    }
  }, []);

  useEffect(() => {
    if (localStorage.role == "Owner Business") {
      setEnabled(false);
    } else if (localStorage.role == "Renter") {
      setEnabled(true);
    }
  }, []);

  const handleDashBoardToggle = () => {
    if (localStorage.role == "Owner Business") {
      setEnabled(true);
      localStorage.setItem("role", "Renter");
      navigate("/home");
    } else if (localStorage.role == "Renter") {
      setEnabled(false);
      localStorage.setItem("role", "Owner Business");
      navigate("/VehicleListing");
    }
  };

  const handleNotiNavigate = (item) => {
    if (item?.document_type == "Reservation" && item?.profile == "Renter") {
      if (localStorage.role == "Owner Business") {
        setEnabled(true);
        localStorage.setItem("role", "Renter");
        navigate(`/ReservationDetails/${item.document_id}`);
      } else if (localStorage.role == "Renter") {
        navigate(`/ReservationDetails/${item.document_id}`);
      }
    } else if (
      item?.document_type == "Reservation" &&
      item?.profile == "Owner"
    ) {
      if (localStorage.role == "Owner Business") {
        navigate(`/reservations_detail/${item.document_id}`);
      } else if (localStorage.role == "Renter") {
        setEnabled(false);
        localStorage.setItem("role", "Owner Business");
        navigate(`/reservations_detail/${item.document_id}`);
      }
    } else if (item?.document_type == "Vehicle" && item?.profile == "Owner") {
      if (localStorage.role == "Owner Business") {
        navigate(`/vehicle/${item.document_id}`);
      } else if (localStorage.role == "Renter") {
        setEnabled(false);
        localStorage.setItem("role", "Owner Business");
        navigate(`/vehicle/${item.document_id}`);
      }
    }
    // else if(item?.document_type == "Vehicle" && item?.profile == "Renter"){
    //   if(localStorage.role == "Owner Business"){
    //     setEnabled(true);
    //     localStorage.setItem("role" , "Renter")
    //     navigate(`/vehicle-detail/${item.document_id}`);
    //     }
    //     else if(localStorage.role == "Renter"){
    //     navigate(`/vehicle-detail/${item.document_id}`);
    //     }

    // }
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div>
    <Toaster richColors/>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex ">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4 ">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    {/* <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt="Your Company"
                    /> */}
                    <img src={Logo} alt="Main Logo" className="h-10" />
                  </div>
                  <div className="mt-5 h-screen flex flex-col overflow-y-auto white-bg">
                    <CheckRole role={localStorage.role} type="mobile" />
                    <div className="flex flex-col justify-between">
                      <nav className="space-y-1 px-2">
                        <a
                          className={classNames(
                            true
                              ? "nav-button-active-color nav-button-active-text-color"
                              : "text-gray-600 hover:bg-purple-50 hover:text-purple-500",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <CogIcon
                            className={classNames(
                              true
                                ? "nav-button-active-text-color"
                                : "text-gray-400 group-hover:text-purple-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          Setting
                        </a>
                      </nav>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden  md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto shadow-sm bg-white pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              /> */}
              <img src={Logo} alt="Main Logo" className="h-10" />
            </div>
            <div className="mt-5 flex flex-grow flex-col">
              <div className="flex flex-col justify-between h-full">
                <CheckRole role={localStorage.role} type="desktop" />
                <nav className="space-y-1 px-2 mb-6">
                  {/* <a
                    className={classNames(
                      false
                        ? "nav-button-active-color nav-button-active-text-color"
                        : "text-gray-600 hover:bg-purple-50 hover:text-purple-500",
                      "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    )}
                  >
                    <CogIcon
                      className={classNames(
                        false
                          ? "nav-button-active-text-color"
                          : "text-gray-400 group-hover:text-purple-500",
                        "mr-4 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    Setting
                  </a> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-end border-none px-1.5 lg:px-4">
              <div className="flex items-center gap-x-2">
                {/* {localStorage.role == "Owner Business" && (
                  <Link
                    onClick={() => {
                      localStorage.setItem("role", "Renter");
                      setRoleToggle(!roleToggle);
                    }}
                    to="/home"
                    className=" bg-gray-200 flex justify-center items-center mr-3 h-10 w-40 rounded-lg  text-gray-400 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <h3 className=" flex gap-2 justify-center items-center text-gray-500 font-semibold px-3">
                      <ArrowsRightLeftIcon className="w-6 h-6" />
                      <span> Business</span>
                    </h3>
                  </Link>
                )}
                {localStorage.role == "Renter" && (
                  <Link
                    onClick={() => {
                      localStorage.setItem("role", "Owner Business");
                      setRoleToggle(!roleToggle);
                    }}
                    to="/Dashboard"
                    className=" bg-gray-200 flex justify-center items-center mr-3 h-10 w-40 rounded-lg  text-gray-400 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <h3 className=" flex gap-2 justify-center items-center text-gray-500 font-semibold px-3">
                      <ArrowsRightLeftIcon className="w-6 h-6" />
                      <span> Renter</span>
                    </h3>
                  </Link>
                )} */}
                <div className="flex items-center">
                  <span
                    className={
                      localStorage.role == "Owner Business"
                        ? " flex gap-2 justify-center bg-purple-500 rounded-md py-1 mr-2 items-center text-white font-semibold lg:px-3 px-1 lg:text-base text-xs"
                        : "flex gap-2 justify-center items-center text-gray-500 font-semibold lg:px-3 px-1 lg:text-base text-xs"
                    }
                  >
                    Owner
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={handleDashBoardToggle}
                    className="mr-2 group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? "bg-purple-600" : "bg-gray-200",
                        "pointer-events-none absolute mx-auto h-2 lg:h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                  <span
                    className={
                      localStorage.role == "Renter"
                        ? " flex gap-2 justify-center bg-purple-500 rounded-md py-1 items-center text-white font-semibold lg:px-3 px-1 lg:text-base text-xs"
                        : "flex gap-2 justify-center items-center text-gray-500 font-semibold lg:px-3 px-1 lg:text-base text-xs"
                    }
                  >
                    Renter
                  </span>
                </div>
                {/* <div className="flex">
                  <button
                    type="button"
                    className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={notificationsButtonClickHandle}
                          className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none "
                        >
                          <span className="sr-only">Open options</span>
                          <div className="relative inline-flex w-fit">
                            {newNotificationCheck == 1 && (
                              <div className="absolute bottom-auto left-auto right-2 top-1 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-purple-500 text-white p-2 text-center align-middle text-xs"></div>
                            )}
                            <div className="flex border border-black rounded-full items-center justify-center px-2 py-2 text-center dark:text-gray-200">
                              <BellIcon className="lg:h-6 lg:w-6 w-5 h-5 text-black" />
                            </div>
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute lg:-right-20 -right-4 h-[20rem] overflow-y-scroll p-3 z-10 mt-2 lg:w-96 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="flex flex-col gap-1 ">
                            {allNotifications?.length > 100 ?
                            allNotifications?.map((item) => {
                              return (
                                <div className="pointer-events-auto lg:w-full w-76 max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 hover:bg-gray-100">
                                  <div className="p-2">
                                    <div className="flex items-start">
                                      <div className="flex-shrink-0">
                                        <InboxIcon
                                          className="lg:h-6 lg:w-6 w-5 h-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </div>

                                      <div
                                        className="lg:ml-3 ml-1 w-0 flex-1 pt-0.5"
                                        onClick={() => handleNotiNavigate(item)}
                                      >
                                        <div className="flex justify-between lg:mb-4 mb-3">
                                          <span className="lg:text-sm text-xs font-medium text-gray-900">
                                            {item?.title}
                                          </span>
                                          <span className="lg:text-[12px] text-[10px] font-medium text-gray-900">
                                            {moment(item?.creation).format(
                                              "lll"
                                            )}
                                          </span>
                                        </div>
                                        <p className="lg:mt-1 mt-0 lg:text-sm text-xs text-gray-500">
                                          {item?.body}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }): <div className="flex items-center justify-center pt-1"><span>You do not have any notifications</span></div>}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </button>
                </div> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative lg:ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center  bg-white text-sm">
                      {loading ? (
                        <LoadingIcons.SpinningCircles
                          stroke="#800080"
                          className="h-10 w-10 "
                        />
                      ) : (
                        <>
                          <span className="sr-only">Open user menu</span>
                          <div className="flex lg:flex-row flex-col items-center ">
                            <div className="">
                              <img
                                className="inline-block object-cover lg:h-10 lg:w-10 w-9 h-9 rounded-full"
                                src={
                                  profilePicture
                                    ? window.$backendURL + profilePicture
                                    : userAvatar
                                }
                                alt=""
                              />
                            </div>
                            <div className="lg:ml-3 lg:flex lg:flex-col hidden">
                              <p className="lg:text-base text-sm font-bold text-gray-700 group-hover:text-gray-900">
                                {currProfile?.user.first_name +
                                  " " +
                                  currProfile?.user.last_name}
                              </p>
                              <span className="text-sm font-medium text-purple-500 group-hover:text-gray-700 check-class bg-purple-100 py-0.5 px-1.5 rounded-full ">
                                View profile
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute flex flex-col items-start right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <Link
                          to="/profile"
                          className="w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        >
                          <div className="flex">
                            <img
                              className="inline-block object-cover h-6 w-6 rounded-full"
                              src={
                                profilePicture
                                  ? window.$backendURL + profilePicture
                                  : userAvatar
                              }
                              alt=""
                            />
                            <span className="px-2 lg:flex items-center hidden">
                              Your Profile
                            </span>
                            <span className="px-2 lg:hidden items-center flex font-semibold">
                              {currProfile?.user.first_name +
                                " " +
                                currProfile?.user.last_name}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                      {localStorage.role == "Owner Business" && (
                        <Menu.Item>
                          <Link
                            to="/setting"
                            className="w-full flex justify-start px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          >
                            <div className="flex">
                              <CogIcon className="w-6 h-6" />
                              <span className="px-2 flex items-center">
                                Manage Payouts
                              </span>
                            </div>
                          </Link>
                        </Menu.Item>
                      )}
                      {localStorage.role == "Renter" && (
                        <Menu.Item>
                          <Link
                            to="/setting"
                            className="w-full flex justify-start px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          >
                            <div className="flex">
                              <CogIcon className="w-6 h-6" />
                              <span className="px-2 flex items-center">
                                Setting
                              </span>
                            </div>
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <button
                          onClick={onLogout}
                          className="w-full flex justify-start px-2 py-2 text-sm text-red-700 hover:bg-red-100 cursor-pointer"
                        >
                          <div className="flex">
                            <ArrowRightOnRectangleIcon className="w-6 h-6" />
                            <span className="px-2 flex items-center">
                              Sign out
                            </span>
                          </div>
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 h-screen">
            <div className="py-6 dashboard-bg">
              <div className="mx-auto w-full px-4 sm:px-6 md:px-8">
                {/* {isOnDashboard && <DashMain/>}
                {isOnVehicle && <Vehicles/>}  */}

                <Routes>
                  <Route path="/Dashboard" element={<DashMain />} />
                  <Route path="/VehicleListing" element={<Vehicles />} />
                  <Route
                    path="/editVehicle/:vehicleID"
                    element={<EditVehicleForm />}
                  />
                  <Route path="/Locations" element={<LocationList />} />
                  <Route path="/AddLocation" element={<AddLocation />} />
                  <Route
                    path="/EditLocation/:locationID"
                    element={<Editlocation />}
                  />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/setting" element={<Setting />} />
                  <Route path="/SrtripeReturn" element={<ReturnStripe />} />
                  <Route path="/SrtripeRefresh" element={<RefreshStripe />} />

                  <Route
                    path="/edit-profile"
                    element={<EditProfileBusiness myFunc={recivedData} />}
                  />

                  <Route
                    path="/vehicle/:vehicleID"
                    element={<VehicleDetailPage />}
                  />
                  <Route
                    path="/vehicleAvailability/:vehicleID"
                    element={<VehicleAvailability />}
                  />
                  <Route path="/Drivers" element={<Drivers />} />
                  <Route path="/add-driver" element={<AddDriver />} />
                  <Route
                    path="/edit-driver/:driverID"
                    element={<EditDriver />}
                  />
                  <Route path="/home" element={<VehicleListing />} />
                  <Route
                    path="/vehicle-detail/:vehicleID"
                    element={<VehicleDetail />}
                  />
                  <Route
                    path="/reservations_all"
                    element={<ReservationList />}
                  />
                  <Route
                    path="/reservations_detail/:reservationID"
                    element={<ReservationDetailPage />}
                  />

                  <Route path="/Reservations" element={<Reservation />} />
                  <Route
                    path="/ReservationDetails/:reservationID"
                    element={<ReservationDetails />}
                  />
                  <Route path="/Chat/:reservationID" element={<Chat />} />
                  <Route
                    path="/ownerChat/:reservationID"
                    element={<OwnerChat />}
                  />
                  <Route
                    path="/renter-support-ticket/:reservationID"
                    element={<SupportTicket />}
                  />
                  <Route
                    path="/add-ticket/:reservationID"
                    element={<AddTicket />}
                  />
                </Routes>

                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
