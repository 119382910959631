import SidebarOwnerDesktop from "./Owner/SidebarOwneDesktop";
import SidebarOwner from "./Owner/SidebarOwnerMobile";
import SidebarRenterDesktop from "./Renter/SidebarRenterDesktop";
import SidebarRenterMobile from "./Renter/SidebarRenterMobile";

const CheckRole = ({ role,type}) => {
    if (role=="Owner Business" || role=="Owner Individual") {
        if (type=="mobile") {
            return <SidebarOwner />;
        }else if (type=="desktop") {
            return <SidebarOwnerDesktop/>;
        }
    }else if (role=="Renter") {
        if (type=="mobile") {
            return <SidebarRenterMobile />;
        }else if (type=="desktop") {
            return <SidebarRenterDesktop/>;
        }
    }
      
      };
      export default CheckRole;