import {
  CheckCircleIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { Link } from "react-router-dom";
import BarSkeleton from "../../Global/Skeleton/BarSkeleton";
import { Switch } from "@headlessui/react";
import { Toaster, toast } from "sonner";
import { loadStripe } from "@stripe/stripe-js";
import { CheckBadgeIcon, StarIcon } from "@heroicons/react/24/solid";
import LoaderSpinner from "../../Global/Skeleton/LoaderSpinner";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

const Drivers = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [allDrivers, setAllDrivers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alreadyDefaultSet, setAlreadyDefaultSet] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [stateUpdated, setStateUpdated] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const initializeStripe = async () => {
      const stripeObject = await loadStripe(
        "pk_live_51McDWdLUqlbLOiA4OFtWtx9aK2FQVZ3WaGYoRBLjfiVUOFMHaJhr53SqWZlsHsKqxbLHBu2Z6W58L9sUBT1yYKEF00x1WJTl0W"
      );
      setStripe(stripeObject);
    };

    initializeStripe();
  }, [allDrivers]);

  

  const handleVerification = async (name) => {
    // event.preventDefault();
    setLoader(true);

    if (!stripe) {
      return;
    }
    try {
      // Call your backend to create the VerificationSession.
      const response = await axios.post(
        `${window.$backendURL}/api/method/truckrent.api.get_client_secret`,
        {
          type: "Driver",
          driver_profile: name,
        }
      );
      console.log("stripeResponse", response.data.data.key);
      const session = await response;
      console.log("stripeResponse", session);

      setTimeout(() => {
        setLoader(false);
      }, 2000);
      // Show the verification modal.
      const { error } = await stripe.verifyIdentity(
        session.data.data.key.client_secret
      );

      if (error) {
        console.error("[error]", error);
        toast.error("Driver Verificaiton Submitted Failed");
      } else {
        toast.success("Driver Verificaiton Submitted");
        console.log("Verification submitted!");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating Verification Session:", error);
      toast.error("Error creating Verification Session");
    }
  };
  useEffect(() => {
    const getDrivers = async () => {
      setLoading(true);
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Driver Profiles?fields=["*"]&filters=[["driver_for_renter","=","${localStorage.userID}"]]&order_by=first_name asc`,
            config
          )
          .then((response) => {
            setAlreadyDefaultSet(false);
            setAllDrivers(response.data.data);
            console.log(response.data.data);
            setLoading(false);
            response.data.data.forEach((element) => {
              if (element.is_default === 1) {
                setAlreadyDefaultSet(true);
              }
            });
          });
      } catch (e) {
        console.error(e);
      }
    };
    getDrivers();
  }, [stateUpdated]);

  const handleDefaultDriverToggle = (e, driver_id) => {
    if (e === true) {
      if (alreadyDefaultSet === true) {
        toast.error("Default driver already exsists");
      } else {
        var jsonUpdate = {
          is_default: 1,
        };
        axios
          .put(
            `${window.$backendURL}/api/resource/Driver Profiles/${driver_id}`,
            jsonUpdate,
            config
          )
          .then(() => {
            toast.success("Default Driver Updated");
            setStateUpdated(!stateUpdated);
          })
          .catch((e) => {
            toast.error("Operation Failed");
            console.log(e);
          });
      }
    } else if (e === false) {
      var jsonUpdate = {
        is_default: 0,
      };
      axios
        .put(
          `${window.$backendURL}/api/resource/Driver Profiles/${driver_id}`,
          jsonUpdate,
          config
        )
        .then((res) => {
          toast.success("Default Driver Updated");
          setStateUpdated(!stateUpdated);
        })
        .catch((e) => {
          toast.error("Operation Failed");
          console.log(e);
        });
    } else {
      toast.error("This Profile is not Verified");
    }
  };
  return (
    <>
      <Toaster richColors />
      <div className="h-screen">
        <div className="flex justify-between mb-4 mx-auto w-full">
          <h1 className="text-2xl font-semibold text-gray-900">Drivers</h1>
          <Link to="/add-driver">
            <button className="flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
              <div>
                <PlusIcon className="w-5 text-white h-5" />
              </div>

              <div>Add Driver</div>
            </button>
          </Link>
        </div>
        <div>
          <div className="px-4 sm:px-6 lg:px-0">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 ">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          License Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Verification
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Default Driver
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {allDrivers?.map((driver) => (
                        <tr key={driver.name} className="">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 ">
                            {driver.first_name + " " + driver.last_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {driver.contact_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {driver.license_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <p
                              className={`inline-flex rounded-full ${
                                driver?.is_verified == 0
                                  ? "bg-red-100"
                                  : "bg-green-100"
                              } px-4 text-xs  font-semibold leading-5  ${
                                driver?.is_verified == 0
                                  ? "text-red-800"
                                  : "text-green-800"
                              } items-center`}
                            >
                              {driver?.is_verified == 1
                                ? "Verified"
                                : "Not Verified"}
                            </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="lg:ml-5 lg:inline-flex ">
                              <Switch
                                checked={() => {
                                  if (driver.is_verified == 1) {
                                    if (driver.is_default === 1) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                }}
                                onChange={(e) => {
                                  var status;
                                  if (driver.is_verified == 1) {
                                    if (driver.is_default === 1) {
                                      status = false;
                                    } else {
                                      status = true;
                                    }
                                  }
                                  handleDefaultDriverToggle(
                                    status,
                                    driver.name
                                  );
                                }}
                                className={classNames(
                                  driver.is_default === 1
                                    ? "bg-purple-600"
                                    : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2  border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                                )}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    driver.is_default === 1
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="flex gap-x-2">
                              <Link to={"/edit-driver/" + driver.name}>
                                <button className="flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
                                  <PencilIcon className="w-5" /> Edit
                                </button>
                              </Link>
                              {driver?.is_verified == 0 ? (
                                <button
                                  role="link"
                                  disabled={!stripe}
                                  onClick={(e) =>
                                    handleVerification(driver.name)
                                  }
                                  className="flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                                >
                                  Verify
                                </button>
                              ) : (
                                <button
                                  disabled
                                  className="flex gap-1 justify-between rounded-md border border-transparent   px-4 text-sm font-medium text-green-400 "
                                >
                                  <CheckBadgeIcon className="w-8 h-8" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader ? <LoaderSpinner /> : ""}
    </>
  );
};

export default Drivers;
