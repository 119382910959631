import React, { useEffect, useRef, useState } from "react";

import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
  TruckIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { StarIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { PencilOutline } from "heroicons-react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import OwnerVehicleDetailSkeleton from "../../Global/Skeleton/OwnerVehicleDetailSkeleton";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Tab } from "@headlessui/react";
import { toast } from 'sonner';
import { Toaster } from 'sonner';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VehicleDetailPage = () => {
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [isLocation, setIsLocation] = useState(null);
  const [loader, setLoader] = useState(true);
  const { vehicleID } = useParams();
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const topRef = useRef(null);

  useEffect(() => {
      
      if (topRef.current) {
          topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
  }, []); 




  // Get Vehicle to View
  useEffect(() => {
    const GetVehicle = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/resource/Vehicle/${vehicleID}`,
            config
          )
          .then((response) => {
            setCurrentVehicle(response.data.data);
            console.log("vehicleDetails>>>>>>>", response.data.data);
            const GetAllLocations = async () => {
              try {
                await axios
                  .get(
                    `${window.$backendURL}/api/resource/Location/${response.data.data.pick_up}`,
                    config
                  )
                  .then((response) => {
                    console.log("VehicleLocation>>>>>", response.data.data);
                    setIsLocation(response.data.data);
                    setLoader(false);
                  });
              } catch (e) {
                console.error(e);
              }
            };
            if (
              response.data.data.pick_up != "" ||
              response.data.data.pick_up != null
            ) {
              GetAllLocations();
            }
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetVehicle();
  }, []);


  const link = `https://beta.rentmytruck.co/homepage-vehicle-details/${vehicleID}`;

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success('Link Copied');
    })
  };

 

  return (
    <>
    <Toaster richColors/>
      {loader ? (
        <OwnerVehicleDetailSkeleton />
      ) : (
        <div className="min-h-full" ref={topRef}>
          <main className="mt-0 ">
            <div className="mx-0 w-full lg:px-4 ">
              <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
                <div className="grid grid-cols- gap-4 lg:col-span-2">
                  <section aria-labelledby="profile-overview-title">
                    <Tab.Group
                      as="div"
                      className="flex flex-col-reverse lg:pr-4"
                    >
                      <div className="mx-auto lg:mt-6 mt-4 w-full max-w-2xl sm:block lg:max-w-none">
                        <Tab.List className="grid grid-cols-4 lg:gap-6 gap-3">
                          {currentVehicle?.attach_images.length > 0 ? (
                            currentVehicle?.attach_images?.map((image) => (
                              <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                                {({ selected }) => (
                                  <>
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                      <img
                                        src={
                                          image.image
                                            ? window.$backendURL + image.image
                                            : "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                        }
                                        alt=""
                                        className="h-full w-full object-contain"
                                      />
                                    </span>
                                    <span
                                      className={
                                        selected
                                          ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                          : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                      }
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </Tab>
                            ))
                          ) : (
                            <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                              {({ selected }) => (
                                <>
                                  <span className="absolute inset-0 overflow-hidden rounded-md">
                                    <img
                                      src={
                                        "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                      }
                                      alt=""
                                      className="h-full w-full object-contain"
                                    />
                                  </span>
                                  <span
                                    className={
                                      selected
                                        ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                        : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                    }
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </Tab>
                          )}
                        </Tab.List>
                      </div>

                      <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                        {currentVehicle?.attach_images.length > 0 ? (
                          currentVehicle?.attach_images?.map((image) => (
                            <Tab.Panel>
                              <img
                                src={window.$backendURL + image.image}
                                alt=""
                                className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                              />
                            </Tab.Panel>
                          ))
                        ) : (
                          <Tab.Panel>
                            <img
                              src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                              alt=""
                              className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                            />
                          </Tab.Panel>
                        )}
                      </Tab.Panels>
                    </Tab.Group>
                  </section>

                  <section aria-labelledby="quick-links-title">
                    <div className=" overflow-hidden rounded-lg  py-2">
                      <div className=" flex justify-between flex-col">
                        <div className="flex items-center justify-between pr-5 mb-4">
                          <div className="flex items-center gap-x-2">
                            <span className="font-medium text-sm">
                              Availability Status
                            </span>
                            <span className="text-sm bg-yellow-100 text-yellow-800 px-5  rounded-full font-medium">
                              {" "}
                              {currentVehicle?.availability_status}
                            </span>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <span className="font-medium text-sm">
                              Approval Status
                            </span>
                            <span className="text-sm bg-green-100 text-green-800 px-5  rounded-full font-medium">
                              {" "}
                              {currentVehicle?.approval_status}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-x-5 pr-5">
                          <h2
                            className="text-2xl font-semibold  text-gray-900"
                            id="announcements-title"
                          >
                            {currentVehicle?.year +
                              ", " +
                              currentVehicle?.make +
                              " " +
                              currentVehicle?.model}
                          </h2>
                          <div className="flex items-center gap-x-2">
                            <Link to={`/editVehicle/${vehicleID}`}>
                              <button className="bg-purple-500 px-4 py-1.5 rounded-md text-white tracking-wide font-medium">
                                Edit Vehicle
                              </button>
                            </Link>
                            <button
                              className="bg-purple-500 px-4 py-1.5 rounded-md text-white tracking-wide font-medium"
                              onClick={() => copyToClipboard(link)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#FFFFFF"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                              </svg>
                            </button>
                          </div>
                        </div>

                        {/* <div className="flex my-4 items-center gap-4">
                      <div className="text-sm font-semibold">4.5</div>
                      <div className="text-sm font-semibold w-6 h-6">
                        <StarIcon />
                      </div>

                      <div>
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          23 Trips
                        </span>
                      </div>
                      <div className="w-6 h-6">
                        <MapPinIcon />
                      </div>
                      <div className="text-sm font-semibold">2.3 miles</div>
                    </div> */}

                        <div className="mt-2">
                          <div className="flex  items-center gap-x-2 lg:gap-2">
                            <div className="text-sm bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
                              {currentVehicle?.fuel_type}
                            </div>

                            <div className="text-sm bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
                              {currentVehicle?.lift_gate}
                            </div>
                          </div>
                        </div>

                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Specifications
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.e_truck === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.e_truck === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.e_truck === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">E-Truck</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.ramp === 1 ? `purple` : `gray`
                              }-100 text-${
                                currentVehicle?.ramp === 1 ? `purple` : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.ramp === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Ramp</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.crew_cab === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.crew_cab === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.crew_cab === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Crew Cab</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.dock_height === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.dock_height === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.dock_height === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Dock Height</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.multi_temp === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.multi_temp === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.multi_temp === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Multi - Temp</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.apu === 1 ? `purple` : `gray`
                              }-100 text-${
                                currentVehicle?.apu === 1 ? `purple` : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.apu === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">APU</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.storage_only === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.storage_only === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.storage_only === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Storage Only</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.has_ifta === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.has_ifta === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.has_ifta === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Has IFTA</span>{" "}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Door Type
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <span className="text-base py-1 flex items-center  text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                              >
                                <path d="M140.001-140.001V-200h80v-547.691q0-30.308 21-51.308t51.308-21h375.382q30.308 0 51.308 21t21 51.308V-200h80v59.999H140.001ZM280-200h400v-547.691q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H292.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463V-200Zm115.384-244.616q14.692 0 25.038-10.346 10.347-10.346 10.347-25.038t-10.347-25.038q-10.346-10.346-25.038-10.346t-25.038 10.346Q360-494.692 360-480t10.346 25.038q10.346 10.346 25.038 10.346ZM280-760v560-560Z" />
                              </svg>{" "}
                              {currentVehicle?.door_type}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Usage Restrictions
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <span className="text-base py-1  text-gray-500">
                              {currentVehicle?.usage_restrictions}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Description
                          </h1>
                          <p className="text-base text-gray-500  my-1">
                            {currentVehicle?.description}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col lg:mt-3 mt-2">
                        <h1 className="text-lg font-semibold text-gray-800">
                          Location
                        </h1>
                        <div className="text-base font-medium mt-1 text-gray-700">
                          {isLocation?.address}
                        </div>
                        <div className="text-base font-normal mb-1 text-gray-500">
                          {isLocation?.other_details &&
                            `(${isLocation?.other_details})`}
                        </div>
                      </div>
                      <iframe
                        className="w-full rounded-lg h-60 sm:h-96"
                        marginheight="0"
                        marginwidth="0"
                        src={`https://maps.google.com/maps?q=${
                          isLocation?.coordinates?.split(",")[0]
                        },${
                          isLocation?.coordinates?.split(",")[1]
                        }&hl=en&z=14&amp&output=embed`}
                      ></iframe>
                      {/* <div className="flex flex-col mt-6 ">
                      <div className="text-lg font-semibold my-2 ">
                        Business hours
                      </div>
                      <div className="text-sm text-gray-500 font-medium">
                        {currProfile?.business_hours}
                      </div>
                    </div> */}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default VehicleDetailPage;
