import { PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";

const BarSkeleton = () => {
  return (
    <div role="status" className="animate-pulse w-full">
      <div className="block mb-4 bg-white rounded-xl">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div className="flex flex-col gap-y-2">
                <p className="truncate text-sm font-semibold text-gray-600">
                  <div className="h-6 w-40 bg-gray-100 rounded-full dark:bg-gray-300"></div>
                </p>
                <p className="truncate text-sm font-semibold text-gray-600">
                  <div className="h-3 w-60 bg-gray-100 rounded-full dark:bg-gray-300"></div>
                </p>
              </div>
              <div className="flex items-center mt-4 md:mt-0">
                <div className="flex gap-1">
                  <PhoneIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="truncate flex items-center text-sm font-semibold text-gray-600">
                    <div className="h-4 w-32 sm:w-16 bg-gray-100 rounded-full dark:bg-gray-300"></div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <span className="flex justify-between rounded-md border border-transparent button py-2 px-4 h-9 sm:w-20 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"></span>
        </div>
      </div>
    </div>
  );
};

export default BarSkeleton;
