import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux'
import store from '../src/app/store';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

window.$backendURL="https://api.rentmytruck.co"
// Local
// window.$FWDurl='http://localhost:3000';
// window.$ClientID='5533fe7dd4';
// Live
window.$FWDurl='https://beta.rentmytruck.co';
window.$ClientID='f63416fcfc';
const stripePromise = loadStripe('pk_live_51McDWdLUqlbLOiA4OFtWtx9aK2FQVZ3WaGYoRBLjfiVUOFMHaJhr53SqWZlsHsKqxbLHBu2Z6W58L9sUBT1yYKEF00x1WJTl0W');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Elements stripe={stripePromise}>

  <Provider store={store}>
    <App />
    </Provider>
  </Elements>

  // </React.StrictMode>
);



