import { TrashIcon } from "@heroicons/react/20/solid";
import axios, { all } from "axios";
import { useFormik } from "formik";
import { Upload } from "heroicons-react";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";

const PickupInspectionAccordian = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [odometerImage, setOdometerImage] = useState("");
  const [fuelGaugeImage, setFuelGaugeImage] = useState("");
  const [allReservation, setAllReservation] = useState(null);
  const { reservationID } = useParams();
  const [loading, setLoading] = useState(false);
  const [reservationChange, setReservationChange] = useState(false);
  useEffect(() => {
    const handleReservation = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/reservation-contract-details?id=${reservationID}`,
            config
          )
          .then((response) => {
            console.log(response.data?.data);
            setAllReservation(response.data?.data);
            setReeferImage(
              response.data?.data?.reservation?.renter_reefer_meter_picture
            );
            setOdometerImage(
              response.data?.data?.reservation?.pickup_renter_odometer_picture
            );
            setFuelGaugeImage(
              response.data?.data?.reservation?.pickup_renter_fuel_gauge_picture
            );
            setHubdometerImage(
              response.data?.data?.reservation?.renter_hubdometer_picture
            );
            setLoading(false);
            setReservationChange(false);
          });
      } catch (error) {
        console.log(error);
      }
    };
    handleReservation();
  }, [reservationChange]);

  const OdometerReadingFrom = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const json = {
        pickup_renter_odometer_reading: values.pickup_renter_odometer_reading,
        pickup_renter_fuel_gauge_reading:
          values.pickup_renter_fuel_gauge_reading,
      };
      console.log(json);
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            json,
            config
          )
          .then((response) => {
            setReservationChange(true);
            setAllReservation(response?.data?.data);
            toast.success("Odometer & Fuel Gauge Reading Added Successfully");
            setIsOpen1(false);
            setIsOpen2(true);
            console.log(response);
          });
      } catch (error) {
        console.log(error);
      }
    },
    enableReinitialize: true,
  });

  console.log(allReservation);

  //Upload Images

  const handleOdometerImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setOdometerImage(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleFuelGaugeImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setFuelGaugeImage(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const OdometerImagesUploadForm = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const json = {
        pickup_renter_odometer_picture: odometerImage,
        pickup_renter_fuel_gauge_picture: fuelGaugeImage,
      };
      console.log(json);
      if (odometerImage !== "" && fuelGaugeImage !== "") {
        try {
          await axios
            .put(
              `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
              json,
              config
            )
            .then((response) => {
              setReservationChange(true);
              setAllReservation(response?.data?.data);
              toast.success(
                "Odometer & Fuel Gauge Images Uploaded Successfully"
              );
              setIsOpen2(false);
              setIsOpen3(true);
              console.log(response);
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Image Not Selected");
      }
    },
    enableReinitialize: true,
  });

  const [reeferImage, setReeferImage] = useState("");

  const handleReeferImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setReeferImage(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const ReeferForm = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const json = {
        renter_reefer_hours: values.renter_reefer_hours,
        renter_reefer_meter_picture: reeferImage,
      };
      console.log(json);
      if (reeferImage !== "") {
        try {
          await axios
            .put(
              `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
              json,
              config
            )
            .then((response) => {
              setReservationChange(true);
              setAllReservation(response?.data?.data);
              toast.success("Reefer Hours & Image Uploaded Successfully");
              setIsOpen3(false);
              // setIsOpen3(true);
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Image Not Selected");
      }
    },
    enableReinitialize: true,
  });

  const [hubdometerImage, setHubdometerImage] = useState("");

  const handleHubdometerImage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setHubdometerImage(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const HubdoMeterForm = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const json = {
        renter_hubdometer_reading: values.renter_hubdometer_reading,
        renter_hubdometer_picture: hubdometerImage,
      };
      console.log(json);
      if (hubdometerImage !== "") {
        try {
          await axios
            .put(
              `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
              json,
              config
            )
            .then((response) => {
              setReservationChange(true);
              setAllReservation(response?.data?.data);
              toast.success("Hubdometer Reading & Image Uploaded Successfully");
              setIsOpen4(false);
              // setIsOpen3(true);
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Image Not Uploaded");
      }
    },
    enableReinitialize: true,
  });

  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
  };

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen1(false);
    setIsOpen3(false);
    setIsOpen4(false);
  };

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen4(false);
  };

  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  return (
    <>
      <Toaster richColors />
      <div className="max-w-full mx-auto mt-5">
        <div className="border rounded-lg mb-4">
          <div
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={toggleAccordion1}
          >
            <span className="flex items-center gap-x-2 text-lg font-semibold text-gray-700">
              Odometer & Fuel Gauge Reading
              {allReservation?.reservation?.pickup_renter_odometer_reading >
                0 &&
              allReservation?.reservation?.pickup_renter_fuel_gauge_reading >
                0 ? (
                loading ? (
                  <Skeleton width={75} height={20} />
                ) : (
                  <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                    Completed
                  </span>
                )
              ) : loading ? (
                <Skeleton width={75} height={20} />
              ) : (
                <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                  Incompleted
                </span>
              )}
            </span>
            <svg
              className={`w-6 h-6 transition-transform  ${
                isOpen1 ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {isOpen1 && (
            <form onSubmit={OdometerReadingFrom.handleSubmit}>
              <div className="py-2 px-4">
                <div className="mb-2 font-semibold text-sm text-gray-600">
                  <span>
                    Note: Trailers used for storage will not accrue mileage
                    charges.
                  </span>
                </div>
                <p className="text-lg font-semibold mb-2 text-gray-800">
                  Please enter the Odometer & Fuel Gauge Reading to record the
                  mileage used during your trip.
                </p>
                <div className="flex flex-col w-full my-4 -px-1">
                  <label
                    for="pickup_renter_odometer_reading"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Odometer Reading (km)
                  </label>
                  <input
                    disabled={
                      allReservation?.reservation
                        ?.pickup_renter_odometer_reading > 0
                    }
                    type="number"
                    id="pickup_renter_odometer_reading"
                    name="pickup_renter_odometer_reading"
                    value={OdometerReadingFrom.pickup_renter_odometer_reading}
                    onChange={OdometerReadingFrom.handleChange}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 ${
                      allReservation?.reservation
                        ?.pickup_renter_odometer_reading > 0 &&
                      "cursor-not-allowed "
                    }`}
                    placeholder={
                      allReservation?.reservation
                        ?.pickup_renter_odometer_reading > 0
                        ? allReservation?.reservation
                            ?.pickup_renter_odometer_reading
                        : "Enter odometer reading"
                    }
                    required
                  />
                </div>
                <div className="flex flex-col w-full my-4 -px-1">
                  <label
                    for="pickup_renter_fuel_gauge_readingpickup_renter_odometer_reading"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Fuel Gauge Reading (liters)
                  </label>
                  <input
                    disabled={
                      allReservation?.reservation
                        ?.pickup_renter_fuel_gauge_reading > 0
                    }
                    type="number"
                    id="pickup_renter_fuel_gauge_reading"
                    name="pickup_renter_fuel_gauge_reading"
                    value={OdometerReadingFrom.pickup_renter_fuel_gauge_reading}
                    onChange={OdometerReadingFrom.handleChange}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 ${
                      allReservation?.reservation
                        ?.pickup_renter_fuel_gauge_reading > 0 &&
                      "cursor-not-allowed "
                    }`}
                    placeholder={
                      allReservation?.reservation
                        ?.pickup_renter_fuel_gauge_reading > 0
                        ? allReservation?.reservation
                            ?.pickup_renter_fuel_gauge_reading
                        : "Enter fuel gauge reading"
                    }
                    required
                  />
                </div>
                <div className="flex items-end justify-end w-full my-4 -px-1">
                  {allReservation?.reservation?.pickup_renter_odometer_reading >
                    0 &&
                  allReservation?.reservation
                    ?.pickup_renter_fuel_gauge_reading > 0 ? (
                    <button
                      onClick={() => toggleAccordion2()}
                      type="button"
                      className="h-11 bg-purple-500 rounded-md px-7 text-white font-semibold hover:bg-purple-600"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="h-11 bg-purple-500 rounded-md px-5 text-white font-semibold hover:bg-purple-600"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="border rounded-lg mb-4">
          <div
            className={`flex justify-between items-center p-4 cursor-pointer 
            `}
            onClick={toggleAccordion2}
          >
            <span className="flex items-center gap-x-2 text-lg font-semibold text-gray-700">
              Odometer & Fuel Gauge Images Upload
              {allReservation?.reservation?.pickup_renter_odometer_picture !=
                null &&
              allReservation?.reservation?.pickup_renter_fuel_gauge_picture !=
                null ? (
                loading ? (
                  <Skeleton width={75} height={20} />
                ) : (
                  <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                    Completed
                  </span>
                )
              ) : loading ? (
                <Skeleton width={75} height={20} />
              ) : (
                <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                  Incompleted
                </span>
              )}
            </span>
            <svg
              className={`w-6 h-6 transition-transform ${
                isOpen2 ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {isOpen2 && (
            <div className="p-4 pb-8">
              <form onSubmit={OdometerImagesUploadForm.handleSubmit}>
                <div className="flex justify-between items-center px-10">
                  {odometerImage !== null ? (
                    <div className="relative">
                      <img
                        src={`${window.$backendURL}${odometerImage}`}
                        className="rounded-md w-60 h-36"
                      />
                      <button
                      type="button"
                        className="absolute -top-2 -right-2 bg-purple-500 rounded-full p-1 "
                        onClick={() => setOdometerImage(null)}
                      >
                        <TrashIcon className="w-6 h-6 text-white" />
                      </button>
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="file-input-img"
                        className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                      >
                        <Upload className="h-8 w-8" />
                        <span>Upload Odometer Image</span>
                      </label>
                      <input
                       
                        accept="image/*"
                        type="file"
                        name="file-input-img"
                        id="file-input-img"
                        onChange={handleOdometerImageUpload}
                        className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                      />
                    </div>
                  )}
                  {fuelGaugeImage !== null ? (
                    <div className="relative">
                      <img
                        src={`${window.$backendURL}${fuelGaugeImage}`}
                        className="rounded-md w-60 h-36"
                      />
                      <button
                      type="button"
                        className="absolute -top-2 -right-2 bg-purple-500 rounded-full p-1"
                        onClick={() => setFuelGaugeImage(null)}
                      >
                        <TrashIcon className="w-6 h-6 text-white" />
                      </button>
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="file-input-img2"
                        className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                      >
                        <Upload className="h-8 w-8" />
                        <span>Upload Fuel Gauge Image</span>
                      </label>
                      <input
                        
                        accept="image/*"
                        type="file"
                        name="file-input-img2"
                        id="file-input-img2"
                        onChange={handleFuelGaugeImageUpload}
                        className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                      />
                    </div>
                  )}
                </div>
                
                <div className="pt-10 flex items-center justify-end px-10">
                  {allReservation?.reservation?.pickup_renter_odometer_picture !=
                null &&
              allReservation?.reservation?.pickup_renter_fuel_gauge_picture !=
                null
                   ? (
                    <button
                      onClick={() => toggleAccordion3()}
                      type="button"
                      className="h-11 bg-purple-500 rounded-md px-7 text-white font-semibold hover:bg-purple-600"
                    >
                      Next
                    </button>
                  ) :  (
                    <button
                      type="submit"
                      className="h-11 bg-purple-500 rounded-md px-5 text-white font-semibold hover:bg-purple-600"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="border rounded-lg mb-4">
          <div
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={toggleAccordion3}
          >
            <span className="flex items-center gap-x-2 text-lg font-semibold text-gray-700">
              Reefer Reading
              {allReservation?.reservation?.renter_reefer_hours > 0 &&
              allReservation?.reservation?.renter_reefer_meter_picture !=
                null ? (
                loading ? (
                  <Skeleton width={75} height={20} />
                ) : (
                  <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                    Completed
                  </span>
                )
              ) : loading ? (
                <Skeleton width={75} height={20} />
              ) : (
                <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                  Incompleted
                </span>
              )}
            </span>
            <svg
              className={`w-6 h-6 transition-transform  ${
                isOpen1 ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {isOpen3 && (
            <form onSubmit={ReeferForm.handleSubmit}>
              <div className="py-2 px-4">
                <div className="mb-2 font-semibold text-sm text-gray-600">
                  <span>
                    Note: Trailers used for storage will not accrue mileage
                    charges.
                  </span>
                </div>
                <p className="text-lg font-semibold mb-2 text-gray-800">
                  Please enter the Reefer Hours & Image to record the mileage
                  used during your trip.
                </p>
                <div className="flex flex-col w-full my-4 -px-1">
                  <label
                    for="dropoff_owner_odometer_reading"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Reefer Hours
                  </label>
                  <input
                    disabled={
                      allReservation?.reservation?.renter_reefer_hours > 0
                    }
                    type="number"
                    id="renter_reefer_hours"
                    name="renter_reefer_hours"
                    value={ReeferForm.renter_reefer_hours}
                    onChange={ReeferForm.handleChange}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 ${
                      allReservation?.reservation?.renter_reefer_hours > 0 &&
                      "cursor-not-allowed "
                    }`}
                    placeholder={
                      allReservation?.reservation?.renter_reefer_hours > 0
                        ? allReservation?.reservation?.renter_reefer_hours
                        : "Enter reefer hours"
                    }
                    required
                  />
                </div>
                <div className="flex items-center justify-center">
                  {reeferImage ? (
                    <img
                      src={`${window.$backendURL}${reeferImage}`}
                      className="w-56 h-32 object-cover object-center rounded-md"
                    />
                  ) : (
                    <>
                      <label
                        htmlFor="file-input-img4"
                        className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                      >
                        <Upload className="h-8 w-8" />
                        <span>Upload Reefer Image</span>
                      </label>
                      <input
                        accept="image/*"
                        type="file"
                        name="file-input-img4"
                        id="file-input-img4"
                        onChange={handleReeferImageUpload}
                        className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                      />
                    </>
                  )}
                </div>
                <div className="flex items-end justify-end w-full my-4 -px-1">
                  {allReservation?.reservation?.renter_reefer_hours > 0 &&
                  allReservation?.reservation?.renter_reefer_meter_picture !=
                    null ? (
                    <button
                      onClick={() => toggleAccordion3()}
                      type="button"
                      className="h-11 bg-purple-500 rounded-md px-7 text-white font-semibold hover:bg-purple-600"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="h-11 bg-purple-500 rounded-md px-5 text-white font-semibold hover:bg-purple-600"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="border rounded-lg mb-4">
          <div
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={toggleAccordion4}
          >
            <span className="flex items-center gap-x-2 text-lg font-semibold text-gray-700">
              Hubometer Reading
              {allReservation?.reservation?.renter_hubdometer_reading > 0 &&
              allReservation?.reservation?.renter_hubdometer_picture != null ? (
                loading ? (
                  <Skeleton width={75} height={20} />
                ) : (
                  <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                    Completed
                  </span>
                )
              ) : loading ? (
                <Skeleton width={75} height={20} />
              ) : (
                <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                  Incompleted
                </span>
              )}
            </span>
            <svg
              className={`w-6 h-6 transition-transform  ${
                isOpen1 ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {isOpen4 && (
            <form onSubmit={HubdoMeterForm.handleSubmit}>
              <div className="py-2 px-4">
                {/* <div className="mb-2 font-semibold text-sm text-gray-600">
                  <span>
                    Note: Trailers used for storage will not accrue mileage
                    charges.
                  </span>
                </div>
                <p className="text-lg font-semibold mb-2 text-gray-800">
                  Please enter the Reefer Hours & Image to record the mileage
                  used during your trip.
                </p> */}
                <div className="flex flex-col w-full pb-4 -px-1">
                  <label
                    for="dropoff_owner_odometer_reading"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Hubometer Reading (km)
                  </label>
                  <input
                    disabled={
                      allReservation?.reservation?.renter_hubdometer_reading > 0
                    }
                    type="number"
                    id="renter_hubdometer_reading"
                    name="renter_hubdometer_reading"
                    value={HubdoMeterForm.renter_hubdometer_reading}
                    onChange={HubdoMeterForm.handleChange}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 ${
                      allReservation?.reservation?.renter_hubdometer_reading >
                        0 && "cursor-not-allowed "
                    }`}
                    placeholder={
                      allReservation?.reservation?.renter_hubdometer_reading > 0
                        ? allReservation?.reservation?.renter_hubdometer_reading
                        : "Enter Hubdometer Reading"
                    }
                    required
                  />
                </div>
                <div className="flex items-center justify-center">
                  {hubdometerImage ? (
                    <img
                      src={`${window.$backendURL}${hubdometerImage}`}
                      className="w-56 h-32 object-cover object-center rounded-md"
                    />
                  ) : (
                    <>
                      <label
                        htmlFor="file-input-img4"
                        className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                      >
                        <Upload className="h-8 w-8" />
                        <span>Upload Hubometer Image</span>
                      </label>
                      <input
                        accept="image/*"
                        type="file"
                        name="file-input-img4"
                        id="file-input-img4"
                        onChange={handleHubdometerImage}
                        className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                      />
                    </>
                  )}
                </div>
                <div className="flex items-end justify-end w-full my-4 -px-1">
                  {allReservation?.reservation?.renter_hubdometer_reading > 0 &&
                  allReservation?.reservation?.renter_hubdometer_picture !=
                    null ? (
                    <button
                      onClick={() => toggleAccordion4()}
                      type="button"
                      className="h-11 bg-purple-500 rounded-md px-7 text-white font-semibold hover:bg-purple-600"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="h-11 bg-purple-500 rounded-md px-5 text-white font-semibold hover:bg-purple-600"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default PickupInspectionAccordian;
