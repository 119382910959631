import React from "react";
import {
    CheckIcon,
    HandThumbUpIcon,
    UserIcon,
    TruckIcon,
  } from "@heroicons/react/24/outline";
  
  import { PencilOutline } from "heroicons-react";
  import LoadingIcons from "react-loading-icons";
  
  import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
  import { StarIcon, MapPinIcon } from "@heroicons/react/24/outline";
  import { Tab } from "@headlessui/react";
const ReservationDetailPageSkeleton = () => {
  return (
    <div className="animate-pulse w-full">
      <div className="min-h-full">
        <main className="mt-0 pb-8">
          <div className="mx-0 w-full px-4 sm:px-0 ">
            <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
              {/* Left column */}
              <div className="grid grid-cols- gap-4 lg:col-span-2">
                {/* Welcome panel */}
                <section aria-labelledby="profile-overview-title">
                <Tab.Group as="div" className="flex flex-col-reverse pr-4">
                <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
             
                  <Tab
                    
                    className="relative flex h-32 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    
                      <>
                        
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png" alt="" className="h-full w-full object-cover" />
                        </span>
                        
                       
                      </>
                    
                  </Tab>
                
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
            
                <Tab.Panel >
                  <img
                    src='https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png'
                    alt=""
                    className="w-full object-contain bg-white rounded-lg "
                  />
                </Tab.Panel>
            
            </Tab.Panels>
          </Tab.Group>
                </section>

                {/* Actions panel */}
                <section aria-labelledby="quick-links-title">
                  <div className=" overflow-hidden rounded-lg p-4  ">
                    <div className=" flex justify-between flex-col">
                      <div className="flex mb-5"></div>
                      <div className="flex items-center gap-8">
                        <h2
                          className="text-2xl  text-gray-900"
                          id="announcements-title"
                        >
                          <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                        
                          <span className="flex h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                        </h2>
                      </div>

                      <div className="flex my-4 items-center gap-4">
                      <span className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></span>
                        <div className="text-sm font-semibold w-6 h-6">
                          <StarIcon />
                        </div>

                        <div>
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          <span className="h-4 w-20 bg-green-100 rounded-full dark:bg-gray-300"></span>
                          </span>
                        </div>
                        <div className="w-6 h-6">
                          <MapPinIcon />
                        </div>
                        <span className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></span>
                      </div>

                      <div className="my-3 lg:w-2/4 border-2 rounded-lg p-3 sm:w-full border-slate-300">
                        <div className="flex my-4 items-center justify-between">
                          <div className="flex gap-1 items-center">
                            <div className="text-sm font-semibold w-6 h-6">
                              <TruckIcon className="w-6 h-6" />
                            </div>
                            <div className="text-sm  text-gray-900">
                            <span className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></span>
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="w-6 h-6 flex justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Layer_1"
                                dataname="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" />
                              </svg>
                            </div>
                            <div className="text-sm  text-gray-900">
                            <span className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></span>
                            </div>
                          </div>
                          <div className="flex gap-1 items-center">
                            <div className="w-6 h-6">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Layer_1"
                                dataname="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23.5,23h-3.5V4.5c0-2.481-2.019-4.5-4.5-4.5h-7c-2.481,0-4.5,2.019-4.5,4.5V23H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5H23.5c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5ZM5,4.5c0-1.93,1.57-3.5,3.5-3.5h7c1.93,0,3.5,1.57,3.5,3.5V23H5V4.5Zm12,7.5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z" />
                              </svg>
                            </div>
                            <div className="text-sm  text-gray-900">
                            <span className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h1 className="text-lg py-3 font-semibold text-slate-500">
                          Description
                        </h1>
                        <p className="text-sm text-slate-700">
                        <span className="flex h-4 w-[20rem] bg-gray-200 rounded-full dark:bg-gray-300"></span>
                        </p>
                      </div>
                    </div>

                    {/* Location  */}
                    <div className="flex  flex-col mt-4">
                      <div className=" text-lg font-semibold  text-slate-500">
                        Location
                      </div>
                      <div className=" text-sm font-normal mt-3 mb-2">
                      <span className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></span>
                      </div>
                    </div>
                    {/* <img
                      className="w-full rounded-lg h-60 sm:h-96"
                      src="https://images.unsplash.com/photo-1569336415962-a4bd9f69cd83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hcHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    /> */}
                    <span className="flex h-[23rem] w-[90%] bg-gray-200 rounded-md dark:bg-gray-300"></span>

                    <div className="flex flex-col mt-6 ">
                      <div className="text-lg font-semibold my-2 ">
                        Business Hours
                      </div>
                      <div className="text-sm text-gray-500 font-medium">
                      <span className="flex h-4 w-[12rem] bg-gray-200 rounded-full dark:bg-gray-300"></span>
                      </div>
                      {/* <div className="text-sm text-gray-500 font-medium">
                        Saturday 8:00am at 8:00pm
                      </div> */}
                    </div>
                    <div className="flex flex-col my-4">
                      <div className="text-lg  font-semibold my-2">
                        Renter Information
                      </div>
                      <div className="text-sm text-gray-500 font-mediu mb-2">
                        
                        <span className="flex h-4 w-[12rem] bg-gray-200 rounded-full dark:bg-gray-300"></span>
                      </div>
                      <div className="text-sm text-gray-500 font-medium mb-2">
                        
                        <span className="flex h-4 w-[12rem] bg-gray-200 rounded-full dark:bg-gray-300"></span>
                      </div>
                      <div className="text-sm text-gray-500 font-medium mb-2" >
                       
                        <span className="flex h-4 w-[12rem] bg-gray-200 rounded-full dark:bg-gray-300"></span>
                      </div>
                    </div>
                    <div>
                    <span className="flex h-11 w-40 bg-purple-400 rounded-md dark:bg-purple-400"></span>
                    </div>
                  </div>
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4 py-3">
                {/* Announcements */}
                <section aria-labelledby="announcements-title">
                  <div className="overflow-hidden rounded-lg bg-white ">
                    <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 mb-5">
                        <div className="flex items-center">
                     

                         <span className="flex h-6 w-32 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                          <span className="ml-2 flex items-center h-4 w-20 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                      
                        
                        </div>
                      <div className="flex items-center justify-between">
                        {/* <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"> */}
                        <span className="h-4 w-28 bg-green-200 rounded-full dark:bg-green-300 mb-2"></span>
                        {/* </span> */}
                        <span>
                        <span className="flex h-4 w-28 bg-green-200 rounded-full dark:bg-green-300 mb-2"></span>
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-semibold mb-3">
                        <span className="flex h-5 w-32 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                        </span>
                        <span className="text-gray-500">
                        <span className="flex h-4 w-24 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex flex-col gap-1">
                          <span className=" font-semibold p-1">
                          <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                          </span>
                          <span className="text-gray-500">
                          <span className="ml-3 flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                          </span>
                        </div>
                        <div className="flex flex-col gap-1">
                          <span className=" font-semibold p-1">
                          <span className="mr-3 flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                          </span>
                          <span className="text-gray-500">
                          <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                          </span>
                        </div>
                      </div>
                      <div className=" flex flex-col justify-between gap-y-4">
                        <div
                          className="flex flex-col p-5 border-2 mt-4 rounded-lg"
                          style={{
                            backgroundColor: "#F9F8FC",
                            borderColor: "#EDEBF3",
                          }}
                        >
                          <div
                            className="text-left font-semibold text-lg pb-2 border-b-2 "
                            style={{ borderColor: "#EDEBF3" }}
                          >
                             <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                          </div>
                          <div
                            className="py-4 border-b-2"
                            style={{ borderColor: "#EDEBF3" }}
                          >
                            <div className="flex  justify-between   ">
                              <div className="text-left my-2">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                              <div className="flex justify-center items-center">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                            </div>
                           
                              <div className="flex  justify-between   ">
                                <div className="text-left"> <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span></div>
                                <div className="flex justify-center items-center">
                                <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                                </div>
                              </div>
                            
                          </div>

                          <div
                            className="py-4   border-b-2  "
                            style={{ borderColor: "#EDEBF3" }}
                          >
                            <div className="flex  justify-between   ">
                              <div className="text-left my-2">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                              <div className="flex justify-center items-center">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                            </div>
                            <div className="flex  justify-between   ">
                              <div className="text-left my-2">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                              <div className="flex justify-center items-center">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                            </div>
                            <div className="flex  justify-between   ">
                              <div className="text-left font-bold my-2">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                              <div className="flex justify-center font-bold items-center">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                            </div>
                            {/* <div className="flex  justify-between   ">
                              
                              <div className="text-left my-2">
                                Insurance & roadside
                              </div>
                              <div className="flex justify-center items-center">
                                {"$" + roadSide}
                              </div>
                            </div> */}
                            {/* <div className="flex  justify-between   ">
                              <div className="text-left">Taxes & Fees</div>
                              <div className="flex justify-center items-center">
                                {"$"+ tax}
                              </div>
                            </div> */}
                          </div>
                          <div
                            className="flex py-4 justify-between  border-b-2 "
                            style={{ borderColor: "#EDEBF3" }}
                          >
                            <div className="flex flex-col">
                              <div className="text-left my-2 flex items-top gap-1">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                                
                              </div>
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                            </div>
                            <div className=" flex justify-center items-center">
                            <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                            </div>
                          </div>
                          <div
                            className="flex py-4 justify-between  border-b-2 "
                            style={{ borderColor: "#EDEBF3" }}
                          >
                            <div className="flex flex-col">
                              <div className="text-left my-2 flex items-top gap-1">
                              <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                              </div>
                            </div>
                            <div className=" flex justify-center items-center">
                            <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                            </div>
                          </div>
                          <div className="flex py-4 justify-between   border-gray-200 border-b-2  ">
                          <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                            <div className="flex justify-center items-center font-bold">
                            <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                            </div>
                          </div>
                          {/* <div className="text-left ">Attach COI</div> */}

                          

                          <div className="flex py-4  justify-between border-gray-200 ">
                            {/* <div className="flex justify-between items-center rounded-md"> */}

                            
                              <>
                              <span className="flex h-11 w-40 bg-purple-400 rounded-md dark:bg-purple-400"></span>
                              <span className="flex h-11 w-40 bg-red-400 rounded-md dark:bg-red-400"></span>
                              </>
                            
                          </div>
                        </div>
                      </div>

                      {/* <span className="text-base mt-2 font-medium text-gray-900">Organic transportation</span> */}
                    </div>
                  </div>
                </section>


                {/* Recent Hires */}
                <section aria-labelledby="recent-hires-title">
                  <div className="overflow-hidden rounded-lg ">
                    <div className="p-6 bg-white">
                      <h3 className="text-2xl text-gray-700 font-bold mb-6 ml-3">
                      <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                      </h3>

                      
                        <ol>
                          <li className="border-l-2 border-purple-600">
                            <div className="md:flex flex-start">
                              <div className="bg-purple-600 w-6 h-6 cursor-pointer flex items-center justify-center rounded-full -ml-3.5">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  className="text-white w-3 h-3"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                                  ></path>
                                </svg>
                              </div>
                              <div className="block p-6 rounded-lg shadow-lg bg-gray-100 max-w-md ml-6 mb-10 w-[28rem]">
                                <div className="flex items-center justify-between">
                                  <a
                                    href="#!"
                                    className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out text-sm"
                                  >
                                     <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                                  </a>
                                  <a
                                    href="#!"
                                    className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out text-sm"
                                  >
                                     <span className="flex h-4 w-28 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></span>
                                  </a>
                                </div>
                                {/* <div className="flex justify-between items-center ">

                        <span className="text-sm text-gray-600 ">Reservation Status:</span>

                        <a
                          type="button"
                          className="inline-block cursor-pointer px-4 py-1.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          data-mdb-ripple="true"
                          
                          >
                          {event?.status}
                        </a>
                          </div> */}
                                {/* <button
                          type="button"
                          className="inline-block px-3.5 py-1 border-2 border-purple-600 text-purple-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                          data-mdb-ripple="true"
                        >
                          See demo
                        </button> */}
                              </div>
                            </div>
                          </li>
                        </ol>
                    </div>
                  </div>
                </section>
              </div>
             
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ReservationDetailPageSkeleton;
