import React, { useState, useEffect } from 'react';

function OwnerCarousel({ images }) {
  const [currentPairIndex, setCurrentPairIndex] = useState(0);

  const imagePairs = React.useMemo(() => {
    // Group images by type and handle missing pairs
    return images.reduce((acc, image) => {
      const type = image.type;
      acc[type] = (acc[type] || []).concat(image);

      // if (acc[type].length === 0) {
      //   acc[type].push({ image: 'https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg', type: 'dummy' });
      // }

      return acc;
    }, {});
  }, [images]);

  console.log(imagePairs);

  const handleNextClick = () => {
    const nextIndex = (currentPairIndex + 1) % Object.keys(imagePairs).length;
    setCurrentPairIndex(nextIndex);
  };

  const handlePrevClick = () => {
    const prevIndex =
      currentPairIndex === 0
        ? Object.keys(imagePairs).length - 1
        : currentPairIndex - 1;
    setCurrentPairIndex(prevIndex);
  };

  return (
    <div className="carousel flex flex-col-reverse gap-y-6 rounded-lg px-10">
      <div className='flex items-center gap-x-6'>
        <button className='bg-purple-500 px-8 py-3 text-white rounded-md ' onClick={handlePrevClick} type='button'>
          Previous
        </button>
        <button className='bg-purple-500 px-8 py-3 text-white rounded-md ' onClick={handleNextClick} type='button'>
          Next
        </button>
      </div>

      {/* Image pairs container */}
      <div className="carousel-content w-full flex transition ease-out duration-700 transform">
        {Object.values(imagePairs)[currentPairIndex].map((image, index) => (
          <div
            key={index}
            className="carousel-item flex-1 h-full bg-gray-100 rounded-lg overflow-hidden relative mr-4" // Add margin-right for spacing
          >
            <img src={image?.image ?  window.$backendURL + image.image : "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"} alt={image.type} className="w-full h-[40rem] object-cover object-center" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OwnerCarousel;
