import React, { useEffect } from "react";

import {
  
  ChevronDownIcon,
  
} from "@heroicons/react/24/outline";
import { ArrowTrendingUpIcon} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";





const reating = [
    { name: "Reservation Acceptance Rate",  amount: "14%" },
    { name: "Average Response Time",  amount: "5.7 Hours" },
    { name: "Reservations to Date",  amount: "2" },
    { name: "Average Reservation Length", amount: "4 Days" },
  
    // More items...
  ];
const cardss = [
  { name: "From" },
  { name: "Through" },
  { name: "Get Statement" },

  // More items...
];



const actions = [
  {

    name: "$30,659.46",
    href: "#",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {

    name: "$30,659.46",
    href: "#",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const DashMain = () => {
  const nevigate = useNavigate();
  useEffect(() => {
    if (localStorage.role=="Renter") {
      nevigate("/home")
    }
  }, [])
  return (
//     <div className="flex flex-col justify-center items-center min-h-screen">
//       <img src="https://digitalxc.com/wp-content/uploads/2020/06/00-6.png" alt="" />
// <h1 className=" font-semibold text-4xl text-purple-500  bg-white px-4 py-2 border border-purple-500 rounded-md mt-8">Dashboard Coming Soon!</h1>
//     </div>
    <div className="min-h-screen">
      <section aria-labelledby="profile-overview-title">
        <div className="m-7 ">
          <div className="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
            {/* Card */}
            
              <div
                style={{ backgroundColor: "#f9c7d2" }}
                className="overflow-hidden rounded-lg shadow"
              >
                <div className="p-6">
                  <div className="flex items-center">
                    <div className=" w-1/4 flex-1">
                      <dl>
                        <dt className="truncate text-sm pb-7 font-medium text-gray-500">
                        Total earned at date
                        </dt>
                        <dd>
                          <div className="flex flex-row justify-between">
                            <div className="text-2xl font-medium text-gray-900">
                            $30,659.45
                            </div>
                            <div className="flex flex-row items-center px-2 ">
                              <span className="truncate pr-1  font-medium text-gray-500">+213113</span>
                              <ArrowTrendingUpIcon
                                className="h-4 font-bold  w-4 text-black-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </dd> 
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ backgroundColor: "#fbdae1" }}
                className="overflow-hidden rounded-lg shadow"
              >
                <div className="p-6">
                  <div className="flex items-center">
                    <div className=" w-1/4 flex-1">
                      <dl>
                        <dt className="truncate text-sm pb-7 font-medium text-gray-500">
                        Active reservation
                        </dt>
                        <dd>
                          <div className="flex flex-row justify-between">
                            <div className="text-2xl font-medium text-gray-900">
                            $30,659.45
                            </div>
                            <div className="flex flex-row items-center px-2 ">
                              <span className="truncate pr-1  font-medium text-gray-500">+213113</span>
                              <ArrowTrendingUpIcon
                                className="h-4 font-bold  w-4 text-black-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ backgroundColor: "#f9c7d2" }}
                className="overflow-hidden rounded-lg shadow"
              >
                <div className="p-6">
                  <div className="flex items-center">
                    <div className=" w-1/4 flex-1">
                      <dl>
                        <dt className="truncate text-sm pb-7 font-medium text-gray-500">
                        Active listing
                        </dt>
                        <dd>
                          <div className="flex flex-row justify-between">
                            <div className="text-2xl font-medium text-gray-900">
                            $30,659.45
                            </div>
                            <div className="flex flex-row items-center px-2 ">
                              <span className="truncate pr-1  font-medium text-gray-500">+213113</span>
                              <ArrowTrendingUpIcon
                                className="h-4 font-bold  w-4 text-black-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ backgroundColor: "#fbdae1" }}
                className="overflow-hidden rounded-lg shadow"
              >
                <div className="p-6">
                  <div className="flex items-center">
                    <div className=" w-1/4 flex-1">
                      <dl>
                        <dt className="truncate text-sm pb-7 font-medium text-gray-500">
                        Rating
                        </dt>
                        <dd>
                          <div className="flex flex-row justify-between">
                            <div className="text-2xl font-medium text-gray-900">
                            $30,659.45
                            </div>
                            <div className="flex flex-row items-center px-2 ">
                              <span className="truncate pr-1  font-medium text-gray-500">+213113</span>
                              <ArrowTrendingUpIcon
                                className="h-4 font-bold  w-4 text-black-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </section>

      <div className="grid  items-start gap-3 lg:grid-cols-3 lg:gap-0">
        {/* Left column */}
        <div className="grid lg:col-span-2">
          {/* Welcome panel */}

          <div className="ml-7 mr-4 py-8 px-6 rounded-md bg-white mb-6">
            <span className="text-2xl pb-7 font-bold ">Payout Statements</span>
            <div className="my-3 mt-8 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
              {/* Card */}
              {cardss.map((card) => (
                <div
                  key={card.name}
                  className="overflow-hidden rounded-lg mb-2 bg-white shadow"
                >
                  <div className="flex flex-row p-3 items-center justify-between py-2  border-gray-0 ">
                    <dt className="truncate text-sm font-semibold text-gray-500">
                      {card.name}
                    </dt>
                    <dd>
                      <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                    </dd>
                  </div>
                </div>
              ))}
            </div>

            {/* Actions panel */}
            <section aria-labelledby="quick-links-title">
              <div className="divide-y overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                {actions.map((action) => (
                  <div key={action.name} className="rounded-tl-lg py-3 rounded-tr-lg">
                    <div className="m-3">
                    <div className="flex justify-between">
                      <h3 className="text-2xl font-medium">
                        <a href={action.href} className="focus:outline-none">
                          {/* Extend touch target to entire panel */}

                          {action.name}
                        </a>
                      </h3>
                      <h2 className=" flex font-semibold text-sm text-gray-500 items-end">Total Transfer</h2>
                      </div>
                      <p className="mt-3 text-sm text-gray-900 text-left">
                        Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>

        {/* Announcements */}
        <section aria-labelledby="profile-overview-title">
          <div className="mt-1 grid grid-sows-1  sm:grid-rows-2 lg:grid-rows-3">
            {/* Card */}
            {reating.map((card) => (
              <div className="  pb-6 px-6">
                <div
                  key={card.name}
                 
                  className="overflow-hidden  bg-white rounded-lg shadow"
                >
                  <div className="flex flex-col p-4 ">
                  
                      <div className=" align-middle text-2xl font-medium text-gray-900">
                        {card.amount}
                      </div>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {card.name}
                      </dt>
                      
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}

export default DashMain




