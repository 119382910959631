import { useFormik } from "formik";
import { EllipsisVerticalIcon, CheckIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Switch } from "@headlessui/react";
import LoadingIcons from "react-loading-icons";
import { Toaster, toast } from "sonner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const EditDriver = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [currDriver, setCurrDriver] = useState();
  const [submitDone, setSubmitDone] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [licenseFront, setLicenseFront] = useState(null);
  const [licenseBack, setLicenseBack] = useState(null);
  const [uploadImgLoading, setUploadImgLoading] = useState(false);
  const { driverID } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const getDriver = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Driver Profiles/${driverID}?fields=["*"]`,
            config
          )
          .then((response) => {
            console.log(response.data.data);
            setCurrDriver(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getDriver();
  }, []);

  const EditDriver = useFormik({
    initialValues: {
      first_name: currDriver?.first_name,
      last_name: currDriver?.last_name,
      phone_no: currDriver?.contact_number,
      date_of_birth: currDriver?.birthday,
      license_number: currDriver?.license_number,
      license_state: currDriver?.license_state,
      license_expiry_date: currDriver?.license_expiry,
      license_front_picture: currDriver?.license_front_picture,
      license_back_picture: currDriver?.license_back_picture,
    },
    onSubmit: (values) => {
      var jsonDriver = {
        first_name: values.first_name,
        last_name: values.last_name,
        birthday: values.date_of_birth,
        contact_number: values.phone_no,
        license_number: values.license_number,
        license_state: values.license_state,
        license_expiry: values.license_expiry_date,
        license_front_picture:
          licenseFront === null || licenseFront === undefined
            ? currDriver?.license_front_picture
            : licenseFront,
        license_back_picture:
          licenseBack === null || licenseBack === undefined
            ? currDriver?.license_back_picture
            : licenseBack,
      };
      const EditDriverApi = async (jsonDriver) => {
        setUploadImgLoading(true);
        console.log("json", jsonDriver);
        try {
          await axios
            .put(
              `${window.$backendURL}/api/resource/Driver Profiles/${driverID}`,
              jsonDriver,
              config
            )
            .then((res) => {
              setUploadImgLoading(false);
              toast.success("Driver Updated Successfully");
              setSubmitDone(!submitDone);

              setTimeout(() => {
                navigate("/Drivers");
                // resetForm();
              }, 1000);
            });
        } catch (e) {
          console.log(e);
          toast.error("Try Again");
        }
      };
      EditDriverApi(jsonDriver);
      // resetForm();
      EditDriver.resetForm();
    },
    enableReinitialize: true,
  });

  const uploadLicenseFront = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadFront = async () => {
      setUploadImgLoading(true);
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setUploadImgLoading(false);
            setLicenseFront(response?.data?.message?.file_url);
            console.log(response?.data?.message?.file_url);
            console.log(licenseFront);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadFront();
  };

  const uploadLicenseBack = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadBack = async () => {
      setUploadImgLoading(true);
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setUploadImgLoading(false);
            setLicenseBack(response.data.message.file_url);
            console.log(response.data.message.file_url);
            console.log(licenseBack);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadBack();
  };

  return (
    <>
      <Toaster richColors />
      <div className="h-screen">
        {isSuccess && (
          <div className="static">
            <div className="border-l-4 w-80 top-15 right-9 border-green-800 bg-green-100 p-4 absolute">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <CheckIcon
                    className="h-6 w-6 text-green-800"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-800">
                    Driver Updated Successfully
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <Link to="/Drivers">
          <button className="flex flex-row gap-2 justify-center items-center">
            <svg
              className="w-5 h-5 font-thin text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className=" font-normal text-gray-600">Back</span>
          </button>
        </Link>

        <form
          onSubmit={EditDriver.handleSubmit}
          onReset={() => EditDriver.resetForm()}
          className="mt-4"
        >
          <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-xl font-semibold leading-6 text-gray-900">
                  Edit Driver
                </h3>
              </div>

              <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.first_name}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder="Jhon"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.last_name}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder="Alex"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="phone_no"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="phone_no"
                        id="phone_no"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.phone_no}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="date_of_birth"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of birth
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="date"
                        name="date_of_birth"
                        id="date_of_birth"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.date_of_birth}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="license_number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      License Number 
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="license_number"
                        id="license_number"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.license_number}
                        required
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="license_expiry_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      License Expiry Date{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="date"
                        name="license_expiry_date"
                        id="license_expiry_date"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.license_expiry_date}
                        required
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="license_state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      License State <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <select
                        type="text"
                        name="license_state"
                        id="license_state"
                        onChange={EditDriver.handleChange}
                        value={EditDriver.values.license_state}
                        required
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      >
                        <option value="">Select</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <span className="block text-sm font-medium text-gray-700">
                      License Front 
                      {/* <span className="text-red-500">*</span> */}
                    </span>
                    <div>
                      {currDriver?.license_front_picture && (
                        <a
                          target="_blank"
                          className="flex items-center justify-center mt-2 rounded-md border border-transparent button py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          href={
                            window.$backendURL +
                            currDriver?.license_front_picture
                          }
                        >
                          View Uploaded File
                        </a>
                      )}

                      <div className="flex items-center justify-center w-full mt-1">
                        <label
                          htmlFor="license_front_picture"
                          className="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                        >
                          <div className="flex items-center justify-center gap-x-2">
                            <svg
                              className="w-6 h-6 text-gray-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            {currDriver?.license_front_picture != undefined ||
                            currDriver?.license_front_picture != null ? (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">Click</span> or
                                update your file
                              </p>
                            ) : (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                            )}
                          </div>
                          <input
                            id="license_front_picture"
                            name="license_front_picture"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              uploadLicenseFront(e);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <span className="block text-sm font-medium text-gray-700">
                      License Back 
                      {/* <span className="text-red-500">*</span> */}
                    </span>
                    <div>
                      {currDriver?.license_back_picture && (
                        <a
                          target="_blank"
                          className="flex items-center justify-center mt-2 rounded-md border border-transparent button py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          href={
                            window.$backendURL +
                            currDriver?.license_back_picture
                          }
                        >
                          View Uploaded File
                        </a>
                      )}

                      <div className="flex items-center justify-center w-full mt-1">
                        <label
                          htmlFor="license_back_picture"
                          className="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 "
                        >
                          <div className="flex items-center justify-center gap-x-2">
                            <svg
                              className="w-6 h-6 text-gray-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            {currDriver?.license_front_picture != undefined ||
                            currDriver?.license_front_picture != null ? (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">Click</span> or
                                update your file
                              </p>
                            ) : (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                            )}
                          </div>
                          <input
                            id="license_back_picture"
                            name="license_back_picture"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              uploadLicenseBack(e);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-span-6 sm:col-span-3 flex flex-col justify-center">
                <span
                    className="block text-sm font-medium text-gray-700"
                  >
                    Default Driver
                  </span>
                <Switch
                disabled
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? 'bg-purple-600' : 'bg-gray-200',
        'relative inline-flex mt-2 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
                </div> */}
                </div>
                <div className="flex justify-end gap-2 pb-4 pt-4">
                  <button
                    className="rounded-md border border-transparent button py-2 px-8 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                    type="submit"
                  >
                    {uploadImgLoading ? (
                      <>
                        <LoadingIcons.BallTriangle className="h-5" />
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditDriver;
