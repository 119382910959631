import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { Toaster, toast } from "sonner";

function PaymentForm({func}) {
    var userEmail;
    var JSToken;
    var accessToken;
    if (localStorage.user) {
      userEmail = localStorage.user_email;
      JSToken = JSON.parse(localStorage.user);
      accessToken = JSToken.access_token;
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const [loading, setLoading] = useState(false);
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
  const [deletedPaymentMethod, setDeletedPaymentMethod] = useState(false);
  const [currProfile, setCurrProfile] = useState(null);

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            setCurrProfile(response.data.data);
            console.log(response.data.data);
            setLoading(false);
          });
      } catch (e) {
        console.error(e);
      }
    };

    getProfileOwner();
  }, []);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      console.error(result.error);
    } else {
        var json = {
            customer_stripe: currProfile?.customer_stripe,
            token:result.paymentMethod.id
          };
      
          try {
            await axios
              .post(
                `${window.$backendURL}/api/method/truckrent.api.attach_payment_method`,
                json
              )
              .then((response) => {
                console.log(response);
                setDeletedPaymentMethod(!deletedPaymentMethod);
                toast.success("Payment Method Successfully Added")
                func(false)
              }).catch((err) => toast.error("Payment Method Error"));
          } catch (e) {
            console.error(e);
            toast.error("Payment Method Error")
          }
      console.log(result);
    }
  };

  return (
    <>
   <Toaster richColors/>
    <form onSubmit={handleSubmit}>
      <CardElement colorPrimary="#0570de" />
      <button
        className="inline-flex w-full mt-4 justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
        type="submit"
      >
        Add Credit Card
      </button>
    </form>
    </>
  );
}

export default PaymentForm;
