import { PlusIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Toaster } from "sonner";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";

const SupportTicket = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  var user_name;
  var userID;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    user_name = localStorage.user_name;
    userID = localStorage.userID;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { reservationID } = useParams();
  const [tickets, setTickets] = useState([]);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [ticketId, setTicketId] = useState("");

  const handleSendMessage = () => {
    const json = {
      sender: user_name,
      message: text,
      parent: ticketId,
      parentfield: "messages",
      parenttype: "Support Ticket",
      doctype: "Support Ticket Message",
    };
    console.log(json);
    try {
      axios
        .post(
          `${window.$backendURL}/api/resource/Support Ticket Message`,
          json,
          config
        )
        .then((res) => {
          console.log("ticket-messages", res?.data?.data);
          const newMessage = res?.data?.data;
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setText("");
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      axios
        .get(
          `${window.$backendURL}/api/resource/Support Ticket?fields=["*"]&filters=[["reservation_contract", "=", "${reservationID}"]]`,
          config
        )
        .then((res) => {
          console.log("ticket", res?.data?.data);
          setTickets(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);


  const handleTicketMessages = (id) => {
    try {
      axios
        .get(`${window.$backendURL}/api/resource/Support Ticket/${id}`, config)
        .then((res) => {
          console.log("ticket-messages", res?.data?.data);
          const messagesSorted = res?.data?.data?.messages.sort(
            (a, b) => (a.timestamp.localeCompare(b.timestamp)));

          console.log("messagesSorted", messagesSorted);
          setMessages(messagesSorted);

        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <>
      <Toaster richColors />
      <div className="h-screen">
        <div className="flex justify-between mb-4 mx-auto w-full">
          <h1 className="text-2xl font-semibold text-gray-900">
            Support Tickets
          </h1>
          <Link to={`/add-ticket/${reservationID}`}>
            <button className="flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
              <div>
                <PlusIcon className="w-5 text-white h-5" />
              </div>

              <div>Add Ticket</div>
            </button>
          </Link>
        </div>
        <div className="bg-white">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                  <table className="min-w-full divide-y divide-gray-300 ">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Business Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Ticket Categories
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Ticket Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Ticket Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 ">
                      {tickets?.length === 0 ? (
                        <tr>
                          <td colSpan={5} className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-center">
                            No tickets available.
                          </td>
                        </tr>
                      ) : (
                        tickets.map((person) => (
                          <tr key={person.name}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              {person.business_name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.ticket_categories}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.ticket_status}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.ticket_title}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div>
                                <button
                                  className="bg-purple-500 text-white px-4 py-2 rounded-md font-medium hover:bg-purple-400"
                                  onClick={() => {
                                    setOpen(true);
                                    handleTicketMessages(person.name);
                                    setTicketId(person?.name);
                                  }}
                                >
                                  Open Messages
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="">
                        <div className="flex items-start justify-between border-b border-gray-200 pb-3 px-4">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Support Ticket Messages
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center ">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="px-2 py-4 flex flex-col ">
                          <div className="flex-grow">
                            {messages?.map((msg) => (
                              <div
                                key={msg.name}
                                className={`chat ${msg.sender == "RentMyTruck Team"
                                    ? "chat-start"
                                    : "chat-end"
                                  }`}
                              >
                                <div className="chat-image avatar">
                                  <div className="w-10 rounded-full">
                                    <img
                                      alt="Tailwind CSS chat bubble component"
                                      src={localStorage.getItem("picture")}
                                    />
                                  </div>
                                </div>
                                <div className="chat-bubble">
                                  {msg?.message}
                                </div>
                                <div className="text-xs text-gray-600 pt-1">
                                  Sent by {msg.sender}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="absolute bottom-20 w-full pr-4 flex items-center gap-x-2 z-50">
                            <input
                              type="text"
                              placeholder="Type your message..."
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                              onKeyDown={handleKeyDown}
                              className="block w-full border-gray-300 rounded-md focus:border-purple-500 focus:ring-purple-500"
                            />
                            <button
                              className="bg-purple-500 text-white px-4 py-2.5 rounded-md font-medium"
                              onClick={handleSendMessage}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {/* Your content */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SupportTicket;
