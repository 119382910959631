import axios from 'axios';
import React, { useState } from 'react'
import LoadingIcons from 'react-loading-icons'
import { useCompanyProfile } from '../../../Context/CompanyProfile';
import { useNavigate } from 'react-router-dom';

const RefreshStripe = () => {
    const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
    const CompanyProfile = useCompanyProfile()
    const navigate = useNavigate()
    const handleReturnFalseCase = async () => {
        var json = {
            company_profile: CompanyProfile?.name,
        };
        
        try {
          setLoadingCreateAccount(true);
          await axios
            .post(`${window.$backendURL}/api/method/truckrent.api.handle_refresh_url_stripe`, json)
            .then((response) => {
              // console.log(response.data.data);
              window.open(response.data.data, "_blank");
                navigate("/profile")
              setLoadingCreateAccount(false);
            });
        } catch (e) {
          console.error(e);
        }
      };

  return (
    <div className='h-screen'>
    <div className='h-full flex justify-center items-center'>
    <div className="flex flex-col gap-4 h-screen justify-center">
        <div>

           <h1 className="text-xl flex justify-center items-center font-boldr">
               Your Onboarding Link is Expired
           </h1>
        </div>
        <div className="flex justify-center items-center">
        {loadingCreateAccount ? (
              <button
                disabled
                className=" rounded-md cursor-not-allowed border border-transparent bg-gray-600 py-2 px-8 text-lg font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
              >
                <LoadingIcons.TailSpin className="h-8 w-8" />
              </button>
            ) : (
                <button onClick={handleReturnFalseCase} className="rounded-md border border-transparent button py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">Resume Stripe Connect Onboarding</button>

            )}
        </div>
        </div>
    </div>
    </div>
  )
}

export default RefreshStripe