import React, { useEffect, useState } from "react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
  TruckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import LoadingIcons from "react-loading-icons";
import { PencilOutline } from "heroicons-react";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { StarIcon, MapPinIcon } from "@heroicons/react/24/outline";

import TextField from "@mui/material/TextField";
import { DateRange } from "react-date-range";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import moment from "moment/moment";

import { Toaster, toast } from "sonner";
import { Tab } from "@headlessui/react";
import ReservationPageSkeleton from "../Global/Skeleton/ReservationPageSkeleton";
import Tooltip from "../Global/SmallComponents/Tooltip";
import HomePageHeader from "./HomePageHeader";
import HomePageFooter from "./HomePageFooter";
import HomePageDetailSkeleton from "../Global/Skeleton/HomePageDetailSkeleton";
var userEmail;
var JSToken;
var accessToken;
var userPic;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  userPic = localStorage.picture;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HomePageVehicleDetails = () => {
  const { id } = useParams();
  const [currentVehicle, setCurrentVehicle] = useState();
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState();
  const [allDrivers, setAllDrivers] = useState(null);
  const [dateToday, setDateOfToday] = useState(new Date().toLocaleDateString());

  const [currProfile, setCurrProfile] = useState(null);

  const [costing, setcosting] = useState();
  const [totalTypeMiles, setTotalMiles] = useState();
  const [allUnAvailDates, setAllUnAvailDates] = useState([]);
  const [isLocation, setIsLocation] = useState(null);
  const [longitude, setLongitude] = useState("")
  const [latitude, setLatitude] = useState("");

  console.log("vehicleId", id);

  useEffect(() => {
    const GetVehicle = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(`${window.$backendURL}/api/resource/Vehicle/${id}`)
          .then((response) => {
            setCurrentVehicle(response.data.data);
            setLoading(false);
            console.log("vehicle", response.data.data);
            if (response.data.data.availability.length > 0) {
              response.data.data.availability.forEach((element) => {
                setAllUnAvailDates((state) => [
                  ...state,
                  new Date(element.reservation_date),
                ]);
              });
            }
            console.log("unavailableDates", allUnAvailDates);
            const getTypeBasedCosting = async () => {
              try {
                await axios
                  .get(
                    `${window.$backendURL}/api/resource/Vehicle Type Based Costing?fields=["*"]&filters=[["vehicle_type","=","${response.data.data.vehicle_type}"]]`
                  )
                  .then((responseCost) => {
                    setcosting(responseCost.data.data[0]);
                    console.log(responseCost.data.data);
                    // console.log(responseCost.data.data[0].allowed_cost_per_mile*responseCost.data.data[0].per_day_miles_allowed);
                    setTotalMiles(
                      0.3 * responseCost.data.data[0]?.per_day_miles_allowed
                    );
                  });
              } catch (e) {
                console.error(e);
              }
            };
            getTypeBasedCosting();
            const getLocation = async () => {
              try {
                await axios
                  .get(
                    `${window.$backendURL}/api/resource/Location/${response.data.data.pick_up}`,
                    
                  )
                  .then((response) => {
                    console.log("VehicleLocation>>>>>", response.data.data);
                    setIsLocation(response.data.data);
                  });
              } catch (e) {
                console.error(e);
              }
            };
            getLocation();
          });
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetVehicle();
    // setLoading(false);
  }, []);
  const [subTotal, setSubTotal] = useState(0);
  const [roadside, setRoadSide] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalRent, setTotalrent] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [totalPrePaidMileage, setTotalPrePaidMileage] = useState(0);
  const [globalStatus, setGlobalStatus] = useState(null);
  const [reservationBooking, setReservationBooking] = useState(false);
  const [processingFee, setProcessingFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const navigation = useNavigate();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  // const handleGrandTotal = () =>{

  //   setSubTotal(totalPrePaidMileage)

  // }

  useEffect(() => {
    var Difference_In_Time =
      state[0].endDate?.getTime() - state[0].startDate?.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
    setTotalDays(Math.floor(Difference_In_Days));
    setTotalrent(Math.floor(Difference_In_Days) * currentVehicle?.rent_per_day);
    console.log(state);
    setTotalPrePaidMileage(Math.floor(totalTypeMiles * 1.5));
    setTax(500);
    setRoadSide(500);
  }, [state, totalPrePaidMileage]);

  console.log("owner", currentVehicle?.vehicle_owner);

  useEffect(() => {
    // handleGrandTotal();
    setServiceFee(totalRent * 0.125);
    setProcessingFee(totalRent * 0.03);
    setTotalFee(serviceFee + processingFee);
    setDeposit(totalRent * 0.3);
    setGrandTotal(totalRent + totalFee + totalPrePaidMileage + deposit);
  }, [totalRent, serviceFee, processingFee, deposit, totalFee]);

  return (
    <>
      <Toaster richColors />
      <HomePageHeader />

      {loading ? (
        <HomePageDetailSkeleton />
      ) : (
        <div className="min-h-full bg-gray-100">
          <main className="lg:px-20 py-5 lg:py-10 pb-8">
            <div className="mx-0 w-full lg:px-4 px-2 sm:px-0 ">
              <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
                <div className="grid grid-cols- gap-4 lg:col-span-2">
                  <section aria-labelledby="profile-overview-title">
                    <Tab.Group
                      as="div"
                      className="flex flex-col-reverse lg:pr-4"
                    >
                      <div className="mx-auto lg:mt-6 mt-4 w-full max-w-2xl sm:block lg:max-w-none">
                        <Tab.List className="grid grid-cols-4 lg:gap-6 gap-3">
                          {currentVehicle?.attach_images.length > 0 ? (
                            currentVehicle?.attach_images?.map((image) => (
                              <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                                {({ selected }) => (
                                  <>
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                      <img
                                        src={
                                          image.image
                                            ? window.$backendURL + image.image
                                            : "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                        }
                                        alt=""
                                        className="h-full w-full object-contain"
                                      />
                                    </span>
                                    <span
                                      className={
                                        selected
                                          ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                          : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                      }
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </Tab>
                            ))
                          ) : (
                            <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                              {({ selected }) => (
                                <>
                                  <span className="absolute inset-0 overflow-hidden rounded-md">
                                    <img
                                      src={
                                        "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                      }
                                      alt=""
                                      className="h-full w-full object-contain"
                                    />
                                  </span>
                                  <span
                                    className={
                                      selected
                                        ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                        : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                    }
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </Tab>
                          )}
                        </Tab.List>
                      </div>

                      <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                        {currentVehicle?.attach_images.length > 0 ? (
                          currentVehicle?.attach_images?.map((image) => (
                            <Tab.Panel>
                              <img
                                src={window.$backendURL + image.image}
                                alt=""
                                className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                              />
                            </Tab.Panel>
                          ))
                        ) : (
                          <Tab.Panel>
                            <img
                              src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                              alt=""
                              className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                            />
                          </Tab.Panel>
                        )}
                      </Tab.Panels>
                    </Tab.Group>
                  </section>

                  <section aria-labelledby="quick-links-title">
                    <div className=" overflow-hidden rounded-lg  py-2">
                      <div className=" flex justify-between flex-col">
                        <div className="flex items-center justify-between pr-5">
                          <h2
                            className="text-2xl font-semibold  text-gray-900"
                            id="announcements-title"
                          >
                            {currentVehicle?.year +
                              ", " +
                              currentVehicle?.make +
                              " " +
                              currentVehicle?.model}
                          </h2>
                        </div>

                        {/* <div className="flex my-4 items-center gap-4">
                      <div className="text-sm font-semibold">4.5</div>
                      <div className="text-sm font-semibold w-6 h-6">
                        <StarIcon />
                      </div>

                      <div>
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          23 Trips
                        </span>
                      </div>
                      <div className="w-6 h-6">
                        <MapPinIcon />
                      </div>
                      <div className="text-sm font-semibold">2.3 miles</div>
                    </div> */}

                        <div className="mt-4">
                          <div className="flex  items-center gap-x-2 lg:gap-2">
                            <div className="text-sm bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
                              {currentVehicle?.fuel_type}
                            </div>

                            <div className="text-sm bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
                              {currentVehicle?.lift_gate}
                            </div>
                          </div>
                        </div>

                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Specifications
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.e_truck === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.e_truck === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.e_truck === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">E-Truck</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.ramp === 1 ? `purple` : `gray`
                              }-100 text-${
                                currentVehicle?.ramp === 1 ? `purple` : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.ramp === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Ramp</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.crew_cab === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.crew_cab === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.crew_cab === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Crew Cab</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.dock_height === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.dock_height === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.dock_height === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Dock Height</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.multi_temp === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.multi_temp === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.multi_temp === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Multi - Temp</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.apu === 1 ? `purple` : `gray`
                              }-100 text-${
                                currentVehicle?.apu === 1 ? `purple` : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.apu === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">APU</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.storage_only === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.storage_only === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.storage_only === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Storage Only</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.has_ifta === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.has_ifta === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.has_ifta === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Has IFTA</span>{" "}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Door Type
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <span className="text-base py-1 flex items-center  text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                              >
                                <path d="M140.001-140.001V-200h80v-547.691q0-30.308 21-51.308t51.308-21h375.382q30.308 0 51.308 21t21 51.308V-200h80v59.999H140.001ZM280-200h400v-547.691q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H292.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463V-200Zm115.384-244.616q14.692 0 25.038-10.346 10.347-10.346 10.347-25.038t-10.347-25.038q-10.346-10.346-25.038-10.346t-25.038 10.346Q360-494.692 360-480t10.346 25.038q10.346 10.346 25.038 10.346ZM280-760v560-560Z" />
                              </svg>{" "}
                              {currentVehicle?.door_type}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Usage Restrictions
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <span className="text-md py-1 rounded-full">
                              {currentVehicle?.usage_restrictions}
                            </span>
                          </div>
                        </div>
                        
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Description
                          </h1>
                          <p className="text-base text-gray-500  my-1">
                            {currentVehicle?.description}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col lg:mt-3 mt-2">
                        <h1 className="text-lg font-semibold text-gray-800">
                          Location
                        </h1>
                        <div className="text-base font-medium mt-1 text-gray-700">
                          {isLocation?.address}
                        </div>
                        <div className="text-base font-normal mb-1 text-gray-500">
                          {isLocation?.other_details &&
                            `(${isLocation?.other_details})`}
                        </div>
                      </div>
                      <iframe
                        className="w-full rounded-lg h-60 sm:h-96"
                        marginheight="0"
                        marginwidth="0"
                        src={`https://maps.google.com/maps?q=${
                          isLocation?.coordinates?.split(",")[0]
                        },${
                          isLocation?.coordinates?.split(",")[1]
                        }&hl=en&z=14&amp&output=embed`}
                      ></iframe>
                      {/* <div className="flex flex-col mt-6 ">
                      <div className="text-lg font-semibold my-2 ">
                        Business hours
                      </div>
                      <div className="text-sm text-gray-500 font-medium">
                        {currProfile?.business_hours}
                      </div>
                    </div> */}
                    </div>
                  </section>
                </div>

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4 ">
                  {/* Announcements */}
                  <section aria-labelledby="announcements-title">
                    <div className="overflow-hidden rounded-lg bg-white ">
                      <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 mb-5">
                        <h1
                          className="text-xl flex gap-1 text-gray-900 mt-2 font-bold items-center"
                          id="announcements-title"
                        >
                          {/* <span className="font-bold">
                          {"$" + currentVehicle?.rent_per_day}
                        </span>{" "} */}
                          Reserve this Equipment
                        </h1>
                        <h2
                          className="text-xl flex gap-1 text-gray-900 mt-1"
                          id="announcements-title"
                        >
                          <span className="font-bold">
                            {"$" + currentVehicle?.rent_per_day}
                          </span>{" "}
                          <span>/ Day</span>
                        </h2>
                        <div className=" flex flex-col justify-between gap-y-4">
                          <div className="flex flex-col justify-center sm:flex-row mt-3">
                            <DateRange
                              editableDateInputs={true}
                              onChange={(item) => setState([item.selection])}
                              moveRangeOnFirstSelection={false}
                              ranges={state}
                              rangeColors={["#EA446A"]}
                              minDate={new Date()}
                              shownDate={new Date()}
                              disabledDates={allUnAvailDates}
                            />
                          </div>
                          {/* <div className="mt-1  rounded-md shadow-sm">
                              <div>
                                <label
                                  htmlFor="time"
                                  className=" mb-2 block text-sm font-medium text-gray-700"
                                >
                                  Time
                                </label>
                                <select
                                  id="time"
                                  name="time"
                                  autoComplete="time"
                                  onChange={ReserveForm.handleChange}
                                  value={ReserveForm.values.time}
                                  required
                                  className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value="0:00:00">Select Time</option>
                                  <option value="0:00:00">12:00 AM</option>
                                  <option value="1:00:00">1:00 AM</option>
                                  <option value="2:00:00">2:00 AM</option>
                                  <option value="3:00:00">3:00 AM</option>
                                  <option value="4:00:00">4:00 AM</option>
                                  <option value="5:00:00">5:00 AM</option>
                                  <option value="6:00:00">6:00 AM</option>
                                  <option value="7:00:00">7:00 AM</option>
                                  <option value="8:00:00">8:00 AM</option>
                                  <option value="9:00:00">9:00 AM</option>
                                  <option value="10:00:00">10:00 AM</option>
                                  <option value="11:00:00">11:00 AM</option>
                                  <option value="12:00:00">12:00 PM</option>
                                  <option value="13:00:00">1:00 PM</option>
                                  <option value="14:00:00">2:00 PM</option>
                                  <option value="15:00:00">3:00 PM</option>
                                  <option value="16:00:00">4:00 PM</option>
                                  <option value="17:00:00">5:00 PM</option>
                                  <option value="18:00:00">6:00 PM</option>
                                  <option value="19:00:00">7:00 PM</option>
                                  <option value="20:00:00">8:00 PM</option>
                                  <option value="21:00:00">9:00 PM</option>
                                  <option value="22:00:00">10:00 PM</option>
                                  <option value="23:00:00">11:00 PM</option>
                                </select>
                              </div>
                            </div> */}
                          {/* <div className="mt-1  rounded-md shadow-sm">
                              <div>
                                <label
                                  htmlFor="payment_details"
                                  className=" mb-2 block text-sm font-medium text-gray-700"
                                >
                                  Payment details
                                </label>
                                <select
                                  id="payment_details"
                                  name="payment_details"
                                  autoComplete="payment_details-name"
                                  onChange={ReserveForm.handleChange}
                                  value={ReserveForm.values.payment_details}
                                  className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option>Select payment method</option>
                                  <option>Canada</option>
                                  <option>Mexico</option>
                                </select>
                              </div>
                            </div> */}

                          <div
                            className="flex flex-col p-5 border-2 mt-4 rounded-lg"
                            style={{
                              backgroundColor: "#F9F8FC",
                              borderColor: "#EDEBF3",
                            }}
                          >
                            <div
                              className="text-left font-semibold text-lg pb-2 border-b-2 "
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              Cost Breakdown
                            </div>
                            <div
                              className="py-4 border-b-2"
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  Rent Per Day{" "}
                                </div>
                                <div className="flex justify-center items-center">
                                  {"$" + currentVehicle?.rent_per_day}
                                </div>
                              </div>
                              {totalDays > 0 && (
                                <div className="flex  justify-between   ">
                                  <div className="text-left">Total</div>
                                  <div className="flex justify-center items-center">
                                    {"$" + totalRent}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div
                              className="py-4   border-b-2  "
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  Processing Fee
                                </div>
                                <div className="flex justify-center items-center">
                                  {"$" + serviceFee.toFixed(2)}
                                </div>
                              </div>
                              <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  Service Fee
                                </div>
                                <div className="flex justify-center items-center">
                                  {"$" + processingFee.toFixed(2)}
                                </div>
                              </div>
                              <div className="flex  justify-between   ">
                                <div className="text-left font-bold my-2">
                                  Total Fee
                                </div>
                                <div className="flex justify-center font-bold items-center">
                                  {"$" + totalFee.toFixed(2)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex   py-4 justify-between  border-b-2 "
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              <div className="flex flex-col">
                                <div className="text-left my-2 flex items-top gap-1">
                                  Prepaid Mileage
                                  <Tooltip
                                    data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${currentVehicle?.vehicle_type}, Rent My Truck charges ${costing?.per_day_miles_allowed} miles per day at $${costing?.allowed_cost_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                  />{" "}
                                </div>
                                <span className=" font-light text-xs">{`${costing?.per_day_miles_allowed} miles per day included ($${costing?.overboard_per_mile_cost} per extra mile)`}</span>
                              </div>
                              <div className=" flex justify-center items-center">
                                {"$" + totalPrePaidMileage}
                              </div>
                            </div>
                            <div
                              className="flex   py-4 justify-between  border-b-2 "
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              <div className="flex flex-col">
                                <div className="text-left my-2 flex items-top gap-1">
                                  Deposit
                                </div>
                              </div>
                              <div className=" flex justify-center items-center">
                                {"$" + deposit.toFixed(2)}
                              </div>
                            </div>

                            <div className="py-4  ">
                              {/* <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                   Platform Charges 
                                </div>
                                <div className="flex justify-center items-center">
                                  {"$" + serviceFee}
                                </div>
                              </div> */}
                              <div className="flex  justify-between   ">
                                <div className="text-left">Total</div>
                                <div className="flex justify-center items-center">
                                  {"$" + grandTotal.toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col gap-y-3 mt-2">
                            {accessToken == null ? (
                              <Link to="/signin">
                                <button
                                  className="w-full text-center tracking-wide bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600 hover:text-white cursor-pointer"
                                  // style={{ backgroundColor: "#625B71" }}
                                >
                                  Login or Register to Reserve
                                </button>
                              </Link>
                            ) : (
                              <Link to={`/vehicle-detail/${id}`}>
                                <button
                                  className="w-full text-center tracking-wide bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600 hover:text-white cursor-pointer"
                                  // style={{ backgroundColor: "#625B71" }}
                                >
                                  See Details
                                </button>
                              </Link>
                            )}
                            {/* <Link to="/signup">
                              <button
                                className="w-full text-center tracking-wide bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700 cursor-pointer"
                                
                              >
                                Register
                              </button>
                                </Link> */}
                          </div>
                        </div>

                        {/* <span className="text-base mt-2 font-medium text-gray-900">Organic transportation</span> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
      <HomePageFooter />
    </>
  );
};

export default HomePageVehicleDetails;
