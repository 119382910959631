import { CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { toast, Toaster } from "sonner";

const AddTicket = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  var userID;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    userID = localStorage.userID;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { reservationID } = useParams();
  const TicketForm = useFormik({
    initialValues: {
      ticket_title: "",
      ticket_categories: "",
      ticket_status: "",
      opened_by: "",
      reservation_contract: "",
    },
    onSubmit: (values) => {
      const json = {
        ticket_title: values?.ticket_title,
        ticket_categories: values?.ticket_categories,
        ticket_status: values?.ticket_status,
        opened_by: userID,
        reservation_contract: reservationID,
      };
      console.log(json);

      try {
        axios
          .post(
            `${window.$backendURL}/api/resource/Support Ticket`,
            json,
            config
          )
          .then((res) => {
            console.log("ticket", res?.data?.data);
            toast.success("Ticket Added Successfully");
          });
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <>
      <Toaster richColors />
      <div className="h-screen">
        <Link to={`/renter-support-ticket/${reservationID}`}>
          <button className="flex flex-row gap-2 justify-center items-center">
            <svg
              className="w-5 h-5 font-thin text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className=" font-normal text-gray-600">Back</span>
          </button>
        </Link>

        <form
          onSubmit={TicketForm.handleSubmit}
          //   onReset={() => AddDriver.resetForm()}
          className="mt-4"
        >
          <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-xl font-semibold leading-6 text-gray-900">
                  Add Ticket
                </h3>
              </div>

              <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="ticket_title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ticket Title <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="ticket_title"
                        id="ticket_title"
                        onChange={TicketForm.handleChange}
                        value={TicketForm.values?.ticket_title}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="ticket_categories"
                      className="block text-sm font-medium text-gray-700 mb-0.5"
                    >
                      Ticket Categories <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="ticket_categories"
                      id="ticket_categories"
                      required
                      onChange={TicketForm.handleChange}
                      value={TicketForm.values?.ticket_categories}
                      class="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    >
                      {" "}
                      <option value="">Select an option</option>{" "}
                      <option value="Account">Account</option>{" "}
                      <option value="Claims">Claims</option>{" "}
                      <option value="Reimbursement">Reimbursement</option>{" "}
                      <option value="Payments">Payments</option>{" "}
                      <option value="Reservations">Reservations</option>{" "}
                      <option value="Services and Vehicles">
                        Services and Vehicles
                      </option>{" "}
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="ticket_status"
                      className="block text-sm font-medium text-gray-700 mb-0.5"
                    >
                      Ticket Status <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="ticket_status"
                      id="ticket_status"
                      required
                      onChange={TicketForm.handleChange}
                      value={TicketForm.values?.ticket_status}
                      class="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    >
                      {" "}
                      <option value="">Select an option</option>{" "}
                      <option value="Open">Open</option>{" "}
                      <option value="In Progress">In Progress</option>{" "}
                      <option value="In Progres - waiting for customer">
                        In Progres - waiting for customer
                      </option>{" "}
                      <option value="Closed">Closed</option>{" "}
                      <option value="Under Review">Under Review</option>{" "}
                      <option value="Pending Return">Pending Return</option>{" "}
                      <option value="Estimated Requested">
                        Estimated Requested
                      </option>{" "}
                      <option value="Repairs In Progress">
                        Repairs In Progress
                      </option>{" "}
                      <option value="Total Loss">Total Loss</option>{" "}
                      <option value="Repairs Complete">Repairs Complete</option>{" "}
                    </select>
                  </div>
                </div>
                <div className="flex justify-end gap-2 pb-4 pt-4">
                  <button
                    className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                    type="submit"
                  >
                    Add Ticket
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTicket;
