import React from 'react'

import {
  
    XMarkIcon,
  } from "@heroicons/react/24/outline";
  
  import { StarIcon, MapPinIcon } from "@heroicons/react/24/outline";
  import { PencilOutline } from "heroicons-react";
const OwnerVehicleDetailSkeleton = () => {
  return (
    <div className="min-h-screen ">
        <main className="animate-pulse">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl ">
            <div className="grid grid-row-1 items-start gap-1 ">
              {/* Left column */}
              <div className="grid grid-cols- gap-4 lg:col-span-2">
                {/* Welcome panel */}
                <section aria-labelledby="profile-overview-title">
                  <div className=" p-3">
                    <img
                      className="w-full  object-contain bg-purple-100 rounded-lg max-h-96"
                      src=
                         "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png" 
                      
                      
                      alt=""
                    />
                  </div>
{/* 
                  <div className="grid grid-cols-1 sm:grid-cols-4 ">
                    
                      <div
                       
                        className="flex gap-x-2 w-full py-2 text-center text-sm font-medium"
                      >
                        <img
                          className="object-cover rounded-lg w-full h-full max-h-80"
                          src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                          alt=""
                        />
                        <img
                          className="object-cover rounded-lg w-full h-full max-h-80"
                          src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                          alt=""
                        />
                        <img
                          className="object-cover rounded-lg w-full h-full max-h-80"
                          src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                          alt=""
                        />
                      </div>
                    
                  </div> */}
                </section>

                {/* Actions panel */}
                <section aria-labelledby="quick-links-title ">
                  <div className=" overflow-hidden rounded-lg ml-5">
                    {/* Location  */}
                    <div className="flex gap-4  font-light items-center">
                      <div>
                        Approval Status:
                      </div>
                        <span className='w-32 h-4 rounded-lg bg-orange-100'></span>
                      <div>
                        Availablity Status:
                      </div>
                        <span className='w-24 h-4 rounded-lg bg-blue-100'></span>
                    </div>

                  </div>
                  {/* <div className=" overflow-hidden rounded-lg px-4  ">
                   
                    <div className="flex  flex-col my-4">
                      <div className="text-lg font-normal text-slate-500">
                        Location
                      </div>
                      <div className=" text-lg font-medium  my-2">
                      {currentVehicle?.pick_up[0]?.address}
                      </div>
                    </div>
                    <img
                      className="w-full rounded-lg h-60 sm:h-96"
                      src="https://images.unsplash.com/photo-1569336415962-a4bd9f69cd83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hcHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    />
                  </div> */}
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4 ml-5">
                {/* Announcements */}
                <section aria-labelledby="announcements-title" className="">
                  <div className="overflow-hidden rounded-lg ">
                    <div className=" py-3 mb-5">
                      <div className="flex items-center gap-2">
                      <span className='w-80 h-16 rounded-lg bg-gray-200'></span>
                        {/* <Link to={"/editVehicle/" + vehicleID}>
                          <button className=" flex gap-1 rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
                            <PencilIcon
                              className="w-4 h-4"
                              aria-hidden="true"
                            />

                          </button>
                        </Link> */}
                      </div>

                      <div className="flex mt-6 items-center gap-1">
                        
                          <MapPinIcon className="w-6 h-6"/>
                        
                        <span className='w-96 h-4 rounded-lg bg-gray-200'></span>
                        
                      </div>

                      <div className="mt-6">

                      <div className="flex items-center gap-x-6">
                          
                          
                          <span className='w-20 h-4 rounded-lg bg-purple-200'></span>
                          
                          <span className='w-20 h-4 rounded-lg bg-purple-200'></span>
                        </div>
                      </div>
                      {/* <div className="py-3">
                        <h1 className="text-lg py-2 font-normal text-slate-500">
                          Features
                        </h1>
                        <div className="flex flex-wrap gap-3">
                        {currentVehicle?.feature?.map((item)=>{
                            return(
                            <p key={item.name} className="inline-flex h-6  rounded-full bg-green-100 px-4 text-xs font-semibold leading-5 text-green-800 items-center">
                          {item.feature_name}
                        </p>
                        )
                        })}
                        
                        </div>
                        
                      </div> */}
                        <div>
                        <h1 className="text-lg py-3 font-normal text-slate-500">
                          Specifications:
                        </h1>
                        <div className="flex items-center flex-wrap gap-2 mb-2 mt-1">
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                       <XMarkIcon className="w-6 h-6"/>
                        <span className="">
                        <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </span>{" "}
                      </div>
                      
                    </div>
                      </div>
                     
                      <div className='flex flex-col '>
                        <h1 className="text-lg py-3 font-normal text-slate-500">
                          Description:
                        </h1>
                       
                        
                        <span className='w-80 h-4 rounded-lg bg-gray-200'></span>
                        
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    
  )
}

export default OwnerVehicleDetailSkeleton