import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from "../../img/Logo.png";
import { Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
const HomePageFooter = () => {
  

  const faqs = [
    {
      question: "What's the best thing about Switzerland?",
      answer:
        "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
    },
    // More questions...
  ]

  return (
    <>
      <footer className="shadow bg-gray-900">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <Link className="flex items-center mb-4 sm:mb-0" to="/">
              <img src={Logo} className="h-8 mr-3" alt="Rent Logo" />
            </Link>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 ">
              <li>
                <a
                  href="#"
                  className="mr-4 hover:underline md:mr-6 text-gray-500 "
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="mr-4 hover:underline md:mr-6 text-gray-500 "
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={window.$FWDurl + `/termsconditions`}
                  target="_blank"
                  className="mr-4 hover:underline md:mr-6 text-gray-500 "
                >
                  Terms And Conditions
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-gray-500 ">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
          <span className="flex item-center justify-center text-sm text-gray-500 sm:text-center">
            © 2024 &nbsp;
            <a href="#" className="hover:underline text-gray-500 ">
              RentMyTruck™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>

      
    </>
  );
};

export default HomePageFooter;
