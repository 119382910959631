import { useFormik } from "formik";
import { EllipsisVerticalIcon, CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LoadingIcons from 'react-loading-icons'



const Editlocation = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const { locationID } = useParams();
  const [submitDone, setSubmitDone] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currLocation, setCurrLocation] = useState(null);
  const navigate = useNavigate();
  const [locationAddress, setLocationAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [coordinatesPlace, setCoordinatesPlace] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [zipCodeName, setZipCode] = useState("");
  const [stateName, setState] = useState("");
  const [cityName, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchlocation = async () => {
      console.log(locationID);
      const results = await axios
        .get(
          `${window.$backendURL}/api/resource/Location/${locationID}`,
          config
        )
        .then((res) => {
          console.log(res?.data);
          setCurrLocation(res.data.data);
          setLocationAddress(res?.data?.data?.address);
        })
        .catch((e) => {
          console.log(">>>>>>>>>>errror", e);
        });
    };
    fetchlocation();
  }, []);

  const handlePlaceChange = (newAddress) => {
    setLocationAddress(newAddress);
  };

  const handleSelect = async (selectedAddress) => {
    setLocationAddress(selectedAddress);
    try {
      setLoading(true);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      console.log("Geocoding results:", results);
      console.log("Latitude and Longitude:", latLng);

      const place = results[0];
      const { formatted_address, address_components, place_id } = place;

      let address = "";
      let state = "";
      let city = "";
      let postalCode = "";

      address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number") || types.includes("route")) {
          address += `${component.long_name} `;
        }
        if (types.includes("locality")) {
          city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (types.includes("postal_code")) {
          postalCode = component.long_name;
        }
      });

      setFormattedAddress(formatted_address);
      setCoordinatesPlace(`${latLng.lat},${latLng.lng}`);
      setPlaceId(place_id);
      setZipCode(postalCode);
      setState(state);
      setCity(city);

      console.log("Formatted Address:", formatted_address);
      console.log("Coordinates:", `${latLng.lat},${latLng.lng}`);
      console.log("Place ID:", place_id);
      console.log("Postal Code:", postalCode);
      console.log("State:", state);
      console.log("City:", city);

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching geocoding results");
      setLoading(false);
    }
  };

  const EditLocation = useFormik({
    initialValues: {
      location_name: currLocation?.location_name,
      address: currLocation?.formatted_address, 
      phone_no: currLocation?.phone_no,
      other_details: currLocation?.other_details,
      userEmail: userEmail, 
      coordinates: currLocation?.coordinates, 
      formatted_address: currLocation?.formatted_address, 
      zip_code: currLocation?.zip_code,
      state: currLocation?.state, 
      city: currLocation?.city, 
      place_id: currLocation?.place_id, 
    },
    onSubmit: (values) => {
      var jsonlocation = {
        user: userEmail,
        location_name: values.location_name,
        address: locationAddress,
        phone_no: values.phone_no,
        other_details: values.other_details,
        coordinates: coordinatesPlace,
        formatted_address: formattedAddress,
        zip_code: zipCodeName,
        state: stateName,
        city: cityName,
        place_id: placeId,
      };
  
      const EditLocationApi = async (jsonlocation) => {
        try {
          setLoader(true);
          await axios
            .put(
              `${window.$backendURL}/api/resource/Location/${locationID}`,
              jsonlocation,
              config
            )
            .then((res) => {
              console.log(res.data);
              toast.success("Location Updated Successfully");
  
              setTimeout(() => {
                setLoader(true);
                navigate("/Locations");
              }, 1000);
            });
        } catch (e) {
          toast.error("Location Update Failed");
          console.log(e);
        }
      };
  
      EditLocationApi(jsonlocation);
    },
  
    enableReinitialize: true,
  });
  
  return (
    <div className="min-h-screen">
      <Toaster richColors />
      <Link to="/Locations">
        <button className="font-semibold flex flex-row gap-2">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Locations</span>
        </button>
      </Link>
      <form onSubmit={EditLocation.handleSubmit} className="mt-4">
        <div className="bg-white px-4 py-5  sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">
                Edit Location
              </h3>
            </div>

            <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="location_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Location Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="location_name"
                      id="location_name"
                      onChange={EditLocation.handleChange}
                      value={EditLocation.values.location_name}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md relative">
                    <PlacesAutocomplete
                      value={locationAddress}
                      onChange={handlePlaceChange}
                      onSelect={handleSelect}
                      searchOptions={{
                        // types: ["(cities)"],
                        componentRestrictions: { country: "us" },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                      }) => (
                        <div className="w-full">
                          <input
                            {...getInputProps({
                              id: "address",
                              name: "address",
                              className:
                                "w-full rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm",
                              type: "text",
                              placeholder: "Enter a location",
                              required: true,
                            })}
                          />
                          <div className="absolute z-10  w-full mt-1 rounded-b-md ">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className:
                                    "p-2 cursor-pointer bg-gray-100 hover:bg-white",
                                })}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="phone_no"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="phone_no"
                      id="phone_no"
                      onChange={EditLocation.handleChange}
                      value={EditLocation.values.phone_no}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                      required
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="other_details"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Other Details <span className="text-red-500"></span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <textarea
                      type="text"
                      name="other_details"
                      id="other_details"
                      onChange={EditLocation.handleChange}
                      value={EditLocation.values.other_details}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end pb-4 pt-4">
                <button
                  className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                  type="submit"
                >
                 {loader ?  <LoadingIcons.BallTriangle className='h-5'/> : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Editlocation;
