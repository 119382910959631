import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReservationBarSkeleton from "../../Global/Skeleton/ReservationBarSkeleton";
const people = [
    { id:1,  img:'https://media.istockphoto.com/id/527056061/photo/convoy-of-trucks-on-the-beautiful-autumn-highway.jpg?s=612x612&w=0&k=20&c=903no5IzsMwDmQVvERfWW3ql4RxOZaBuNcCoflDtfPc=',name: 'Returned', title: '$354324244253432', Date:"10/10/2022", Location:"The href attribute requires", model:"2017great2018", Company: 'RRT Transport', Reservationid: 'k75DwSHo ', Action:"Leave review" },
    { id:2,  img:'https://media.istockphoto.com/id/527056061/photo/convoy-of-trucks-on-the-beautiful-autumn-highway.jpg?s=612x612&w=0&k=20&c=903no5IzsMwDmQVvERfWW3ql4RxOZaBuNcCoflDtfPc=',name: 'Returned', title: '$354324244253432', Date:"10/10/2022", Location:"The href attribute requires", model:"2017great2018", Company: 'RRT Transport', Reservationid: 'k75DwSHo ', Action:"Leave review" },
    { id:3,  img:'https://media.istockphoto.com/id/527056061/photo/convoy-of-trucks-on-the-beautiful-autumn-highway.jpg?s=612x612&w=0&k=20&c=903no5IzsMwDmQVvERfWW3ql4RxOZaBuNcCoflDtfPc=',name: 'Returned', title: '$354324244253432', Date:"10/10/2022", Location:"The href attribute requires", model:"2017great2018", Company: 'RRT Transport', Reservationid: 'k75DwSHo ', Action:"Leave review" },
    { id:4,  img:'https://media.istockphoto.com/id/527056061/photo/convoy-of-trucks-on-the-beautiful-autumn-highway.jpg?s=612x612&w=0&k=20&c=903no5IzsMwDmQVvERfWW3ql4RxOZaBuNcCoflDtfPc=',name: 'Returned', title: '$354324244253432', Date:"10/10/2022", Location:"The href attribute requires", model:"2017great2018", Company: 'RRT Transport', Reservationid: 'k75DwSHo ', Action:"Leave review" },

  ]
 
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const ReservationList = () => {
    var userEmail;
    var JSToken;
    var accessToken;
    if (localStorage.user) {
      userEmail = localStorage.user_email;
      JSToken = JSON.parse(localStorage.user);
      accessToken = JSToken.access_token;
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const [allReservation, setAllReservation] = useState(null)
    const [vehicleOfReservation, setVehicleOfReservation] = useState(null)
    const [loading, setLoading] = useState(false)
    const [apiFilters, setApiFilters] = useState(JSON.stringify([
      ["owner_reservation", "=", localStorage.userID],
    ]))
    const [tabs, setTabs] = useState([
      { name: 'All', href: '#tab1', current: true },
      { name: 'Rejected', href: '#tab2', current: false },
      { name: 'Cancelled', href: '#tab3', current: false },
      { name: 'Approved', href: '#tab3', current: false },
      { name: 'Active', href: '#tab3', current: false },
    ]);
  
    const changeTab = (tabName) => {
      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        current: tab.name === tabName,
      }));
      setTabs(updatedTabs);
  
      // TODO: Make API call based on the selected tab
      console.log(`API call for ${tabName}`);
      if(tabName === "All"){
        setApiFilters(JSON.stringify([
          ["owner_reservation", "=", localStorage.userID],
        ]));
      }
      else if(tabName === "Rejected"){
        setApiFilters(JSON.stringify([
          ["owner_reservation", "=", localStorage.userID],
          ["reservation_status", "=", "Rejected"],
         
        ])); 
      }
      else if(tabName === "Cancelled"){
        setApiFilters(JSON.stringify([
          ["owner_reservation", "=", localStorage.userID],
          ["reservation_status", "=", "Cancelled"],
         
        ])); 
      }
      else if(tabName === "Approved"){
        setApiFilters(JSON.stringify([
          ["owner_reservation", "=", localStorage.userID],
          ["reservation_status", "=", "Approved"],
         
        ])); 
      }
      else if(tabName === "Active"){
        setApiFilters(JSON.stringify([
          ["owner_reservation", "=", localStorage.userID],
          ["reservation_status", "=", "Active"],
         
        ])); 
      }
    };
    const navigate  = useNavigate();
    useEffect(()=>{
      const getReservations= async () => {
        setLoading(true)
        try {
          await axios
            .get(
              `${window.$backendURL}/api/method/get-all-contracts?filters=${encodeURIComponent(apiFilters)}`,
              config
            )
            .then((response) => {
                setAllReservation(response.data.data);
                console.log("response" ,response.data.data);
                setLoading(false)
            });
        } catch (e) {
          console.error(e);
        }
      };
      getReservations();
    },[apiFilters])
    useEffect(()=>{
      const getReservations= async () => {
        setLoading(true)
        try {
          await axios
            .get(
              `${window.$backendURL}/api/method/get-all-contracts?filters=[["owner_reservation","=","${localStorage.userID}"]]`,
              config
            )
            .then((response) => {
                setAllReservation(response.data.data);
                console.log("response" ,response.data.data);
                setLoading(false)
            });
        } catch (e) {
          console.error(e);
        }
      };
      getReservations();
    },[])
    const handleClick = (name) =>{
    
      navigate(`/reservations_detail/${name}`)
  
    }
    
    return (
      <>
       <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={tabs.find((tab) => tab.current).name}
          onChange={(e) => changeTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              className={`
                ${tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}
                ${tabIdx === 0 ? 'rounded-l-lg' : ''}
                ${tabIdx === tabs.length - 1 ? 'rounded-r-lg' : ''}
                group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10
              `}
              onClick={() => changeTab(tab.name)}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
              <span
                aria-hidden="true"
                className={`${tab.current ? 'bg-purple-500' : 'bg-transparent'} absolute inset-x-0 bottom-0 h-0.5`}
              />
            </button>
          ))}
        </nav>
      </div>
    </div>
        <div className="px-4 sm:px-6 lg:px-8 min-h-screen">
            {loading ? (
        <ReservationBarSkeleton />
      ) : (   
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8  ">
              {allReservation?.length > 0 ? (
                <div className="inline-block rounded-lg bg-white overflow-y-auto overflow-x-auto py-2 align-middle sm:px-8 px-4">
                  <table className="min-w-full divide-y  divide-gray-300">
                    <thead>
                      <tr >
                      <th
                          scope="col"
                          className="py-4 flex-shrink-0  pl-4 px-4  pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        >
                         Vehicle Image
                        </th>
                       
                        
                        {/* <th scope="col" className="py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Earning
                        </th> */}
                        {/* <th scope="col" className="whitespace-nowrap py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Paid to date
                        </th> */}
                        <th scope="col" className="whitespace-nowrap py-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Actual Dates
                        </th>
                        <th scope="col" className="py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Make Model
                        </th>
                        <th scope="col" className="py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Location
                        </th>
                        {/* <th scope="col" className="py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Unit
                        </th> */}
                        <th scope="col" className=" py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Company
                        </th>
                        <th
                          scope="col"
                          className="py-4  px-4 pl-5 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="py-4  px-4 pl-5 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        >
                          Secondary Status
                        </th>
                        <th scope="col" className=" py-4 px-4 pl-4  text-left text-sm font-semibold text-gray-900">
                        Action
                        </th>
                        
                        
                      </tr>
                    </thead>
                    
                    <tbody className="divide-y divide-gray-200">
                      {allReservation?.map((person) => (
                        <tr key={person.id} onClick={()=>handleClick(person?.reservation?.name)} className="cursor-pointer">
                          <td className=" whitespace-nowrap  py-2">
                            <img className='w-20 object-cover object-center rounded-md' src={window.$backendURL+person.vehicle.attach_images[0]?.image} alt="" />
                          </td>
                        
                       
                          {/* <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500">--</td> */}
                          {/* <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500">--</td> */}
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500">{person?.reservation?.reservation_start_date} &nbsp; {person?.reservation?.reservation_end_date}</td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500">
                            <p className="truncate w-40 text-sm text-gray-500">
                            {person?.vehicle?.make+" "+person?.vehicle?.model}
                            </p>
                            </td>
                          <td className="whitespace-nowrap py-4 px-4  ">
                            <p className="truncate w-60 text-sm text-gray-500">
                            {person?.location?.address}
                            </p>
                            </td>
                          {/* <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500">--</td> */}
                          <td className="  whitespace-nowrap py-4 px-4 text-sm text-gray-500">{person?.owner?.business_name}</td>
                          <td className="whitespace-nowrap rounded-xl py-2 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                           <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"> {person?.reservation?.reservation_status}</span>
                          </td>
                          
                          <td className="whitespace-nowrap rounded-xl py-2 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                            {" "}
                            {(person?.reservation?.global_status ==
                              "Requested.Requested" && (
                              <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                Waiting for Owners Approval{" "}
                              </span>
                            )) ||
                              (person?.reservation?.global_status ==
                                "Requested.OwnerApproved" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for COI{" "}
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Requested.COIuploaded" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  COI Uploaded{" "}
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Requested.OwnerRejected" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Rejected by Owner{" "}
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Requested.RenterApproved" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Reservation Approved by Renter{" "}
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Requested.RenterRejected" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Rejected by Renter{" "}
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Cancelled" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Cancelled{" "}
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Active.Waiting for Pickup" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Pickup
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Active.Active" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Pickup Completed
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Completed.Rated" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Reconcillation
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Completed.Returned" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Rating
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Completed.Reconcilled" && (
                                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                                  Reconcillation Completed
                                </span>
                              )) ||
                              (person?.reservation?.global_status ==
                                "Active.Time Extension Requested" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting For Owner's Extension Approval
                                </span>
                              ))}
                          </td>
                      <td className=" whitespace-nowrap py-4 px-2 text-sm text-gray-500 underline"> <Link to={"/reservations_detail/"+person?.reservation?.name}>View Details</Link></td>
                           {/* <td className="whitespace-nowrap relative  py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                            
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                    
                    
                  </table>
                </div>): <div className="flex w-full items-center justify-center mx-auto">
                 <span className="text-lg py-5">
                  No Result Found
                  </span> 
                  </div>}
              </div> 
            </div>
            )}
          </div>
          </>
    )
}

export default ReservationList;