import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import axios from "axios";

const RenterFAQs = ({ open, setOpen }) => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(
          `${window.$backendURL}/api/resource/Reservation FAQ?fields=["*"]`,
          config
        )
        .then((res) => {
          console.log("FAQS", res?.data.data);
          setFaqs(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="bg-white">
                      <div className="w-full px-6 py-10 sm:py-10 lg:px-8 lg:py-10">
                        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                            Frequently asked questions
                          </h2>
                          <dl className="mt-4 space-y-6 divide-y divide-gray-900/10">
                            {faqs?.map((faq) => (
                              <Disclosure
                                as="div"
                                key={faq.name}
                                className="pt-6"
                              >
                                {({ open }) => (
                                  <>
                                    <dt>
                                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base font-semibold leading-7">
                                          {faq.question}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center">
                                          {open ? (
                                            <MinusSmallIcon
                                              className="h-6 w-6"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <PlusSmallIcon
                                              className="h-6 w-6"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </span>
                                      </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel
                                      as="dd"
                                      className="mt-2 pr-12"
                                    >
                                      <p className="text-base leading-7 text-gray-600">
                                        {faq.answer}
                                      </p>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            ))}
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RenterFAQs;
