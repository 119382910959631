import { MapPinIcon, StarIcon, TruckIcon } from '@heroicons/react/24/outline'
import React from 'react'
import LoadingIcons from 'react-loading-icons'

const VehicleCardSkeleton = () => {
  return (
    <div
                role="status"
                className=" animate-pulse w-full"
              >
    <div
                  
                  className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                >
                  <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none h-64">
                    <img
                      src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                      alt="No Image Attached"
                      className="min-h-64 w-full object-cover object-center h-full sm:h-full sm:w-full"
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between space-y-2 p-4">
                    <div className="flex items-start flex-row justify-between ">
                      <span className=" font-semibold text-xl">
                        {" "}
                        <div className="h-6 w-32 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                      </span>
                      <span className="inline-flex h-6 rounded-full bg-blue-100 px-4 text-xs font-semibold leading-5 text-blue-100  sm:ml-2 items-center">
                        Available
                      </span>
                    </div>

                    <div className="flex flex-row gap-1 items-center mt-4">
                      <MapPinIcon className="w-5 text-gray-700 mt-4" />
                      <span className=" font-light text-gray-700 items-center mt-4">
                      <div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                      </span>
                    </div>

                    <div className=" pt-4 pb-2  ">
                      <div className="flex items-center  ">
                        <div className="flex justify-between gap-2">
                          <span className=" font-light"><div className="h-4 w-8 bg-gray-200 rounded-full dark:bg-gray-300"></div></span>
                          <StarIcon className="h-5 w-5" />
                        </div>
                        <span className="ml-2 inline-flex rounded-full bg-purple-100 px-2  text-sm leading-5 text-purple-100 font-light items-center">
                          23 Trips
                        </span>
                      </div>

                      <div className="flex justify-between my-2  items-center gap-2 lg:gap-2">
                        <div className="flex gap-1">
                          {" "}
                          <TruckIcon className="h-6 w-6  " aria-hidden="true" />
                          <span className=" px-0.5 inline-flex  text-sm font-light leading-5 text-black-50 items-center">
                          <div className="h-4 w-12 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                          </span>
                        </div>
                        <div className="flex gap-1">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            id="Layer_1"
                            dataname="Layer 1"
                            viewBox="0 0 28 28"
                          >
                            <path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" />
                          </svg>
                          <span className=" inline-flex  px-0.5 leading-5 text-base text-center font-light  items-center">
                          <div className="h-4 w-10 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                          </span>
                        </div>
                        <div className="flex gap-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            dataname="Layer 1"
                            viewBox="0 0 26 26"
                            width="22"
                          >
                            <path d="M23.5,23h-3.5V4.5c0-2.481-2.019-4.5-4.5-4.5h-7c-2.481,0-4.5,2.019-4.5,4.5V23H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5H23.5c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5ZM5,4.5c0-1.93,1.57-3.5,3.5-3.5h7c1.93,0,3.5,1.57,3.5,3.5V23H5V4.5Zm12,7.5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z" />
                          </svg>
                          <span className=" inline-flex  px-0.5 text-base leading-5 text-center font-light items-center">
                          <div className="h-4 w-10 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                          </span>
                        </div>
                      </div>
                    </div>
                    
                      <span className="flex w-full items-center justify-center rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
                        <LoadingIcons.BallTriangle className='h-4'/>
                      </span>
                    

                    {/* <p className="text-sm text-gray-500">{product.description}</p> */}
                  </div>
                </div>
                </div>
  )
}

export default VehicleCardSkeleton