import React from 'react'
import { useState } from "react";

import {
  CalendarIcon,
  TruckIcon,
  UserIcon,
  CalendarDaysIcon,
  ChatBubbleOvalLeftEllipsisIcon
  
} from "@heroicons/react/24/outline";
import { Link } from 'react-router-dom';

const SidebarRenterDesktop = () => {
  // const [isOnVehicles, setIsOnVehicles] = useState(false);
  // const [isOnReservation, setIsOnReservation] = useState(false);
  // const [isOnDrivers, setIsOnDrivers] = useState(false);

  const href  = [
    {
    Name: "Vehicles",
    to: "/home",
    Icon: TruckIcon,
  },
  {
    Name: "Drivers",
    to: "/Drivers",
    Icon: UserIcon,
  },
  {
    Name: "Reservations",
    to: "/Reservations",
    Icon: CalendarDaysIcon,
  },
  
 
  
]
  
  function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
  return (
    <div className="flex flex-col justify-between h-full">
                <nav className="flex-1 space-y-1 px-2 pb-4">
                {href.map((item,index)=>(<Link key={item.Name}
                    onClick={() => {
                      // setIsOnVehicles(true);
                      // setIsOnReservation(false);
                      
                      // setIsOnDrivers(false);
                    }}
                    to={item.to}
                  >
                    <button
                      className={classNames(
                        window.location.pathname.includes(item.to)
                        ? "bg-gray-200 border-gray-300 w-full nav-button-active-text-color"
                        : "text-gray-500 hover:bg-gray-100  w-full border-transparent hover:text-gray-600",
                      "group flex items-center px-2 py-2 text-base font-medium rounded-md border-l-4"
                      )}
                    >
                      <item.Icon
                        className={classNames(
                          window.location.pathname.includes(item.to)
                          ? "nav-button-active-text-color"
                          : "text-gray-500 group-hover:text-gray-600",
                        "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.Name}
                    </button>
                  </Link>))}
                  {/* <Link
                    onClick={() => {
                      setIsOnReservation(true);
                      setIsOnVehicles(false)
                      setIsOnDrivers(false);
                    }}
                    to="/Reservations"
                  >
                    <button
                      className={classNames(
                        isOnReservation
                          ? "nav-button-active-color border-purple-500 w-full nav-button-active-text-color"
                          : "text-gray-600 hover:bg-purple-50 w-full border-transparent hover:text-purple-800",
                        "group border-l-4 flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                    >
                      <CalendarIcon
                        className={classNames(
                          isOnReservation
                            ? "nav-button-active-text-color"
                            : "text-gray-400 group-hover:text-purple-800",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Reservations
                    </button>
                  </Link> */}
                  {/* <Link
                    onClick={() => {
                      // setIsOnReservation(false);
                      // setIsOnVehicles(false)
                      // setIsOnDrivers(true);
                    }}
                    to="/Drivers"
                  >
                    <button
                      className={classNames(
                        window.location.pathname.includes("/Drivers")
                          ? "nav-button-active-color border-purple-500 w-full nav-button-active-text-color"
                          : "text-gray-600 hover:bg-purple-50 border-transparent w-full hover:text-purple-800",
                        "group flex border-l-4 items-center px-2 py-2 text-base font-medium rounded-md mt-1"
                      )}
                    >
                      <UserIcon
                        className={classNames(
                           window.location.pathname.includes("/Drivers")
                            ? "nav-button-active-text-color"
                            : "text-gray-400 group-hover:text-purple-800",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Drivers
                    </button>
                  </Link> */}
                  
                </nav>
                
              </div>
  )
}

export default SidebarRenterDesktop