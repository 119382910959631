import { useFormik } from "formik";
import { EllipsisVerticalIcon, CheckIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { Toaster, toast } from "sonner";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LoadingIcons from 'react-loading-icons'


const AddLocation = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const inputRef = useRef();
  const [submitDone, setSubmitDone] = useState(false);
  const [loader, setLoader] = useState(false);
  const [locationAddress, setLocationAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [coordinatesPlace, setCoordinatesPlace] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [zipCodeName, setZipCode] = useState("");
  const [stateName, setState] = useState("");
  const [cityName, setCity] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // const restrictToUS = {
  //   types: ["(cities)"],
  //   componentRestrictions: { country: "us" },
  // };
  // const handlePlacesChanged = () => {
  //   const [places] = inputRef.current.getPlaces();
  //   if (places) {
  //     console.log(places);
  //     setCoordinatesPlace(
  //       places.geometry.location.lat() + "," + places.geometry.location.lng()
  //     );

  //     setPlaceId(places.place_id);
  //     setLocationAddress(places.formatted_address);
  //   }
  // };

  const handlePlaceChange = (newAddress) => {
    setLocationAddress(newAddress);
  };

  const handleSelect = async (selectedAddress) => {
    setLocationAddress(selectedAddress);
    try {
      setLoading(true);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      console.log("Geocoding results:", results);
      console.log("Latitude and Longitude:", latLng);
      

      const place = results[0];
      const { formatted_address, address_components, place_id } = place;

      let address = "";
      let state = "";
      let city = "";
      let postalCode = "";

      address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number") || types.includes("route")) {
          address += `${component.long_name} `;
        }
        if (types.includes("locality")) {
          city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (types.includes("postal_code")) {
          postalCode = component.long_name;
        }
      });

      setFormattedAddress(formatted_address);
      setCoordinatesPlace(`${latLng.lat},${latLng.lng}`);
      setPlaceId(place_id);
      setZipCode(postalCode);
      setState(state);
      setCity(city);
      
      console.log('Formatted Address:', formatted_address);
      console.log('Coordinates:', `${latLng.lat},${latLng.lng}`);
      console.log('Place ID:', place_id);
      console.log('Postal Code:', postalCode);
      console.log('State:', state);
      console.log('City:', city);

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching geocoding results");
      setLoading(false);
    }
  };

  console.log("locationAddress",locationAddress);

  //   const handlePlaceChange = () => {
  //     const map = new window.google.maps.Map(document.getElementById("map"), {
  //         center: { lat: 50.064192, lng: -130.605469 },
  //         zoom: 3,
  //     });

  //     const card = document.getElementById("pac-card");
  //     map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(card);

  //     const center = { lat: 50.064192, lng: -130.605469 };
  //     const defaultBounds = {
  //         north: center.lat + 0.1,
  //         south: center.lat - 0.1,
  //         east: center.lng + 0.1,
  //         west: center.lng - 0.1,
  //     };

  //     const input = document.getElementById("address");
  //     const options = {
  //         bounds: defaultBounds,
  //         componentRestrictions: { country: "us" },
  //         fields: ["address_components", "geometry", "icon", "name", "formatted_address"],
  //         strictBounds: false,
  //     };

  //     const autocomplete = new window.google.maps.places.Autocomplete(input, options);
  //     autocomplete.setComponentRestrictions({ country: ['us'] });

  //     const southwest = { lat: 5.6108, lng: 136.589326 };
  //     const northeast = { lat: 61.179287, lng: 2.64325 };
  //     const newBounds = new window.google.maps.LatLngBounds(southwest, northeast);
  //     autocomplete.setBounds(newBounds);

  //     autocomplete.addListener('place_changed', () => {
  //         const place = autocomplete.getPlace();
  //         let address = '';
  //         let state = '';
  //         let city = ''; // Add a new variable for the city

  //         if (place.address_components) {
  //             address = [
  //                 (place.address_components[0] && place.address_components[0].long_name) || '',
  //                 (place.address_components[1] && place.address_components[1].long_name) || '',
  //                 (place.address_components[2] && place.address_components[2].long_name) || '',
  //             ].join(' ');

  //             // Find the city and state in the address components
  //             for (const component of place.address_components) {
  //                 for (const type of component.types) {
  //                     if (type === 'locality' && component.long_name.length >= 2) { // Check for city
  //                         city = component.long_name;
  //                     } else if (type === 'administrative_area_level_1' && component.long_name.length >= 2) { // Check for state
  //                         state = component.long_name;
  //                     }
  //                 }
  //                 if (city && state) break;
  //             }
  //         }

  //         console.log('Place ID:', place.place_id);
  //         console.log('Formatted Address:', place.formatted_address);
  //         console.log('City:', city);
  //         console.log('State:', state);

  //         let postalCode = '';
  //         for (const component of place.address_components) {
  //             for (const type of component.types) {
  //                 if (type === 'postal_code') {
  //                     postalCode = component.short_name;
  //                     break;
  //                 }
  //             }
  //             if (postalCode) break;
  //         }

  //         // Retrieve the latitude, longitude, and place ID
  //         const latitude = place.geometry.location.lat();
  //         const longitude = place.geometry.location.lng();
  //         const placeId = place.place_id;

  //         setFormattedAddress(place.formatted_address)
  //         setLocationAddress(address)
  //         setCoordinatesPlace(latitude + "," + longitude);
  //         setZipCode(postalCode);
  //         setCity(city)
  //         setState(state)
  //         console.log('Latitude:', latitude);
  //         console.log('Longitude:', longitude);
  //         console.log('Place ID:', placeId);
  //         console.log('Postal Code:', postalCode);
  //     });
  // };

  const AddLocation = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      var jsonlocation = {
        user: userEmail,
        location_name: values.location_name,
        address: locationAddress,
        phone_no: values.phone_no,
        other_details: values.other_details,
        coordinates: coordinatesPlace,
        formatted_address: formattedAddress,
        zip_code: zipCodeName,
        state: stateName,
        city: cityName,
        place_id: placeId,
      };
      console.log(jsonlocation);
      try {
        setLoader(true)
        axios
          .post(
            `${window.$backendURL}/api/resource/Location`,
            jsonlocation,
            config
          )
          .then((res) => {
            toast.success("Location Added Successfully");
            console.log(res);
            setTimeout(() => {
              setLoader(false)
              navigate("/Locations");
            }, 1000);

            AddLocation.resetForm();
          });
      } catch (e) {
        toast.error("Error Adding Location");
        console.log(e);
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className="min-h-screen flex flex-col">
      <Toaster richColors />

      <div className="flex-shrink-0">
        <Link to="/Locations">
          <button className="flex items-center gap-2 text-gray-400">
            <svg
              className="w-5 h-5 font-thin text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="font-normal text-gray-600">Back</span>
          </button>
        </Link>
      </div>

      <form onSubmit={AddLocation.handleSubmit} className="flex-grow mt-4">
        <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6 w-full">
          <div className="md:flex md:flex-col md:gap-6">
            <h3 className="text-xl font-semibold leading-6 text-gray-900">
              Add Location
            </h3>

            <div className="mt-5 space-y-6">
              <div className="flex flex-col sm:flex-row sm:gap-6">
                <div className="flex flex-col sm:flex-grow">
                  <label
                    htmlFor="location_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Location Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="location_name"
                      id="location_name"
                      onChange={AddLocation.handleChange}
                      value={AddLocation.values.location_name}
                      className="block w-full rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-grow">
                  <label
                    htmlFor="address"
                    className="block mt-4 sm:mt-0 text-sm font-medium text-gray-700"
                  >
                    Address <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md relative">
                    <PlacesAutocomplete
                      value={locationAddress}
                      onChange={handlePlaceChange}
                      onSelect={handleSelect}
                      searchOptions={{
                        // types: ["(cities)"],
                        componentRestrictions: { country: "us" },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                      }) => (
                        <div className="w-full">
                          <input
                            {...getInputProps({
                              id: "address",
                              name: "address",
                              className:
                                "w-full rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm",
                              type: "text",
                              placeholder: "Enter a location",
                              required: true,
                            })}
                          />
                          <div className="absolute z-10  w-full mt-1 rounded-b-md ">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className:
                                    "p-2 cursor-pointer bg-gray-100 hover:bg-white",
                                })}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row sm:gap-6">
                <div className="flex flex-col sm:flex-grow">
                  <label
                    htmlFor="phone_no"
                    className="block mt-4 sm:mt-0 text-sm font-medium text-gray-700"
                  >
                    Phone Number <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="phone_no"
                      id="phone_no"
                      onChange={AddLocation.handleChange}
                      value={AddLocation.values.phone_no}
                      className="block w-full rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-grow">
                  <label
                    htmlFor="other_details"
                    className="block mt-4 sm:mt-0 text-sm font-medium text-gray-700"
                  >
                    Other Details <span className="text-red-500"></span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <textarea
                      type="text"
                      name="other_details"
                      id="other_details"
                      onChange={AddLocation.handleChange}
                      value={AddLocation.values.other_details}
                      className="block w-full rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-2 pt-4">
                <button
                  className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                  type="submit"
                >
                 {loader ?  <LoadingIcons.BallTriangle className='h-5'/> : "Add Location"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddLocation;
