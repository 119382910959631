import axios from "axios";
import React, { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import "./TermsAndCondtions.css"
import HomePageHeader from "../../HomePage/HomePageHeader";
import HomePageFooter from "../../HomePage/HomePageFooter";
const TermsandConditions = () => {
  return (
    <>
    <HomePageHeader/>
      <div class="c17 doc-content">
        <h1 class="c16" id="h.4x5s1z6k3ris">
          <span class="c12">Terms &amp; Conditions</span>
        </h1>
        <p class="c13">
          <span class="c1">Last Updated October 2023</span>
        </p>
        <ol class="c6 lst-kix_k6vku0be8pt4-0 start" start="1">
          <li class="c2 li-bullet-0">
            <h4 id="h.xqatkec6mxzq" className="display: inline">
              <span class="c5">Introduction to Program.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">
              The RentMyTruck Truck Sharing Program (&ldquo;TSP&rdquo;) is an
              electronic platform offered by Southern Transport and Logistics,
              LLC. d/b/a RentMyTruck (&ldquo;RentMyTruck&rdquo;) that enables
              parties that are registered pursuant to the terms of this
              RentMyTruck Truck Sharing Program Agreement
              (&ldquo;Agreement&rdquo;) to (1) sublease equipment to RentMyTruck
              pursuant to an LP Master Lease and subsequent LP Short-Term TSP
              Leases to enable RentMyTruck to contemporaneously lease the same
              equipment to other registered parties; and/or (2) allow other
              registered parties to sublease equipment from RentMyTruck through
              the TSP Software and pay on the basis of the actual use pursuant
              to the terms of a BMC Master Lease and individual BMC Short-Term
              TSP Leases.
              <br />
              <br />
            </span>
            <span class="c0">1.1.</span>
            <span class="c3">
              &nbsp;By participating in the TSP, you agree to be bound by all
              Terms and Conditions of this Agreement, the TSP, and the
              additional services offered.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">1.2. </span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;reserves the right to modify the Terms and Conditions of
              this Agreement and any attachments, addendums, or annexes, at any
              time, notifying customers of these modifications through the TSP
              Software.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">1.3.</span>
            <span class="c1">
              &nbsp;A vehicle shall not be eligible to participate in the TSP
              unless it has been registered in the TSP and is subject to an LP
              Master Lease and LP Short-Term TSP Lease. A party shall be
              required to accept and agree to the terms of this Agreement,
              including the BMC Master Lease and LP Master Lease attached as
              Exhibits A and B, respectively, at the time of registration for
              the TSP and before such registration is complete. The Agreement
              will be available for review by selecting the applicable review
              box prior to final acceptance of this Agreement. A party shall be
              deemed to further accept and agree to any future versions of this
              Agreement upon execution of either an LP Short-Term TSP Lease or a
              BMC Short-Term TSP Lease. The lease documents are available for
              review within the TSP App by clicking on the &ldquo;Lease
              Documents&rdquo; option.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.a7500g9gnpos" className="display: inline">
              <span class="c5">
                Definitions; Website and Mobile Application Terminology
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">2.1. Definitions.</span>
            <span class="c1">
              &nbsp;In &nbsp;this &nbsp;Agreement, &nbsp;unless &nbsp;the
              &nbsp;context &nbsp;clearly &nbsp;indicates otherwise, the
              following words and phrases shall have the following meaning:
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">2.1.1. &ldquo;Borrowing Motor Carrier&rdquo;</span>
            <span class="c3">&nbsp;or </span>
            <span class="c0">&ldquo;BMC&rdquo;</span>
            <span class="c3">
              &nbsp;is &nbsp; a &nbsp; motor &nbsp; carrier registered pursuant
              to this Agreement that accesses the TSP Software to identify
              equipment and subleases a TSP Vehicle fromRentMyTruck pursuant to
              a BMC Master Lease and BMC Short-Term TSP Lease.
              <br />
              <br />
            </span>
            <span class="c0">2.1.2. &ldquo;BMC Master Lease&rdquo;</span>
            <span class="c3">
              &nbsp;means a master equipment lease arranged through the use of
              the TSP Software between RentMyTruck and BMC that provides the
              basic terms for future TSP Vehicle-specific BMC Short-Term TSP
              Leases. &nbsp;A form of the BMC Master Lease is attached as
              Exhibit A.
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.3. &ldquo;BMC Short-Term TSP Lease&rdquo;
            </span>
            <span class="c3">
              &nbsp;means the Short-Term TSP Lease between RentMyTruck and the
              BMC that has been arranged through the use of the TSP Software
              between RentMyTruck and a BMC with a term of less than thirty (30)
              days for the use of a TSP Vehicle, unless RentMyTruck expressly
              agrees to a term of thirty (30) days or more in writing.
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.4. &ldquo;BMC Short-Term TSP Lease Termination and
              Equipment Receipts&rdquo;
            </span>
            <span class="c3">
              &nbsp;means a receipt delivered via the TSP Software to a BMC
              advising and confirming that the BMC Short-Term TSP Lease between
              RentMyTruck and the BMC is terminated as relates to a specific
              vehicle that has been subject to a BMC Short-Term TSP Lease as a
              TSP Vehicle and possession of equipment has been returned to the
              designated location provided in the TSP App (upon completion of
              vehicle inspection and execution of the inspection form).
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.5. &quot;BMC Statement of Lease and Short-Term TSP Lease
              Inception Equipment Receipts&rdquo;
            </span>
            <span class="c3">
              &nbsp;means a Statement of Lease under 49 CFR &sect; 376.11(c) and
              equipment receipt delivered via the TSP Software to a BMC advising
              and confirming that the BMC Short-Term TSP Lease between
              RentMyTruck and the BMC is activated as it relates to certain TSP
              Vehicle(s) and possession of equipment has been taken by the BMC
              (upon completion of vehicle inspection and execution of the
              inspection form). A copy of the BMC Statement of Lease and
              Short-Term TSP Lease Inception Equipment Receipt is available in
              the TSP App and must be kept available in the TSP Vehicle for
              inspection by law enforcement at all times.
              <br />
              <br />
            </span>
            <span class="c0">&zwj;2.1.6. &ldquo;Compensation&rdquo;</span>
            <span class="c3">
              &nbsp;means remuneration or anything of economic value that is
              provided, promised, or donated primarily in exchange for the
              sublease of equipment to RentMyTruck from an LP, including but not
              limited to, the waiver of lease payments under a preexisting Truck
              Lease and Service Agreement (&ldquo;TLSA&rdquo;) between
              RentMyTruck and the LPs and the sharing of payments under BMC
              Short-Term TSP Leases that are specific to a TSP Vehicle leased
              toRentMyTruck by an LP.
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.7. &ldquo;Lending Participant&rdquo;
            </span>
            <span class="c3">&nbsp;or </span>
            <span class="c0">&ldquo;LP&rdquo;</span>
            <span class="c3">
              &nbsp;means a party registered pursuant to this Agreement that
              enters into an LP Master Lease withRentMyTruck and accesses the
              TSP Software to identify certain equipment that is available for
              sublease to RentMyTruck that can be leased to a BMC under a BMC
              Short-Term TSP Lease as a TSP Vehicle.
              <br />
              <br />
            </span>
            <span class="c0">&zwj;2.1.8. &ldquo;LP Master Lease&rdquo;</span>
            <span class="c3">
              &nbsp;means a master equipment lease arranged through the use of
              the TSP Software between RentMyTruck and LP that provides the
              basic terms for future TSP Vehicle-specific LP Short-Term TSP
              Leases. A form of the LP Master Lease is attached as Exhibit B.
              <br />
              <br />
              &zwj;2
            </span>
            <span class="c0">.1.9. &ldquo;LP Short-Term TSP Lease&rdquo;</span>
            <span class="c3">
              &nbsp;means the Short-Term TSP Lease between RentMyTruck and the
              LP that has been arranged through the use of the TSP Software
              between RentMyTruck and an LP with a term of less than thirty (30)
              days for the use of a TSP Vehicle, unless RentMyTruck expressly
              agrees to a term of thirty (30) days or more in writing.
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.10. &ldquo;LP Short-Term TSP Lease Termination and
              Equipment Receipts&rdquo;
            </span>
            <span class="c3">
              &nbsp;means a receipt delivered via the TSP Software to an LP
              advising and confirming that the LP Short-Term TSP Lease between
              RentMyTruck and the LP is terminated as it relates to a specific
              vehicle that has been subject to an LP Short-Term TSP Lease as a
              TSP Vehicle and possession of equipment has been returned to LP
              (upon completion of vehicle inspection and execution of the
              inspection form).
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.11. &ldquo;LP Statement of Lease and Short-Term TSP Lease
              Inception Equipment Receipts&rdquo;
            </span>
            <span class="c3">
              &nbsp;means a receipt delivered via the TSP Software to an LP
              advising and confirming that the LP Short-Term TSP Lease between
              RentMyTruck and the LP is activated as relates to certain TSP
              Vehicle(s) and possession of equipment has been taken (upon
              completion of vehicle inspection and execution of the inspection
              form). Where necessary, this document also serves as a Statement
              of Lease under 49 CFR &sect; 376.11(c). A copy of the LP Statement
              of Lease and Short-Term TSP Lease Inception Equipment Receipt is
              available in the TSP App.
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.12. &ldquo;Participating Party&rdquo;
            </span>
            <span class="c3">
              &nbsp;means both LPs and BMCs registered pursuant to this
              Agreement that accesses the TSP Software.
              <br />
              <br />
            </span>
            <span class="c0">
              &zwj;2.1.13. &ldquo;Supplemental Terms&rdquo;
            </span>
            <span class="c3">
              &nbsp;means additional terms that may apply to Participating
              Party&rsquo;s use of the TSP Software, such as use policies or
              terms related to certain features, functionality, or additional
              services or product offerings, which may be modified from time to
              time and which may be presented to Participating Party from time
              to time. Supplemental Terms are in addition to and shall be deemed
              a part of, this Agreement
              <br />
              <br />
            </span>
            <span class="c0">&zwj;2.1.14. &ldquo;TSP Documentation&rdquo;</span>
            <span class="c3">
              &nbsp;means the Agreement and any other TSP agreement, terms, or
              documentation, including but not limited to the LP and BMC Master
              Leases, the LP and BMC Short-Term Leases, the LP and BMC
              Statements of Lease and Short-Term TSP Lease Inception Equipment
              Receipts, the terms and conditions of any insurance policies
              facilitated through RentMyTruck, the terms and conditions of the
              Limited Damage (LDW) program, the Carrier Transportation Services
              Rate Quotation (applicable when a driver and vehicle are provided
              by Southern Transportation and Logistics, LLC. under its for-hire
              motor carrier authority) and any other Supplemental Terms.
              <br />
              <br />
            </span>
            <span class="c0">&zwj;2.1.15. &ldquo;TSP Software&rdquo;</span>
            <span class="c3">
              &nbsp;means the electronic, web-based system that is accessible by
              a computer, electronic device, or smartphone or tablet application
              for the TSP (TSP App) used to share information and electronic
              documents with current customers necessary to facilitate the TSP.
              <br />
              <br />
            </span>
            <span class="c0">2.1.16. &ldquo;TSP Vehicle&rdquo;</span>
            <span class="c1">
              &nbsp;means a vehicle that is designated by an LP and subleased
              toRentMyTruck pursuant to an LP Short-Term TSP Lease and
              subsequently subleased by RentMyTruck to a BMC under a BMC
              Short-Term TSP Lease. 2.1.1. &ldquo;Borrowing Motor Carrier&rdquo;
              or &ldquo;BMC&rdquo; is a motor carrier registered pursuant to
              this Agreement that accesses the TSP Software to identify
              equipment and subleases a TSP Vehicle from RentMyTruck pursuant to
              a BMC Master Lease and BMC Short-Term TSP Lease.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">
              2.2 Website and Mobile Application Terminology.
            </span>
            <span class="c3">&nbsp;The </span>
            <span class="c11">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.rentmytruck.co&amp;sa=D&amp;source=editors&amp;ust=1698250211596230&amp;usg=AOvVaw19v6wKGiC5VgeaEkgSn5q3"
              >
                www.rentmytruck.co
              </a>
            </span>
            <span class="c1">
              &nbsp;website, mobile application or certain other written
              communications from RentMyTruck (including but not limited to
              reservation confirmations) may, from time to time, use general
              terms such as &ldquo;renter&rdquo;, &ldquo;lessee&rdquo;,
              &ldquo;borrower&rdquo; or similar or related terms
              (&ldquo;Incidental Terms&rdquo;) to describe a party making any
              type of rentmytruck reservation in the TSP. &nbsp;The use of any
              such Incidental Terms is not intended to infer, describe or
              signify any specific legal relationship between parties. All
              defined terms are set forth in the applicable TSP Documentation.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.8npjfnahgn92" className="display: inline">
              <span class="c5">TSP Registration</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">3.1. Proposal and Information.</span>
            <span class="c1">
              &nbsp;Only motor carriers conducting operations for which no motor
              carrier operating authority is required or motor carriers with the
              current motor carrier operating authority are permitted to
              register as a BMC for the TSP through the TSP Software on the
              website or TSP App. Registration is only activated whenRentMyTruck
              accepts the proposal by the prospective participant, after:
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">3.1.1.</span>
            <span class="c3">
              &nbsp;The prospective participant has completed all compulsory
              fields.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.2.</span>
            <span class="c3">
              &nbsp;Information relating to any applicable RentMyTruck TLSA has
              been submitted by the prospective participant.
              <br />
              &zwj;
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.3.</span>
            <span class="c3">
              &nbsp;All information and documents requested by RentMyTruck have
              been verified and validated by RentMyTruck or by another party
              specifically identified by RentMyTruck.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.4.</span>
            <span class="c3">
              &nbsp;Each prospective participant who may be an LP must verify
              identification and provide a valid mobile telephone number and
              email address to register with the TSP and agree to use the same
              mobile telephone number and email for mobile and personal
              communications while participating in the TSP.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.5.</span>
            <span class="c3">
              &nbsp;Each prospective participant that may be an LP must also
              identify vehicle(s), including its year, make, model and vehicle
              identification number, that may be subleased toRentMyTruck and all
              information that is required for Compensation.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.6.</span>
            <span class="c3">
              &nbsp;Each prospective participant that may be a &nbsp;BMC must
              correctly enter the details of a valid form of identification,
              valid driver&rsquo;s license, proof of all applicable operating
              authorities, proof of commercial insurance coverage for auto
              liability, &nbsp;general liability and physical damages, and all
              information for &nbsp;payment related to the TSP.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.7.</span>
            <span class="c3">
              &nbsp;Each prospective participant who may be a BMC must also
              provide a &nbsp;valid mobile telephone number and email address to
              register with the TSP &nbsp;and agree to use the same mobile
              telephone number and email for mobile and personal communications
              while participating in the TSP.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.8. </span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;may use participant data for mailings by RentMyTruck, its
              business partners, and other parties. In addition, RentMyTruck may
              rent, sell, or exchange such data for marketing purposes.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.9.</span>
            <span class="c3">
              &nbsp;A prospective participant must verify that it has read,
              accepted and will fully comply with all Terms and Conditions of
              this Agreement.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.10.</span>
            <span class="c3">
              &nbsp;The prospective participant must authorize the use of its
              business information and related data for purposes of facilitating
              participation in the TSP.
              <br />
              <br />
              &zwj;
            </span>
            <span class="c0">3.1.11.</span>
            <span class="c1">
              &nbsp;The prospective participant selects the designated command
              to register for and accept the terms and conditions of the TSP in
              the TSP Software. Selection of this option indicates the
              prospective participant&rsquo;s agreement to comply with all of
              the terms and conditions of the TSP.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">3.2. TSP Administration.</span>
            <span class="c1">
              &nbsp;At the time of registration for the TSP, the prospective
              participant is informed of the services provided through the TSP.
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">3.2.1.</span>
            <span class="c3">
              &nbsp;The prospective participant will complete any optional
              fields for additional services, promotions and/or agreements
              related to the TSP.
              <br />
              <br />
            </span>
            <span class="c0">3.2.2.</span>
            <span class="c3">
              &nbsp;In order for the proposal to be sent to RentMyTruck for
              final approval and participation in the TSP, the prospective
              participant must, among other things, provide express consent to
              the geo-location of vehicles that are used as TSP Vehicles (as
              applicable), to the detection of data regarding vehicle
              circulation, to the processing of utilization data and to sending
              RentMyTruck updated information regarding the vehicles, including
              their condition and location, if required by RentMyTruck.
              <br />
              <br />
            </span>
            <span class="c0">3.2.3.</span>
            <span class="c3">
              &nbsp;The data entered into the TSP Software will be processed
              and, if no irregularities are detected, the prospective
              participant&rsquo;s proposal for registering with the TSP will be
              sent to RentMyTruck. Following evaluation of the data, RentMyTruck
              will send the prospective participant a notification either
              accepting or rejecting the proposal.
              <br />
              <br />
            </span>
            <span class="c0">3.2.4.</span>
            <span class="c3">
              &nbsp;If the proposal is accepted, RentMyTruck will send the
              participant any relevant notices, receipts and contractual
              documentation allowing for participation as either an LP or BMC,
              as applicable.
              <br />
              <br />
            </span>
            <span class="c0">3.2.5.</span>
            <span class="c3">
              &nbsp;In order to complete enrollment following acceptance by
              RentMyTruck, the participant must log in to the TSP Software and
              complete any additional required documentation.
              <br />
              <br />
            </span>
            <span class="c0">3.2.6.</span>
            <span class="c3">
              &nbsp;For operations conducted by a motor carrier for which no
              operating authority is required, RentMyTruck may limit the type of
              operations permitted pursuant to the TSP or may limit the size of
              vehicles eligible for registration.
              <br />
              <br />
            </span>
            <span class="c0">3.2.7.</span>
            <span class="c1">&nbsp;For California operations:</span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c0">3.2.7.1.</span>
            <span class="c3">
              &nbsp;An LP must designate whether any equipment is fully
              registered for port and drayage use and must provide all
              documentation requested byRentMyTruck or in the TSP Software
              (including identification of relevant port concession agreements,
              participation in the Uniform Intermodal Interchange and Facilities
              Access Agreement (UIIA), Drayage Truck Registry (DTR) numbers, and
              Standard Carrier Alpha Codes (SCAC)). BMCs may only use fully
              registered equipment as indicated in the reservation for port and
              drayage operations and must be fully authorized to perform such
              operations (including possession of relevant port concession
              agreements, UIIA participation, DTR numbers, and SCAC).
              BMC&rsquo;s use of non-designated equipment for port or drayage
              operations will constitute a breach of this Agreement.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">3.2.7.2.</span>
            <span class="c3">
              &nbsp;Transportation Refrigeration Unit (TRU) regulation: LP may
              only register equipment that is fully compliant with TRU
              regulations. Any equipment that is no longer compliant with TRU
              regulations must be immediately removed from the TSP. The
              inclusion of non-compliant equipment as &ldquo;available&rdquo; in
              the TSP will constitute a breach of this Agreement by LP.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">3.2.7.3.</span>
            <span class="c3">
              &nbsp;California Air Resources Board (CARB) and Truck and Bus
              Regulation (TBR) compliance. LP may only register equipment that
              is fully compliant with CARB and TBR regulations. The listing of
              any equipment in the TSP as available for a reservation is
              evidence that the LP specifically certifies that all equipment is
              fully CARB and TBR compliant and LP will provide a certificate of
              compliance or written verification of compliance upon request. Any
              equipment that is no longer compliant with CARB regulations must
              be immediately removed from the TSP. The inclusion of
              non-compliant equipment as &ldquo;available&rdquo; in the TSP will
              constitute a breach of this Agreement by LP.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">3.2.7.4.</span>
            <span class="c3">
              &nbsp;Tractor-Trailer Greenhouse Gas Regulation (TTGHG)
              compliance. LP may only register equipment that is fully compliant
              with TTGHG regulations. The listing of any equipment in the TSP as
              available for a reservation is evidence that the LP specifically
              certifies that all equipment is fully TTGHG compliant and LP will
              provide a certificate of compliance or written verification of
              compliance upon request. Any equipment that is no longer compliant
              with TTGHG regulations must be immediately removed from the TSP.
              The inclusion of non-compliant equipment as
              &ldquo;available&rdquo; in the TSP will constitute a breach of
              this Agreement by LP.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">3.2.7.5.</span>
            <span class="c1">
              &nbsp;Basic Inspection of Terminals (BIT) compliance. LP may only
              register equipment that is fully compliant with BIT regulations.
              The listing of any equipment in the TSP as available for a
              reservation is evidence that the LP specifically certifies that
              all equipment is fully BIT compliant and LP will provide a
              certificate of compliance or written verification of compliance
              upon request. LP will maintain all vehicle maintenance files and
              is responsible for all BIT periodic vehicle inspections; if BMC
              performs any maintenance during the term of the rental, BMC shall
              provide records of such maintenance to LP. Any equipment that is
              no longer compliant with BIT regulations must be immediately
              removed from the TSP. The inclusion of non-compliant equipment as
              &ldquo;available&rdquo; in the TSP will constitute a breach of
              this Agreement by LP.
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">3.2.8.</span>
            <span class="c1">
              &nbsp;The information provided by the participant when registering
              is stored in the participant&#39;s profile along with credentials
              created during registration or use of the platform. The
              participant is able to access and modify its profile at any time
              through the reserved area on the TSP Software using the website or
              TSP App. The participant is able to review and access information
              relating to TSP participation, including but not limited to: (a)
              the services used; (b) vehicles identified for sublease
              toRentMyTruck; (c) vehicles that were accepted by RentMyTruck as
              TSP Vehicles; (d) status of the applicable Master Lease(s); (e)
              status of the applicable Short-Term TSP Lease(s); (f) copies of
              all applicable notices and equipment receipts, including Master
              Leases, Statement of Lease and Short-Term TSP Lease Inception
              Equipment Receipts, Short-Term TSP Lease Termination and Equipment
              Receipts, Short-Term TSP Lease(s); and other available TSP
              information and data specific to each participant.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.cudcjdmclc8o" className="display: inline">
              <span class="c5">TSP Registration</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">4.1. Vehicle Registration.</span>
            <span class="c3">
              &nbsp;In order to register a vehicle as available for use in the
              TSP, an LP must log in to the TSP Software to identify each
              vehicle, including the year, make, model, and vehicle
              identification number for each vehicle the LP is willing to
              sublease to RentMyTruck. A vehicle subject to a RentMyTruck TLSA
              is specifically authorized by RentMyTruck, notwithstanding the
              terms of the TLSA, to participate in the TSP upon acceptance of
              the vehicle as available in the TSP Software. An LP shall not
              submit a vehicle for participation in the TSP where the LP does
              not have the authority to do so. Upon completion of vehicle
              registration in the TSP Software, RentMyTruck may but is not
              required to make LP vehicles available for viewing by BMCs even if
              such vehicles are not designated as available by an LP.RentMyTruck
              may provide information about LP-registered vehicles participating
              in the TSP Program to BMCs even if such vehicles are not
              designated as available for purposes of marketing and soliciting
              BMC participation in the TSP.
              <br />
              <br />
            </span>
            <span class="c0">4.2. Physical Location and Description.</span>
            <span class="c1">
              &nbsp;The LP must provide the exact location of each vehicle,
              including the address, and a physical description of each vehicle.
              The LP must also provide the exact dates and times that each
              vehicle may be available for sublease to RentMyTruck and the exact
              location where the vehicle must be returned following its use as a
              TSP Vehicle. Where unattended pick-up is available, the LP must
              indicate whether pick-up must be an &ldquo;attended&rdquo; pick-up
              during specified hours or whether pick-up is available on a
              24-hour, &ldquo;unattended&rdquo; basis, depending on vehicle
              technology and ability to remotely access the TSP Vehicle,
              presence of a lock box containing the TSP Vehicle keys or other
              specified method for unattended pick-up. This availability may be
              updated on an ongoing basis prior to acceptance for sublease.
              Listing a vehicle as available for sublease is not acceptance of
              that offer to sublease. Such acceptance shall be deemed to occur
              (1) at the time for lease initiation as indicated by the BMC in
              its reservation for future use in the TSP App; or (2) when the BMC
              selects an immediately available unit as &ldquo;Reserved&rdquo;
              and such reservation is confirmed in the TSP App or by
              RentMyTruck. IfRentMyTruck accepts a vehicle that has been offered
              for sublease, it will then send an LP Statement of Lease and
              Short-Term TSP Lease Inception Equipment Receipt to the LP via the
              TSP website or TSP App confirming the identity of the vehicle and
              its receipt as a TSP Vehicle and identifying the amount of
              Compensation the LP may receive through participation in the TSP.
              LPs do not have any rights or responsibilities for granting
              permission or authority, expressly or impliedly, for the use of a
              TSP Vehicle by a BMC. Rather, RentMyTruck is solely responsible
              for granting any BMC permission to make use of a TSP Vehicle and
              the determination of whether a TSP Vehicle may be used by a BMC in
              the first instance.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.xblb4t3e1q6e" className="display: inline">
              <span class="c5">Locate and Access TSP Vehicles (Phase II).</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">GPS Location.</span>
            <span class="c1">
              &nbsp;All TSP Vehicles may be geo-localized through a Global
              Positioning System (&ldquo;GPS&rdquo;) signal (including but not
              limited to TSP Vehicles subject to a RentMyTruck TLSA or equipped
              with a RentMyTruck supported GPS device) and may be traced by the
              TSP administrator and other Participating Parties at any time they
              are listed (or confirmed) as available or are in use pursuant to a
              Short-Term TSP Lease, even when in use by the BMC. The available
              TSP Vehicles are located in various locations as designated by the
              LP and are available for use within certain designated service
              areas during the designated dates and times as defined by the LP
              on the TSP Software.
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">5.1. Reserving a TSP Vehicle.</span>
            <span class="c1">
              &nbsp;After a potential BMC has completed enrollment in the TSP,
              it is able to search available or participating vehicles (in
              RentMyTruck&rsquo;s discretion) through the TSP website or TSP App
              and select and reserve the vehicle(s) through the TSP website or
              TSP App. In advance of a TSP Vehicle being reserved, the TSP
              administrator provides the BMC with the identity of the TSP
              Vehicle, its exact location via the TSP App and any restrictions
              regarding service area, time of use and the location for the
              return of the vehicle. Phase II shall begin (1) at the time for
              lease initiation as indicated by the BMC in its reservation for
              future use in the TSP App; or (2) when the BMC selects an
              immediately available unit as &ldquo;Reserved&rdquo; and such
              reservation is confirmed in the TSP App or byRentMyTruck. At this
              time, RentMyTruck shall be deemed to have accepted a vehicle that
              has been offered for sublease and will send an LP Statement of
              Lease and Short-Term TSP Lease Inception Equipment Receipt to the
              LP via the TSP website or TSP App confirming the identity of the
              vehicle and its receipt byRentMyTruck as a TSP Vehicle and
              identifying the amount of Compensation the LP may receive through
              participation in the TSP. A TSP Vehicle cannot be booked for more
              than 30 days, unlessRentMyTruck expressly agrees to a term of
              thirty (30) days or more in writing and is further limited based
              upon the available dates, time and service area designated by the
              LP. A BMC must book a vehicle only when it intends to use the TSP
              Vehicle pursuant to the terms of the Short-Term TSP Lease and this
              Agreement.RentMyTruck reserves the right to verify the proper use
              of the TSP Vehicle and may suspend the BMC from the TSP, terminate
              the Short-Term TSP Lease, or remotely disable access to or
              operation of the TSP Vehicle because of a violation.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.2in696a7tt5e" className="display: inline">
              <span class="c5">Inspection of TSP Vehicle (Phase III).</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">
              The &nbsp;BMC travels to the location of the TSP Vehicle to gain
              legal possession of the TSP Vehicle.
              <br />
              <br />
            </span>
            <span class="c0">
              6.1. Access to TSP Vehicle and Exterior Inspection.
            </span>
            <span class="c1">
              &nbsp;Upon arrival, the BMC&rsquo;s representative either (1)
              meets the LP representative to obtain the TSP Vehicle keys (after
              providing the LP representative with BMC representative&rsquo;s
              driver&rsquo;s license in an attended delivery); or (2) uses the
              TSP App to unlock the vehicle or obtains the TSP Vehicle keys from
              a lock box or other specified method in an unattended delivery (if
              available). The BMC is then able to inspect and assess the vehicle
              for any signs of damage, including but not limited to, damage to
              the bodywork, wheels, interior, unusual vehicle noises,
              fault-warning lights, electrical, mechanical, engine, transmission
              or other operating systems. BMC must follow all pre-trip and
              post-trip inspection procedures provided to BMC byRentMyTruck via
              the TSP platform, including procedures for providingRentMyTruck
              with the completed inspection report and documenting all pre-trip
              and post-trip damage.
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">6.1.1. Attended delivery.</span>
            <span class="c3">
              &nbsp;In the case of an attended delivery, the BMC will meet the
              LP to gain access to the TSP Vehicle. During the attended
              delivery, the LP must (1) request and confirm BMC
              representative&rsquo;s driver&rsquo;s license and (2) confirm the
              identity of such BMC representative against the information
              provided in the TSP Software or the TSP App. In the event the BMC
              representative is not identified as an authorized party to pick up
              the TSP Vehicle, the LP shall not give the BMC the keys to the TSP
              Vehicle and the reservation will be canceled. BMC shall remain
              liable for all amounts due as if the reservation had been
              completed and shall be liable for any other costs or damages
              relating to its failure to properly identify the driver.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">6.1.2. Unattended delivery.</span>
            <span class="c1">
              &nbsp;In the case of an unattended delivery (if available), the
              BMC will locate the TSP Vehicle and use the TSP App to unlock the
              vehicle, obtain the TSP Vehicle keys through a lock box or use any
              other method specified for unattended pick-up. The BMC is then
              able to inspect and assess the vehicle for any signs of damage,
              including but not limited to, damage to the bodywork, wheels,
              interior, unusual vehicle noises, fault-warning lights,
              electrical, mechanical, engine, transmission or other operating
              systems.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">6.2.</span>
            <span class="c3">
              &nbsp;If the vehicle is unclean or not suitable for use, the BMC
              should contact the TSP administrator by calling the customer
              service (including 24/7 roadside assistance) phone number on the
              first page of this Agreement to report the faults detected or (if
              available) such faults may be reported using the TSP App, and the
              BMC may cancel the booking. If a BMC decides to cancel the
              booking, the vehicle is once again made available through the TSP
              Software and can be booked by other potential BMCs. In general and
              when necessary, the BMC should contact the TSP administrator
              directly for assistance and to provide any other notifications by
              calling the customer service phone number. If BMC does not
              identify faults or damage with the vehicle in the TSP App or with
              a TSP administrator, then the vehicle shall be deemed to not have
              any faults or damage at the time BMC takes possession and control
              of the vehicle.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">6.3.</span>
            <span class="c1">
              &nbsp;If the TSP Vehicle is acceptable, the BMC will take
              possession and control of the vehicle (upon completion of vehicle
              inspection and execution of the inspection form) and be asked to
              confirm or reject the Short-Term TSP Vehicle Lease, execute the
              Short-Term TSP Lease, and accept the Statement of Lease and
              Short-Term TSP Lease Inception Equipment Receipt for use of the
              vehicle. As soon as the BMC accepts the terms of the Short-Term
              TSP Lease, the lease comes into effect. If BMC fails to confirm
              acceptance of the Short-Term TSP Lease or cancel the booking
              within one hour after accessing the vehicle or within four hours
              of the time of reservation or if the TSP administrator receives
              GPS confirmation the vehicle is being operated, then BMC will be
              deemed to have consented to the acceptance of the Short-Term TSP
              Lease. A copy of the LP Statement of Lease and Short-Term TSP
              Lease Inception Equipment Receipt and the BMC Statement of Lease
              and Short-Term TSP Lease Inception Equipment Receipt are available
              in the TSP App. A copy of the BMC Statement of Lease and
              Short-Term TSP Lease Inception Equipment Receipt must be kept
              available in the TSP Vehicle for inspection by law enforcement at
              all times.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.drim5kcg60vb" className="display: inline">
              <span class="c5">Rules of Conduct for TSP Vehicle Use.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">7.1.</span>
            <span class="c3">
              &nbsp;For each booking, the BMC agrees to comply fully with the
              Short-Term TSP Lease and this Agreement. If there is a conflict
              between the terms of this Agreement and the terms of Master Lease
              or Short-Term TSP Lease, the terms of this Agreement shall apply.
              <br />
              <br />
            </span>
            <span class="c0">7.2.</span>
            <span class="c3">
              &nbsp;The TSP Vehicles are to be used in the manner and within the
              limits established by the applicable federal and state traffic
              laws, rules or regulations and any prescriptions or limits
              provided by RentMyTruck at the time of the booking and/or during
              the use of the TSP Vehicle.
              <br />
              <br />
            </span>
            <span class="c0">7.3.</span>
            <span class="c3">
              &nbsp;Only drivers operating under the motor carrier operating
              authority where required or explicit authorization and supervision
              where no motor carrier operating authority is required of a BMC
              registered with the TSP Software and in possession of a valid
              driver&rsquo;s license and in accordance with this Agreement can
              use the TSP Vehicles.
              <br />
              <br />
            </span>
            <span class="c0">7.4.</span>
            <span class="c3">
              &nbsp;The BMC must notify RentMyTruck of any changes to the status
              of any applicable driver&rsquo;s license, operating authority, or
              auto-liability insurance such as withdrawal, suspension, or
              expiration.
              <br />
              <br />
            </span>
            <span class="c0">7.5.</span>
            <span class="c3">
              &nbsp;A TSP Vehicle may only be operated under the motor carrier
              operating authority where required or explicit authorization and
              supervision where no motor carrier operating authority is required
              of the BMC registered with the TSP Software whose name appears on
              the current booking, the Master Lease, and the Short-Term TSP
              Lease. It is strictly prohibited to allow drivers not registered
              with the TSP or not operating pursuant to the motor carrier
              operating authority where required or explicit authorization and
              supervision where no motor carrier operating authority is required
              of the BMC to drive the TSP Vehicle, even if the third party is
              another BMC or is operating under the motor carrier operating
              authority of a BMC and is authorized driver registered in the TSP.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">7.6.</span>
            <span class="c3">
              &nbsp;While operating the TSP Vehicle, the driver must not be
              under the influence of any type of drug, alcohol or medication
              which could potentially limit the driver&rsquo;s ability to drive.
              <br />
              <br />
            </span>
            <span class="c0">7.7.</span>
            <span class="c3">
              &nbsp;In general, the TSP Vehicles are not permitted access to
              areas that are prohibited to such vehicles (e.g., pedestrian areas
              or roads with weight restrictions) unless proper permits or
              licenses have been obtained as outlined in the Master Lease and
              the Short-Term TSP Lease.
              <br />
              <br />
            </span>
            <span class="c0">7.8.</span>
            <span class="c3">
              &nbsp;Any financial penalties for violation of the laws, rules or
              regulations relating to the operation or movement of TSP Vehicles
              (traffic codes, federal regulations, municipal regulations,
              failure to pay tolls and parking fines) and any costs, expenses,
              liabilities or damages (including costs of collecting such
              penalties, costs, expenses, liabilities or damages) arising out or
              related to such violation will be the sole responsibility of the
              BMC during the term of the Short-Term TSP Lease. Such violations
              may also be subject to an administrative fee charged by
              RentMyTruck. RentMyTruck will use commercially reasonable efforts
              to collect the full amount of any of the items set forth in this
              Paragraph from BMC but shall not be responsible to LP for any
              amounts RentMyTruck is unable to collect.
              <br />
              <br />
            </span>
            <span class="c0">7.9.</span>
            <span class="c1">
              &nbsp;Smoking in TSP Vehicles is strictly prohibited. The BMC is
              responsible for cleaning the TSP Vehicle and shall be charged a
              fee for failing to properly clean the TSP Vehicle prior to
              returning it.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.rqsne5e3wktp" className="display: inline">
              <span class="c5">End of Short-Term TSP Lease.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">
              After use of the TSP Vehicle, the BMC must return the TSP Vehicle
              to the designated location provided in the TSP App. The BMC may
              not return the TSP Vehicle to any location other than the location
              specified in the TSP App. Failure to observe this obligation will
              result in the BMC being held responsible for continuing payments
              for the use of the TSP Vehicle and all expenses incurred in
              recovering the TSP Vehicle
              <br />
              <br />
            </span>
            <span class="c0">8.1.</span>
            <span class="c1">&nbsp;The BMC must also ensure that:</span>
          </li>
        </ol>
        <ol class="c6 lst-kix_k6vku0be8pt4-1 start" start="1">
          <li class="c9 li-bullet-0">
            <span class="c1">All windows and doors are closed;</span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c1">The brakes are engaged;</span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c1">Wheels are properly chocked, as needed;</span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c1">
              The radio, lights and all electronics are switched off;
            </span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c1">
              The ignition key and the Fuel Card, if applicable, are placed back
              in their original position inside the designated storage
              compartments;
            </span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c1">
              All documents, manuals and accessories are in order and in their
              original position.
            </span>
          </li>
          <li class="c9 li-bullet-0">
            <span class="c1">
              BMC &nbsp;has &nbsp;followed all post-trip inspection procedures,
              &nbsp;including procedures for providing RentMyTruck with the
              completed inspection report and BMC has documented all post-trip
              damage, as required.
            </span>
          </li>
        </ol>
        <ol class="c6 lst-kix_k6vku0be8pt4-0" start="27">
          <li class="c4 li-bullet-0">
            <span class="c3">
              Failure to observe these obligations will result in fines and
              penalties.
              <br />
              <br />
            </span>
            <span class="c0">8.2.</span>
            <span class="c3">
              &nbsp;To conclude the use of the TSP Vehicle, the BMC must select
              &ldquo;End Lease&rdquo; on the TSP App. The BMC will then receive
              a BMC Short-Term TSP Lease Termination and Equipment Receipt via
              the TSP App and a summary of the TSP Vehicle use, including hours
              of use, mileage and total payment due. In the event of any anomaly
              during the &ldquo;End Lease&rdquo; procedure or if the email
              communications mentioned above are not received, the BMC is to
              contact the TSP administrator. Contemporaneously with the delivery
              of the Short-Term TSP Lease Termination and Equipment Receipt to
              the BMC, the TSP administrator will send an LP Short-Term TSP
              Lease Termination and Equipment Receipt to the LP via the TSP App.
              <br />
              <br />
            </span>
            <span class="c0">
              8.3. Cleaning the TSP Vehicle and Lost Items.
            </span>
            <span class="c1">
              &nbsp;The BMC must leave the TSP Vehicle clean. RentMyTruck
              reserves the right to charge the BMC a cleaning fee for any
              unscheduled cleaning that is necessary. The TSP administrator is
              to be notified of any items left in the TSP Vehicle, and the TSP
              administrator will provide all instructions necessary to recover
              these items by the BMC.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.an8dbyaub3ln" className="display: inline">
              <span class="c5">Rates, Payment, and Billing.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">9.1. Compensation Rates.</span>
            <span class="c3">
              &nbsp;The compensation for LPs will be set forth in the electronic
              notice accompanying the LP Statement of Lease and Short-Term TSP
              Lease Inception Equipment Receipt and published on the TSP website
              and/or TSP App.
              <br />
              <br />
            </span>
            <span class="c0">9.2. Short-Term TSP Lease Payments.</span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;shall issue a statement at the end of the Short-Term TSP
              Lease setting forth amounts due pursuant to the applicable
              Short-Term TSP Lease. Fixed charges (such as the daily fixed
              rental rate) (collectively, the &ldquo;Fixed Charges&rdquo;) will
              be charged at the time of reservation and any other charges will
              be charged at the end of the Short-Term TSP Lease. If a Short-Term
              TSP Lease is extended beyond its original term or if a TSP Vehicle
              is operated outside the service area, RentMyTruck reserves the
              right to charge additional amounts (such amounts, the
              &ldquo;Additional Amounts&rdquo;). When the amount due for the
              Short-Term TSP Lease is charged, the TSP Software automatically
              calculates the total amount to be charged discounting any credits
              for the BMC and including additional charges for any additional
              services requested or applicable fines, penalties or
              administration fees, all of which shall also be considered
              Additional Amounts.
              <br />
              <br />
            </span>
            <span class="c0">9.3. Payment and Compensation Invoicing.</span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;will pay Compensation (subject to Section 9.4, Right to
              Offset, if applicable) to LPs and charge the BMCs through the
              issuance of invoices that will show the debits and/or credits for
              participation in the TSP for a completed transaction, specifying
              the use and any additional compensation or costs (for example,
              fines and penalties) for a particular vehicle. Notwithstanding
              anything set forth herein or in any other agreement between the
              parties, the parties acknowledge and agree thatRentMyTruck&rsquo;s
              obligation to pay Compensation to LPs shall only extend to
              Compensation for the Fixed Charges for the original Short Term TSP
              Lease of a TSP Vehicle. RentMyTruck shall pay Compensation to LPs
              every thirty (30) days during active reservations, with an
              additional three (3) to five (5) days for processing. For all
              Additional Amounts, the proceeds of any such Additional Amounts
              actually received by RentMyTruck from BMC will be paid to LP, and
              LP acknowledges that RentMyTruck shall not be required to remit
              any such proceeds to LP unless and until received from BMC. The
              invoice will be issued in electronic format and can be downloaded
              from the TSP website or TSP App. Subject to the provisions set
              forth in this Section 9.3, RentMyTruck will electronically remit
              all payments (subject to Section 9.4, Right to Offset, if
              applicable) to LPs and debit all amounts due from BMCs using the
              financial information and payment method specified during TSP
              registration.
              <br />
              <br />
            </span>
            <span class="c0">9.4. Right to Offset.</span>
            <span class="c1">
              &nbsp;Notwithstanding anything to the contrary herein or in any
              other TSP Documentation, in the event of a breach or default by LP
              or BMC in connection with the LP Master Lease or BMC Master Lease,
              respectively, or any other agreements between LP andRentMyTruck or
              BMC and RentMyTruck, including, but not limited to any lease (e.g.
              TLSA), rental and/or maintenance agreements (the &ldquo;Other
              Agreements&rdquo;), and other agreements between LP and
              RentMyTruck affiliates or BMC and RentMyTruck affiliates, then
              RentMyTruck shall be entitled to reduce and/or offset the amount
              of any payment otherwise payable to LP or BMC under the Master
              Lease or any TSP Documentation by the amount of any obligation of
              the LP or BMC to RentMyTruck that is or becomes due and owing
              under the Master Lease, any TSP Documentation, or any Other
              Agreements and the LP or BMC shall be deemed to have consented to
              such reduction and/or offset.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.ctkij2rspd8k" className="display: inline">
              <span class="c5">Auto Liability Insurance.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">
              The BMC agrees to furnish and maintain, at its sole cost, a policy
              of auto liability insurance with combined single limits of at
              least $1 million per occurrence for death, bodily injury and
              property damage for the ownership, maintenance, use and operation
              of each TSP Vehicle for the periods of time that the BMC is
              required to have primary auto liability coverage under the terms
              of paragraph 10.2. Coverage provided by the BMC must be endorsed
              to extend coverage toRentMyTruck as an &ldquo;additional
              insured&rdquo; and &ldquo;loss payee&rdquo; for the ownership,
              maintenance, use and operation of each TSP Vehicle for the periods
              of time that the BMC is required to have primary auto liability
              coverage under the terms of paragraph 10.2. The terms of the
              policy and coverage must be acceptable to RentMyTruck and must not
              be issued using a &ldquo;Scheduled Auto&rdquo; policy. The LP
              agrees to furnish and maintain, at its sole cost, a policy of auto
              liability insurance with combined single limits of at least $1
              million per occurrence for death, bodily injury and property
              damage for the ownership, maintenance, use and operation of each
              TSP Vehicle for the periods of time that the LP is required to
              have primary auto liability coverage under the terms of paragraph
              10.2. Notwithstanding the foregoing LP insurance requirements,
              RentMyTruck, in its sole discretion, may waive or modify such LP
              insurance requirements for a particular LP, in whole or in part,
              applicable only to the TSP Vehicle transactions designated by
              RentMyTruck and only for the time period designated by
              RentMyTruck, if the LP meets all other TSP participation
              requirements and either (1) is not a motor carrier (such as a duly
              registered, authorized equipment lessor or similar entity) or (2)
              is a motor carrier but is not required under applicable laws to
              register with the U.S. Department of Transportation
              (&ldquo;DOT&rdquo;) and its Federal Motor Carrier Safety
              Association (&ldquo;FMCSA&rdquo;) (such as a motor carrier
              operating certain light duty vehicles or a similarly situated
              motor carrier). Any such waiver or modified LP insurance
              requirements will be noted by RentMyTruck in its internal LP
              registration files. Upon request, LP may request written
              confirmation of such waiver or modification. RentMyTruck may, in
              its sole discretion, revoke such LP insurance waiver or
              modifications at any time. Notwithstanding the BMC and LP
              insurance requirements set forth above (and whether or not
              RentMyTruck has waived or modified the insurance requirements for
              a particular LP), in all circumstances the BMC and LP shall
              maintain any applicable insurance covering the TSP Vehicle as may
              be required by applicable law, including without limitation any
              statutory compulsory or financial responsibility minimum limits
              applicable to the BMC or LP. As applicable, the LP and the BMC
              will be responsible for the entire deductible amount under any
              applicable insurance policies. The auto liability insurance must
              provide that coverage is primary and not additional or excess over
              any other insurance coverage and must provide coverage for all
              statutory requirements imposed upon the Participating Carrier and
              RentMyTruck for the periods of time that the party is required to
              have primary auto liability coverage under the terms of paragraph
              10.2. Further, the liability insurance must provide that it cannot
              be canceled or materially altered unless 30 days&rsquo; prior
              written notice is provided to RentMyTruck.
              <br />
              <br />
            </span>
            <span class="c0">
              10.1. Applicability of Auto Liability Insurance.
            </span>
            <span class="c1">
              &nbsp;As noted above, participation in the TSP is separated into
              the following three distinct &ldquo;phases&rdquo;:
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">Phase I</span>
            <span class="c3">
              &nbsp;begins when an LP logs onto the TSP App or TSP website and
              designates a vehicle as being available (or confirms
              RentMyTruck&rsquo;s request in the TSP to make the vehicle
              available, if applicable) for sublease to RentMyTruck and ends at
              (1) the time for lease initiation as indicated by the BMC in its
              reservation for future use in the TSP App; or (2) when the BMC
              selects an immediately-available unit as &ldquo;Reserved and such
              reservation is confirmed in the TSP App or by RentMyTruck.&rdquo;
              <br />
              <br />
            </span>
            <span class="c0">Phase II</span>
            <span class="c3">
              &nbsp;begins at (1) the time for lease initiation as indicated by
              the BMC in its reservation for future use in the TSP App; or (2)
              when the BMC selects an immediately-available unit as
              &ldquo;Reserved&rdquo; and such reservation is confirmed in the
              TSP App or byRentMyTruck, and ends when the BMC agrees to the
              terms of the Short-Term TSP Lease.
              <br />
              <br />
            </span>
            <span class="c0">Phase III</span>
            <span class="c1">
              &nbsp;begins when the BMC enters into a valid Short-Term TSP Lease
              withRentMyTruck and ends when RentMyTruck issues and delivers a
              Short-Term TSP Lease Termination and Equipment Receipt to the LP.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">10.2.</span>
            <span class="c3">
              &nbsp;LP and its auto liability insurer will be responsible for
              providing coverage during Phase I of the TSP. BMC and its auto
              liability insurer will be responsible for providing coverage
              during Phases II and III of the TSP. A PARTICIPATING
              CARRIER&rsquo;S INSURANCE COVERAGE MAY BE IMPACTED BY
              PARTICIPATION IN THE TSP. IT IS THE PARTICIPATING CARRIER&rsquo;S
              RESPONSIBILITY TO UNDERSTAND ITS INSURANCE COVERAGE AND THE IMPACT
              PARTICIPATION IN THE TSP MAY HAVE ON THIS COVERAGE.
              <br />
              <br />
            </span>
            <span class="c0">10.3.</span>
            <span class="c1">
              &nbsp;A Participating Party may authorize RentMyTruck to
              facilitate, on the Participating Party&rsquo;s behalf, the
              insurance coverages required by paragraph 10 (or other coverage
              approved by RentMyTruckk). For each coverage elected by the
              Participating Party and facilitated byRentMyTruck, RentMyTruck
              will charge or otherwise recover the amounts due for such coverage
              from the Participating Party, plus applicable taxes and an
              administrative fee. If a Participating Party fails to provide
              proper evidence of the purchase or maintenance of the insurance
              required by paragraph 10, RentMyTruck is authorized but not
              required to obtain the insurance (or other coverage approved by
              RentMyTruck) at the Participating Party&rsquo;s expense and charge
              or otherwise recover the amounts due from the Participating Party,
              plus applicable taxes and fees. The Participating Party recognizes
              that RentMyTruck is not in the business of selling insurance, and
              any insurance coverage requested by a Participating Party from
              RentMyTruck is subject to all of the terms, conditions, and
              exclusions of the actual policy.RentMyTruck will ensure that a
              Participating Party is provided with a certificate of insurance
              for each facilitated policy, and RentMyTruck will provide the
              Participating Party with a copy of each such policy upon request.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.jvagsrjjx94a" className="display: inline">
              <span class="c5">Indemnification.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">11.1.</span>
            <span class="c3">
              &nbsp;Each Participating Party agrees to defend, release,
              indemnify and hold RentMyTruck harmless for all damages and
              defense costs: (1) in excess of or not covered by your liability
              insurance arising out of or related to the ownership, maintenance,
              use or operation of each TSP Vehicle; (2) arising out of or
              related to the death or injury to you, your drivers, employees or
              agents caused by or related to the ownership, maintenance, use or
              operation of a TSP Vehicle; (3) arising out of or related to your
              violation of the terms and conditions of this Agreement; or (4)
              arising out of your failure to procure and maintain the required
              liability insurance as provided for and required under this
              Agreement and as required under applicable laws.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">11.2.</span>
            <span class="c3">
              &nbsp;The BMC agrees to defend, release, indemnify and hold
              &nbsp;RentMyTruck and the LP harmless from all damages, costs and
              expenses associated with the use of a TSP Vehicle, including but
              not limited to the following: (1) the BMC&rsquo;s failure, refusal
              or delay to return a TSP Vehicle to the designated location in
              accordance with the terms and conditions of the BMC Short-Term TSP
              Lease; (2) any cost, expense or liability for damages to the TSP
              Vehicle related to or arising out of the BMC&rsquo;s use of the
              vehicle; (3) any cost, expense or liability for the cleaning or
              repair of the TSP Vehicle related to or arising out of the
              BMC&rsquo;s use of the TSP Vehicle; (4) any cost, expense or
              liability related to the payment of fuel tax, vehicle registration
              or plates incurred by the LP related to or arising out of the
              BMC&rsquo;s use of the TSP Vehicle; and (5) any cost, expense or
              operational charges, including but not limited to traffic
              violations, tolls, parking citations, permits or other fines, fees
              or penalties incurred by the LP arising out of or related to the
              BMC&rsquo;s use of the TSP Vehicle.
              <br />
              <br />
            </span>
            <span class="c0">11.3.</span>
            <span class="c1">
              &nbsp;LP shall indemnify RentMyTruck for any liability or damages
              relating to or arising out of LP&rsquo;s negligence or failure to
              maintain the TSP Vehicle (&ldquo;LP&rsquo;s Liability&rdquo;); and
              LP shall provide proof of insurance coverage for such liability
              upon enrollment in the TSP, except to the extent RentMyTruck, in
              its sole discretion, waives the LP insurance requirements pursuant
              to Section 10 of this Agreement.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.pkp8a38obaug" className="display: inline">
              <span class="c5">TSP Vehicle Breakdown.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              The BMC must contact the TSP administrator by calling the customer
              service (including 24/7 roadside assistance) phone number on the
              first page of this Agreement or (if available) via the TSP website
              or TSP App to report a TSP Vehicle breakdown. The BMC must
              describe the problem and provide the exact address where the TSP
              Vehicle is parked. After exiting the TSP Vehicle, the driver of
              the BMC is required to stay with the TSP Vehicle unless the TSP
              Vehicle is disabled in a position that would block traffic or
              unless advised otherwise by the TSP administrator.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.sf307ulhop8r" className="display: inline">
              <span class="c5">Claims.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              If a TSP Vehicle is involved in an accident, the BMC must call the
              TSP administrator immediately to describe the nature of the
              accident and potential damage to the TSP Vehicle and provide the
              exact location and address where the TSP Vehicle is located. The
              TSP administrator will provide instructions and may send a
              representative to the location of the accident. In this case, and
              as long as the driver of the BMC does not require medical
              attention, the BMC must remain with the vehicle until the
              representative arrives unless advised otherwise by the TSP
              administrator. The BMC must follow all instructions received from
              the TSP administrator and/or representative who arrives. The BMC
              must also contact the appropriate authorities and provide copies
              of any accident report and any reports issued by intervening
              authorities (local police and any other authorities). If the
              representative is not able to intervene, the TSP administrator
              will provide instructions to the BMC and, unless advised otherwise
              by TSP administrator, BMC must leave the accident report and any
              other reports in the storage compartment inside the vehicle. If
              the TSP Vehicle can no longer be driven, the BMC can make
              arrangements to have the vehicle towed to a repair center and must
              contact the TSP administrator for additional instructions. If the
              TSP Vehicle can still be driven and if the BMC wishes to operate
              the vehicle, the BMC may reach a destination where it can request
              authorization from the TSP administrator to continue to use the
              TSP Vehicle. The BMC must promptly file any and all claims.
              Failure to comply with these instructions may result in the
              application of penalties.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.by2m4wkw4zjz" className="display: inline">
              <span class="c5">
                Theft of TSP Vehicle, Robbery and Acts of Vandalism.
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              In the event of theft of the vehicle, robbery or acts of vandalism
              while the TSP Vehicle is being used by the BMC, the BMC must
              contact the TSP administrator immediately and provide the
              vehicle&rsquo;s last location and describe the related
              circumstances and events. In this case, the BMC must remain with
              the vehicle until the TSP administrator provides further
              instructions. The BMC must also report the incident to the
              appropriate authorities.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.lwt77xdm5dqn" className="display: inline">
              <span class="c5">Geo-location.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              While in active service under a Short-Term TSP Lease, geo-location
              information about TSP Vehicles (including but not limited to
              vehicles subject to a RentMyTruck TLSA or equipped with a
              &nbsp;RentMyTruck supported GPS device) may be provided to
              RentMyTruck and the LP in order to provide accurate tracking of
              leased units. The parties acknowledge and agree that: (a) TSP
              Vehicle geo-location information may be obtained by RentMyTruck
              and the LP while a TSP Vehicle is in service as an available
              vehicle under Phase I or is providing active service through
              Phases II and III, and (b) the approximate location of TSP
              Vehicles may be displayed to Participating Parties during the
              selection, active use and return phases of operation. In addition,
              RentMyTruck may monitor, track and share with third parties a TSP
              Vehicle&rsquo;s geo-location information obtained by the TSP
              Software for safety and security purposes. Purposefully tampering
              with geo-location information or disabling geo-location services
              while logged in to the TSP Software shall be deemed a material
              breach of the Agreement and a basis for immediate termination of
              the Agreement. RentMyTruck reserves the right to remotely disable
              access to or operation of a TSP Vehicle in the event of such a
              breach by BMC.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.4wdfm4shuvqj" className="display: inline">
              <span class="c5">Term and Termination.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">16.1. Term.</span>
            <span class="c3">
              &nbsp;This Agreement is effective as of the Effective Date and
              will continue to be in effect for a period of one (1) year. The
              parties may, by mutual consent, renew the Agreement for an
              additional one (1) year term.
              <br />
              <br />
            </span>
            <span class="c0">16.2. Termination.</span>
            <span class="c3">
              &nbsp;Either party may terminate this Agreement at any time and
              for any reason by giving thirty (30) days&rsquo; notice to the
              other party in accordance with paragraph 20.3. At the time of
              termination of the Agreement, as set forth in the notice,
              RentMyTruck shall cancel the Participating Party&rsquo;s ability
              to access the TSP Software. The parties may, by mutual written
              agreement, terminate the Agreement with immediate effect.
              Termination shall not be effective until all TSP Vehicles under a
              BMC Short-Term TSP Lease have been returned in accordance with the
              terms and conditions of the TSP, the Master Lease and Short-Term
              TSP Lease. &nbsp;RentMyTruck reserves the right to remotely
              disable access to or operation of a TSP Vehicle as of the
              termination date in the event of termination by BMC.
              <br />
              <br />
            </span>
            <span class="c0">16.3. Grounds for Immediate Termination.</span>
            <span class="c3">
              &nbsp;A Participating Party shall have access to the TSP Software
              for all periods for which the Participating Party maintains
              approved status. If any party (or any party&rsquo;s driver or
              other workers) does any of the following, the other party may
              elect to terminate this Agreement immediately: (i)
              commits&mdash;or attempts, conspires, or threatens to
              commit&mdash;a felony or intentional tort; (ii) violates any
              applicable federal, state, local, Native American tribal, or
              foreign law, regulation, or ordinance; or (iii) materially
              breaches this Agreement. RentMyTruck reserves the right to
              remotely disable BMC access to or operation of a TSP Vehicle in
              the event of such immediate termination.
              <br />
              <br />
            </span>
            <span class="c0">16.4. Inactivity.</span>
            <span class="c3">
              &nbsp;If a Participating Party is inactive for more than 180 days,
              RentMyTruck may deactivate the Participating Party&rsquo;s
              account. If the Participating Party&rsquo;s account is deactivated
              for inactivity, it may apply to re-enroll to obtain access to the
              TSP Software.
              <br />
              <br />
            </span>
            <span class="c0">16.5. Removal of TSP Software.</span>
            <span class="c3">
              &nbsp;A Participating Party is required to and agrees to uninstall
              the TSP Software application on all devices upon termination of
              this Agreement.
              <br />
              <br />
            </span>
            <span class="c0">16.6. Survival.</span>
            <span class="c1">
              &nbsp;All of the defense, release, indemnification and hold
              harmless provisions of this Agreement shall survive its
              Termination (for any reason) or expiration.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.q0eoxxwnxm45" className="display: inline">
              <span class="c5">Disclosure of Information</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">17.1. Proprietary Software.</span>
            <span class="c3">
              &nbsp;The Participating Party shall not, and shall not allow any
              third party to: (a) license, sublicense, sell, resell, transfer,
              assign, distribute or otherwise provide or make available to any
              other party the TSP Software in any way; (b) modify or make
              derivative works based upon the TSP Software; (c) improperly use
              the TSP Software, including creating Internet &ldquo;links&rdquo;
              to any part of the TSP Software, &ldquo;framing&rdquo; or
              &ldquo;mirroring&rdquo; any part of the TSP Software on any other
              websites or systems, or &ldquo;scraping&rdquo; or otherwise
              improperly obtaining data from the TSP Software; (d) reverse
              engineer, decompile, modify, or disassemble the TSP Software,
              except as allowed under applicable law; or (e) send spam or
              otherwise duplicative or unsolicited messages. In addition, the
              Participating Party shall not, and shall not allow any other party
              to, access or use the TSP Software to (i) design or develop a
              competitive or substantially similar product or service; (ii) copy
              or extract any features, functionality, or content thereof; (iii)
              launch or cause to be launched on or in connection with the TSP
              Software an automated program or script, including web spiders,
              crawlers, robots, indexers, bots, viruses or worms, or any program
              which may make multiple server requests per second, or unduly
              burden or hinder the operation and/or performance of the TSP
              Software; or (iv) attempt to gain unauthorized access to the TSP
              Software or its related systems or networks.
              <br />
              <br />
            </span>
            <span class="c0">17.2. Confidentiality.</span>
            <span class="c3">
              &nbsp;The Participating Party acknowledges that RentMyTruck&#39;s
              trade secrets, private or secret processes as they exist from time
              to time, and information concerning products, processes, methods,
              sales activities and procedures, promotion and pricing techniques,
              and credit and financial data concerning customers, as well as
              information relating to the management, operation, or planning of
              RentMyTruck as it relates to services and operations provided
              under this Agreement (&ldquo;Proprietary Information&rdquo;) are
              valuable, special, and unique assets of RentMyTruck, access to and
              knowledge of which may be essential to the performance of the
              Participating Party&rsquo;s duties under this Agreement. The
              Participating Party agrees that all Proprietary Information
              obtained by the Participating Party as a result of the
              Participating Party&rsquo;s relationship with RentMyTruck under
              this Agreement shall be considered confidential. In recognition of
              this fact, the Participating Party agrees that the Participating
              Party will not, during and after the Term, disclose any of such
              Proprietary Information to any person or entity for any reason or
              purpose whatsoever, and the Participating Party will not make use
              of any Proprietary Information for the Participating Party&rsquo;s
              own purposes or for the benefit of any other person or entity
              (except RentMyTruck) under any circumstances.
              <br />
              <br />
            </span>
            <span class="c0">17.3. Privacy.</span>
            <span class="c3">
              &nbsp;The Participating Parties acknowledge and agree that the TSP
              Software may provide certain information regarding other carriers
              and equipment, including name, contact information, photo, and
              location. Participating Parties shall not contact any other
              Participating Parties or use any such personal information for any
              reason other than for the purpose of fulfilling the Agreement and
              shall treat all such information as confidential. RentMyTruck may
              collect Participating Party sensitive data during the course of
              application for, and use of, the TSP Software, or may obtain
              information about Participating Parties from third parties. Such
              information may be stored, processed, transferred, and accessed by
              RentMyTruck, third parties, and service providers for business
              purposes, including marketing, lead generation, service
              development, and improvement, analytics, industry and market
              research, and such other purposes consistent with
              RentMyTruck&rsquo;s legitimate business needs. The Participating
              Party expressly consents to such use of its personal data.
              <br />
              <br />
            </span>
            <span class="c0">17.4. Equitable Relief.</span>
            <span class="c1">
              &nbsp;In the event of any material breach or threatened material
              breach by any of Participating Party&rsquo;s workers of this
              paragraph 17, RentMyTruck will be entitled to an injunction,
              restraining the Participating Party&rsquo;s workers from
              disclosing, in whole or in part, the list of RentMyTruck&rsquo;s
              customers or other Participating Parties, and all other
              Proprietary Information or information relating to the TSP
              Software. RentMyTruck will be irreparably damaged in the event of
              any material breach of this paragraph 17 by a Participating Party.
              Accordingly, in addition to any other legal or equitable remedies
              that may be available to RentMyTruck, the Participating Party
              agrees that RentMyTruck will be able to seek and obtain immediate
              injunctive relief in the form of a temporary restraining order
              without notice, preliminary injunction, or permanent injunction
              against the Participating Party&rsquo;s workers to enforce this
              confidentiality provision and that RentMyTruck will not be
              required to post any bond or other security and will not be
              required to demonstrate any actual injury or damage to obtain
              injunctive relief from the courts. Nothing in this section should
              be construed as prohibiting RentMyTruck from pursuing any remedies
              available toRentMyTruck at law or in equity for the material
              breach, including the recovery of monetary damages from the
              Participating Party.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.lpu7vglle3wm" className="display: inline">
              <span class="c5">Disclaimer of Warranty; Indemnity.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">18.1. DISCLAIMER OF WARRANTY. </span>
            <span class="c3">RENTMYTRUCK </span>
            <span class="c3">
              &nbsp;PROVIDES, AND THE PARTICIPATING PARTY ACCEPTS, THE TSP
              SOFTWARE ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;
              BASIS. RENTMYTRUCK &nbsp;DOES NOT REPRESENT, WARRANT, OR GUARANTEE
              THAT ACCESS TO OR USE OF THE TSP SOFTWARE: (A) WILL BE
              UNINTERRUPTED OR ERROR-FREE, OR (B) WILL RESULT IN ANY REQUESTS
              FOR EQUIPMENT OR EQUIPMENT AVAILABLE FOR USE. RENTMYTRUCK
              FUNCTIONS AS AN ON-DEMAND LEAD GENERATION AND RELATED SERVICE ONLY
              AND MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES AS TO THE
              ACTIONS OR INACTIONS OF THE PARTICIPATING PARTIES WHO MAY REQUEST
              OR RECEIVE SERVICES, ANDRentMyTruck DOES NOT SCREEN OR OTHERWISE
              EVALUATE CUSTOMERS BY USING THE TSP SOFTWARE BEYOND BASIC
              INSURANCE AND AUTHORITY INFORMATION. THE PARTICIPATING PARTY
              ACKNOWLEDGES AND AGREES THAT IT MAY BE INTRODUCED TO A THIRD PARTY
              THAT MAY POSE HARM OR RISK TO THE PARTICIPATING PARTY&rsquo;S
              EQUIPMENT OR OTHER THIRD PARTIES. PARTICIPATING PARTY IS ADVISED
              TO TAKE REASONABLE PRECAUTIONS WITH RESPECT TO INTERACTIONS WITH
              THIRD PARTIES ENCOUNTERED IN CONNECTION WITH THE USE OF THE TSP
              SOFTWARE. NOTWITHSTANDING RENTMYTRUCK&rsquo;S LIMITED ROLE,
              RENTMYTRUCK EXPRESSLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR
              OMISSION OF THE PARTICIPATING PARTY, ANY CUSTOMER OR OTHER THIRD
              PARTY. RENTMYTRUCK &nbsp;DOES NOT GUARANTEE THE AVAILABILITY OF
              THE TSP SOFTWARE. PARTICIPATING PARTY ACKNOWLEDGES AND AGREES THAT
              THE TSP SOFTWARE MAY BE UNAVAILABLE AT ANY TIME AND FOR ANY REASON
              (e.g., DUE TO SCHEDULED MAINTENANCE OR NETWORK FAILURE). FURTHER,
              THE TSP SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER
              PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
              COMMUNICATIONS, AND RENTMYTRUCK &nbsp;IS NOT RESPONSIBLE FOR ANY
              DELAYS, DELIVERY FAILURES, OR OTHER DAMAGES, LIABILITIES OR LOSSES
              THAT MAY RESULT.
              <br />
              <br />
            </span>
            <span class="c0">18.2. Attorneys&rsquo; Fees.</span>
            <span class="c3">
              &nbsp;If any party initiates litigation to enforce its rights
              under the Agreement, the prevailing party in such litigation will
              also be entitled to receive from the other party its reasonable
              attorneys&rsquo; fees (pre-trial, trial, and appellate) and costs
              (including those paid to a collection agency).
              <br />
              <br />
            </span>
            <span class="c0">18.3. LIMITATION OF LIABILITY.</span>
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;SHALL NOT BE LIABLE UNDER OR RELATED TO THIS AGREEMENT FOR,
              ANY OF THE FOLLOWING, WHETHER BASED ON CONTRACT, TORT, OR ANY
              OTHER LEGAL THEORY, EVEN IF A PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES: (i) ANY INCIDENTAL, PUNITIVE,
              SPECIAL, EXEMPLARY, CONSEQUENTIAL, OR OTHER INDIRECT DAMAGES OF
              ANY TYPE OR KIND; OR (ii) PARTICIPATING PARTY&rsquo;S OR ANY THIRD
              PARTY&rsquo;S PROPERTY DAMAGE, OR LOSS OR INACCURACY OF DATA, OR
              LOSS OF BUSINESS, REVENUE, PROFITS, USE OR OTHER ECONOMIC
              ADVANTAGE. EXCEPT FOR RENTMYTRUCK&rsquo;S OBLIGATIONS TO PAY
              AMOUNTS DUE TO THE PARTICIPATING PARTY PURSUANT TO PARAGRAPH 9,
              BUT SUBJECT TO ANY LIMITATIONS OR OTHER PROVISIONS CONTAINED IN
              THIS AGREEMENT WHICH ARE APPLICABLE THERETO, IN NO EVENT SHALL THE
              LIABILITY OF RENTMYTRUCK UNDER THIS AGREEMENT EXCEED THE AMOUNT OF
              FEES ACTUALLY PAID TO OR DUE TO PARTICIPATING PARTY AS A RESULT OF
              PARTICIPATION IN THE TSP IN THE SIX (6) MONTH PERIOD IMMEDIATELY
              PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. Nothing in this
              Agreement limits or excludes either party&rsquo;s liability for
              any matter that may not be limited or excluded by applicable laws,
              rules, or regulations.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.dc701jbchv5y" className="display: inline">
              <span class="c5">
                Disclaimer Regarding Geo Location/Audio/Video Recording.
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              By participating in the TSP, each Participating Party acknowledges
              and agrees that certain TSP Vehicles may contain GPS/geo-location
              tracking devices, onboard cameras, ELDs, video/audio equipment and
              other devices that may allow for the tracking of the TSP Vehicle
              or video and audio recordings from within or outside the TSP
              Vehicle. By participating in the TSP, each Participating Party
              acknowledges and consents to the tracking of the TSP Vehicle and
              video and audio recordings. If a Participating Party refuses to
              consent or attempts to withdraw consent then the Participating
              Party&rsquo;s vehicle reservation and enrollment/registration in
              the TSP may be canceled without reinstatement. Each Participating
              Party acknowledges and agrees that RentMyTruck does not have any
              responsibility, obligation, or liability to inspect the TSP
              Vehicles to identify or confirm the presence of such devices. Each
              Participating Party further acknowledges and agrees
              thatRentMyTruck does not have any responsibility, obligation, or
              liability to monitor any tracking or recording devices, nor
              doesRentMyTruck have any responsibility, obligation, or liability
              to take any action based upon the presence of a recording device
              or the images, recordings or data collected by such devices. Each
              Participating Party waives all rights to and releases RentMyTruck
              from any and all claims related to or in any way associated with
              the presence of tracking devices or recording devices in the TSP
              Vehicles and any photographs, video or audio recordings taken or
              created by such devices.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.isvajqlw0454" className="display: inline">
              <span class="c5">California Residents.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">
              The California Consumer Privacy Act (CCPA) entitles California
              residents to certain disclosures before collection of their
              personal information (PI). California residents should review the
              RentMyTruck Privacy Policy found at
              https://rentmytruck.com/privacy-policy. In addition to the
              information contained at that link, the TSP Software may collect
              additional personally identifiable information in the process of
              enrolling in and operating pursuant to the TSP. This information
              is not sold to any third party but may be transmitted to third
              parties for the business purpose of completing the equipment lease
              transaction, completing payments, and verifying the eligibility of
              drivers operating TSP Vehicles. This collected information
              includes:
              <br />
              <br />
            </span>
            <span class="c0">20.1. Personal identifiers:</span>
            <span class="c3">
              &nbsp;Name, address, driver&#39;s license number, unique personal
              identifier, online identifier, internet protocol address, email
              address, account name, social security number, passport number, or
              other similar identifiers. This information is captured to confirm
              the identity of the TSP Vehicle driver and to confirm eligibility
              to operate the TSP Vehicle.
              <br />
              <br />
            </span>
            <span class="c0">20.2. Personal identifying information:</span>
            <span class="c3">
              &nbsp;The TSP Software may capture information relating to your
              name, physical characteristics, address, telephone number,
              driver&rsquo;s license number, insurance policy number, or
              employment.
              <br />
              <br />
            </span>
            <span class="c0">20.3. Protected characteristics:</span>
            <span class="c3">
              &nbsp;The TSP Software may capture information related to your
              age, medical condition, sex, and gender.
              <br />
              <br />
            </span>
            <span class="c0">
              20.4. Internet and electronic network activity:
            </span>
            <span class="c3">
              &nbsp;The TSP Software may capture electronic messages and
              information related to your driving time, speed, and distance.
              <br />
              <br />
            </span>
            <span class="c0">20.5. Geolocation data:</span>
            <span class="c3">
              &nbsp; The &nbsp;TSP &nbsp;Software may capture information
              related to your origin, destination, and route.
              <br />
              <br />
            </span>
            <span class="c0">
              20.6. Audio, electronic, and visual information:
            </span>
            <span class="c3">
              &nbsp;The TSP Software may capture still images of your in-cab
              activities that you upload.
              <br />
              <br />
            </span>
            <span class="c0">20.7. Employment information:</span>
            <span class="c1">
              &nbsp;The TSP Software may capture information related to your
              employment, including your eligibility to operate TSP Vehicles.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.d69g441ps1a2" className="display: inline">
              <span class="c5">Intellectual Property.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              TSP Software and Proprietary Information, including all
              intellectual property rights therein, are and shall remain the
              property of RentMyTruck. Neither this Agreement nor the
              Participating Party&rsquo;s use of the TSP Software conveys or
              grants to the Participating Party any rights in or related to the
              TSP Software, except for the limited license granted to access the
              TSP Software. Other than as specifically permitted by RentMyTruck
              in connection with the Services, the Participating Party is not
              permitted to use or reference in any manner RentMyTruck&rsquo;s
              names, logos, products and service names, trademarks, service
              marks, trade dress, copyrights or other indicia of ownership (the
              &ldquo;IP&rdquo;). Participating Party agrees that it will not try
              to register or otherwise use and/or claim ownership in any of the
              IP, alone or in combination with other letters, punctuation,
              words, symbols, and/or designs, or in any confusingly similar
              mark, name or title, for any goods and services, and that this
              engagement does not violate the terms of any agreement between the
              Participating Party and any third party. Participating Party
              acknowledges and agrees that any questions, comments, suggestions,
              ideas, feedback, or other information (&ldquo;Submissions&rdquo;)
              provided by Participating Party to RentMyTruck are
              non-confidential and shall become the sole property of
              RentMyTruck.RentMyTruck shall own exclusive rights, including all
              intellectual property rights, and shall be entitled to the
              unrestricted use and dissemination of these Submissions for any
              purpose, commercial or otherwise, without acknowledgment or
              compensation to the Participating Party. If the Participating
              Party creates any materials bearing the IP (in violation of this
              Agreement or otherwise), the Participating Party agrees that upon
              their creation RentMyTruck exclusively owns all rights, titles,
              and interest in and to such materials, including without
              limitation any modifications to the IP or derivative works based
              on the IP. Participating Party further agrees to assign any
              interest or right Participating Party may have in such materials
              to RentMyTruck, and to provide information and execute any
              documents as reasonably requested by RentMyTruck to
              enableRentMyTruck to formalize such assignment.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.bkppqps46wd" className="display: inline">
              <span class="c5">Miscellaneous Provisions.</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">22.1. Assignment.</span>
            <span class="c3">
              &nbsp;This Agreement shall not be assignable by either party,
              except by RentMyTruck to any subsidiary or affiliate of
              RentMyTruck or to any successor in interest to RentMyTruck&#39;s
              business.
              <br />
              <br />
            </span>
            <span class="c0">22.2. Binding Effect.</span>
            <span class="c3">
              &nbsp;The provisions of this Agreement shall be binding upon and
              inure to the benefit of the heirs, personal representatives,
              successors, and assigns of the parties.
              <br />
              <br />
            </span>
            <span class="c0">22.3. Notice.</span>
            <span class="c1">
              &nbsp;Each party shall deliver all written notices, requests,
              consents, claims, demands, waivers, and other communications under
              this Agreement (each, a &quot;Notice&quot;) addressed to the other
              party as provided in the underlying Master Lease (or to such other
              address that the receiving party may designate from time to time).
              Each party shall deliver all Notices by personal delivery,
              nationally recognized overnight courier (with all fees prepaid),
              certified or registered mail (in each case, return receipt
              requested, postage prepaid), (if available) through the
              &ldquo;Notices&rdquo; section of the TSP Software (notice using
              the TSP Software shall only be effective when a confirmation of
              receipt is indicated via the interface), or via confirmed
              electronic communication. Except as otherwise provided in this
              Agreement, a Notice is effective only (a) upon receipt by the
              receiving party and (b) if the party giving the Notice has
              complied with the requirements of this Section. A copy of any
              Notice by Participating Party to RentMyTruck must be sent to:
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c3 c8">
              Attn: General Counsel
              <br />
              RentMyTruck
              <br />
              Souther Transportation and Logistics, LLC
              <br />
              825 Watters Creek Blvd Ste. 250
              <br />
              Allen, Texas 75013
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">
              The parties may provide notice through the TSP Software (if
              available) or by confirmed email.
              <br />
              <br />
            </span>
            <span class="c0">22.4. Modification; Supplement.</span>
            <span class="c3">
              &nbsp;In the eventRentMyTruck modifies the terms and conditions of
              this Agreement at any time, such modifications shall be provided
              in writing and shall be binding on the Participating Party only
              upon the Participating Party&rsquo;s acceptance of the modified
              Agreement. RentMyTruck reserves the right to modify any
              information referenced from this Agreement from time to time. The
              Participating Party must not engage in any side oral or written
              modifications or agreements to this or any other TSP Documentation
              without the direct participation of RentMyTruck (the
              &ldquo;Unauthorized Modifications&rdquo;); otherwise, any such
              Unauthorized Modifications shall be deemed null and void and merit
              RentMyTruck&rsquo;s right to terminate (at its sole discretion)
              the Participating Party&rsquo;s TSP Documentation and its existing
              (and possibly its future) participation in the TSP Program.
              Participating Party hereby acknowledges and agrees that, by using
              the TSP Software, Participating Party is bound by any future
              amendments and additions to information or documents incorporated
              herein, including with respect to fees. Continued use of the TSP
              Software after any such changes shall constitute consent to such
              changes. Supplemental Terms may apply to Participating
              Party&rsquo;s use of the TSP Software. Supplemental Terms shall
              prevail over this Agreement in the event of a conflict. You may
              obtain an e-mail copy of this Agreement by e-mailing a request to
              Support@RentMyTruck.co. This Agreement will be available to you at
              any time in the TSP Software.
              <br />
              <br />
            </span>
            <span class="c0">22.5. Opportunity to Review Agreement.</span>
            <span class="c3">
              &nbsp;Each of the parties hereto has read this Agreement and knows
              and understands its terms and contents. Each party acknowledges
              that such party has had the opportunity to consult with such
              party&rsquo;s own attorney and has had adequate and reasonable
              time to evaluate this Agreement. Each party further acknowledges
              that such party is fully aware of such party&rsquo;s rights, or
              has been afforded the opportunity to seek qualified legal counsel
              relating to each party&rsquo;s rights, has knowingly and
              voluntarily waived those rights, and has carefully read and fully
              understands all provisions of this Agreement.
              <br />
              <br />
            </span>
            <span class="c0">22.6. Waiver.</span>
            <span class="c3">
              &nbsp;No waiver of any provision of this Agreement shall be
              deemed, or shall constitute, a waiver of any other provision,
              whether or not similar, nor shall any waiver constitute a
              continuing waiver. No waiver shall be binding unless executed in
              writing by the party making the waiver.
              <br />
              <br />
            </span>
            <span class="c0">22.7. Severability.</span>
            <span class="c3">
              &nbsp;If any provision of this Agreement is or becomes invalid or
              non-binding, the parties shall remain bound by all other
              provisions hereof. In that event, the parties shall replace the
              invalid or non-binding provision with provisions that are valid
              and binding and that have, to the greatest extent possible, a
              similar effect as the invalid or non-binding provision, given the
              contents and purpose of this Agreement.
              <br />
              <br />
            </span>
            <span class="c0">22.8. Governing Law and Jurisdiction.</span>
            <span class="c3">
              &nbsp;This Agreement shall be subject to, construed, and
              interpreted under the laws of the State of Texas without regard to
              its conflicts of laws provisions. The parties agree that the
              exclusive venue for any action relating to this Agreement shall be
              in a court of competent jurisdiction in Collin County, Texas.
              <br />
              <br />
            </span>
            <span class="c0">22.9. Entire Agreement.</span>
            <span class="c3">
              &nbsp;This Agreement, including all Supplemental Terms,
              constitutes the entire agreement and understanding of the parties
              with respect to its subject matter and replaces and supersedes all
              prior or contemporaneous agreements or undertakings regarding such
              subject matter.
              <br />
              <br />
            </span>
            <span class="c0">22.10. Headings.</span>
            <span class="c1">
              &nbsp;Headings are for reference purposes only and in no way
              define, limit, construe, or describe the scope or extent of such
              section.
            </span>
          </li>
        </ol>
        <h2 class="c10" id="h.b5h8d2arspt0">
          <span class="c14">Borrowing Motor Carrier &ndash; Master Lease</span>
        </h2>
        <p class="c13">
          <span class="c1">
            This RentMyTruck Truck Sharing Program (&ldquo;TSP&rdquo;) Master
            Lease (&ldquo;Master Lease&rdquo;) is entered into as of the
            Effective Date (as later defined)by and between Southern Logistics
            &amp; Transportation, LLC dba Rentmytruck, a Texas limited liability
            corporation whose address is 825 Watters Creek Blvd Allen, Texas
            (&ldquo;RentMyTruck&rdquo;) and BMC (as later defined).
            <br />
            <br />
            WHEREAS, for the purposes of this Agreement, &ldquo;BMC&rdquo; (also
            known as &ldquo;You&rdquo;/&rdquo;Your&rdquo; (whether or not
            capitalized) and &ldquo;Borrowing Motor Carrier&rdquo;) shall mean
            the party listed as the Borrowing Motor Carrier in the TSP
            Short-Term Program Lease by and between You and RentMyTruck (the
            &ldquo;Short-Term TSP Lease&rdquo;); and
            <br />
            <br />
            WHEREAS, this Agreement is made effective concurrently with the TSP
            Short-Term Effective Date set forth in the Short- Term TSP Lease
            (the &ldquo;Effective Date&rdquo;).
          </span>
        </p>
        <ol class="c6 lst-kix_8kdpim9qcksn-0 start" start="1">
          <li class="c2 li-bullet-0">
            <h4 id="h.of3p6l43q9d9" className="display: inline">
              <span class="c5">EQUIPMENT COVERED AND TERM:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. MASTER LEASE AND SHORT-TERM TSP LEASE(S).</span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;agrees to lease to you and you agree to lease from
              &nbsp;RentMyTruck the vehicles listed on the Short-Term TSP Lease
              (the &ldquo;TSP Vehicle(s)&rdquo;). Each Short-Term TSP Lease is a
              part of this Master Lease and contains additional terms and
              conditions.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">B. LEASE TERM.</span>
            <span class="c3">
              &nbsp;The lease term for each TSP Vehicle begins when (i) the BMC
              has taken possession and control of the TSP Vehicle, (ii) the BMC
              has received the BMC Statement of Lease and Short-Term TSP Lease
              Inception Equipment Receipt for Possession of Equipment from
              RentMyTruck (&ldquo;Equipment Receipt&rdquo;) and executed the
              Short-Term TSP Lease, (iii) when the TSP Vehicle is moved from the
              agreed TSP Vehicle Pick-Up Location by the BMC (as evidenced by
              physical movement from the location or by the RentMyTruck TSP
              Software (&ldquo;TSP Software&rdquo;) geo-location information
              indicating movement), or (iv) 4 hours after the Estimated
              Short-Term Lease Inception Date and Time as listed in the
              Equipment Receipt, whichever occurs first, and shall continue
              until the lease term is terminated by return of the TSP Vehicle to
              the TSP Vehicle Drop-Off Location, return of possession and
              control of the TSP Vehicle and the selection of the &ldquo;End
              Lease&rdquo; option on the TSP Software. TSP Vehicles enrolled in
              the RentMyTruck TSP shall be subject to the terms and conditions
              of the TSP Documentation (as defined in the TSP Agreement Terms
              and Conditions). If there is a conflict between the terms of any
              Short-Term TSP Lease and any other terms and conditions of the TSP
              or the Master Lease, then the terms and conditions of the TSP will
              apply. In the event of a conflict between the terms of any
              Short-Term TSP Lease and the Master Lease, the terms and
              conditions of the Master Lease will apply.
              <br />
              <br />
            </span>
            <span class="c0">C. TSP VEHICLE USE AND CONDITION.</span>
            <span class="c1">
              &nbsp;You understand that TSP Vehicles may be owned or leased by a
              party other than RentMyTruck (the Lending Participant or
              &ldquo;LP&rdquo;). When you accept a Short-Term TSP Lease
              Inception Equipment Receipt or place a TSP Vehicle in service, you
              acknowledge that it conforms to the TSP Vehicle specifications,
              the condition of the TSP Vehicle is acceptable to you for its
              intended use, and is in good working order; and that any visible
              damage or defect has been documented in accordance with all
              pre-trip inspection procedures provided to BMC by RentMyTruck
              (including the taking and uploading of BMC Inspection Photographs,
              as later defined) via the TSP Software. It is the sole
              responsibility of BMC to ensure that the condition of the TSP
              Vehicle is acceptable to BMC for its intended use and that use of
              the TSP Vehicle will not result in any violation of any laws,
              regulations, or ordinances applicable to such use. RENTMYTRUCK
              MAKES NO EXPRESS OR IMPLIED WARRANTY OR GUARANTY AS TO ANY MATTER
              WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THE CONDITION OF THE
              TSP VEHICLE, OR THE TSP VEHICLE&#39;S MERCHANTABILITY OR FITNESS
              FOR ANY PARTICULAR PURPOSE. RENTMYTRUCK SHALL NOT BE LIABLE TO BMC
              OR ANY THIRD PARTY FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES FOR
              ANY REASON WHATSOEVER
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.z8bxs0i33eu5" className="display: inline">
              <span class="c5">PROHIBITED USE OF TSP VEHICLE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC will NOT use or permit the TSP Vehicle to be used: (i) in
              violation of any federal, state, or local law, ordinance, or
              regulation; (ii) to push or tow anything with a gross weight
              (empty weight of towing device plus weight of load on towing
              device) in excess of the maximum towable weight shown on the TSP
              Vehicle; (iii) or driven by anyone other than a qualified driver
              authorized to operate the TSP Vehicle pursuant to BMC&rsquo;s
              operating authority where required or explicit authorization and
              supervision where no operating authority is required (ALL DRIVERS
              MUST BE PROPERLY LICENSED AND QUALIFIED, 18 YEARS OF AGE OR OLDER,
              AND HAVE BMC&#39;S PRIOR EXPLICIT WRITTEN OR VERBAL PERMISSION TO
              DRIVE THE TSP VEHICLE PURSUANT TO BMC&rsquo;S OPERATING
              AUTHORITY); (iv) off an improved road or other paved surface, in a
              reckless or abusive manner, in excess of applicable speed limits,
              or while the driver is under the influence of alcohol or a
              controlled substance; (v) to transport any car, truck, animal, or
              human being inside the cargo section of the TSP Vehicle; (vi) to
              transport any passengers or animals in the cabin, except as
              required in the normal and ordinary course of business; (vii) to
              transport persons for hire or for the illegal transportation of
              any intoxicating liquor, cigarettes, a controlled substance, or
              other contraband; (viii) to haul any load that would make the TSP
              Vehicle exceed the gross vehicle weight (empty weight of the TSP
              Vehicle plus the weight of load) shown on the TSP Vehicle or on
              the Short-Term TSP Lease, nor with any load improperly packed or
              secured; (ix) in any area of strike, labor or civil disruption or
              violence, or natural disaster; (x) in or taken into Mexico or
              anywhere else outside of the United States (&quot;U.S.&quot;);
              (xi) TO STORE, TREAT, TRANSPORT, OR DISPOSE OF ANY REGULATED,
              TOXIC, OR HAZARDOUS MATERIAL and/or (xii) in violation of any
              other restriction set forth in the TSP Short-Term Lease or in the
              TSP platform, including restrictions provided to BMC at the time
              of reservation of a vehicle. BMC acknowledges and represents that
              it (including any employees and third&#8208;party agents who
              interact with RentMyTruck under this Master Lease on BMC&rsquo;s
              behalf) has been, is, and shall remain in compliance with U.S.
              export controls and sanctions laws and regulations, and all
              designations made under these laws and regulations. To the extent
              that BMC is subject to an enforcement action or designation under
              any such export controls or sanctions law, BMC will
              notifyRentMyTruck within ten business days following the date of
              the government&rsquo;s notice to BMC of such action.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.2z8tfexqfg6c" className="display: inline">
              <span class="c5">CHARGES:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC will pay all charges due to RentMyTruck on demand in U.S.
              currency or by RentMyTruck accepted credit card or other form of
              payment acceptable to RentMyTruck as established in the TSP
              Software. BMC remains responsible for all charges even in the
              event of a disputed transaction.RentMyTruck reserves the right to
              utilize legal collections services to collect disputed sums and to
              charge BMC a fee for each of BMC&rsquo;s unsuccessful disputed
              transactions.RentMyTruck reserves the right to require BMC to
              furnish a security deposit as a condition precedent to BMC&rsquo;s
              initial or continuing participation in the TSP in
              RentMyTruck&rsquo;s sole discretion. The amount of the security
              deposit may be based upon but is not limited to, the following:
              (i) BMC&rsquo;s creditworthiness, financial condition, or
              RentMyTruck&rsquo;s desire for further assurances, (ii) any
              increase in the number of BMC&rsquo;s TSP Vehicles participating
              in the TSP and (iii) BMC&rsquo;s lease history in the TSP,
              including any misconduct or negative ratings from other
              rentmytruck participants. If RentMyTruck is required to recoup any
              portion of the security deposit to pay any amounts then due from
              BMC, BMC shall be required to restore the security deposit to the
              original amount of the initial security deposit or any higher
              amount required by RentMyTruck pursuant to this paragraph. Any BMC
              deposit will be applied to, and guarantee, BMC&#39;s complete
              performance of its obligations under this Master Lease.
              RentMyTruck may charge any amounts due to RentMyTruck against any
              credit card used in connection with the rental. BMC will pay the
              full charge for each full or partial rental day. A partial rental
              day is any day the TSP Vehicle is leased for less than 24 hours.
              The daily fixed rental rate or flat rate plus any applicable
              service charges for the given reservation, if any, set forth in
              the TSP Software is the minimum charge and may be charged for a
              rental period of less than 24 hours. Picking up the TSP Vehicle
              late or returning the Vehicle early shall not reduce BMC&rsquo;s
              charges. If BMC requests an adjustment of services during the term
              (resulting in an account profile change or otherwise), RentMyTruck
              may accept the request in writing or via the TSP Software, which
              shall notify BMC of any adjustment of charges.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.lolujug6m196" className="display: inline">
              <span class="c5">LIEN ON BMC&#39;S PROPERTY:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;will have a lien on all of the BMC&#39;s property
              transported in the TSP Vehicle or any trailer attached to the TSP
              Vehicle for all charges and expenses incurred, including those
              related to damage to the TSP Vehicle. If BMC does not pay all
              charges when due, RentMyTruck may, at its option, sell at public
              or private sale, without further notice to BMC, all such property
              to satisfy these charges and all costs associated with this sale
              consistent with applicable law.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.66jpecxqkdbc" className="display: inline">
              <span class="c5">BMC LIABILITY INSURANCE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC agrees to insure the TSP Vehicle with a standard form
              automobile liability insurance policy, with RentMyTruck named as
              an additional insured and loss payee, covering all risks of loss
              or damage to persons or property, arising out of the ownership,
              maintenance, use, or operation of the TSP Vehicle during the term
              of the Short-Term TSP Lease, regardless of fault. The limits of
              this insurance will not be less than $1,000,000 for all bodily
              injury and property damage arising from any one accident or such
              higher limits as RentMyTruck may require and the policy must not
              be issued using a &ldquo;Scheduled Autos&rdquo; policy. BMC agrees
              to provide RentMyTruck with a certificate of insurance, evidencing
              the required coverage and limits of liability, the form of which
              must be approved by RentMyTruck before participating in the TSP
              Program and before using the TSP Vehicle. BMC shall maintain such
              required coverage for the full duration of its participation in
              the TSP Program and the full duration of the lease of any TSP
              Vehicle. BMC&rsquo;S INSURANCE COVERAGE MAY BE IMPACTED BY
              PARTICIPATING IN THE TSP. IT IS THE BMC&rsquo;S RESPONSIBILITY TO
              UNDERSTAND ITS INSURANCE COVERAGE AND THE IMPACT PARTICIPATION IN
              THE TSP MAY HAVE ON THIS COVERAGE. BMC agrees to release,
              indemnify, and hold LP, RentMyTruck, LP&rsquo;s insurance carriers
              and RentMyTruck&#39;s insurance carriers harmless from and against
              any and all expenses (including without limitation any Defense
              Costs), Damages, and/or liability arising out of the ownership,
              maintenance, use, or operation of the TSP Vehicle during the term
              of the Short-Term TSP Lease. If any applicable state law requires
              LP, RentMyTruck or their respective insurance carriers to extend
              liability insurance coverage, then notwithstanding BMC&#39;s
              election to provide such liability insurance, such coverage
              extended by LP, RentMyTruck or their respective insurance carrier
              will be limited to the statutory compulsory or financial
              responsibility minimum limits and will be excess over (i) that
              insurance BMC or BMC&#39;s insurance carriers have agreed to
              provide, and (ii) any other insurance coverage that any insurance
              carrier (other than LP&rsquo;s or RentMyTruck&#39;s insurance
              carriers) furnishes.
              <br />
              &zwj;
              <br />
              BMC may authorize RentMyTruck to facilitate, on BMC&rsquo;s
              behalf, the insurance coverages required by this paragraph (or
              other coverage approved by RentMyTruck). For each coverage elected
              by BMC and facilitated byRentMyTruck, RentMyTruck will charge or
              otherwise recover the amounts due for such coverage from BMC, plus
              applicable taxes and an administrative fee. If BMC fails to
              provide proper evidence of the purchase or maintenance of the
              insurance required by this paragraph, RentMyTruck is authorized
              but not required to obtain the insurance (or other coverage
              approved by RentMyTruck) at BMC&rsquo;s expense and charge or
              otherwise recover the amounts due for such coverage from BMC, plus
              applicable taxes and fees. BMC recognizes that RentMyTruck is not
              in the business of selling insurance, and any insurance coverage
              requested by BMC from RentMyTruck is subject to all of the terms,
              conditions, and exclusions of the actual policy. Such insurance is
              extended solely and entirely under the automobile insurance
              policies set forth in the insurance card or insurance policy
              information listed in your Short-Term BMC Lease for your
              particular TSP Vehicle reservation for which you elect
              insurance.RentMyTruck will provide BMC with a certificate of
              insurance for the facilitated policies and/or a copy of each such
              policy upon request. It is BMC&rsquo;s sole responsibility to
              ensure that it is aware of and has reviewed the terms, conditions,
              and limitations in the facilitated policies. By selecting the
              facilitated insurance option, the BMC acknowledges and agrees that
              it has reviewed such facilitated policies and agrees to be bound
              by all terms, conditions, and limitations in such policies.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.bzmm1l1begdl" className="display: inline">
              <span class="c5">THEFT AND DAMAGE TO TSP VEHICLE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              Unless BMC elects a Limited Damage Waiver (&quot;LDW&quot;) in the
              TSP Software and on the Short-Term TSP Lease, BMC is totally
              responsible for all loss (including theft) and/or damage,
              including road hazard damage, to the TSP Vehicle, irrespective of
              any fault on the BMC&#39;s part. RentMyTruck agrees to waive
              BMC&#39;s liability for theft of and/or physical damage to the TSP
              Vehicle (less the RCL Amount) to the extent set forth in the
              Short-Term Lease Agreement if (i) BMC accepts and pays the charge
              for an LDW (where available), (ii) BMC TAKES A PHOTOGRAPH OF ALL
              FOUR SIDES OF THE TSP VEHICLE AND THE DASHBOARD AND UPLOADS SUCH
              PHOTOGRAPHS TO BMC&rsquo;S TSP ACCOUNT IMMEDIATELY UPON PICK-UP
              AND DROP-OFF OF THE TSP VEHICLE (THE &ldquo;TSPV LDW INSPECTION
              PHOTOGRAPHS&rdquo;) and (ii) BMC IS NOT IN VIOLATION OF ANY
              PROVISION OF THE TSP DOCUMENTATION. Each of these three
              requirements is a condition precedent to the LDW becoming
              effective. Notwithstanding the election of a LDW, BMC is totally
              responsible for all loss or damage to the TSP Vehicle due to a
              violation of any provision of the TSP Documentation. LDW IS NOT
              INSURANCE AND IS OPTIONAL. It is BMC&rsquo;s sole responsibility
              to ensure that it is aware of and has reviewed the terms,
              conditions, and limitations of the LDW program. The LDW terms may
              be attached to the applicable BMC Short-Term Lease.
              Notwithstanding the foregoing, if BMC has not received a copy of
              such LDW terms, it is BMC&rsquo;s sole responsibility to request a
              copy from the TSP Administrator. By electing to participate in the
              LDW program, the BMC acknowledges and agrees that it has reviewed
              and agrees to be bound by all terms, conditions, and limitations
              of such LDW program. If BMC does not elect, at the start of the
              rental, LDW, BMC shall furnishRentMyTruck with evidence of
              physical damage insurance covering the TSP Vehicle for loss or
              damage withRentMyTruck endorsed as loss payee. If BMC fails to
              procure or maintain physical damage insurance in accordance with
              this Paragraph, then upon written notice, &nbsp;RentMyTruck may
              immediately extend LDW in accordance with this Paragraph and
              charge BMC for such services. RentMyTruck may provide access to
              its 24/7 roadside assistance call center. Any incident requests
              may be performed by RentMyTruck, at RentMyTruck&rsquo;s sole
              discretion, and at BMC&rsquo;s expense (including towing, tire
              replacement, and associated service charges) upon BMC&rsquo;s
              written request to RentMyTruck. The cost of tire replacement
              (including tire and associated service charges) shall not be
              covered by 24/7 roadside assistance. 24/7 roadside assistance does
              not include the cost of performing any repairs to the TSP
              Vehicle.RentMyTruck may at its sole discretion, at any time and
              without prior written notice discontinue the 24/7 roadside
              assistance call center access or immediately terminate BMC&rsquo;s
              participation in the 24/7 roadside assistance.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.xqfgp9xd1u7" className="display: inline">
              <span class="c5">
                IN THE EVENT OF A BREAKDOWN, ACCIDENT, THEFT OR ANY OTHER LOSS,
                DAMAGE OR CLAIM:
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              As a condition precedent to recovery, the Participating Party must
              (i) follow all pre- and post-trip inspection procedures, including
              the documentation of all pre- and post-trip damage(s), if any, and
              procedures for providingRentMyTruck with the completed inspection
              report, as required; (ii) immediately report any TSP Vehicle
              breakdown, accident, theft or any other loss, damage or claim by
              contacting the TSP administrator at the customer service
              (including 24/7 roadside assistance) phone number listed on the
              first page of this Master Lease; (iii) promptly (within 24 hours)
              complete a RentMyTruck TSP accident report form and
              provideRentMyTruck with a detailed inventory of all cargo that was
              in the TSP Vehicle; and (iv) submit a written claim toRentMyTruck
              within fifteen (15) days after the incident. Any lawsuit to
              recover for loss or damage to the TSP Vehicle must be commenced
              within one (1) year from the incident. The Participating Party
              shall furnish any other information and evidence RentMyTruck
              reasonably requests. The Participating Party agrees
              thatRentMyTruck shall have the right but not the responsibility,
              at its option, to conduct, arrange or assist in coordinating
              accident or any other investigations and administer, facilitate or
              assist in coordinating repairs, claims handling and settlements
              and the Participating Party shall adhere to and accept
              RentMyTruck&#39;s conclusions and decisions.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.w5f8zardea3x" className="display: inline">
              <span class="c5">DAMAGE RESPONSIBILITY:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC is responsible for loss or damage to the TSP Vehicle under the
              Short-Term TSP Lease, including responsibility for a deductible
              under Paragraph 6.RentMyTruck may obtain a third party vendor
              estimate for any major damage. In the event of a theft of the TSP
              Vehicle or a total loss, BMC shall payRentMyTruck the TSP
              Vehicle&rsquo;s actual cash value.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.t2o2ubo9m1df" className="display: inline">
              <span class="c5">ACCIDENT AND CARGO RESPONSIBILITY:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC agrees thatRentMyTruck will have absolutely no liability
              whatsoever, and agrees to release, indemnify, and holdRentMyTruck
              harmless for any and all (i) loss or damage to cargo or other
              property transported in the TSP Vehicle and BMC assumes all such
              risk of loss or damage, and (ii) claims, damages, or losses
              arising from injuries of any nature whatsoever, or death of BMC,
              BMC&rsquo;s agents, employees, guests, family, members of
              BMC&rsquo;s family, or other occupants of the TSP Vehicle and BMC
              assumes all such risk and liability.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.d6hrb628jzjn" className="display: inline">
              <span class="c5">TERMINATION:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. TERMINATION RIGHTS:</span>
            <span class="c3">
              &nbsp;Either party may terminate this Master Lease by giving the
              other party at least 30 days prior written notice. Termination
              shall not be effective until all TSP Vehicles under a Short-Term
              TSP Lease to BMC have been returned in accordance with the terms
              and conditions of the TSP.
              <br />
              <br />
            </span>
            <span class="c0">B. RETURN OF TSP VEHICLES:</span>
            <span class="c1">
              &nbsp;As a condition precedent to returning the TSP Vehicle upon
              expiration of each Short-Term TSP Lease or upon termination of
              this Master Lease and after having completed and submitted all
              required pre- and post-inspection procedures, including, but not
              limited to, all BMC Inspection Photographs and Claims procedures
              (as set forth in the TSP Documentation), you agree to physically
              (and by way of digital facilitation, via the TSP Platform) return
              each TSP Vehicle to the TSP Vehicle Drop-Off Location clean, dry,
              and free of odor, debris, pests, insects, contamination, or
              infestation, and in at least the same good condition as received,
              on the due date, and at the time and TSP Vehicle Drop-Off location
              as specified in each Short-Term TSP Lease. Unless the Master Lease
              or an individual Short-Term TSP Lease is terminated by
              RentMyTruck, the term of the Short-Term TSP Lease shall continue
              until the lease term is terminated by return of the TSP Vehicle to
              the TSP Vehicle Drop-Off Location and the selection of the
              &ldquo;End Lease&rdquo; option on the TSP Software. In addition to
              all other charges due and remedies available toRentMyTruck under
              this Master Lease, BMC WILL BE LIABLE FOR A LATE CHARGE IF THE TSP
              VEHICLE IS NOT RETURNED TO THE TSP VEHICLE DROP-OFF LOCATION AT
              THE TIME SPECIFIED IN THE SHORT-TERM TSP LEASE. A CLEANING SERVICE
              CHARGE MAY APPLY IF THE T S P VEHICLE IS NOT RETURNED CLEAN. If
              BMC does not return the TSP Vehicle within three (3) days after
              the due date and time specified for its return or upon
              RentMyTruck&rsquo;s demand, if BMC disables the geo-location
              component of the TSP software, or if information provided to
              RentMyTruck by BMC is false, RentMyTruck may consider the TSP
              Vehicle stolen and pursue all appropriate legal action, both
              criminal and civil, to recover the TSP Vehicle. RentMyTruck may
              additionally remotely deactivate your access to and ability to
              operate the TSP Vehicle and may retrieve the TSP Vehicle from your
              possession. BMC AGREES NOT TO SUBLEASE OR RELET THE TSP VEHICLE OR
              ASSIGN THIS MASTER LEASE, AND ANY ATTEMPT TO DO ANY OF THE
              FOREGOING SHALL BE NULL AND VOID AND CONSTITUTE A MATERIAL BREACH
              OF THIS MASTER LEASE.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.59olbu5wxivn" className="display: inline">
              <span class="c5">BREACH OF THIS MASTER LEASE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0 c15">A. BREACH OR DEFAULT:</span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">(1) DEFAULT UNDER THIS AGREEMENT:</span>
            <span class="c3">
              &nbsp;In the event BMC breaches or is in default of any obligation
              in the TSP Documentation, or if the TSP Vehicle is overdue,
              illegally parked, or apparently abandoned, or if any information
              BMC provides to RentMyTruck is false, RentMyTruck may, without
              prior notice or demand to BMC, remotely deactivate your access to
              and ability to operate the TSP Vehicle, recover the TSP Vehicle
              wherever it is located, terminate your access to the TSP Software,
              and immediately terminate either the Short-Term TSP Lease or
              Master Lease or both without prejudice to any of RentMyTruck&#39;s
              rights or other remedies available under the TSP Documentation, at
              law or in equity. RentMyTruck will be entitled to recover from BMC
              all reasonable costs, expenses, and attorneys&rsquo; fees incurred
              by RentMyTruck or LP to repossess or recover any TSP Vehicle
              and/or enforce the terms of the TSP Documentation.
              <br />
              <br />
            </span>
            <span class="c0">(2) DEFAULT UNDER OTHER AGREEMENTS:</span>
            <span class="c1">
              &nbsp;If you breach any other agreement between you and
              RentMyTruck, including but not limited to any truck leasing and
              service (e.g. TLSA), rental and/or maintenance agreements (the
              &ldquo;Other Agreements&rdquo;), then you will be in default of
              this Master Lease. If you breach this Master Lease, you will be in
              default of any Other Agreement between you and RentMyTruck.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">B. BANKRUPTCY:</span>
            <span class="c1">
              &nbsp;It shall be a default under this Master Lease if you become
              insolvent, file a voluntary petition in bankruptcy, make an
              assignment for the benefit of creditors, are adjudicated bankrupt,
              permit a receiver to be appointed for your business, or permit or
              suffer a material disposition of your assets.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.wqrss3mr3lpo" className="display: inline">
              <span class="c5">
                MAINTENANCE RESPONSIBILITY AND INSPECTION RIGHTS:
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC shall conduct pre- and post-inspections of the TSP Vehicle,
              complete required reports, and promptly notify RentMyTruck of any
              TSP Vehicle condition issues. BMC shall be responsible for
              verifying that the TSP Vehicle has a decal, sticker, or other
              documentation evidencing a valid and current Federal Annual Safety
              Inspection. RentMyTruck will not be liable for any FMCSA
              violations or other citations. For TSP Vehicles operated in
              California, LP shall be responsible for the maintenance of the TSP
              Vehicle maintenance files and periodic vehicle inspections
              required by the Basic Inspection of Terminals (BIT) program.
              Instructions for pre- and post-trip inspections are provided in
              the TSP Platform and BMC shall comply with all such instructions.
              BMC agrees to be responsible and pay for all mechanical damage to
              the TSP Vehicle caused by: (i) BMC&#39;s breach of any provision
              of the TSP Documentation; (ii) BMC&#39;s failure to maintain
              adequate oil, fluids, coolants, and water levels in the engine;
              and/or (iii) BMCs neglect of any necessary servicing or
              maintenance of the TSP Vehicle during the rental period. BMC will
              not perform, and RentMyTruck will not be responsible for, any
              service, repairs, or other maintenance to the TSP Vehicle during
              the rental period unless RentMyTruck gives prior written
              authorization for such work.RentMyTruck may inspect the TSP
              Vehicle at any time wherever it is located. In accordance with the
              owner&#39;s manual, the engine in the TSP Vehicle may require
              Diesel Exhaust Fluid or a similar additive (DEF). All charges for
              DEF are BMC&#39;s responsibility. Failure to maintain required
              tank levels or adding non DEF fluids to this tank may cause TSP
              Vehicle to derate or system damage. Any failure to maintain the
              required proper DEF fluid level is driver abuse and BMC is
              responsible for all mechanical damage resulting from such failure.
              Where applicable, instructions for DPF regeneration may be located
              on the backside of the driver&rsquo;s side sun visor. It is
              BMC&rsquo;s sole responsibility to properly and timely perform the
              regeneration. Any failure to perform DPF regeneration is driver
              abuse and BMC is responsible for all costs necessary to repair and
              tow the TSP Vehicle as well as any other mechanical damages
              resulting from such failure.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.m9aovsbw448x" className="display: inline">
              <span class="c5">
                TSP VEHICLE OPERATING CREDENTIALS, TAXES, FEES, PERMITS,
                TICKETS, AND FINES:
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. LICENSING AND TAXES:</span>
            <span class="c3">
              &nbsp;BMC shall keep the TSP Vehicle free and clear of all levies,
              liens and encumbrances and, with the exception of
              registration/plate fees (which shall be obtained by the LP) shall
              pay any and all taxes, assessments, license fees, registration
              fees, and similar charges on or related to the Equipment incurred
              during the term of the Short-Term TSP Lease, including without
              limitation, any and all sales tax, use tax, excise tax, personal
              property tax, assessments and other governmental fees and charges
              on or relating to the Equipment incurred during the term of the
              Short-Term TSP Lease. If BMC claims a sales tax exemption, all tax
              forms and documentation related to the exemption must be submitted
              to RentMyTruck before the start of the rental reservation.
              BMC&rsquo;s claimed sales tax exemption is subject to approval by
              RentMyTruck&rsquo;s tax group, at its sole discretion. Any sales
              tax that is collected from a BMC with an approved sales tax
              exemption shall be refunded at the end of the reservation. The
              parties acknowledge that the lease charges for the Equipment are
              exclusive of sales receipt or use taxes, and third structure
              taxes, including, but not limited to, weight, distance, ton
              mileage, and axle mile taxes. BMC shall pay the amount of any
              sales receipt, use tax, or third structure tax now or hereafter
              imposed upon the lease, rental, or use of the Equipment during the
              term of the Short-Term TSP Lease. BMC will pay RentMyTruck all
              charges incurred by RentMyTruck in states other than the state of
              domicile for any of the items listed in this Paragraph.
              <br />
              <br />
            </span>
            <span class="c0">B. FUEL TAX: </span>
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;or LP will: (i) apply and pay for fuel tax permits/IFTA fuel
              tax permits and highway use/mileage tax permits for each TSP
              Vehicle; (ii) prepare and file IFTA fuel tax and highway
              use/mileage tax returns; and (iii) pay fuel taxes and highway
              use/mileage taxes imposed on the operation of the TSP Vehicles
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">(1) REQUIRED DOCUMENTATION:</span>
            <span class="c3">
              &nbsp;BMC must provide RentMyTruck with all necessary
              documentation requested by RentMyTruck (including trip records and
              fuel tickets or information necessary to request a DataQ
              modification) upon termination of the Short-Term Lease using the
              interface provided in the TSP Software. If you fail to provide
              RentMyTruck with the requested information, you agree to: (i)
              reimburse RentMyTruck for any charges, penalties, expenses, or
              disallowed credits; (ii) pay RentMyTruck an amount equal to the
              estimated taxes computed on a per mile basis: and (iii) pay
              RentMyTruck a surcharge of $.20 for each mile that you fail to
              properly report.
              <br />
              <br />
            </span>
            <span class="c0">
              (2) REIMBURSEMENT OF FUEL, HIGHWAY USE, AND MILEAGE TAXES:
            </span>
            <span class="c1">
              &nbsp;The mileage rate is intended to cover the costs of fuel,
              highway use, and mileage taxes during the Short-Term Vehicle
              Lease, provided BMC complies with all terms of the Short-Term
              Vehicle Lease, including any use restrictions. Notwithstanding,
              BMC must provide all necessary documentation pursuant to Paragraph
              13.B.(1) above, and shall be subject to the surcharge therein
              and/or other fines and penalties, if BMC fails to provide all
              required documentation or fails to comply with all applicable
              terms of the Short-Term Vehicle Lease, including any use
              restrictions.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">C. ALL OTHER TAXES, FEES, TOLLS, OR FINES:</span>
            <span class="c3">
              &nbsp;BMC shall pay for all taxes, fees, special licenses, traffic
              and parking violations, towing and storage expenses (with the
              exception of any towing expenses resulting from a mechanical
              breakdown of the TSP Vehicle that is not otherwise caused by
              BMC&rsquo;s negligence) and other similar fines and tolls (whether
              in effect now or imposed after the date of this Master Lease)
              relating to the use of any TSP Vehicle during the term of the
              Short-Term TSP Lease or other charges under this Master Lease
              (excluding any taxes based on RentMyTruck&#39;s or LP&rsquo;s net
              income). If your failure to pay any of the above items results in
              an outstanding charge, claim, or lien involving any TSP Vehicle or
              another vehicle listed herein, then RentMyTruck or LP may settle
              such, and you shall promptly pay RentMyTruck or LP the full amount
              of such settlement, any related costs, and an administrative
              charge not to exceed $10 per occurrence for tolls administered and
              $25 per occurrence for all other taxes, fees, special licenses,
              violations, towing and storage expenses, fines, or tolls.
              <br />
              <br />
            </span>
            <span class="c0">D. PLACARDING:</span>
            <span class="c1">
              &nbsp;The TSP specifically contemplates a Short-Term TSP Lease
              shall be for a period of less than 30 days. In the event any TSP
              Vehicle is operated or leased by BMC for a period equal to or in
              excess of 30 days, BMC must identify the vehicle in compliance
              with federal and state regulations.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.ze9yu2hc7ytg" className="display: inline">
              <span class="c5">
                FUEL: RENTAL RATES DO NOT INCLUDE THE COST OF FUEL. BMC IS
                RESPONSIBLE FOR ALL FUEL DURING THE COURSE OF THE RENTAL.
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              BMC IS RESPONSIBLE FOR ALL FUEL DURING THE COURSE OF THE RENTAL.
              All fuel used in the TSP Vehicle shall be of a type and grade that
              meets all manufacturers&rsquo; recommendations and the
              requirements of applicable law. BMC agrees to return the TSP
              Vehicle to the TSP Vehicle Drop-Off Location (or any other
              location RentMyTruck or LP agrees to in writing in advance) with
              the same fuel level in the TSP Vehicle at TSP Vehicle Pick-Up. BMC
              shall be charged the cost for any shortfall plus an administrative
              mark-up per gallon. Such charges will be set forth in the
              Short-Term TSP Lease.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.w7rqbfxg6it2" className="display: inline">
              <span class="c5">TELEMATICS DEVICE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              Each TSP Vehicle may contain a telematics device consisting of a
              transceiver box, antenna, and other related equipment and hardware
              (collectively &quot;Device&quot;). You agree not to tamper with or
              disable the Device without the express written consent of
              RentMyTruck. You shall be responsible for any loss of or damage to
              the Device at full replacement cost. The Device, all related
              software, and all data collected from the Device shall at all
              times remain the property of RentMyTruck. The Device may be
              removed by RentMyTruck at any time or upon termination of the
              Master Lease. Participation in the TSP requires consent to the use
              of the Device and any geolocation information it may provide as
              further outlined in the TSP terms and conditions.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.cwbxlhlykovb" className="display: inline">
              <span class="c5">COMPLIANCE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. PICK-UP OUTSIDE OF CALIFORNIA:</span>
            <span class="c3">
              &nbsp;TSP Vehicles obtained at a TSP Vehicle Pick-Up Location
              outside of the State of California and not designated in the
              Reservation as &ldquo;California Compliant&rdquo; may not be
              compliant with all California Air Resources Board requirements. If
              BMC intends to operate the TSP Vehicle within the State of
              California, BMC is solely responsible for compliance with all laws
              and regulations within the State of California. If the TSP Vehicle
              is designated in the Reservation as &ldquo;California
              Compliant&rdquo; then the provisions of sub-paragraph B apply.
              <br />
              <br />
            </span>
            <span class="c0">B. PICK-UP WITHIN CALIFORNIA:</span>
            <span class="c1">
              &nbsp;TSP Vehicles obtained at a TSP Vehicle Pick-Up Location
              within the State of California (or designated in the Reservation
              as &ldquo;California Compliant&rdquo;) will be fully compliant
              with all California-specific equipment regulations, including CARB
              and BIT periodic vehicle inspections. LP is responsible for
              maintaining such compliance.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.8x8lqqo6jv6t" className="display: inline">
              <span class="c5">MISCELLANEOUS PROVISIONS:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. ASSIGNMENT OF LEASE:</span>
            <span class="c3">
              &nbsp;This Master Lease will be binding on both parties, and our
              respective successors, legal representatives, and permitted
              assigns. YOU DO NOT HAVE THE RIGHT TO SUBLEASE ANY TSP VEHICLE,
              NOR THE RIGHT TO ASSIGN THIS MASTER LEASE OR ANY INTEREST
              HEREUNDER WITHOUT RENTMYTRUCK&rsquo;S PRIOR WRITTEN CONSENT. ANY
              ATTEMPT TO DO SO WILL BE VOIDABLE AT RENTMYTRUCK&rsquo;S OPTION.
              Unless RentMyTruck expressly releases you from your obligations in
              writing, you will remain liable for all of your and the
              assignee&rsquo;s obligations under this Master Lease including,
              but not limited to, liability claims, physical damage and
              associated Damages and Defense Costs. RentMyTruck or LP may assign
              all or part of its interest in this Master Lease and any TSP
              Vehicle without notice to you or your consent.
              <br />
              <br />
            </span>
            <span class="c0">B. CHANGE OF OWNERSHIP/SALE OF ASSETS:</span>
            <span class="c3">
              &nbsp;If you change ownership or dispose of a substantial amount
              of your assets, you will notify RentMyTruck in writing. You shall
              give RentMyTruck at least 30 days prior written notice of (a) any
              change of your name, address, or state of the organization from
              that set forth above or (b) any proposed merger, consolidation or
              sale of all or substantially all of your assets or transfer of a
              majority interest of your ownership interests or control from the
              persons(s) or entity(ies) holding such interests or control as of
              the date hereof.
              <br />
              <br />
            </span>
            <span class="c0">C. FORCE MAJEURE:</span>
            <span class="c3">
              &nbsp;Neither party will be liable to the other if it is prevented
              from performing under this Master Lease by any present or future
              cause beyond its control. These causes include, but are not
              limited to, Acts of God, national emergencies, wars, acts of
              terrorism, riots, fires, labor disputes, federal, state, or local
              laws, rules or regulations, shortages (local or national), or fuel
              allocation programs, provided that no Force Majeure event shall
              affect any of your payment obligations hereunder.
              <br />
              <br />
            </span>
            <span class="c0">D. LIMITATION OF LIABILITY: </span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &rsquo;s liability to you for any breach of the TSP Documentation
              shall be limited to the actual value of the services that
              RentMyTruck fails to provide, provided that in no event shall such
              liability exceed the amount of fees actually paid or due to you as
              a result of participation in the TSP in the six (6) month period
              immediately preceding the event giving rise to such claim.
              <br />
              <br />
            </span>
            <span class="c0">E. NOTICES:</span>
            <span class="c3">
              &nbsp;Any notice, demand, consent, or request for consent under
              this Master Lease must be written and sent to you or RentMyTruck
              at the address specified during enrollment in the TSP and included
              at the beginning of this Master Lease (or any new address of which
              notice is given) or (if available) via use of the TSP Software.
              Notices shall be given by certified mail (with a return receipt),
              overnight delivery service, facsimile transmission (if a written
              record of either a machine-generated or verbal telephonic
              confirmation is obtained), hand-delivery, or confirmed electronic
              communication. Notices will be effective only (a) upon receipt by
              the receiving party and (b) if the party giving the notice has
              complied with the requirements of this Section.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">F. SAVINGS CLAUSE:</span>
            <span class="c3">
              &nbsp;If a court rules that any provision of this Master Lease is
              illegal, invalid, or unenforceable, all other provisions will
              remain binding, effective, and fully enforceable.
              <br />
              <br />
            </span>
            <span class="c0">G. WAIVER:</span>
            <span class="c3">
              &nbsp;Delay or failure to exercise, or partial exercise of any
              right under this Master Lease will not operate to waive that or
              any other right hereunder. By failing to declare or act on a
              default, a party does not waive that default. Either party may act
              on any default at any time. BOTH PARTIES WAIVE ANY RIGHT TO A
              TRIAL BY A JURY IN ANY LAWSUIT RELATING TO THE TRANSACTIONS
              CONTEMPLATED BY THIS MASTER LEASE.
              <br />
              <br />
            </span>
            <span class="c0">H. CUMULATIVE REMEDIES:</span>
            <span class="c3">
              &nbsp;All remedies in this Master Lease are cumulative and
              non-exclusive.
              <br />
              <br />
            </span>
            <span class="c0">I. CONTENT AND MODIFICATION OF MASTER LEASE:</span>
            <span class="c3">
              &nbsp;Enrollment in the TSP and electronic acceptance of the terms
              and conditions and this Master Lease shall be deemed to be
              acceptance of and execution of this Master Lease by the
              parties&rsquo; duly authorized representatives. The agreements
              contained in the TSP Documentation are the entire agreement and
              understanding between the parties concerning the TSP subject
              matter. All previous written or oral agreements and
              representations regarding the subject matter of this Master Lease
              will be null and void. The Master Lease can be modified only by a
              written amendment signed or electronically executed by a duly
              authorized representative of the party against which enforcement
              is sought. Any attempt to modify orally or through the course of
              performance shall be void. The Participating Party must not engage
              in any side oral or written modifications or agreements to this or
              any other TSP Documentation without the direct participation of
              RentMyTruck (the &ldquo;Unauthorized Modifications&rdquo;);
              otherwise, any such Unauthorized Modifications shall be deemed
              null and void and merit RentMyTruck&rsquo;s right to terminate (at
              its sole discretion) the Participating Party&rsquo;s TSP
              Documentation and its existing (and possibly its future)
              participation in the TSP Program.
              <br />
              <br />
            </span>
            <span class="c0">
              J. DISCLAIMER OF WARRANTIES/INTENDED USE. RENTMYTRUCK MAKES NO
              EXPRESS OR IMPLIED WARRANTY REGARDING ANY TSP VEHICLE, CHARGES,
              ACCESS TO TSP SOFTWARE OR ANY OTHER MATTER WHATSOEVER, INCLUDING,
              BUT NOT LIMITED TO, ANY WARRANTY THAT A TSP VEHICLE IS
              MERCHANTABLE OR FIT FOR A PARTICULAR PURPOSE OR SPECIAL PURPOSE:
            </span>
            <span class="c3">
              &nbsp;It is the sole responsibility of BMC to ensure that the
              condition of any TSP Vehicle is acceptable to BMC for its intended
              use, and that use of such TSP Vehicle will not result in any
              violation of any laws, regulations, or ordinances applicable to
              such use.
              <br />
              <br />
            </span>
            <span class="c0">K. SURVIVABILITY:</span>
            <span class="c3">
              &nbsp;All of the defense, release, indemnification and hold
              harmless provisions of this Master Lease shall survive its
              termination (for any reason) or expiration.
              <br />
              <br />
            </span>
            <span class="c0">L. GOVERNING LAW AND JURISDICTION:</span>
            <span class="c3">
              &nbsp;This Master Lease shall be subject to, construed, and
              interpreted under the laws of the State of Texas without regard to
              its conflicts of laws provisions. The parties agree that the
              exclusive venue for any action relating to this Master Lease shall
              be in a court of competent jurisdiction in Collin County, Florida.
              <br />
              <br />
            </span>
            <span class="c0">M. ATTORNEYS&rsquo; FEES:</span>
            <span class="c3">
              &nbsp;If either party initiates litigation to enforce its rights
              under this Master Lease, the prevailing party in such litigation
              will also be entitled to receive from the other party its
              reasonable attorneys&rsquo; fees (pre-trial, trial, and appellate)
              and costs (including those paid to a collection agency).
              <br />
              <br />
            </span>
            <span class="c0">N. THIRD PARTY INVOICES:</span>
            <span class="c3">
              &nbsp;If RentMyTruck engages a third party to perform repairs,
              maintenance, or road service not covered by the fixed and variable
              charges (e.g. driver abuse, physical damage), you agree to pay the
              third party&rsquo;s charges plus a reasonable mark-up (up to
              twenty percent (20%) of the third party&rsquo;s charges) to cover
              RentMyTruck&rsquo;s related administrative expenses.
              <br />
              <br />
            </span>
            <span class="c0">O. VALIDITY:</span>
            <span class="c3">
              &nbsp;A paper or electronic copy, scan, or electronic signature of
              this Master Lease or any Short-Term TSP Lease is sufficient and
              binding in the same manner and for the same purposes as the
              originally signed version. Neither party may deny the legal
              effect, validity, or enforceability of this Master Lease solely
              because it is in electronic form and any arguments to this effect
              are expressly waived. This Master Lease shall be admissible in any
              proceeding as a copy or scan, or with electronic or digital
              signatures, as if it contained original handwritten signatures.
              <br />
              <br />
            </span>
            <span class="c0">P. LIMITATION OF SUPPLEMENTAL PROTECTIONS:</span>
            <span class="c1">
              &nbsp;NO PROTECTION OF ANY KIND (IF ANY, INCLUDING LDW), WILL BE
              EFFECTIVE IF THE BMC (A) CONVERTS THE T S P VEHICLE BY FAILING TO
              RETURN IT FOR ANY REASON WHEN DUE, AND/OR (B) OTHERWISE IS IN
              VIOLATION OF THE TSP DOCUMENTATION, TO THE EXTENT PERMITTED BY
              APPLICABLE LAW.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.p99688xxxu7v" className="display: inline">
              <span class="c5">DEFINED TERMS:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. DAMAGES:</span>
            <span class="c3">
              &nbsp;All damages, claims, suits, causes of action, penalties,
              fees, costs, expenses, and liabilities for death or injury to
              persons and loss or damage to property, including, but not limited
              to, damage to the environment and all environmental clean-up
              costs.
              <br />
              <br />
            </span>
            <span class="c0">B. DEFENSE COSTS:</span>
            <span class="c3">
              &nbsp;Attorneys&rsquo; fees, experts&rsquo; fees, and court costs
              (pre-trial, trial, and appellate).
              <br />
              <br />
            </span>
            <span class="c0">C. HAZARDOUS MATERIAL:</span>
            <span class="c3">
              &nbsp;Any cargo, property, or hazardous material in a quantity
              which requires placarding by the United States Department of
              Transportation, and any medical, bio-hazardous, or radioactive
              waste.
              <br />
              <br />
            </span>
            <span class="c0">D. INSPECTION PHOTOGRAPHS:</span>
            <span class="c1">
              &nbsp;Collectively refers to both TSPV LDW Inspection Photographs
              (as defined in Section 6 of the BMC-Master Lease) and &ldquo; BMC
              Mileage Inspection Photographs&rdquo;, which are photographs taken
              by BMC of the TSP Vehicle&rsquo;s odometer immediately upon
              BMC&rsquo;s pick-up and drop-off of the TSP Vehicle. BMC
              Inspection Photographs are pre- and post-inspection requirements
              of the BMC whereby BMC must take and upload such photographs to
              its TSP account via the TSP Software.
            </span>
          </li>
        </ol>
        <h2 class="c10" id="h.mxnnufulf6fo">
          <span class="c14">Lending Participant - Master Lease</span>
        </h2>
        <p class="c13">
          <span class="c1">
            This Truck Sharing Program (&ldquo;TSP&rdquo;) Master Lease
            (&ldquo;Master Lease&rdquo;) is entered into as of the Effective
            Date (as later defined) by and between Southern Logistics &amp;
            Transportation, LLC dba RentMyTruck, a Texas limited liability
            corporation whose address is 825 Watters Creek Blvd Allen, Texas
            (&ldquo;RentMyTruck&rdquo;) and LP (as later defined).
            <br />
            <br />
            WHEREAS, for the purposes of this Agreement, &ldquo;LP&rdquo; (also
            known as &ldquo;You&rdquo;/&rdquo;Your&rdquo; (whether or not
            capitalized) and &rdquo;Lending Participant&rdquo;) shall mean the
            party listed as the TSP Vehicle Owner in the TSP Short-Term Program
            Lease by and between You and RentMyTruck (the &ldquo;Short-Term TSP
            Lease&rdquo;); and
            <br />
            <br />
            WHEREAS, this Agreement is made effective concurrently with the TSP
            Short-Term Effective Date set forth in the Short- Term TSP Lease
            (the &ldquo;Effective Date&rdquo;).
          </span>
        </p>
        <ol class="c6 lst-kix_bdhh2yg1lde2-0 start" start="1">
          <li class="c2 li-bullet-0">
            <h4 id="h.yhy5xlr3q9ir" className="display: inline">
              <span class="c5">EQUIPMENT COVERED AND TERM:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. MASTER LEASE AND SHORT-TERM TSP LEASE(S):</span>
            <span class="c3">
              &nbsp;LP agrees to lease to RentMyTruck and RentMyTruck agrees to
              lease from LP the vehicles listed on the Short-Term TSP Lease (the
              &ldquo;TSP Vehicle(s)&rdquo;). &nbsp;Each Short-Term TSP Lease is
              a part of this Master Lease and contains additional terms and
              conditions.
              <br />
              &zwj;
              <br />
            </span>
            <span class="c0">B. LEASE TERM:</span>
            <span class="c3">
              &nbsp;The lease term for each TSP Vehicle begins when RentMyTruck
              has received the LP Statement of Lease and Short-Term TSP Lease
              Inception Equipment Receipt for Possession of Equipment from LP
              (&ldquo;Equipment Receipt&rdquo;) and shall continue until
              RentMyTruck issues a Short-Term TSP Lease Termination and
              Equipment Receipt to LP. TSP Vehicles enrolled in the RentMyTruck
              TSP shall be subject to the terms and conditions of the TSP, the
              Master Lease, and the Short-Term TSP Lease (the &ldquo;TSP
              Documentation&rdquo;). If there is a conflict between the terms of
              any Short-Term TSP Lease and any other terms and conditions of the
              TSP or the Master Lease, then the terms and conditions of the TSP
              will apply. In the event of a conflict between the terms of any
              Short-Term TSP Lease and the Master Lease, the terms and
              conditions of the Master Lease will apply.
              <br />
              <br />
            </span>
            <span class="c0">
              C. VEHICLE SPECIFICATIONS, ALTERATIONS, AND EQUIPMENT:
            </span>
            <span class="c1">
              &nbsp;You understand that TSP Vehicles may be leased to a party
              other than RentMyTruck (the Borrowing Motor Carrier or
              &ldquo;BMC&rdquo;). LPs do not have any rights or responsibilities
              for granting permission or authority, expressly or impliedly, for
              the use of a TSP Vehicle by a BMC. Rather, RentMyTruck is solely
              responsible for granting any BMC permission to make use of a TSP
              Vehicle and the determination of whether a TSP Vehicle may be used
              by a BMC in the first instance. It is the sole responsibility of
              RentMyTruck to ensure that the condition of the TSP Vehicle is
              acceptable for its intended use and that use of the TSP Vehicle
              will not result in any violation of any laws, regulations, or
              ordinances applicable to such use. LP represents and warrants that
              it has complied with good operating procedures, industry
              standards, and all applicable laws, rules, and regulations,
              including but not limited to those promulgated by &nbsp;the
              California Air Resources Board, the U.S. Department of
              Transportation, and its Federal Motor Carrier Safety
              Administration, through its ownership and/or possession of such
              TSP Vehicle, and has maintained the TSP Vehicle in good repair,
              mechanical condition, and appearance, and has performed all
              required or recommended maintenance and repairs in accordance with
              the manufacturer&rsquo;s specifications &nbsp;and industry
              standards, &nbsp;and in a timely manner. &nbsp; EXCEPT &nbsp;AS
              &nbsp;SET &nbsp;FORTH &nbsp;IN &nbsp;THE &nbsp;FOREGOING
              &nbsp;SENTENCE &nbsp;LP &nbsp;MAKES &nbsp;NO &nbsp;EXPRESS
              &nbsp;OR &nbsp;IMPLIED &nbsp;WARRANTY &nbsp;OR &nbsp;GUARANTY
              &nbsp;AS &nbsp;TO &nbsp;ANY &nbsp;MATTER WHATSOEVER, INCLUDING,
              WITHOUT LIMITATION, THE TSP VEHICLE&#39;S MERCHANTABILITY OR
              FITNESS FOR &nbsp;ANY &nbsp;PARTICULAR &nbsp;PURPOSE.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.tk9qpqr8c16x" className="display: inline">
              <span class="c5">PROHIBITED USE OF TSP VEHICLE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              LP represents and warrants that it is fully authorized (and has
              obtained any necessary consents) to participate in the TS P
              program and for its TSP Vehicle to be leased to RentMyTruck under
              this Master &nbsp;Lease. RentMyTruck will NOT &nbsp;permit
              &nbsp;the TSP &nbsp;Vehicle to &nbsp;be &nbsp;used: &nbsp;(i) in
              violation of any federal, state, or &nbsp;local law, ordinance, or
              regulation; (ii) to push or tow anything with a gross weight
              (empty weight of towing device plus weight of load on towing
              device) in excess of the maximum towable weight shown on the TSP
              Vehicle; (iii) or driven by anyone other than a qualified driver
              authorized to operate the TSP Vehicle pursuant &nbsp;to
              &nbsp;adequate operating &nbsp;authority &nbsp;where
              &nbsp;required &nbsp;or &nbsp;explicitly &nbsp;authorized
              &nbsp;and &nbsp;supervised by a BMC where no operating authority
              is required (ALL DRIVERS &nbsp;MUST BE PROPERLY LICENSED AND
              QUALIFIED AND 18 YEARS OF AGE OR OLDER.); (iv) off an improved
              road or other paved surface, in a reckless or abusive manner, in
              excess of applicable speed limits, or while the driver is under
              the influence of alcohol or a controlled substance; (v) to
              transport any car, truck, animal, or human being inside the cargo
              section of the TSP Vehicle; (vi) to transport any passengers or
              animals in the cabin, except as required in the normal and
              ordinary course of business; (vii) to transport persons for hire
              or for the illegal transportation of any intoxicating liquor,
              cigarettes, a controlled substance, or other contraband; (viii) to
              haul any load that would make the TSP Vehicle exceed the gross
              vehicle weight (empty weight of the TSP Vehicle plus the weight of
              load) shown on the TSP Vehicle or on the Short-Term TSP Lease, nor
              with any load improperly packed or secured; (ix) in any area of
              strike, labor or civil disruption or violence, or natural
              disaster; (x) in or taken into Mexico &nbsp;or &nbsp;anywhere else
              outside of the United States (&quot;U.S.&quot;); (xi) TO STORE,
              TREAT, TRANSPORT, OR DISPOSE OF ANY REGULATED, TOXIC, OR HAZARDOUS
              MATERIAL; and/or (xii) in violation of any other restriction set
              forth in the TSP Short-Term Lease or in the TSP platform,
              including restrictions provided at the time of reservation of a
              vehicle. RentMyTruck acknowledges and represents that it
              (including any employees and third&#8208;party agents who interact
              with RentMyTruck under this Master Lease or pursuant to the TSP)
              has been, is, and shall remain in compliance with U.S. export
              controls and sanctions laws and regulations, and all designations
              made under these laws and regulations. To the extent that
              RentMyTruck or any BMC is subject to an enforcement action or
              designation under any such export controls or sanctions law,
              RentMyTruck will notify LP within ten business days following the
              date that RentMyTruck becomes aware of such action.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.3kn7h44r9xk3" className="display: inline">
              <span class="c5">CHARGES:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;will pay (subject to the provisions of Article 9 of the
              Master Lease (including, without limitation, Section 9.3 Payment
              and Compensation Invoicing and Section 9.4 Right of Offset, if
              applicable) LP for all Short-Term TSP Leases participating in the
              TSP after the end of each respective Short-Term TSP Lease using
              the electronic payment method entered during enrollment in the TSP
              on the TSP Software.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.lxxoad4uuaf6" className="display: inline">
              <span class="c5">LIABILITY PROTECTION</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. INSURING PARTY LIABILITY INSURANCE: </span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;agrees that any BMC leasing the TSP Vehicle (the
              &ldquo;Insuring Party&rdquo;) from RentMyTruck will furnish and
              maintain, at its sole cost, a policy of automobile liability
              insurance with limits specified on each Short-Term TSP Lease for
              death, bodily injury and property damage, covering BMC as an
              insured for the ownership, maintenance, use and operation of each
              TSP Vehicle (&ldquo;Liability Insurance&rdquo;). The Liability
              Insurance must provide that its coverage is primary and not
              additional or excess coverage over any insurance otherwise
              provided by the LP, must be at least equal in scope in all
              respects to any insurance coverage provided by LP, and must
              include any and all statutory requirements of insurance imposed
              upon LP and/or BMC. In addition, the Liability Insurance must
              provide that it cannot be canceled or materially altered without
              30 days prior written notice to LP and RentMyTruck. The Insuring
              Party agrees to designate RentMyTruck as an additional insured on
              the Liability Insurance and to provide RentMyTruck with insurance
              certificates evidencing the required coverage. LP&rsquo;S
              INSURANCE COVERAGE MAY BE IMPACTED BY PARTICIPATING IN THE TSP. IT
              IS THE LP&rsquo;S RESPONSIBILITY TO UNDERSTAND ITS INSURANCE
              COVERAGE AND THE IMPACT PARTICIPATION IN THE TSP MAY HAVE ON THIS
              COVERAGE.
              <br />
              <br />
            </span>
            <span class="c0">B. SCOPE OF COVERAGE:</span>
            <span class="c1">
              &nbsp;This Master Lease is subject to all of the terms and
              conditions of the Liability Insurance, which will exclude
              uninsured or underinsured motorist coverage, personal injury
              protection coverage, medical payment coverage, and/or
              supplementary no-fault coverage. If any of these coverages cannot
              be rejected, waived, or excluded under the law of any applicable
              state, or if rejection, waiver, or exclusion is otherwise
              unenforceable, the coverage will only be provided to the extent
              and with the minimum limits required by the laws of that state.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.2vbgvtmimszv" className="display: inline">
              <span class="c5">
                LIABILITY RELATING TO OWNERSHIP OF TSP VEHICLE:
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              LP is totally responsible for all loss and/or damage, including
              road hazard damage, to the TSP Vehicle, that may arise from or
              relate to LP&rsquo;s ownership of the TSP Vehicle. LP shall
              indemnify RentMyTruck for any liability or damages relating to or
              arising out of LP&rsquo;s negligence or failure to maintain the
              TSP Vehicle (&ldquo;LP&rsquo;s Liability&rdquo;); and LP shall
              provide proof of insurance coverage for such liability upon
              enrollment in the TSP, except to the extent RentMyTruck, in its
              sole discretion, waives the LP insurance requirements pursuant to
              Section 10 of the TSP Agreement Terms and Conditions.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.nmtk2m1o9uj" className="display: inline">
              <span class="c5">DAMAGE RESPONSIBILITY:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;agrees that BMC is responsible for loss or damage to the TSP
              Vehicle during the term of the Short-Term TSP Lease, including
              responsibility for the required deductible (except to the extent
              set forth otherwise in this Master Lease and the TSP Documents).
              In no event shall RentMyTruck be responsible for any loss or
              damage to the TSP Vehicle or for BMC&rsquo;s deductible. In the
              event of a theft of the TSP Vehicle or a total loss for which BMC
              is responsible, RentMyTruck agrees that BMC will pay to
              RentMyTruck the TSP Vehicle&rsquo;s actual cash value and
              RentMyTruck will pay to LP such proceeds received from BMC. IF THE
              LP LEASES OR FINANCES THE TSP VEHICLE (WHETHER FROM Southern
              Logistics &amp; Transportation, LLC dba Rentmytruck OR FROM A
              THIRD PARTY), IT IS POSSIBLE THE ACTUAL CASH VALUE OF THE TSP
              VEHICLE MAY BE LESS THAN ANY &ldquo;SCHEDULE A&rdquo; VALUE OR
              OTHER AMOUNT YOU MAY OWE TO YOUR LESSOR OR FINANCING COMPANY IN
              THE EVENT OF A THEFT OR A TOTAL LOSS OF THE TSP VEHICLE. IT IS THE
              LP&rsquo;S RESPONSIBILITY TO UNDERSTAND THE TERMS OF SUCH TLSA,
              VEHICLE LEASE, OR FINANCING AGREEMENT AND THE IMPACT PARTICIPATION
              IN THE TSP MAY HAVE ON YOUR OBLIGATIONS TO SUCH LESSOR AND
              FINANCING COMPANY. ANY AMOUNTS LP MAY OWE TO ITS LESSOR OR
              FINANCING COMPANY IN EXCESS OF THE ACTUAL CASH VALUE ARE THE SOLE
              RESPONSIBILITY OF LP.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.11qebxuu40wz" className="display: inline">
              <span class="c5">ACCIDENT AND CARGO RESPONSIBILITY:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;agrees that LP will have absolutely no liability whatsoever
              for (i) loss or damage to cargo or other property transported in
              the TSP Vehicle during the term of the Short-Term TSP Lease
              (except when the loss or damage was due to LP&rsquo;s negligence
              or failure to maintain the TSP Vehicle) and (ii) claims, damages,
              or losses arising from injuries of any nature whatsoever, or death
              of BMC&rsquo;s agents, employees, guests, family, members of
              BMC&rsquo;s family, or other occupants of the TSP Vehicle (except
              when such death or injury was due to LP&rsquo;s negligence or
              failure to maintain the TSP Vehicle). Where LP has selected and
              paid for the optional Downtime Vehicle Coverage Program in the TSP
              Software, RentMyTruck will reimburse LP for the daily fixed rental
              rate specified in the LP&rsquo;s Short-Term Lease for the covered
              TSP Vehicle, capped at a daily limit of $150 per tractor/truck,
              commencing at the end of the Scheduled Short-Term Lease
              Termination Date and continuing for no more than 29 days, or in
              the case of a commercial van or trailer, RentMyTruck will
              reimburse LP up to a daily limit of $75 per commercial van or
              trailer for no more than 29 days, in all cases, less any fees owed
              by LP to RentMyTruck which shall be deducted from the daily fixed
              rental rate. The Downtime Vehicle Coverage Program only applies in
              those instances when a TSP Vehicle is involved in an accident
              and/or is subject to theft. Notwithstanding the foregoing, in no
              event will the Downtime Vehicle Coverage Program cover
              reimbursement for any breakdown, accident, or theft caused by
              LP&rsquo;s failure to properly maintain the TSP Vehicle or
              LP&rsquo;s failure to comply with any other provision set forth in
              the TSP Documentation.RentMyTruck&rsquo;s obligation to pay lease
              charges shall abate for each day LP receives reimbursement
              pursuant to the Downtime Vehicle Coverage Program.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.qaq6esaz3lc9" className="display: inline">
              <span class="c5">TERMINATION:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. TERMINATION RIGHTS:</span>
            <span class="c3">
              &nbsp;Either party may terminate this Master Lease by giving the
              other party at least 30 days prior written notice. Termination
              shall not be effective until all TSP Vehicles under a Short-Term
              TSP Lease to BMC have been returned in accordance with the terms
              and conditions of the TSP.
              <br />
              <br />
            </span>
            <span class="c0">B. RETURN OF TSP VEHICLES:</span>
            <span class="c1">
              &nbsp;Upon expiration of each Short-Term TSP Lease or upon
              termination of this Master Lease, RentMyTruck agrees to cause to
              be returned TSP Vehicle to the TSP Vehicle Drop-Off Location
              clean, dry, and free of odor, debris, pests, insects,
              contamination, or infestation, and in at least the same good
              condition as received, on the due date, and at the time and TSP
              Vehicle Drop-Off location as specified in each Short-Term TSP
              Lease. Unless the Master Lease is terminated pursuant to Paragraph
              7A, the term of the Short-Term TSP Lease shall continue until
              RentMyTruck issues a Short-Term TSP Lease Termination and
              Equipment Receipt to LP.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.h0qviwe4xhh2" className="display: inline">
              <span class="c5">BREACH OF THIS MASTER LEASE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c15 c0">A. BREACH OR DEFAULT:</span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">(1) DEFAULT PROCEDURE:</span>
            <span class="c3">
              &nbsp;If you breach this Master Lease or violate the terms of the
              TSP Documentation, then RentMyTruck may send you a notice of
              default. You will have 48 hours from the time that RentMyTruck
              sends you the notice to cure the default. If you fail to cure a
              default as required by this Paragraph, then RentMyTruck may, at
              its option, without prejudice to RentMyTruck&rsquo;s other
              remedies under the TSP Documentation, at law or in equity: (i)
              immediately return any or all TSP Vehicles to the proper TSP
              Vehicle Drop-Off Location, without further demand or notice
              (unless required by law in the relevant jurisdiction); (ii) remove
              all TSP Vehicles from any available vehicle listings in the TSP;
              (iii) terminate your access to the TSP Software; and/or (iv)
              terminate the Master Lease or individual Short-Term TSP Leases as
              to any or all of the TSP Vehicles. Default related to uncertified
              or unsafe TSP Vehicles shall not be subject to the 48 hour cure
              period and may be immediately removed from the TSP at
              RentMyTruck&rsquo;s sole discretion.
              <br />
              <br />
            </span>
            <span class="c0">(2) DEFAULT UNDER OTHER AGREEMENTS:</span>
            <span class="c3">
              &nbsp;If you breach any other agreement between you and
              RentMyTruck, including but not limited to any lease (e.g. TLSA),
              rental and/or maintenance agreements (the &ldquo;Other
              Agreements&rdquo;), then you will be in default of this Master
              Lease. If you breach this Master Lease, you will default on any
              Other Agreement between you and RentMyTruck.
              <br />
              <br />
            </span>
            <span class="c0">(3) RIGHT OF OFFSET:</span>
            <span class="c1">
              &nbsp;Notwithstanding anything to the contrary herein or in any
              other TSP Documentation, in the event of a breach or default by LP
              in connection with Master Lease, any TSP Documentation, or any
              Other Agreements, RentMyTruck shall be entitled to reduce and/or
              offset the amount of any payment otherwise payable to LP under
              this Master Lease or any TSP Documentation by the amount of any
              obligation of the LP to RentMyTruck that is or becomes due and
              owing under this Master Lease, any TSP Documentation, or any Other
              Agreements and the LP shall be deemed to have consented to such
              reduction and/or offset.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">B. BANKRUPTCY:</span>
            <span class="c1">
              &nbsp;It shall be a default under this Master Lease if you become
              insolvent, file a voluntary petition in bankruptcy, make an
              assignment for the benefit of creditors, are adjudicated bankrupt,
              permit a receiver to be appointed for your business, or permit or
              suffer a material disposition of your assets.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.kotrfeyy63n0" className="display: inline">
              <span class="c5">
                MAINTENANCE RESPONSIBILITY AND INSPECTION RIGHTS:
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;shall cause to be conducted pre- and post-inspections of the
              TSP Vehicle, complete required reports, and promptly notify LP of
              any TSP Vehicle condition issues. LP shall be responsible for
              ensuring that each TSP Vehicle has a decal, sticker, or other
              documentation evidencing a valid and current Federal Annual Safety
              Inspection. LP will not be liable for any FMCSA violations or
              other citations unless due to the actions or omissions of LP. For
              TSP Vehicles operated in California, LP shall be responsible for
              the maintenance of the TSP Vehicle maintenance files and periodic
              vehicle inspections required by the Basic Inspection of Terminals
              (BIT) program. Instructions for pre-and post-trip inspections are
              provided in the TSP platform.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.dff6vy6shd8j" className="display: inline">
              <span class="c5">
                TSP VEHICLE OPERATING CREDENTIALS, TAXES, FEES, PERMITS,
                TICKETS, AND FINES:
              </span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. LICENSING AND TAXES:</span>
            <span class="c3">
              &nbsp;Each TSP Vehicle must be duly licensed and registered in the
              state where such TSP Vehicle is domiciled. RentMyTruck agrees the
              BMC is required to keep the TSP Vehicle free and clear of all
              levies, liens, and encumbrances and, with the exception of
              registration/plate fees (which shall be obtained by the LP) and
              agrees that BMC will be responsible for paying any and all taxes,
              assessments, license fees, registration fees, and similar charges
              on or related to the Equipment incurred during the term of the
              Short-Term TSP Lease, including without limitation, any and all
              sales tax, use tax, excise tax, personal property tax, assessments
              and other governmental fees and charges on or relating to the
              Equipment incurred during the term of the Short-Term TSP Lease.
              The parties acknowledge that the lease charges for the Equipment
              are exclusive of sales receipt or use taxes, third structure
              taxes, including, but not limited to, weight, distance, ton
              mileage, and axle mile taxes.RentMyTruck agrees BMC will be
              responsible for paying the amount of any sales receipt, use tax,
              or third structure tax now or hereafter imposed upon the lease,
              rental, or use of the Equipment during the term of the Short-Term
              TSP Lease and RentMyTruck will promptly pass through to LP the
              actual amount of such taxes received from BMC. RentMyTruck will
              also promptly pass through the actual proceeds received from BMC
              to cover any charges incurred by LP in states other than the state
              of domicile for any of the items listed in this Paragraph.
              RentMyTruck will use commercially reasonable efforts to collect
              the full amount of any of the items set forth in this Paragraph
              from BMC but shall not be responsible to LP for any amounts
              RentMyTruck is unable to collect.
              <br />
              &zwj;
              <br />
              &zwj;
            </span>
            <span class="c0">B. FUEL TAX:</span>
            <span class="c1">
              &nbsp;LP will: (i) apply and pay for fuel tax permits/IFTA fuel
              tax permits and highway use/mileage tax permits for each TSP
              Vehicle; (ii) prepare and file IFTA fuel tax and highway
              use/mileage tax returns; and (iii) pay fuel taxes and highway
              use/mileage taxes imposed on the operation of the TSP Vehicles.
            </span>
          </li>
          <li class="c7 li-bullet-0">
            <span class="c0">(1) REQUIRED DOCUMENTATION: </span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &nbsp;must provide LP with all necessary documentation requested
              by LP (including trip records and fuel tickets or information
              necessary to request a DataQ modification) using the interface
              provided in the TSP Software.
              <br />
              <br />
            </span>
            <span class="c0">
              (2) REIMBURSEMENT OF FUEL, HIGHWAY USE, AND MILEAGE TAXES:
            </span>
            <span class="c1">
              &nbsp;The mileage rate is intended to cover the costs of fuel,
              highway use and mileage taxes during the Short-Term Vehicle Lease.
              Notwithstanding, RentMyTruck must provide all necessary
              documentation pursuant to Paragraph 13.B.(1) above.
            </span>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">C. ALL OTHER TAXES, FEES, TOLLS, OR FINES: </span>
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;agrees that BMC is responsible for paying all taxes, fees,
              special licenses, traffic and parking violations, towing and
              storage expenses (with the exception of any towing expenses
              resulting from a mechanical breakdown of the TSP Vehicle that is
              not otherwise caused by BMC&rsquo;s negligence) and other similar
              fines and tolls (whether in effect now or imposed after the date
              of this Master Lease) relating to the use of any TSP Vehicle
              during the term of the Short-Term TSP Lease or other charges under
              this Master Lease (excluding any taxes based on RentMyTruck&#39;s
              or LP&rsquo;s net income). If the failure to pay any of the above
              items results in an outstanding charge, claim, or lien involving
              any TSP Vehicle or another vehicle listed herein, then RentMyTruck
              or LP may settle such, and, in the event, LP makes any such
              payment, RentMyTruck shall promptly pass through to LP the actual
              amount of such settlement and any related costs received from BMC.
              RentMyTruck will use commercially reasonable efforts to collect
              the full amount from BMC but shall not be responsible to LP for
              any amounts RentMyTruck is unable to collect. As a condition
              precedent to LP receiving reimbursement for any applicable traffic
              and parking violations or other similar fines and tolls (the
              &ldquo;Fine(s)&rdquo;), the Participating Party must submit the
              Fine(s) together with a written claim to RentMyTruck as soon as
              possible; provided such submittal is no later than sixty (60) days
              from (i) the citation date listed on the Fine (if such Fine was
              written-up and hand-delivered at the time of the incident) or (ii)
              the date post-marked on the envelope of the Fine (if such Fine was
              delivered via mail) (the &ldquo;Fine Claim&rdquo;). If the
              Participating Party&rsquo;s request for reimbursement is approved,
              RentMyTruck shall reimburse the Participating Party within thirty
              (30) days from the date the written claim is received by
              RentMyTruck. The proceeds of any such Fine Claim actually received
              by RentMyTruck from BMC will be paid to LP, and LP acknowledges
              that RentMyTruck shall not be required to remit any such proceeds
              to LP unless and until received from BMC. Participating Party may
              be liable for additional charges associated with the Fine(s) (e.g.
              late fees or penalties associated with an outstanding charge) and
              subject to an additional administrative charge (as listed in
              Section 11 (Other Charges) of the Short-Term Lease under the
              Subsection titled &ldquo;Failure to Timely Submit Fine Claim(s)
              toRentMyTruck&rdquo;) if it fails to timely submit the Fine Claim
              to RentMyTruck, as required pursuant to this Section.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.leo2my89omt7" className="display: inline">
              <span class="c5">FUEL</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c3">RentMyTruck</span>
            <span class="c1">
              &nbsp;agrees the BMC is responsible for returning the TSP Vehicle
              to the TSP Vehicle Drop-Off Location (or any other location LP
              agrees to in writing in advance) with the same fuel level in the
              TSP Vehicle at the TSP Vehicle Pick-Up. RentMyTruck will charge
              the BMC for the cost of any shortfall plus an administrative
              mark-up per and RentMyTruck will pass through to LP the actual
              amount received from BMC (less any administrative mark-up charge).
              Such charges will be set forth in the Short-Term TSP
              Lease.RentMyTruck will use commercially reasonable efforts to
              collect the full amount of such charges from BMC but shall not be
              responsible to LP for any amounts RentMyTruck is unable to
              collect.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.u7n4rsj2ani8" className="display: inline">
              <span class="c5">TELEMATICS DEVICE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c1">
              Each TSP Vehicle may contain a telematics device consisting of a
              transceiver box, antenna, and other related equipment and hardware
              (collectively &quot;Device&quot;). The Device, all related
              software, and all data collected from the Device shall at all
              times remain the property of LP.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.eu6m5ma14ny8" className="display: inline">
              <span class="c5">COMPLIANCE:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. PICK-UP OUTSIDE OF CALIFORNIA:</span>
            <span class="c3">
              &nbsp;TSP Vehicles obtained at a TSP Pick-Up Location outside of
              the State of California and not designated in the Reservation as
              &ldquo;California Compliant&rdquo; may not be compliant with all
              California Air Resources Board requirements. If a TSP Participant
              intends to operate the TSP Vehicle within the State of California,
              the operating TSP Participant is solely responsible for compliance
              with all laws and regulations within the State of California. If
              the TSP Vehicle is designated in the Reservation as
              &ldquo;California Compliant&rdquo; then the provisions of
              sub-paragraph B apply.
              <br />
              <br />
            </span>
            <span class="c0">B. PICK-UP WITHIN CALIFORNIA:</span>
            <span class="c1">
              &nbsp;TSP Vehicles obtained at a TSP Vehicle Pick-Up Location
              within the State of California (or designated in the Reservation
              as &ldquo;California Compliant&rdquo;) will be fully compliant
              with all California-specific equipment regulations, including CARB
              and BIT periodic vehicle inspections. LP is responsible for
              maintaining such compliance.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.lgzo6grbkgsa" className="display: inline">
              <span class="c5">MISCELLANEOUS PROVISIONS:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. ASSIGNMENT OF LEASE:</span>
            <span class="c3">
              &nbsp;This Master Lease will be binding on both parties, and our
              respective successors, legal representatives, and permitted
              assigns. YOU DO NOT HAVE THE RIGHT TO ASSIGN THIS MASTER LEASE OR
              ANY INTEREST HEREUNDER WITHOUTRentMyTruck&rsquo;S PRIOR WRITTEN
              CONSENT. ANY ATTEMPT TO DO SO WILL BE VOIDABLE AT
              RENTMYTRUCK&rsquo;S OPTION. Unless RentMyTruck expressly releases
              you from your obligations in writing, you will remain liable for
              all of your and the assignee&rsquo;s obligations under this Master
              Lease including, but not limited to, liability claims, Physical
              Damage and associated Damages and Defense Costs. You specifically
              understand and acknowledge that TSP Vehicles may be leased to a
              party other than RentMyTruck (the Borrowing Motor Carrier or
              &ldquo;BMC&rdquo;). RentMyTruck may assign all or part of its
              interest in this Master Lease and any TSP Vehicle without notice
              to you or your consent.
              <br />
              <br />
            </span>
            <span class="c0">B. CHANGE OF OWNERSHIP/SALE OF ASSETS:</span>
            <span class="c3">
              &nbsp;If you change ownership or dispose of a substantial amount
              of your assets, you will notify RentMyTruck in writing. You shall
              give RentMyTruck at least 30 days prior written notice of (a) any
              change of your name, address or state of the organization from
              that set forth above or (b) any proposed merger, consolidation or
              sale of all or substantially all of your assets or transfer of a
              majority interest of your ownership interests or control from the
              persons(s) or entity(ies) holding such interests or control as of
              the date hereof.
              <br />
              <br />
            </span>
            <span class="c0">C. FORCE MAJEURE:</span>
            <span class="c3">
              &nbsp;Neither party will be liable to the other if it is prevented
              from performing under this Master Lease by any present or future
              cause beyond its control. These causes include, but are not
              limited to, Acts of God, national emergencies, wars, acts of
              terrorism, riots, fires, labor disputes, federal, state, or local
              laws, rules or regulations, shortages (local or national), or fuel
              allocation programs, provided that no Force Majeure event shall
              affect any of RentMyTruck&rsquo;s payment obligations hereunder.
              <br />
              <br />
            </span>
            <span class="c0">D. LIMITATION OF LIABILITY: </span>
            <span class="c3">RentMyTruck</span>
            <span class="c3">
              &rsquo;s liability to you for any breach of this Master Lease
              shall be limited to the actual value of the services that
              RentMyTruck fails to provide, provided that in no event shall such
              liability exceed the amount of fees actually paid or due to you as
              a result of participation in the TSP in the six (6) month period
              immediately preceding the event giving rise to such claim.
              <br />
              <br />
            </span>
            <span class="c0">E. NOTICES:</span>
            <span class="c3">
              &nbsp;Any notice, demand, consent, or request for consent under
              this Master Lease must be written and sent to you or RentMyTruck
              at the address specified during enrollment in the TSP and included
              at the beginning of this Master Lease (or any new address of which
              notice is given) or (if available) via use of the TSP Software.
              Notices shall be given by certified mail (with a return receipt),
              overnight delivery service, facsimile transmission (if a written
              record of either a machine generated or verbal telephonic
              confirmation is obtained), hand-delivery, or confirmed electronic
              communication. Notices will be effective only (a) upon receipt by
              the receiving party and (b) if the party giving the notice has
              complied with the requirements of this Section.
              <br />
              <br />
            </span>
            <span class="c0">F. SAVINGS CLAUSE:</span>
            <span class="c3">
              &nbsp;If a court rules that any provision of this Master Lease is
              illegal, invalid, or unenforceable, all other provisions will
              remain binding, effective, and fully enforceable.
              <br />
              <br />
            </span>
            <span class="c0">G. WAIVER:</span>
            <span class="c3">
              &nbsp;Delay or failure to exercise, or partial exercise of any
              right under this Master Lease will not operate to waive that or
              any other right hereunder. By failing to declare or act on a
              default, a party does not waive that default. Either party may act
              on any default at any time. BOTH PARTIES WAIVE ANY RIGHT TO A
              TRIAL BY A JURY IN ANY LAWSUIT RELATING TO THE TRANSACTIONS
              CONTEMPLATED BY THIS MASTER LEASE.
              <br />
              <br />
            </span>
            <span class="c0">H. CUMULATIVE REMEDIES:</span>
            <span class="c3">
              &nbsp;All remedies in this Master Lease are cumulative and
              non-exclusive.
              <br />
              <br />
            </span>
            <span class="c0">I. CONTENT AND MODIFICATION OF MASTER LEASE:</span>
            <span class="c3">
              &nbsp;Enrollment in the TSP and electronic acceptance of the terms
              and conditions and this Master Lease shall be deemed to be
              acceptance of and execution of this Master Lease by the
              parties&rsquo; duly authorized representatives. The agreements
              contained in the TSP Documentation are the entire agreement and
              understanding between the parties concerning the TSP subject
              matter. All previous written or oral agreements and
              representations regarding the subject matter of this Master Lease
              will be null and void. The Master Lease can be modified only by a
              written amendment signed or electronically executed by a duly
              authorized representative of the party against which enforcement
              is sought. Any attempt to modify orally or through the course of
              performance shall be void. The Participating Party must not engage
              in any side oral or written modifications or agreements to this or
              any other TSP Documentation without the direct participation of
              RentMyTruck (the &ldquo;Unauthorized Modifications&rdquo;);
              otherwise, any such Unauthorized Modifications shall be deemed
              null and void and merit RentMyTruck&rsquo;s right to terminate (at
              its sole discretion) the Participating Party&rsquo;s TSP
              Documentation and its existing (and possibly its future)
              participation in the TSP Program.
              <br />
              <br />
            </span>
            <span class="c0">
              J. DISCLAIMER OF WARRANTIES/INTENDED USE. RENTMYTRUCK MAKES NO
              EXPRESS OR IMPLIED WARRANTY REGARDING ANY TSP SOFTWARE ACCESS, OR
              ANY OTHER MATTER WHATSOEVER:
            </span>
            <span class="c3">
              &nbsp;It is the sole responsibility of RentMyTruck to ensure that
              the condition of any TSP Vehicle is acceptable for its intended
              use, and that use of such TSP Vehicle will not result in any
              violation of any laws, regulations, or ordinances applicable to
              such use.
              <br />
              <br />
            </span>
            <span class="c0">K. SURVIVABILITY:</span>
            <span class="c3">
              &nbsp;All of the defense, release, indemnification and hold
              harmless provisions of this Master Lease shall survive its
              termination (for any reason) or expiration.
              <br />
              <br />
            </span>
            <span class="c0">L. GOVERNING LAW AND JURISDICTION:</span>
            <span class="c3">
              &nbsp;This Master Lease shall be subject to, construed, and
              interpreted under the laws of the State of Texas without regard to
              its conflicts of laws provisions. The parties agree that the
              exclusive venue for any action relating to this Master Lease shall
              be in a court of competent jurisdiction in Collin County, Texas.
              <br />
              <br />
            </span>
            <span class="c0">M. ATTORNEYS&rsquo; FEES:</span>
            <span class="c3">
              &nbsp;If either party initiates litigation to enforce its rights
              under this Master Lease, the prevailing party in such litigation
              will also be entitled to receive from the other party its
              reasonable attorneys&rsquo; fees (pre-trial, trial, and appellate)
              and costs (including those paid to a collection agency).
              <br />
              <br />
            </span>
            <span class="c0">N. VALIDITY:</span>
            <span class="c3">
              &nbsp;A paper or electronic copy, scan, or electronic signature of
              this Master Lease or any Short-Term TSP Lease is sufficient and
              binding in the same manner and for the same purposes as the
              originally signed version. Neither party may deny the legal
              effect, validity, or enforceability of this Master Lease solely
              because it is in electronic form and any arguments to this effect
              are expressly waived. This Master Lease shall be admissible in any
              proceeding as a copy or scan, or with electronic or digital
              signatures, as if it contained original handwritten signatures.
              <br />
              <br />
            </span>
            <span class="c0">O. LIMITATION OF SUPPLEMENTAL PROTECTIONS.</span>
            <span class="c1">
              &nbsp;NO PROTECTION OF ANY KIND (IF ANY) WILL BE EFFECTIVE IF THE
              LP IS IN VIOLATION OF THE TSP DOCUMENTATION, TO THE EXTENT
              PERMITTED BY APPLICABLE LAW.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <h4 id="h.8qmwvk138mj6" className="display: inline">
              <span class="c5">DEFINED TERMS:</span>
            </h4>
          </li>
          <li class="c4 li-bullet-0">
            <span class="c0">A. DAMAGES:</span>
            <span class="c3">
              &nbsp;All damages, claims, suits, causes of action, penalties,
              fees, costs, expenses, and liabilities for death or injury to
              persons and loss or damage to property, including, but not limited
              to, damage to the environment and all environmental clean-up
              costs.
              <br />
              <br />
            </span>
            <span class="c0">B. DEFENSE COSTS:</span>
            <span class="c3">
              &nbsp;Attorneys&rsquo; fees, experts&rsquo; fees, and court costs
              (pre-trial, trial, and appellate).
              <br />
              <br />
            </span>
            <span class="c0">C. HAZARDOUS MATERIAL:</span>
            <span class="c1">
              &nbsp;Any cargo, property, or hazardous material in a quantity
              which requires placarding by the United States Department of
              Transportation, and any medical, bio-hazardous, or radioactive
              waste.
            </span>
          </li>
        </ol>
        <p class="c19">
          <span class="c15 c20"></span>
        </p>
      </div>
      <HomePageFooter/>
    </>
  );
};

export default TermsandConditions;
