import React from 'react'
import { useState } from "react";

import {
  TruckIcon,
  CalendarIcon,
  UserIcon,
  CalendarDaysIcon
} from "@heroicons/react/24/outline";


import { Link } from 'react-router-dom';

const SidebarRenterMobile = () => {
  //   const [isOnReservation, setIsOnReservation] = useState(false);
  // const [isOnDrivers, setIsOnDrivers] = useState(false);
  // const [isOnVehicle, setIsOnVehicle] = useState(false);

  const href  = [
    {
    Name: "Vehicles",
    to: "/home",
    Icon: TruckIcon,
  },
  {
    Name: "Drivers",
    to: "/Drivers",
    Icon: UserIcon,
  }, 
  {
    Name: "Reservations",
    to: "/Reservations",
    Icon: CalendarDaysIcon,
  }, 
]

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
  return (
    <div className="flex flex-col justify-between h-full">
    <nav className="space-y-1 px-2">
      {href.map((item,index) => (<Link key={item.Name}
        // onClick={() => {
        //   setIsOnReservation(false);
        //   setIsOnDrivers(false);
        //   setIsOnVehicle(true)
        // }}
        to={item.to}
      >
        <button
          className={classNames(
             window.location.pathname.includes(item.to)
            ? "nav-button-active-color  border-purple-500 w-full nav-button-active-text-color"
              : "text-gray-600 hover:bg-purple-50 border-transparent w-full hover:text-purple-500",
            "group flex items-center border-l-4 mt-1 px-2 py-2 text-base font-medium rounded-md"
          )}
        >
          <item.Icon
            className={classNames(
               window.location.pathname.includes(item.to)
                ? "nav-button-active-text-color"
                : "text-gray-400 group-hover:text-purple-500",
              "mr-4 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          {item.Name}
        </button>
      </Link>))}
      {/* <Link
        onClick={() => {
          setIsOnReservation(true);
          setIsOnDrivers(false);
          setIsOnVehicle(false)
          
        }}
        to="/Reservation"
      >
        <button
          className={classNames(
            isOnReservation
            ? "nav-button-active-color  border-purple-500 w-full nav-button-active-text-color"
              : "text-gray-600 hover:bg-purple-50 border-transparent w-full hover:text-purple-800",
            "group flex items-center border-l-4 mt-1 px-2 py-2 text-base font-medium rounded-md"
          )}
        >
          <CalendarIcon
            className={classNames(
              isOnReservation
                ? "nav-button-active-text-color"
                : "text-gray-400 group-hover:text-purple-800",
              "mr-4 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          Reservations
        </button>
      </Link> */}
      {/* <Link
        // onClick={() => {
        //   setIsOnReservation(false);
        //   setIsOnDrivers(true);
        //   setIsOnVehicle(false)
        // }}
        to="/Drivers"
      >
        <button
          className={classNames(
             window.location.pathname.includes("/Drivers")
              ? "nav-button-active-color  border-purple-500 w-full nav-button-active-text-color"
              : "text-gray-600 hover:bg-purple-50 border-transparent w-full hover:text-purple-800",
            "group flex items-center border-l-4 mt-1 px-2 py-2 text-base font-medium rounded-md"
          )}
        >
          <TruckIcon
            className={classNames(
               window.location.pathname.includes("/Drivers")
                ? "nav-button-active-text-color"
                : "text-gray-400 group-hover:text-purple-800",
              "mr-4 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          Drivers
        </button>
      </Link> */}
      
    </nav>
    
  </div>
  )
}

export default SidebarRenterMobile