import React, { useState } from "react";
import "./Signup.css";
import Cover from "../../img/truck renter image copy 11.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "sonner";

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [signup, setSignup] = useState(true);

  const [companyInfo, setCompanyInfo] = useState(false);

  const [firstNameSignup, setFirstNameSignUp] = useState(null);
  const [lastNameSignup, setLastNameSignUp] = useState(null);
  const [emailSignup, setEmailSignUp] = useState(null);
  const [passwordSignUp, setPasswordSignUp] = useState(null);
  const [companyLogoSrc, setCompanyLogoSrc] = useState(
    "https://t4.ftcdn.net/jpg/04/81/13/43/360_F_481134373_0W4kg2yKeBRHNEklk4F9UXtGHdub3tYk.jpg"
  );
  const [companyLogoURL, setCompanyLogoURL] = useState();

  // Formik for Signup
  const SignUpForm = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      setFirstNameSignUp(values.first_name);
      setLastNameSignUp(values.last_name);
      setEmailSignUp(values.email);
      setPasswordSignUp(values.password);
      setSignup(false);
      setCompanyInfo(true);
    },
    enableReinitialize: true,
  });
  // End Formik for Signup

  const handleCompanyLogoUpload = (e) => {
    console.log(e.target.files[0]);
    let filePicture = e.target.files[0];
    const formData = new FormData();
    formData.append("file", filePicture);

    const uploadPictureApi = async () => {
      try {
        // setLoaderPassportFile(true);
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData
            // config
          )
          .then((responsePicture) => {
            setCompanyLogoURL(responsePicture.data.message.file_url);
            setCompanyLogoSrc(
              window.$backendURL + responsePicture.data.message.file_url
            );
          });
      } catch (e) {
        console.log(e);
      }
    };
    uploadPictureApi();
  };

  // Formik for Company Info
  const CompanyInfoForm = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      var signupJSON = {
        first_name: firstNameSignup,
        last_name: lastNameSignup,
        email: emailSignup,
        password: passwordSignUp,
        user_image: companyLogoURL,

        role: "Owner Business",
        business_profile: {
          user: emailSignup,
          business_number: values.company_number,
          business_entity: values.business_entity,
          business_address: values.company_address,
          employer_identification_number: values.emp_id_number,
          license_number: values.license_number_com,
          department_transport_number:
            values.department_transportation_number_comp,
          motor_carrier_number: values.motor_carrier_number_comp,
          business_hours: values.business_hours,
          company_logo: companyLogoURL,
          business_name: values.business_name,
          company_state: values.company_state,
        },
      };
      const SignUpUser = async () => {
        try {
          setLoading(true);
          await axios
            .post(
              `${window.$backendURL}/api/method/truckrent.api.add_user`,
              signupJSON
            )
            .then((response) => {
              toast.success("Signup Sucessfully");
              console.log(response.data);
              setTimeout(() => {
                navigate("/signin");
              }, 2000);
            });
        } catch (e) {
          // toast.error("Some of your required fields are missing!");
          console.log(e);
          setLoading(false);
          if (e.message == "Request failed with status code 409") {
            toast.error("Account already exists with this email address");
          }
        }
      };

      SignUpUser();
    },
  });

  return (
    <>
      {/* Main Signup form Div */}
      <div className="relative flex min-h-full">
        <Toaster richColors />
        <div className="flex min-h-screen flex-col justify-center lg:py-12 lg:px-10 px-2 ">
          {signup && (
            <form onSubmit={SignUpForm.handleSubmit} className="space-y-6">
              <div className="mx-auto bg-gray-100 lg:w-[40rem] rounded-lg">
                <div className="px-10 py-10">
                  <div>
                    <div className="mb-3 flex items-center justify-center">
                      <img
                        src="https://rentmytruck.co/wp-content/uploads/2023/01/WhatsApp-Image-2023-01-10-at-9.12.35-PM1-copy.png"
                        alt="Logo"
                        className="w-1/4 "
                      />
                    </div>
                    <h2 className="mt-6  text-3xl font-semibold tracking-tight text-gray-900">
                      Sign up
                    </h2>
                    <p className="mt-2  text-sm text-gray-600">
                      Already have an account?{" "}
                      <Link to="/signin">
                        <span className="font-medium text-color hover:text-purple-700">
                          Signin
                        </span>
                      </Link>
                    </p>
                  </div>

                  <div className="mt-8">
                    <div className="mt-6 flex flex-col gap-y-3">
                      <div>
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First Name <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            autoComplete="first_name"
                            onChange={SignUpForm.handleChange}
                            value={SignUpForm.values.first_name}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last Name <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            autoComplete="last_name"
                            onChange={SignUpForm.handleChange}
                            value={SignUpForm.values.last_name}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            onChange={SignUpForm.handleChange}
                            value={SignUpForm.values.email}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            onChange={SignUpForm.handleChange}
                            value={SignUpForm.values.password}
                            required
                            pattern="(?=.*[A-Z])(?=.*\d).{8,}"
                            title="Password must be at least 8 characters long and contain at least one uppercase letter and one number."
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md mt-5 border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                        >
                          Sign up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          {companyInfo && (
            <form onSubmit={CompanyInfoForm.handleSubmit}>
              <div className="bg-gray-100  w-full  rounded-lg">
                <div className="px-10 py-10">
                  <div>
                    <button
                      className="font-semibold flex flex-row gap-2 mb-4"
                      onClick={() => {
                        setCompanyInfo(false);
                        setSignup(true);
                      }}
                    >
                      <svg
                        className="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>Back</span>
                    </button>
                    {/* <div className="mb-3 flex items-center justify-center">
                      <img
                        src="https://rentmytruck.co/wp-content/uploads/2023/01/WhatsApp-Image-2023-01-10-at-9.12.35-PM1-copy.png"
                        alt="Logo"
                        className=""
                      />
                    </div> */}
                    <h2 className="mt-6 text-3xl font-semibold tracking-tight text-left text-gray-900">
                      Company Information
                    </h2>
                  </div>

                  <div className="mt-2">
                    <div className="mt-4">
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Logo
                        </label>
                        <div className="mt-1 flex items-center gap-6">
                          <span className="inline-block lg:h-20 lg:w-20  w-32 overflow-hidden rounded-full bg-gray-100">
                            <img
                              src={companyLogoSrc}
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              alt=""
                            />
                          </span>
                          <div className="flex flex-col gap-1">
                            <label
                              htmlFor="company_logo"
                              className="w-44 rounded-md border  button py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                            >
                              Upload Company Logo
                            </label>
                            <div className="mt-1">
                              <input
                                id="company_logo"
                                name="company_logo"
                                type="file"
                                onChange={(e) => {
                                  handleCompanyLogoUpload(e);
                                }}
                                className="sr-only"
                              />
                              <p className="font-thin text-sm">
                                Image size should be at least 320px big. Allowed
                                file .png and jpg.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      

                      <div>
                        <label
                          htmlFor="business_name"
                          className="block text-sm mt-4 font-medium text-gray-700"
                        >
                          Business Name <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="business_name"
                            name="business_name"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.business_name}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="company_state"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Company State <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            type="text"
                            name="company_state"
                            id="company_state"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.company_state}
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                            placeholder=""
                            required
                          >
                            <option value="">Select</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">
                              North Carolina
                            </option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">
                              South Carolina
                            </option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                          </select>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="business_name"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Business Entity{" "}
                          <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <select
                            id="business_entity"
                            name="business_entity"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.business_entity}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          >
                            <option>Select Business Entitiy</option>
                            <option>CORP</option>
                            <option>LLC</option>
                            <option>Sole Proprietor</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="company_address"
                          className="block mt-2.5 text-sm font-medium text-gray-700"
                        >
                          Business Address{" "}
                          <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="company_address"
                            name="company_address"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.company_address}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="company_number"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Business Phone <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="company_number"
                            name="company_number"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.company_number}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>

                      {/* <div>
                        <label
                          htmlFor="license_number_com"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          License Number
                        </label>
                        <div className="mt-1">
                          <input
                            id="license_number_com"
                            name="license_number_com"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.license_number_com}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div> */}
                      <div>
                        <label
                          htmlFor="department_transportation_number_comp"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Department Transportation Number (DOT){" "}
                          <span className="text-red-400">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="department_transportation_number_comp"
                            name="department_transportation_number_comp"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={
                              CompanyInfoForm.values
                                .department_transportation_number_comp
                            }
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="emp_id_number"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Employer Identification Number (EIN){" "}
                          <span className="text-gray-400 italic">Optional</span>
                        </label>
                        <div className="mt-1">
                          <input
                            id="emp_id_number"
                            name="emp_id_number"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.emp_id_number}
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div>
                      {/* <div>
                        <label
                          htmlFor="motor_carrier_number_comp"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Motor Carier Number (MC)
                        </label>
                        <div className="mt-1">
                          <input
                            id="motor_carrier_number_comp"
                            name="motor_carrier_number_comp"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={
                              CompanyInfoForm.values.motor_carrier_number_comp
                            }
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div> */}

                      {/* <div>
                        <label
                          htmlFor="business_hours"
                          className="block text-sm mt-2.5 font-medium text-gray-700"
                        >
                          Business Hours
                        </label>
                        <div className="mt-1">
                          <input
                            id="business_hours"
                            name="business_hours"
                            type="text"
                            onChange={CompanyInfoForm.handleChange}
                            value={CompanyInfoForm.values.business_hours}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                          />
                        </div>
                      </div> */}

                      <div>
                        {loading ? (
                          <div className="flex w-full justify-center mt-5 bg-gray-500 text-white rounded-md border border-transparent py-2 px-4 text-sm font-medium  shadow-sm ">
                            Creating Account...
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="flex w-full justify-center mt-5 rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          >
                            Sign Up
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="relative hidden w-0 flex-1 lg:block  ">
          <img className="w-full h-full" src={Cover} alt="" />
        </div>
      </div>
    </>
  );
};

export default Signup;
