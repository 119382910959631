import React, { useEffect, useState } from "react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
  TruckIcon,
  XMarkIcon,
  CreditCardIcon,
  PlusIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import LoadingIcons from "react-loading-icons";
import { PencilOutline } from "heroicons-react";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { StarIcon, MapPinIcon } from "@heroicons/react/24/outline";

import TextField from "@mui/material/TextField";
import { DateRange } from "react-date-range";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Tooltip from "../../Global/SmallComponents/Tooltip";
import { useFormik } from "formik";
import moment from "moment/moment";
import ReservationPageSkeleton from "../../Global/Skeleton/ReservationPageSkeleton";
import { Toaster, toast } from "sonner";
import { Dialog, Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../../Global/Setting/PaymentForm";
import RenterFAQs from "./RenterFAQs";

const people = [
  { id: 1, name: "Wade Cooper", online: true },
  { id: 2, name: "Arlene Mccoy", online: false },
  { id: 3, name: "Devon Webb", online: false },
  { id: 4, name: "Tom Cook", online: true },
  { id: 5, name: "Tanya Fox", online: false },
  { id: 6, name: "Hellen Schmidt", online: true },
  { id: 7, name: "Caroline Schultz", online: true },
  { id: 8, name: "Mason Heaney", online: false },
  { id: 9, name: "Claudie Smitham", online: true },
  { id: 10, name: "Emil Schaefer", online: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VehicleDetail = () => {
  const stripePromise = loadStripe(
    "pk_live_51McDWdLUqlbLOiA4OFtWtx9aK2FQVZ3WaGYoRBLjfiVUOFMHaJhr53SqWZlsHsKqxbLHBu2Z6W58L9sUBT1yYKEF00x1WJTl0W"
  );
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [selected, setSelected] = useState(null);
  console.log(selected);
  const { vehicleID } = useParams();
  const [currentVehicle, setCurrentVehicle] = useState();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState();
  const [allDrivers, setAllDrivers] = useState(null);
  const [currProfile, setCurrProfile] = useState(null);
  const [defaultDriver, setDefaultDriver] = useState(null);
  const [dateToday, setDateOfToday] = useState(new Date().toLocaleDateString());
  const [bookingDisabled, setBookingDisabled] = useState(false);
  const [addPaymentMethodModal, setAddPaymentMethodModalOpen] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [deletedPaymentMethod, setDeletedPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();
  const [newAdded, setNewAdded] = useState(false);

  const [vehilceOwner, setVehicleOwner] = useState(null);
  const [bookingDisabledMessage,setBookingDisabledMessage] = useState("");
  const getNewAdded = () => {
    setAddPaymentMethodModalOpen(!addPaymentMethodModal);
    setNewAdded(!newAdded);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        // setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            setCurrProfile(response?.data?.data);
            const getPayemntsList = async () => {
              var json = {
                customer_stripe: response.data.data?.customer_stripe,
              };
              try {
                // setLoading(true);
                await axios
                  .post(
                    `${window.$backendURL}/api/method/truckrent.api.list_payment_method`,
                    json
                  )
                  .then((responsePayments) => {
                    setPaymentMethods(responsePayments?.data?.data?.data);
                    console.log(">>>>", responsePayments);
                    // setLoading(false);
                  });
              } catch (e) {
                console.error(e);
              }
            };

            getPayemntsList();
          });
      } catch (e) {
        console.error(e);
      }
    };

    getProfileOwner();
  }, [newAdded, deletedPaymentMethod]);

  const handleDeletePaymentMethod = async (id) => {
    setSelected(null);
    var json = {
      payment_id: id,
    };

    try {
      await axios
        .post(
          `${window.$backendURL}/api/method/truckrent.api.delete_payment_method`,
          json
        )
        .then((response) => {
          setSelected(null);
          toast.success("Payment Method Deleted Successfully");
          setDeletedPaymentMethod(!deletedPaymentMethod);
        });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const getDrivers = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Driver Profiles?fields=["*"]&filters=[["driver_for_renter","=","${localStorage.userID}"]]`,
            config
          )
          .then((response) => {
            setAllDrivers(response.data.data);
            response?.data?.data?.map((driver) => {
              if (driver?.is_verified == 1 && driver?.is_default == 1) {
                setDefaultDriver(driver.name);
              }
            });
            console.log("default", defaultDriver);
            console.log(">>>drivers", response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };

    getDrivers();
  }, []);

  const [costing, setcosting] = useState();
  const [allUnAvailDates, setAllUnAvailDates] = useState([]);
  const [currLocation, setCurrLocation] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [isRenterFaqOpen, setIsRenterFaqOpen] = useState(false);
  const navigation = useNavigate();
  const [message, setMessage] = useState("");
  const [globalStatus, setGlobalStatus] = useState(null);
  const [reservationBooking, setReservationBooking] = useState(false);
  const [minimumRentalDays, setMinimumRentalDays] = useState(0);
  const today = new Date();
  const defaultEndDate = new Date(today);
  defaultEndDate.setDate(defaultEndDate.getDate() + minimumRentalDays);

  const [state, setState] = useState([
    {
      startDate: today,
      endDate: defaultEndDate,
      key: "selection",
    },
  ]);

  console.log(state);

  const formattedStartDate = formatDate(state[0]?.startDate);

  // Format the endDate
  const formattedEndDate = formatDate(state[0]?.endDate);

  // Create a new object with formatted dates
  const formattedState = {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    key: state.key,
  };

  function formatDate(date) {
    const formattedDate = date?.toISOString()?.split("T")[0];
    return formattedDate;
  }

  const handleSelect = (ranges) => {
    const startDateString = ranges.selection.startDate.toDateString();
    const endDateString = ranges.selection.endDate.toDateString();
    const selectedDays = calculateTotalDays(startDateString, endDateString);

    if (selectedDays < minimumRentalDays) {
      // If the selected range is less than minimum rental days, reset to the minimum range
      setMessage(`Minimum rental period is ${minimumRentalDays} days`);
      const newEndDate = new Date(ranges.selection.startDate);
      newEndDate.setDate(newEndDate.getDate() + minimumRentalDays);
      setState([
        {
          startDate: ranges.selection.startDate,
          endDate: newEndDate,
          key: "selection",
        },
      ]);
    } else {
      setMessage("");
      setState([ranges.selection]);
    }
  };

  const calculateTotalDays = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const startTimestamp = Date.parse(startDate);
    const endTimestamp = Date.parse(endDate);
    if (!startTimestamp || !endTimestamp) {
      return 0;
    }
    const diffDays = Math.round(
      Math.abs((endTimestamp - startTimestamp) / oneDay)
    );
    return diffDays;
  };

  useEffect(() => {
    const GetVehicle = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/resource/Vehicle/${vehicleID}`,
            config
          )
          .then((response) => {
            console.log("vehicle", response.data.data);
            setCurrentVehicle(response.data.data);

            if (
              response?.data?.data?.vehicle_owner ===
              localStorage.getItem("userID")
            ) {
              setBookingDisabled(true);
              setBookingDisabledMessage("You can book your own Vehicle")
            }
            else {
              try {
                axios.get(`${window.$backendURL}/api/resource/Company Profile/${response?.data?.data?.vehicle_owner}`).then((res) => {
                  console.log("vehicle_owner", res?.data?.data);
                  setVehicleOwner(res?.data?.data)
                  if(res?.data?.data?.stripe_connected == 0){
                    setBookingDisabled(true)
                    setBookingDisabledMessage("This vehicle is not available for rental right now.")
                  }
                })
              } catch (error) {
                console.log(error);
              }
            }


            if (response.data.data.availability.length > 0) {
              response.data.data.availability.forEach((element) => {
                setAllUnAvailDates((state) => [
                  ...state,
                  new Date(element.reservation_date),
                ]);
              });
            }
            console.log("unavailableDates", allUnAvailDates);
            const getLocation = async () => {
              try {
                await axios
                  .get(
                    `${window.$backendURL}/api/resource/Location?fields=["*"]&filters=[["name","=","${response.data.data.pick_up}"]]`,
                    config
                  )
                  .then((responseLoc) => {
                    setCurrLocation(responseLoc.data.data[0]);
                    setLat(
                      responseLoc.data?.data[0]?.coordinates?.split(",")[0]
                    );
                    setLong(
                      responseLoc.data?.data[0]?.coordinates?.split(",")[1]
                    );
                  });
              } catch (e) {
                console.error(e);
              }
            };
            getLocation();
          });
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetVehicle();
    // setLoading(false);
  }, []);

  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);

  useEffect(() => {
    const getTypeBasedCosting = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/method/truckrent.api.get_pricing_breakdown?vehicle=${vehicleID}&start_date=${formattedState?.startDate}&end_date=${formattedState?.endDate}`,
            config
          )
          .then((responseCost) => {
            console.log("responseCost", responseCost?.data?.data);
            setShowPriceBreakdown(true);
            setcosting(responseCost?.data?.data);
            setMinimumRentalDays(
              responseCost?.data?.data?.vehicle?.min_rental_days_out
            );
            // console.log("TypeCostinf>>>",responseCost?.data?.data);
            // // console.log(responseCost.data.data[0].allowed_cost_per_mile*responseCost.data.data[0].per_day_miles_allowed);
            // setTotalMiles(
            //   0.3 * responseCost.data.data[0]?.per_day_miles_allowed
            // );
          });
      } catch (e) {
        console.error(e);
      }
    };
    getTypeBasedCosting();
  }, [state]);

  // const handleGrandTotal = () =>{

  //   setSubTotal(totalPrePaidMileage)

  // }

  // useEffect(() => {
  //   var Difference_In_Time =
  //     state[0].endDate?.getTime() - state[0].startDate?.getTime();
  //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
  //   setTotalDays(Math.floor(Difference_In_Days));
  //   setTotalrent(Math.floor(Difference_In_Days) * currentVehicle?.rent_per_day);
  //   console.log(state);
  //   setTotalPrePaidMileage(Math.floor(totalTypeMiles * 1.5));
  //   setTax(500);
  //   setRoadSide(500);
  // }, [state, totalPrePaidMileage]);

  // console.log("owner", currentVehicle?.vehicle_owner);

  // useEffect(() => {
  //   // handleGrandTotal();
  //   setServiceFee(totalRent * 0.125);
  //   setProcessingFee(totalRent * 0.03);
  //   setTotalFee(serviceFee + processingFee);
  //   setDeposit(totalRent * 0.3);
  //   setGrandTotal(totalRent + totalFee + totalPrePaidMileage + deposit);
  // }, [totalRent, serviceFee, processingFee, deposit, totalFee]);

  const ReserveForm = useFormik({
    initialValues: {
      dot: currProfile?.department_transport_number,
      driver: defaultDriver,
    },
    onSubmit: async (values) => {
      console.log(values);
      if (values.terms_accepted == true) {
        if(selected != null) {
        var makeReservationJSON = {
          vehicle: vehicleID,
          start_date: moment(state[0].startDate).format("YYYY-MM-DD"),
          end_date: moment(state[0].endDate).format("YYYY-MM-DD"),
          renter: currProfile?.name,
          pickup_dropoff_time: values?.time,
          driver: values?.driver,
          accepted_terms: 1,
          payment_method: selected?.id,

          // owner_reservation: currentVehicle?.vehicle_owner,
          // pickup_location: currentVehicle?.pick_up,
          // reservation_status: "Requested",
          // global_status: "Requested.Requested",
          // applied_vehicle_type_based_costing: costing?.name,
          // per_day_miles_allowed: costing?.per_day_miles_allowed,
          // allowed_cost_per_mile: costing?.allowed_cost_per_mile,
          // overboard_per_mile_cost: costing?.overboard_per_mile_cost,
          // review_status:"In Team Review",
          // price_break_down_renter: [
          //   {
          //     stage: "Requested.Requested",
          //     rent_per_day: currentVehicle?.rent_per_day,
          //     per_day_miles_allowed: costing?.per_day_miles_allowed,
          //     allowed_cost_per_mile: costing?.allowed_cost_per_mile,
          //     overboard_per_mile_cost: costing?.overboard_per_mile_cost,
          //     service_fees: serviceFee,
          //     total: grandTotal,
          //     deposit: deposit,
          //     parentfield: "price_break_down_renter",
          //     parenttype: "Reservation",
          //     doctype: "Price Breakdown Renter",
          //   },
          // ],
        };
        console.log("makeReservationJSON", makeReservationJSON);
        const makeReservation = async () => {
          setReservationBooking(true);
          try {
            // setLoading(true);
            await axios
              .post(
                `${window.$backendURL}/api/method/truckrent.api.create_reservation`,
                makeReservationJSON,
                config
              )
              .then((response) => {
                toast.success("Reservation Booked Successfully");
                console.log("api", response.data.data);
                // setLoading(false);
                console.log(
                  "globalStauts",
                  response?.data?.data?.global_status
                );
                setGlobalStatus(response?.data?.data?.global_status);
                setTimeout(() => {
                  navigation(
                    `/ReservationDetails/${response?.data?.data?.reservation?.name}`
                  );
                }, 1200);
                setReservationBooking(false);
                // console.log("helloooooooo",globalStatus);
              });
          } catch (e) {
            console.error(e);
            setReservationBooking(false);
            setTimeout(() => {
              toast.error("Reservation Booking Failed");
            }, 1000);
          }
        };

        makeReservation();
      }
      else{
        toast.error("Add your Payment Method")
      }
    }
  },
    enableReinitialize: true,
  });

  const link = `https://beta.rentmytruck.co/homepage-vehicle-details/${vehicleID}`;

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success("Link Copied");
    });
  };
  return (
    <>
      <Toaster richColors />
      {loading ? (
        <ReservationPageSkeleton />
      ) : (
        <div className="min-h-full">
          <main className="mt-0 ">
            <div className="mx-0 w-full lg:px-4 ">
              <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
                <div className="grid grid-cols- gap-4 lg:col-span-2">
                  <section aria-labelledby="profile-overview-title">
                    <Tab.Group
                      as="div"
                      className="flex flex-col-reverse lg:pr-4"
                    >
                      <div className="mx-auto lg:mt-6 mt-4 w-full max-w-2xl sm:block lg:max-w-none">
                        <Tab.List className="grid grid-cols-4 lg:gap-6 gap-3">
                          {currentVehicle?.attach_images.length > 0 ? (
                            currentVehicle?.attach_images?.map((image) => (
                              <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                                {({ selected }) => (
                                  <>
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                      <img
                                        src={
                                          image.image
                                            ? window.$backendURL + image.image
                                            : "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                        }
                                        alt=""
                                        className="h-full w-full object-contain"
                                      />
                                    </span>
                                    <span
                                      className={
                                        selected
                                          ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                          : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                      }
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </Tab>
                            ))
                          ) : (
                            <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                              {({ selected }) => (
                                <>
                                  <span className="absolute inset-0 overflow-hidden rounded-md">
                                    <img
                                      src={
                                        "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                      }
                                      alt=""
                                      className="h-full w-full object-contain"
                                    />
                                  </span>
                                  <span
                                    className={
                                      selected
                                        ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                        : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                    }
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </Tab>
                          )}
                        </Tab.List>
                      </div>

                      <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                        {currentVehicle?.attach_images.length > 0 ? (
                          currentVehicle?.attach_images?.map((image) => (
                            <Tab.Panel>
                              <img
                                src={window.$backendURL + image.image}
                                alt=""
                                className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                              />
                            </Tab.Panel>
                          ))
                        ) : (
                          <Tab.Panel>
                            <img
                              src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                              alt=""
                              className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                            />
                          </Tab.Panel>
                        )}
                      </Tab.Panels>
                    </Tab.Group>
                  </section>

                  <section aria-labelledby="quick-links-title">
                    <div className=" overflow-hidden rounded-lg  py-2">
                      <div className=" flex justify-between flex-col">
                        <div className="flex items-center gap-x-4">
                          <h2
                            className="text-2xl font-semibold  text-gray-900"
                            id="announcements-title"
                          >
                            {currentVehicle?.year +
                              ", " +
                              currentVehicle?.make +
                              " " +
                              currentVehicle?.model}
                          </h2>
                          <button
                            className="bg-purple-500 px-4 py-1.5 rounded-md text-white tracking-wide font-medium"
                            onClick={() => copyToClipboard(link)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#FFFFFF"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                            </svg>
                          </button>
                        </div>

                        {/* <div className="flex my-4 items-center gap-4">
                        <div className="text-sm font-semibold">4.5</div>
                        <div className="text-sm font-semibold w-6 h-6">
                          <StarIcon />
                        </div>

                        <div>
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            23 Trips
                          </span>
                        </div>
                        <div className="w-6 h-6">
                          <MapPinIcon />
                        </div>
                        <div className="text-sm font-semibold">2.3 miles</div>
                      </div> */}

                        <div className="my-2">
                          <div className="flex  items-center gap-x-2 lg:gap-2">
                            <div className="text-sm bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
                              {currentVehicle?.fuel_type}
                            </div>

                            <div className="text-sm bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
                              {currentVehicle?.lift_gate}
                            </div>
                          </div>
                        </div>

                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Specifications
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.e_truck === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.e_truck === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.e_truck === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">E-Truck</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.ramp === 1 ? `purple` : `gray`
                              }-100 text-${
                                currentVehicle?.ramp === 1 ? `purple` : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.ramp === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Ramp</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.crew_cab === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.crew_cab === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.crew_cab === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Crew Cab</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.dock_height === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.dock_height === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.dock_height === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Dock Height</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.multi_temp === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.multi_temp === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.multi_temp === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Multi - Temp</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.apu === 1 ? `purple` : `gray`
                              }-100 text-${
                                currentVehicle?.apu === 1 ? `purple` : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.apu === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">APU</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.storage_only === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.storage_only === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.storage_only === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Storage Only</span>{" "}
                            </div>
                            <div
                              className={`flex gap-2 justify-center bg-${
                                currentVehicle?.has_ifta === 1
                                  ? `purple`
                                  : `gray`
                              }-100 text-${
                                currentVehicle?.has_ifta === 1
                                  ? `purple`
                                  : `gray`
                              }-400 px-2 py-1 rounded-full items-center`}
                            >
                              {currentVehicle?.has_ifta === 1 ? (
                                <CheckIcon className="w-5 h-5" />
                              ) : (
                                <XMarkIcon className="w-5 h-5" />
                              )}
                              <span className="">Has IFTA</span>{" "}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Door Type
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <span className="text-base py-1 flex items-center  text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                              >
                                <path d="M140.001-140.001V-200h80v-547.691q0-30.308 21-51.308t51.308-21h375.382q30.308 0 51.308 21t21 51.308V-200h80v59.999H140.001ZM280-200h400v-547.691q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H292.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463V-200Zm115.384-244.616q14.692 0 25.038-10.346 10.347-10.346 10.347-25.038t-10.347-25.038q-10.346-10.346-25.038-10.346t-25.038 10.346Q360-494.692 360-480t10.346 25.038q10.346 10.346 25.038 10.346ZM280-760v560-560Z" />
                              </svg>{" "}
                              {currentVehicle?.door_type}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Usage Restrictions
                          </h1>
                          <div className="flex justify-start flex-wrap gap-2 my-1 text-sm ">
                            <span className="text-base  py-1 text-gray-500">
                              {currentVehicle?.usage_restrictions}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h1 className="text-lg lg:mt-3 mt-2 font-semibold text-gray-800">
                            Description
                          </h1>
                          <p className="text-base text-gray-500  my-1">
                            {currentVehicle?.description}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col lg:mt-3 mt-2">
                        <h1 className="text-lg font-semibold text-gray-800">
                          Location
                        </h1>
                        <div className="text-base font-medium mt-1 text-gray-700">
                          {currLocation?.address}
                        </div>
                        <div className="text-base font-normal mb-1 text-gray-500">
                          {currLocation?.other_details &&
                            `(${currLocation?.other_details})`}
                        </div>
                      </div>
                      <iframe
                        className="w-full rounded-lg h-60 sm:h-96"
                        marginheight="0"
                        marginwidth="0"
                        src={`https://maps.google.com/maps?q=${lat},${long}&hl=en&z=14&amp&output=embed`}
                      ></iframe>
                      {/* <div className="flex flex-col mt-6 ">
                        <div className="text-lg font-semibold my-2 ">
                          Business hours
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                          {currProfile?.business_hours}
                        </div>
                      </div> */}
                    </div>
                  </section>
                </div>

                <div className="grid grid-cols-1 gap-4 ">
                  <section aria-labelledby="announcements-title">
                    <div className="overflow-hidden rounded-lg bg-white ">
                      <div className=" flex flex-col justify-between lg:px-4 lg:py-2 px-3 py-2 mb-5">
                        <h1
                          className="text-xl flex gap-1 text-gray-900 mt-2 font-semibold items-center"
                          id="announcements-title"
                        >
                          Reserve this Equipment
                        </h1>
                        <h2
                          className="lg:text-xl text-lg flex gap-1 text-gray-900 lg:my-1"
                          id="announcements-title"
                        >
                          <span className="font-semibold">
                            {"$" + currentVehicle?.rent_per_day}
                          </span>{" "}
                          <span>/ Day</span>
                        </h2>
                        <div className=" flex flex-col justify-between gap-y-4">
                          <form
                            onSubmit={ReserveForm.handleSubmit}
                            className="space-y-3"
                          >
                            <div className="flex flex-col justify-center sm:flex-row mt-3 lg:px-0 px-5">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handleSelect}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                                rangeColors={["#EA446A"]}
                                minDate={new Date()}
                                shownDate={new Date()}
                                disabledDates={allUnAvailDates}
                              />
                            </div>
                            <div className="flex items-center justify-center">
                              <span className="text-md font-semibold px-8">
                                {message}
                              </span>
                            </div>
                            <div className="mt-1 rounded-md shadow-sm">
                              <div>
                                <label
                                  htmlFor="time"
                                  className=" block text-sm font-medium text-gray-700"
                                >
                                  Time
                                </label>
                                <span className="text-xs text-gray-600 font-semibold">
                                  (The time indicated will also become your
                                  vehicle return deadline)
                                </span>
                                <select
                                  id="time"
                                  name="time"
                                  autoComplete="time"
                                  onChange={ReserveForm.handleChange}
                                  value={ReserveForm.values.time}
                                  required="required"
                                  className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                                >
                                  <option value="">Select Time</option>
                                  <option value="0:00:00">12:00 AM</option>
                                  <option value="1:00:00">1:00 AM</option>
                                  <option value="2:00:00">2:00 AM</option>
                                  <option value="3:00:00">3:00 AM</option>
                                  <option value="4:00:00">4:00 AM</option>
                                  <option value="5:00:00">5:00 AM</option>
                                  <option value="6:00:00">6:00 AM</option>
                                  <option value="7:00:00">7:00 AM</option>
                                  <option value="8:00:00">8:00 AM</option>
                                  <option value="9:00:00">9:00 AM</option>
                                  <option value="10:00:00">10:00 AM</option>
                                  <option value="11:00:00">11:00 AM</option>
                                  <option value="12:00:00">12:00 PM</option>
                                  <option value="13:00:00">1:00 PM</option>
                                  <option value="14:00:00">2:00 PM</option>
                                  <option value="15:00:00">3:00 PM</option>
                                  <option value="16:00:00">4:00 PM</option>
                                  <option value="17:00:00">5:00 PM</option>
                                  <option value="18:00:00">6:00 PM</option>
                                  <option value="19:00:00">7:00 PM</option>
                                  <option value="20:00:00">8:00 PM</option>
                                  <option value="21:00:00">9:00 PM</option>
                                  <option value="22:00:00">10:00 PM</option>
                                  <option value="23:00:00">11:00 PM</option>
                                </select>
                              </div>
                            </div>
                            <div className="mt-1  rounded-md shadow-sm">
                              <Listbox value={selected} onChange={setSelected}>
                                {({ open }) => (
                                  <>
                                    <div className="flex items-center justify-between">
                                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Method
                                      </Listbox.Label>
                                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                        <div className="flex items-center">
                                          <button
                                            onClick={() => {
                                              setAddPaymentMethodModalOpen(
                                                true
                                              );
                                            }}
                                            className="block text-right text-sm font-medium text-purple-500 cursor-pointer"
                                          >
                                            Add Payment Method
                                          </button>
                                        </div>
                                      </Listbox.Label>
                                    </div>
                                    <div className="relative mt-2">
                                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6">
                                        {selected === null ? (
                                          <span className="flex items-center">
                                            <span className="block truncate">
                                              Select Payment Method
                                            </span>
                                          </span>
                                        ) : (
                                          <p className="text-sm leading-6 text-gray-900">
                                            <div className="flex">
                                              <div className="flex  justify-center items-center mr-1">
                                                <CreditCardIcon className="h-8 w-8 text-gray-900 " />
                                              </div>
                                              <div className="flex items-center justify-center">
                                                <span className="mr-2">
                                                  {selected?.card?.brand}
                                                </span>
                                                <span className="mt-1 mr-1">
                                                  **** **** ****{" "}
                                                </span>
                                                <span className="mr-1">
                                                  {selected?.card?.last4}
                                                </span>
                                              </div>
                                            </div>
                                          </p>
                                        )}
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {paymentMethods?.map((card) => (
                                            <>
                                              <Listbox.Option
                                                key={card.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    "relative cursor-pointer select-none py-2 pl-3 pr-3 border-b w-full"
                                                  )
                                                }
                                                value={card}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <div className="flex w-full">
                                                      <div className="flex items-center w-full ">
                                                        <div className="text-sm leading-6 text-gray-900">
                                                          <div className="flex">
                                                            <div className="flex  justify-center items-center mr-1">
                                                              <CreditCardIcon className="h-8 w-8 text-gray-900 " />
                                                            </div>
                                                            <div className="flex items-center justify-center ">
                                                              <span className="mr-2">
                                                                {
                                                                  card.card
                                                                    .brand
                                                                }
                                                              </span>

                                                              <span className="mt-1 mr-1">
                                                                **** **** ****{" "}
                                                              </span>
                                                              <span className="mr-1">
                                                                {
                                                                  card.card
                                                                    .last4
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="flex items-center justify-end px-2 absolute top-4 right-0">
                                                          {/* <span className="mr-2 hidden sm:inline">
                                                            {"expires at " +
                                                              card.card
                                                                .exp_month +
                                                              "/" +
                                                              card.card
                                                                .exp_year}
                                                          </span> */}

                                                          <button
                                                            type="button"
                                                            className=""
                                                          >
                                                            <TrashIcon
                                                              onClick={() =>
                                                                handleDeletePaymentMethod(
                                                                  card.id
                                                                )
                                                              }
                                                              className="w-5 h-5"
                                                              aria-hidden="true"
                                                            />
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            </>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                              <Transition.Root
                                show={addPaymentMethodModal}
                                as={Fragment}
                              >
                                <Dialog
                                  as="div"
                                  className="relative z-10"
                                  onClose={setAddPaymentMethodModalOpen}
                                >
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                  </Transition.Child>

                                  <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                      >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                          <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                            <button
                                              type="button"
                                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                              onClick={() =>
                                                setAddPaymentMethodModalOpen(
                                                  false
                                                )
                                              }
                                            >
                                              <span className="sr-only">
                                                Close
                                              </span>
                                              <XMarkIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                          <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                              <PlusIcon
                                                className="h-6 w-6 text-blue-600"
                                                aria-hidden="true"
                                              />
                                            </div>
                                            <div className="mt-3 text-center w-full sm:ml-4 sm:mt-0 sm:text-left">
                                              <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900"
                                              >
                                                Add Payment Method
                                              </Dialog.Title>
                                              <div className="mt-2">
                                                <Elements
                                                  stripe={stripePromise}
                                                >
                                                  <PaymentForm
                                                    func={getNewAdded}
                                                  />
                                                  {/* Other components */}
                                                </Elements>
                                              </div>
                                            </div>
                                          </div>
                                        </Dialog.Panel>
                                      </Transition.Child>
                                    </div>
                                  </div>
                                </Dialog>
                              </Transition.Root>
                            </div>
                            <div className="mt-1  rounded-md shadow-sm">
                              <div>
                                <label
                                  htmlFor="driver"
                                  className="flex justify-between mb-2 text-sm font-medium text-gray-700"
                                >
                                  <span>Select your driver</span>
                                  <Link
                                    to={"/add-driver"}
                                    className="block text-right text-sm font-medium text-purple-500 cursor-pointer"
                                  >
                                    Add New Driver
                                  </Link>
                                </label>
                                <select
                                  id="driver"
                                  name="driver"
                                  autoComplete="driver"
                                  required="required"
                                  onChange={ReserveForm.handleChange}
                                  value={ReserveForm.values.driver}
                                  className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                                >
                                  {allDrivers?.map((driver) =>
                                    driver?.is_verified == 1 &&
                                    driver?.is_default == 1 ? (
                                      <option
                                        key={driver?.name}
                                        value={driver?.name}
                                      >
                                        {driver.first_name +
                                          " " +
                                          driver.last_name}
                                      </option>
                                    ) : (
                                      ""
                                    )
                                  )}
                                  {/* <option value="">Select</option>  */}
                                  {allDrivers?.map((driver) =>
                                    driver?.is_verified == 1 &&
                                    driver?.is_default == 0 ? (
                                      <option
                                        key={driver?.name}
                                        value={driver?.name}
                                      >
                                        {driver.first_name +
                                          " " +
                                          driver.last_name}
                                      </option>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="mt-1  rounded-md shadow-sm">
                              <div>
                                <label
                                  htmlFor="dot"
                                  className="flex gap-1 items-center text-sm font-medium text-gray-700"
                                >
                                  DOT Number{" "}
                                  {currentVehicle?.requires_dot == 0 ? (
                                    <span className=" font-thin italic text-gray-500">
                                      (Optional for this vehicle)
                                    </span>
                                  ) : (
                                    <span className="text-red-500">*</span>
                                  )}{" "}
                                  <Tooltip data="Edit your DOT number in your profile" />
                                </label>
                                <input
                                  type="text"
                                  name="dot"
                                  id="dot"
                                  onChange={ReserveForm.handleChange}
                                  value={ReserveForm.values.dot}
                                  required={
                                    currentVehicle?.requires_dot == 1
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    currProfile?.department_transport_number ==
                                      "" ||
                                    currProfile?.department_transport_number ==
                                      null
                                      ? false
                                      : true
                                  }
                                  className={
                                    currProfile?.department_transport_number ==
                                      "" ||
                                    currProfile?.department_transport_number ==
                                      null
                                      ? "block w-full rounded-md border-gray-300  focus:border-purple-500 focus:ring-purple-500  sm:text-sm"
                                      : "block w-full rounded-md border-gray-300 text-gray-400 sm:text-sm"
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex items-center justify-between mt-1">
                              <div className="flex gap-2 items-center">
                                <input
                                  type="checkbox"
                                  name="terms_accepted"
                                  id="terms_accepted"
                                  onChange={ReserveForm.handleChange}
                                  value={ReserveForm.values.terms_accepted}
                                  required
                                  className="block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                                />

                                <label
                                  htmlFor="terms_accepted"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  I accept the{" "}
                                  <a
                                    href={window.$FWDurl + `/termsconditions`}
                                    target="_blank"
                                    className=" hover:underline text-blue-900"
                                  >
                                    Terms & Conditions
                                  </a>
                                </label>
                              </div>
                              <div>
                              <span onClick={()=>setIsRenterFaqOpen(true)} className="cursor-pointer hover:underline text-blue-900 font-medium px-2 py-1 text-sm rounded-md">Renter FAQs</span>

                              <RenterFAQs open={isRenterFaqOpen} setOpen={setIsRenterFaqOpen}/>
                            </div>
                            </div>
                            

                            {showPriceBreakdown && (
                              <>
                                <div
                                  className="flex flex-col p-5 border-2 mt-4 rounded-lg bg-gray-50"
                                  
                                >
                                  <div
                                    className="text-left font-semibold text-lg pb-2 border-b-2 "
                                    style={{ borderColor: "#EDEBF3" }}
                                  >
                                    Cost Breakdown
                                  </div>
                                  <div
                                    className="py-4 border-b-2"
                                    style={{ borderColor: "#EDEBF3" }}
                                  >
                                    <div className="flex  justify-between   ">
                                      <div className="text-left my-2">
                                        Rent Per Day{" "}
                                      </div>
                                      <div className="flex justify-center items-center">
                                        {"$" + currentVehicle?.rent_per_day}
                                      </div>
                                    </div>
                                    {costing?.rental_days > 0 && (
                                      <div className="flex  justify-between   font-semibold">
                                        <div className="text-left">
                                          Total (
                                          {`for ${costing?.rental_days} days`})
                                        </div>
                                        <div className="flex justify-center items-center">
                                          {"$" + costing?.total_rental_price}
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    className="py-4   border-b-2  "
                                    style={{ borderColor: "#EDEBF3" }}
                                  >
                                    <div className="flex  justify-between   ">
                                      <div className="text-left my-2">
                                        Service Fee
                                      </div>
                                      <div className="flex justify-center items-center">
                                        {"$" + costing?.split_fee}
                                      </div>
                                    </div>
                                    <div className="flex  justify-between   ">
                                      <div className="text-left my-2">
                                        Processing Fee
                                      </div>
                                      <div className="flex justify-center items-center">
                                        {"$" + costing?.processing_fee}
                                      </div>
                                    </div>
                                    <div className="flex  justify-between  font-semibold ">
                                      <div className="text-left  my-2">
                                        Total Fee
                                      </div>
                                      <div className="flex justify-center items-center">
                                        {"$" + costing?.total_fee}
                                      </div>
                                    </div>
                                    {/* <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  Insurance & roadside
                                </div>
                                <div className="flex justify-center items-center">
                                  {"$" +roadside}
                                </div>
                              </div> */}
                                    {/* <div className="flex  justify-between   ">
                                <div className="text-left">Taxes & Fees</div>
                                <div className="flex justify-center items-center">
                                  
                                  {"$" + tax}
                                </div>
                              </div> */}
                                  </div>
                                  <div
                                    className="flex   py-4 justify-between  border-b-2 "
                                    style={{ borderColor: "#EDEBF3" }}
                                  >
                                    {costing?.prepaid_mileage_applied_type ==
                                      "Tractors" && (
                                      <div className="flex flex-col">
                                        <div className="text-left my-2  flex items-top gap-1">
                                          Prepaid Mileage
                                          <Tooltip
                                            data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${currentVehicle?.vehicle_type}, Rent My Truck charges ${costing?.global_reservation_constraints?.allocated_miles_tractors} miles per day at $${costing?.global_reservation_constraints?.tractor_mileage_rate_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                          />{" "}
                                        </div>
                                        <span className=" font-light text-xs">{`${costing?.global_reservation_constraints?.allocated_miles_tractors} miles per day included ($${costing?.global_reservation_constraints?.tractor_overboard_miles_rate} per extra mile)`}</span>
                                      </div>
                                    )}
                                    {costing?.prepaid_mileage_applied_type ==
                                      "Trailers" && (
                                      <div className="flex flex-col">
                                        <div className="text-left my-2 flex items-top gap-1">
                                          Prepaid Mileage
                                          <Tooltip
                                            data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${currentVehicle?.vehicle_type}, Rent My Truck charges ${costing?.global_reservation_constraints?.allocated_miles_trailers} miles per day at $${costing?.global_reservation_constraints?.trailer_mileage_rate_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                          />{" "}
                                        </div>
                                        <span className=" font-light text-xs">{`${costing?.global_reservation_constraints?.allocated_miles_trailers} miles per day included ($${costing?.global_reservation_constraints?.trailer_overboard_miles_rate} per extra mile)`}</span>
                                      </div>
                                    )}
                                    <div className=" flex justify-center items-center">
                                      {"$" + costing?.prepaid_mileage}
                                    </div>
                                  </div>
                                  <div
                                    className="flex   py-4 justify-between  border-b-2 "
                                    style={{ borderColor: "#EDEBF3" }}
                                  >
                                    <div className="flex flex-col">
                                      <div className="text-left my-2 flex items-top gap-1">
                                        Deposit
                                      </div>
                                    </div>
                                    <div className=" flex justify-center items-center">
                                      {"$" + costing?.deposit}
                                    </div>
                                  </div>

                                  {/* <div className="flex   py-4 justify-between   border-gray-200 border-b-2  ">
                              <div className="text-left ">Subtotal</div>
                              <div className="flex justify-center items-center">
                                {"$" + subTotal}
                              </div>
                            </div> */}
                                  {/* <div className="py-4  black-200 border-b-2  ">
                              <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  Prepaid mileage
                                </div>
                                <div className="flex justify-center items-center">
                                  $500
                                </div>
                              </div>
                              <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  250 miles per day included ($0.04 per mile)
                                </div>
                              </div>
                            </div> */}
                                  <div className="py-4  ">
                                    {/* <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                   Platform Charges 
                                </div>
                                <div className="flex justify-center items-center">
                                  {"$" + serviceFee}
                                </div>
                              </div> */}
                                    <div className="flex  justify-between  font-semibold ">
                                      <div className="text-left">Total</div>
                                      <div className="flex justify-center items-center">
                                        {"$" +
                                          costing?.calculated_reservation_total}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-col justify-between sm:flex-row lg:px-0 px-0 gap-4 mt-2">
                                  <button
                                    type="submit"
                                    
                                    disabled={bookingDisabled}
                                    className={`w-full text-center tooltip bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-700 cursor-pointer`}
                                  >
                                    {reservationBooking ? (
                                      <LoadingIcons.BallTriangle className="w-full text-center h-5 " />
                                    ) : (
                                      "Book Now"
                                    )}
                                    {bookingDisabled && (
                                      <span class="tooltiptext bg-purple-100 text-purple-800 font-medium">
                                        {bookingDisabledMessage}                                      
                                        </span>
                                    )}
                                  </button>
                                  {/* <button className="w-full bg-white hover:bg-gray-100  text-white py-2 px-4 border border-gray-700 rounded" style={{'color':"#1D192B"}}>
                            Cancle
                          </button> */}
                                </div>
                              </>
                            )}
                          </form>
                        </div>

                        {/* <span className="text-base mt-2 font-medium text-gray-900">Organic transportation</span> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default VehicleDetail;
