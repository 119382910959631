import React from "react";
import { Link } from "react-router-dom";
import Cover from "../../img/truck renter image copy 11.png"
import "./Signin.css";

const Signin = () => {
  return (
    <>
      <div className="relative flex min-h-full">
        <div className="flex flex-2 min-h-screen flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto bg-gray-100 w-full max-w-sm lg:w-96 rounded-lg">
            <div className="px-10 py-10">
            <div className="mb-3 flex items-center justify-center">
              <img src="https://rentmytruck.co/wp-content/uploads/2023/01/WhatsApp-Image-2023-01-10-at-9.12.35-PM1-copy.png" alt="Logo" className="w-3/4 " />
            </div>
              <div>
                <a
                  href={window.$backendURL+'/api/method/frappe.integrations.oauth2.authorize?client_id='+window.$ClientID+'&state=444&response_type=code&scope=openid%20all&redirect_uri='+ window.$FWDurl +'/login'}
                  className="flex w-full justify-center rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                >
                  Sign in
                </a>
                <p className="mt-4 mx-10 text-sm text-gray-600">
                  Don't have an account?{" "}
                  <Link to="/signup">
                  <span
                    className="font-medium text-color hover:text-purple-700"
                  >
                    Signup
                  </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden flex-1 lg:block">
          <img
            className="w-full h-full"
            src={Cover}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Signin;
