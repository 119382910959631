import React from 'react'
import { Tab } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
const HomePageDetailSkeleton = () => {
  return (
    <>
    <div
                role="status"
                className=" animate-pulse w-full"
              >
       <div className="min-h-full">
        <main className="px-20 py-10 pb-8">
          <div className="mx-0 w-full px-4 sm:px-0 ">
            <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
              {/* Left column */}
              <div className="grid grid-cols- gap-4 lg:col-span-2">
                {/* Welcome panel */}
                <section aria-labelledby="profile-overview-title">
                <Tab.Group as="div" className="flex flex-col-reverse pr-4">
                <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
             
                  <Tab
                    
                    className="relative flex h-32 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    
                      <>
                        
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png" alt="" className="h-full w-full object-cover" />
                        </span>
                        
                       
                      </>
                    
                  </Tab>
                
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
            
                <Tab.Panel >
                  <img
                    src='https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png'
                    alt=""
                    className="w-full object-contain bg-white rounded-lg "
                  />
                </Tab.Panel>
            
            </Tab.Panels>
          </Tab.Group>
                </section>

                {/* Actions panel */}
                <section aria-labelledby="quick-links-title">
                  <div className=" overflow-hidden rounded-lg p-4  ">
                    <div className=" flex justify-between flex-col">
                      <div className="flex items-center gap-8">
                        
                         <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                     
                      </div>

                      {/* <div className="flex my-4 items-center gap-4">
                        <div className="text-sm font-semibold">4.5</div>
                        <div className="text-sm font-semibold w-6 h-6">
                          <StarIcon />
                        </div>

                        <div>
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            23 Trips
                          </span>
                        </div>
                        <div className="w-6 h-6">
                          <MapPinIcon />
                        </div>
                        <div className="text-sm font-semibold">2.3 miles</div>
                      </div> */}

                      <div className="">
                        
                        <div className="flex my-4 items-center gap-5">
                          
                          
                          <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                          
                          <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </div>
                      </div>
                      
                      <div>
                        <h1 className="text-lg py-3 font-normal text-slate-500">
                          Specifications
                        </h1>
                        <div className="flex justify-center flex-wrap gap-2 mb-2 mt-1">
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                       <XMarkIcon className="w-6 h-6"/>
                        <span className="">
                          E-Truck
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        Ramp
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        Crew Cab
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        Dock Height
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        Multi - Temp
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        APU
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        Storage Only
                        </span>{" "}
                      </div>
                      <div className="flex gap-2 justify-center bg-purple-100 text-purple-600 px-2 py-1 rounded-md items-center">
                      <XMarkIcon className="w-6 h-6"/>                        <span className="">
                        Has IFTA
                        </span>{" "}
                      </div>
                      
                    </div>
                      </div>
                      <div>
                        <h1 className="text-lg py-3 font-normal text-slate-500">
                          Description
                        </h1>
                        <p className="text-sm text-slate-700">
                        <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                        </p>
                      </div>
                    </div>

                    {/* Location  */}
                    <div className="flex  flex-col my-6">
                      <div className=" ">Location</div>
                      
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                      
                    </div>
                   
                    <div className="flex flex-col mt-6 ">
                      <div className="text-lg font-semibold my-2 ">
                        Business hours
                      </div>
                      
                      <span className='w-32 mb-2 h-4 rounded-lg bg-gray-300'></span>
                      
                      
                      <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                      
                    </div>
                    
                    
                  </div>
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4 py-3">
                {/* Announcements */}
                <section aria-labelledby="announcements-title">
                  <div className="overflow-hidden rounded-lg bg-white ">
                    <div className="ring-1 ring-gray-400 flex flex-col justify-between gap-y-4 px-5 py-4 mb-5">
                      <h2
                        className="text-xl flex gap-1 text-gray-900 mt-2"
                        id="announcements-title"
                      >
                        
                        <span className='w-8 mt-2 h-4 rounded-lg bg-gray-300'></span>
                        
                        <span>/ Day</span>
                      </h2>
                      <div className=" flex flex-col justify-between gap-y-4">
                        <form  className="space-y-3">
                          

                          <div
                            className="flex flex-col p-5 border-2 mt-4 rounded-lg"
                            style={{
                              backgroundColor: "#F9F8FC",
                              borderColor: "#EDEBF3",
                            }}
                          >
                            <div
                              className="text-left font-semibold text-lg pb-2 border-b-2 "
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              Cost Breakdown
                            </div>
                            <div
                              className="py-4 border-b-2"
                              style={{ borderColor: "#EDEBF3" }}
                            >
                              <div className="flex  justify-between   ">
                                <div className="text-left my-2">
                                  Rent Per Day{" "}
                                </div>
                                <div className="flex justify-center items-center">
                                <span className='w-32 h-4 rounded-lg bg-gray-300'></span>
                                </div>
                              </div>
                              
                            </div>
                           
                          </div>
                          
                        </form>
                      </div>

                      {/* <span className="text-base mt-2 font-medium text-gray-900">Organic transportation</span> */}
                    </div>
                  </div>
                </section>

                
                
              </div>
            </div>
          </div>
        </main>
      </div> 
      </div>
    </>
  )
}

export default HomePageDetailSkeleton