import React from "react";
import { useState } from "react";
import {FcInfo} from "react-icons/fc"

const Tooltip = (props) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleOnMouseOver= ()=>{
    setShowInfo(true);
  }
  const handleOnMouseOut=() => {
    setShowInfo(false);
  }
  return (
    <div>
      <button
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        onClick={()=>{setShowInfo(!showInfo)}}
        type="button"
        className="mt-1"
      >
        <FcInfo />
      </button>
      {showInfo ? (
    <span className="ml-2 mr-4 absolute rounded px-2 py-2 text-sm bg-gray-700 text-white z-10">{props.data}</span>
      ) : null}
    </div>
  );
};

export default Tooltip;
