import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingIcons from "react-loading-icons";
import SettingRenter from "./SettingRenter";

const Setting = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [loading, setLoading] = useState(false);
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
  const [currProfile, setCurrProfile] = useState(null);

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            setCurrProfile(response.data.data);
            console.log(response.data.data);
            setLoading(false);
          });
      } catch (e) {
        console.error(e);
      }
    };

    getProfileOwner();
  }, []);

  //   Login Link Generator
  const handleGoToDashboard = async () => {
    var json = {
      stripe_account_id: currProfile?.stripe_account,
    };

    try {
      setLoading(true);
      await axios
        .post(`${window.$backendURL}/api/method/truckrent.api.login_link`, json)
        .then((response) => {
          console.log(response.data.data);
          window.open(response.data.data, "_blank");
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  //   Create Express Account
  const handleCreateStripeAccount = async () => {
    var json = {
      company_profile: currProfile?.name,
    };

    try {
      setLoadingCreateAccount(true);
      await axios
        .post(
          `${window.$backendURL}/api/method/truckrent.api.create_stripe_express_account`,
          json
        )
        .then((response) => {
          console.log(response.data.data);
          window.open(response.data.data.url, "_blank");
          setLoadingCreateAccount(false);
        });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="h-screen">
      {localStorage?.role == "Owner Business" ? (
        <>
          <h1 className="text-xl flex justify-start items-center font-bold">
            Manage Payouts
          </h1>
          {currProfile?.stripe_connected != undefined ? (
            currProfile?.stripe_connected === 0 ? (
              <div className="flex flex-col gap-4 h-screen justify-center">
                <div>
                  <h1 className="text-xl flex justify-center items-center font-boldr">
                    You've Not Done Onboarding Stripe Express Connect Account
                  </h1>
                </div>
                <div className="flex justify-center items-center">
                  {loadingCreateAccount ? (
                    <button
                      disabled
                      className=" rounded-md cursor-not-allowed border border-transparent bg-gray-600 py-2 px-8 text-lg font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
                    >
                      <LoadingIcons.TailSpin className="h-8 w-8" />
                    </button>
                  ) : (
                    <button
                      onClick={handleCreateStripeAccount}
                      className="rounded-md border border-transparent button py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                    >
                      Stripe Connect Onboarding
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-4 h-screen justify-center ">
                <h1 className="text-xl flex justify-center items-center font-bold">
                  Your Stripe Express Connect Successfully Connected
                </h1>
                <div className="flex justify-center items-center">
                  {loading ? (
                    <button
                      disabled
                      className=" rounded-md cursor-not-allowed border border-transparent bg-gray-600 py-2 px-8 text-lg font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
                    >
                      <LoadingIcons.TailSpin className="h-8 w-8" />
                    </button>
                  ) : (
                    <button
                      onClick={handleGoToDashboard}
                      className=" rounded-md border border-transparent button py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                    >
                      Go to Stripe Dashboard
                    </button>
                  )}
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <SettingRenter />
        </>
      )}
    </div>
  );
};

export default Setting;
