import { useFormik } from "formik";
import { EllipsisVerticalIcon, CheckIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from "sonner";
const AddDriver = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [submitDone, setSubmitDone] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [licenseFront, setLicenseFront] = useState(null);
  const [licenseBack, setLicenseBack] = useState(null);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  const AddDriver = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_no: "",
      date_of_birth: "",
      license_number: "",
      license_state: "",
      license_expiry_date: "",
      license_front_picture: "",
      license_back_picture: "",
    },
    onSubmit: (values) => {
      var jsonDriver = {
        first_name: values.first_name,
        last_name: values.last_name,
        birthday: values.date_of_birth,
        contact_number: values.phone_no,
        license_number: values.license_number,
        license_state: values.license_state,
        license_expiry: values.license_expiry_date,
        driver_for_renter: localStorage.userID,
        license_front_picture: licenseFront,
        license_back_picture: licenseBack,
      };
      const AddDriverApi = async (jsonDriver) => {
        try {
          await axios
            .post(
              `${window.$backendURL}/api/resource/Driver Profiles`,
              jsonDriver,
              config
            )
            .then((res) => {
              toast.success("Driver Added Successfully");
              setSubmitDone(!submitDone);
              console.log(res.data);
              setProfile(res.data.data);
              setTimeout(() => {
                navigate("/Drivers");
                // resetForm();
              }, 1000);
            });
        } catch (e) {
          console.log(e);
          toast.error("Try Again");
        }
      };
      AddDriverApi(jsonDriver);
      // resetForm();
      AddDriver.resetForm();
    },
    enableReinitialize: true,
  });

  const uploadLicenseFront = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadFront = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            toast.success("Driver Front Image Uploaded");
            setLicenseFront(response.data.message.file_url);
            console.log(response.data.message.file_url);
            console.log(licenseFront);
          });
      } catch (e) {
        console.log(e);
        toast.error("Try Again");
      }
    };
    await uploadFront();
  };

  const uploadLicenseBack = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadBack = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            toast.success("Driver Back Image Uploaded");
            setLicenseBack(response.data.message.file_url);
            console.log(response.data.message.file_url);
            console.log(licenseBack);
          });
      } catch (e) {
        console.log(e);
        toast.error("Try Again");
      }
    };
    await uploadBack();
  };

  // const AddDriver = useFormik({
  //     initialValues: {},
  //     onSubmit: (values) => {

  //     },
  //     enableReinitialize: true,
  //   });

  return (
    <>
      <Toaster richColors />
      <div className="h-screen">
        {isSuccess && (
          <div className="static">
            <div className="border-l-4 w-80 top-15 right-9 border-green-800 bg-green-100 p-4 absolute">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <CheckIcon
                    className="h-6 w-6 text-green-800"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-800">
                    Driver Add Successfully
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <Link to="/Drivers">
          <button className="flex flex-row gap-2 justify-center items-center">
            <svg
              className="w-5 h-5 font-thin text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className=" font-normal text-gray-600">Back</span>
          </button>
        </Link>

        <form
          onSubmit={AddDriver.handleSubmit}
          onReset={() => AddDriver.resetForm()}
          className="mt-4"
        >
          <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-xl font-semibold leading-6 text-gray-900">
                  Add Driver
                </h3>
              </div>

              <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.first_name}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder="John"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.last_name}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder="Alex"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="phone_no"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="phone_no"
                        id="phone_no"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.phone_no}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="date_of_birth"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of birth
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="date"
                        name="date_of_birth"
                        id="date_of_birth"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.date_of_birth}
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="license_number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      License Number <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="license_number"
                        id="license_number"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.license_number}
                        required
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="license_expiry_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      License Expiry Date{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="date"
                        name="license_expiry_date"
                        id="license_expiry_date"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.license_expiry_date}
                        required
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="license_state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      License State <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <select
                        type="text"
                        name="license_state"
                        id="license_state"
                        onChange={AddDriver.handleChange}
                        value={AddDriver.values.license_state}
                        required
                        className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        placeholder=""
                      >
                        <option value="">Select</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="license_front_picture"
                    className="block text-sm font-medium text-gray-700"
                  >
                    License Front <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md ">
                  <input
                    type="file"
                    name="license_front_picture"
                    id="license_front_picture"
                    onChange={(e) => {
                      uploadLicenseFront(e);
                    }}
                    className="block w-full mb-2 border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0 shadow-sm
file:bg-purple-800 file:text-white file:mr-4
file:py-2 file:px-4"
                  />
                  </div>
                </div> */}
                  <div className="col-span-6 sm:col-span-3">
                    <span className="block text-sm font-medium text-gray-700">
                      License Front 
                      {/* <span className="text-red-500">*</span> */}
                    </span>
                    <div className="">
                      {licenseFront && (
                        <a
                          target="_blank"
                          className="flex items-center justify-center mt-2 rounded-md border border-transparent button py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          href={window.$backendURL + licenseFront}
                        >
                          View Uploaded File
                        </a>
                      )}
                      <div className="flex items-center justify-center w-full mt-1">
                        <label
                          htmlFor="license_front_picture"
                          className="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                        >
                          <div className="flex items-center justify-center gap-x-2">
                            <svg
                              className="w-6 h-6 text-gray-500"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            {licenseFront != undefined ||
                            licenseFront != null ? (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">Click</span> or
                                update your file
                              </p>
                            ) : (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                            )}
                          </div>
                          <input
                            id="license_front_picture"
                            name="license_front_picture"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              uploadLicenseFront(e);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <span className="block text-sm font-medium text-gray-700">
                      License Back 
                      {/* <span className="text-red-500">*</span> */}
                    </span>
                    <div className="">
                      {licenseBack && (
                        <a
                          target="_blank"
                          className="flex items-center justify-center mt-2 rounded-md border border-transparent button py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          href={window.$backendURL + licenseBack}
                        >
                          View Uploaded File
                        </a>
                      )}
                      <div className="flex items-center justify-center w-full mt-1">
                        <label
                          htmlFor="license_back_picture"
                          className="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                        >
                          <div className="flex items-center justify-center gap-x-2">
                            <svg
                              className="w-6 h-6 text-gray-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            {licenseBack != undefined || licenseBack != null ? (
                              <p className="text-sm text-gray-500">
                                <span className="font-semibold">Click</span> or
                                update your file
                              </p>
                            ) : (
                              <p className="text-sm text-gray-500 ">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                            )}
                          </div>
                          <input
                            id="license_back_picture"
                            name="license_back_picture"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              uploadLicenseBack(e);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="license_back_picture"
                    className="block text-sm font-medium text-gray-700"
                  >
                    License Back <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md ">
                  <input
                    type="file"
                    name="license_back_picture"
                    id="license_back_picture"
                    onChange={(e) => {
                      uploadLicenseBack(e);
                    }}
                    className="block w-full mb-2 border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0 shadow-sm
file:bg-purple-800 file:text-white file:mr-4
file:py-2 file:px-4"
                  />
                  </div>
                </div> */}
                </div>
                <div className="flex justify-end gap-2 pb-4 pt-4">
                  <button
                    className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                    type="reset"
                  >
                    Reset
                  </button>
                  <button
                    className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                    type="submit"
                  >
                    Add Driver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddDriver;
