import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const VehicleAvailability = () => {
  const { vehicleID } = useParams();
  const [vehicleDetails, setVehilceDetails] = useState(null);
  const [availabilityData, setAvailabilityData] = useState({
    unavailableDates: [],
    maintenanceDates: [],
    bookedDates: [],
  });
  const [selectedRange, setSelectedRange] = useState({
    from: undefined,
    to: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [isSelectionValid, setIsSelectionValid] = useState(true);

  // Assuming `accessToken` is already handled similarly to your original code
  const accessToken = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).access_token
    : "";
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${window.$backendURL}/api/resource/Vehicle/${vehicleID}`,
        config
      );
      setLoading(false);
      const { data } = res.data;
      setVehilceDetails(data);
      const unavailableDates = [],
        maintenanceDates = [],
        bookedDates = [];
      data.availability.forEach((item) => {
        const dateObj = {
          from: new Date(item.reservation_date),
          to: new Date(item.reservation_date),
        };
        if (item.reason === "Unavailable") unavailableDates.push(dateObj);
        else if (item.reason === "Maintenance") maintenanceDates.push(dateObj);
        else if (item.reason === "Booked") bookedDates.push(dateObj);
      });
      setAvailabilityData({ unavailableDates, maintenanceDates, bookedDates });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Call it here to fetch data on component mount
  }, [vehicleID, accessToken]);

  const checkSelectionValidity = (selectedRange) => {
    const overlapsWithUnavailable = availabilityData.unavailableDates.some(
      (dateRange) =>
        selectedRange.from <= new Date(dateRange.to) &&
        selectedRange.to >= new Date(dateRange.from)
    );
    const overlapsWithMaintenance = availabilityData.maintenanceDates.some(
      (dateRange) =>
        selectedRange.from <= new Date(dateRange.to) &&
        selectedRange.to >= new Date(dateRange.from)
    );

    // If overlaps with either, selection is not valid for marking unavailable
    return !(overlapsWithUnavailable || overlapsWithMaintenance);
  };

  const handleDaySelection = (range) => {
    setSelectedRange(range);
    if (range.from && range.to) {
      // Ensure we have a complete range selected
      const isValid = checkSelectionValidity(range);
      setIsSelectionValid(isValid);
    } else {
      setIsSelectionValid(true); // Reset if selection is cleared or incomplete
    }
  };

  const modifiers = {
    unavailable: availabilityData.unavailableDates,
    maintenance: availabilityData.maintenanceDates,
    booked: availabilityData.bookedDates,
    selected: selectedRange,
  };

  const modifiersStyles = {
    unavailable: { backgroundColor: "red", color: "white" },
    maintenance: { backgroundColor: "green", color: "white" },
    booked: { border: "2px solid currentColor" },
    selected: { backgroundColor: "#66a3ff", color: "#fff" },
  };

  const handleUpdateAvailability = async (operation) => {
    const payload = {
      vehicle: vehicleID,
      reason,
      operation,
      start_date: moment(selectedRange.from).format("YYYY-MM-DD"),
      end_date: moment(selectedRange.to).format("YYYY-MM-DD"),
    };
    try {
      await axios.post(
        `${window.$backendURL}/api/method/truckrent.api.update_vehicle_availability`,
        payload,
        config
      );
      toast.success(`Availability Updated: ${operation}`);
      fetchData();
      setSelectedRange({ from: undefined, to: undefined });
      setIsSelectionValid(true);
      setReason("");
    } catch (error) {
      console.error(error);
      toast.error("Error updating availability");
    }
  };

  return (
    <>
      <Toaster richColors />
      <div className="flex h-screen gap-x-40 mx-auto justify-center">
        <div className="flex flex-col items-center  gap-y-4">
          <div className="">
            <h2 className="text-3xl flex items-center gap-x-2 font-semibold tracking-normal mt-3 mb-1">
              Vehicle Availability <span className="text-[22px]">
                ({vehicleDetails?.year + ", " + vehicleDetails?.make + " " + vehicleDetails?.model})
                </span>
            </h2>
            <span className="text-sm text-gray-700">
              Note: You can change the vehicle availability by change the range
              on Calendar
            </span>
          </div>
          <div className="bg-white">
            {loading ? (
              <Skeleton count={1} height={300} width={310} />
            ) : (
              <DayPicker
                mode="range"
                selected={selectedRange}
                onSelect={handleDaySelection}
                modifiers={modifiers}
                modifiersStyles={modifiersStyles}
                disabled={availabilityData.bookedDates}
              />
            )}
          </div>
          <div className="flex bg-white gap-x-4 px-4 items-center py-2 rounded-md">
            <div className="my-4 flex gap-x-2 items-center">
              <span className="h-3 w-3 bg-gray-500 rounded-full block"></span>
              {loading ? (
                <Skeleton width={100} height={10} />
              ) : (
                <span className="text-gray-700 text-xs">Reservation Dates</span>
              )}
            </div>
            <div className="my-4 flex gap-x-2 items-center">
              <span className="h-3 w-3 bg-[#FF0000] rounded-full block"></span>
              {loading ? (
                <Skeleton width={100} height={10} />
              ) : (
                <span className="text-gray-700 text-xs">Unavailable Dates</span>
              )}
            </div>
            <div className="my-4 flex gap-x-2 items-center">
              <span className="h-3 w-3 bg-green-700 rounded-full block"></span>
              {loading ? (
                <Skeleton width={100} height={10} />
              ) : (
                <span className="text-gray-700 text-xs">Maintenance Dates</span>
              )}
            </div>
          </div>

          <select
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
            class="bg-gray-50 border border-gray-300 text-gray-1000 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-80 p-2.5 "
          >
            <option value="">Choose a reason</option>
            <option value="Unavailable">Unavailable</option>
            <option value="Maintenance">Maintenance</option>
          </select>

          <button
            onClick={() => handleUpdateAvailability("add")}
            disabled={!isSelectionValid}
            className={`${
              !isSelectionValid
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-purple-400 hover:bg-purple-500"
            } text-white font-medium tracking-wide py-2.5 w-80 rounded-lg focus:outline-none focus:shadow-outline`}
          >
            Mark Unavailable
          </button>
          <button
            onClick={() => handleUpdateAvailability("delete")}
            disabled={isSelectionValid}
            className={`${
              isSelectionValid
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-purple-400 hover:bg-purple-500"
            } text-white font-medium tracking-wide py-2.5 w-80 rounded-lg focus:outline-none focus:shadow-outline`}
          >
            Remove Available
          </button>
        </div>
      </div>
    </>
  );
};

export default VehicleAvailability;
