import React, { useEffect, useState } from "react";
import { Fragment } from "react";

import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
  TruckIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { loadStripe } from "@stripe/stripe-js";
import { PencilOutline } from "heroicons-react";
import { db } from "../../Global/Firebase/firebase";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { StarIcon, MapPinIcon } from "@heroicons/react/24/outline";
import {
  addDoc,
  doc,
  collection,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  getDocs,
  getDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { query, onSnapshot, orderBy, limit } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import { DateRange } from "react-date-range";
import axios, { all } from "axios";
import { json, Link, useParams } from "react-router-dom";
import Tooltip from "../../Global/SmallComponents/Tooltip";
import moment from "moment";
import { useFormik } from "formik";
import { toast, Toaster } from "sonner";
import CCarousel from "../../Global/SmallComponents/OwnerCarousel";
import ReservationDetailPageSkeleton from "../../Global/Skeleton/ReservationDetailPageSkeleton";
import { Dialog, Transition, Tab } from "@headlessui/react";
import OwnerCarousel from "../../Global/SmallComponents/OwnerCarousel";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import LoadingIcons from "react-loading-icons";
import InspectionAccordian from "./PickupInspectionAccordian";
import PcikupInsepectionAccordion from "./PickupInspectionAccordian";
import DropoffInsepectionAccordian from "./DropoffInsepectionAccordian";
import ReservationPickupImages from "./ReservationPickupImages";
import ReservationDropOffImages from "./ReservationDropoffImages";
const noimgfound = [
  {
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
    caption: "No Image Uploaded",
  },
  {
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
    caption: "No Image Uploaded",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ReservationDetailPage = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { reservationID } = useParams();
  const [allReservation, setAllReservation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRent, setTotalrent] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [reviewStatusUpdated, setReviewStatusUpdated] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [totalPrePaidMileage, setTotalPrePaidMileage] = useState(0);
  const [tax, setTax] = useState(0);
  const [roadSide, setRoadSide] = useState(0);
  const [total, setTotal] = useState(0);
  const [cancelReason, setCancelReason] = useState(null);
  const [loaderForButton, setLoaderForButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCarouselModal, setShowCarouselModal] = useState(false);
  const [reservationTimeline, setReservationTimeline] = useState(null);
  const [reservationImages, setReservationImages] = useState([]);
  const [serivceFee, setServiceFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [cancelReservationModal, setCancelReservationModal] = useState(false);
  const [totalAfterCancellationFee, setTotalAfterCancellationFee] = useState(0);
  const [check, setCheck] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [loader, setLoader] = useState(false);
  const [ownerNotes, setOwnerNotes] = useState("");
  const [ownerPickupConfirms, setownerPickupConfirms] = useState(null);
  const [ownerDropoffConfirms, setownerDropoffConfirms] = useState(null);
  const [ownerDamageInspection, setOwnerDamageInspection] = useState(null);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(null);
  //New States
  const [message, setMessage] = useState([]);
  const [lastSeen, setLastSeen] = useState(null);
  const [showInspectionDrawer, setShowInspectionDrawer] = useState(false);
  const [showReservationImagesDrawer, setShowReservationImagesDrwaer] =
    useState(false);

  const [openDriverProfile, setOpenDriverProfile] = useState(false);
  const [driverProfile, setDriverProfile] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, `RentMyTruck/${reservationID}/Message`),
      orderBy("dateTime")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
      });
      console.log("message", messages);
      setMessage(messages);
    });

    return () => unsubscribe;
  }, []);

  useEffect(() => {
    const fetchCollectionData = async () => {
      try {
        const docSnapshot = await getDoc(
          doc(collection(db, "OwnerLastSeen"), reservationID)
        );
        if (docSnapshot.exists()) {
          const collectionData = docSnapshot.data();
          const formattedDate = moment
            .unix(collectionData?.last_seen?.seconds)
            .format("lll");
          setLastSeen(formattedDate);
          console.log("Collection data:", formattedDate);
        } else {
          console.log("Collection does not exist.");
        }
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    fetchCollectionData();
  }, []);

  console.log(lastSeen);

  const filterMessage = message?.filter(
    (msg) => moment.unix(msg?.dateTime?.seconds).format("lll") > lastSeen
  );
  console.log("filterMessage", filterMessage);

  useEffect(() => {
    const getReservations = async () => {
      setLoading(true);
      try {
        await axios
          .get(
            `${window.$backendURL}/api/method/reservation-contract-details?id=${reservationID}`,
            config
          )
          .then((response) => {
            setAllReservation(response.data?.data);
            console.log(response.data?.data);
            setReview(response.data.data.reservation.owner_reviews);
            setRating(response.data.data.reservation.owner_rating * 5);
            setLat(response.data.data.location.coordinates?.split(",")[0]);
            setLong(response.data.data.location.coordinates?.split(",")[1]);
            console.log(response.data?.data?.reservation?.notes_from_owner);
            setOwnerNotes(response.data?.data?.reservation?.notes_from_owner);
            setOwnerDamageInspection(
              response.data?.data?.reservation?.owner_damage_inspection_confirm
            );
            setCheck(
              response.data.data?.reservation?.auto_approve_extension_request ==
                0
                ? false
                : true
            );
            // console.log(">>>>",response.data.data[0]);
            setownerPickupConfirms(
              response.data?.data?.reservation?.owner_pickup_confirm == 1
                ? true
                : false
            );
            setownerDropoffConfirms(
              response.data?.data?.reservation?.owner_dropoff_confirm == 1
                ? true
                : false
            );
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            var Difference_In_Time =
              new Date(
                response.data.data?.reservation?.reservation_end_date
              ).getTime() -
              new Date(
                response.data.data?.reservation?.reservation_start_date
              ).getTime();
            var Difference_In_Days =
              Difference_In_Time / (1000 * 3600 * 24) + 1;
            setTotalDays(Math.floor(Difference_In_Days));
            setTotalrent(
              Math.floor(Difference_In_Days) *
                response.data.data?.reservation?.price_break_down_renter[0]
                  ?.rent_per_day
            );

            setServiceFee(totalRent * 0.125);
            setProcessingFee(totalRent * 0.03);
            setTotalFee(serivceFee + processingFee);

            setTotalPrePaidMileage(
              Math.floor(
                response.data.data?.reservation?.price_break_down_renter[0]
                  ?.per_day_miles_allowed *
                  0.3 *
                  response.data.data?.reservation?.price_break_down_renter[0]
                    ?.allowed_cost_per_mile
              )
            );

            // setTax(500);
            // setRoadSide(500);

            // setServiceFee(Math.floor((totalPrePaidMileage + totalRent) * 0.125))

            setTotal(totalRent + totalPrePaidMileage - totalFee);
            setTotalAfterCancellationFee(total * 0.05); //service fee is still not add
            const images = [];
            if (
              allReservation?.reservation?.renter_pickup_images?.length == 0
            ) {
              images.push({
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
                caption: `<div>No Image</div>`,
              });
            } else {
              const arr1 =
                allReservation?.reservation?.renter_pickup_images?.map(
                  (img, id) =>
                    images.push({
                      image: `https://api.rentmytruck.co${img.image}`,
                      caption: `<div>
                    Taken by Renter at Pickup (${img?.type})
                    <br/>
                    ${moment(img.creation).format("LLL")}
                  </div>`,
                      type: img.type,
                    })
                );
            }
            if (allReservation?.reservation?.owner_pickup_images?.length == 0) {
              images.push({
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
                caption: `<div>No Image</div>`,
              });
            } else {
              const arr2 =
                allReservation?.reservation?.owner_pickup_images?.map(
                  (img, id) =>
                    images.push({
                      image: `https://api.rentmytruck.co${img.image}`,
                      caption: `<div>
                  Taken by Owner at Pickup (${img?.type})
                  <br/>
                  ${moment(img.creation).format("LLL")}
                </div>`,
                      type: img.type,
                    })
                );
            }
            if (
              allReservation?.reservation?.renter_dropoff_images?.length == 0
            ) {
              images.push({
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
                caption: `<div>No Image</div>`,
              });
            } else {
              const arr3 =
                allReservation?.reservation?.renter_dropoff_images?.map(
                  (img, id) =>
                    images.push({
                      image: `https://api.rentmytruck.co${img.image}`,
                      caption: `<div>
                  Taken by Renter at Dropoff (${img?.type})
                  <br/>
                  ${moment(img.creation).format("LLL")}
                </div>`,
                      type: img.type,
                    })
                );
            }
            if (
              allReservation?.reservation?.owner_dropoff_images?.length == 0
            ) {
              images.push({
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
                caption: `<div>No Image</div>`,
              });
            } else {
              const arr4 =
                allReservation?.reservation?.owner_dropoff_images?.map(
                  (img, id) =>
                    images.push({
                      image: `https://api.rentmytruck.co${img.image}`,
                      caption: `<div>
                      Taken by Owner at Dropoff (${img?.type})
                      <br/>
                      ${moment(img.creation).format("LLL")}
                    </div>`,
                      type: img.type,
                    })
                );
            }

            setReservationImages(images);
            console.log(images);
            console.log(noimgfound);
            console.log(reservationImages);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getReservations();
  }, [
    reviewStatusUpdated,
    serivceFee,
    processingFee,
    totalFee,
    totalPrePaidMileage,
  ]);

  useEffect(() => {
    const getTimlines = async () => {
      // setLoading(true);
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Reservation Contract Timeline?fields=["*"]&filters=[["reservation","=","${reservationID}"]]&order_by=creation asc`,
            config
          )
          .then((response) => {
            console.log("Reservation timline", response.data.data);
            setReservationTimeline(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getTimlines();
  }, []);

  const handlePickupConfirmsChange = async (e) => {
    setownerPickupConfirms((prev) => !prev);

    var JSONtoUpdate = {
      owner_pickup_confirm: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Owner Reservation Pickup Confirms");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const handleSetRating = (star) => {
    setRating(star);
  };

  const handleReviewsUpload = async (e) => {
    e.preventDefault();

    var JSONtoUpdate = {
      owner_rating: rating / 5,
      owner_reviews: review,
      reservation_status: "Rated",
      global_status: "Completed.Rated",
    };

    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          setReviewStatusUpdated(!reviewStatusUpdated);
          setTimeout(() => {
            toast.success("Rating & Reviews Submitted");
          }, 500);
          console.log(response);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Rating & Reviews Failed to Submit");
      }, 500);
    }
  };

  const handleDropoffConfirmsChange = async (e) => {
    setownerDropoffConfirms((prev) => !prev);

    var JSONtoUpdate = {
      owner_dropoff_confirm: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Owner Reservation Drop-off Confirms");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const handleDamageInspection = async (e) => {
    setOwnerDamageInspection((prev) => !prev);

    var JSONtoUpdate = {
      owner_damage_inspection_confirm: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Owner Damage Inspection Confrims");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const handleCancelReservation = () => {
    handleReviewUpdate("Cancelled");
    setCancelReservationModal(false);
  };

  const fetchDriverProfile = (id) => {
    try {
      axios
        .get(`${window.$backendURL}/api/resource/Driver Profiles/${id}`, config)
        .then((res) => {
          console.log("driver_profile", res?.data?.data);
          setDriverProfile(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOwnerNotes = async () => {
    var JSONtoUpdate = {
      notes_from_owner: ownerNotes,
    };
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          setReviewStatusUpdated(!reviewStatusUpdated);
          setTimeout(() => {
            toast.success("Notes Added");
          }, 500);

          console.log(response);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Notes Can't Add");
      }, 500);
    }
  };
  const RejectedReservationReason = useFormik({
    initialValues: {
      cancellation_reason: "",
    },
    onSubmit: (values) => {
      console.log(values);
      setCancelReason(values.cancellation_reason);
      console.log(cancelReason);

      handleReviewUpdate("Rejected", values.cancellation_reason);
    },
  });

  const handlePopup = () => {
    setShowModal(true);
  };

  function addZeroPrefix(value) {
    return value < 10 ? "0" + value : value;
  }

  const handleReviewUpdate = async (status, value) => {
    if (status == "Approved") {
      var JSONtoUpdate = {
        // review_status:"Reviewed",
        global_status: "Requested.OwnerApproved",
        reservation_status: "Requested",
        // price_breakdown_owner: [
        //   {
        //     stage: "Requested.OwnerApproved",
        //     advertised_day_rate:
        //       allReservation.reservation?.price_break_down_renter[0]
        //         ?.rent_per_day,
        //     service_fees: serivceFee,
        //     total: total,
        //     parentfield: "price_breakdown_owner",
        //     parenttype: "Reservation",
        //     doctype: "Price Breakdown Owner",
        //   },
        // ],
      };
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setTimeout(() => {
              toast.success("Reservation Approved");
            }, 500);

            console.log(response);
          });
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          toast.error("Reservation Failed");
        }, 500);
      }
    } else if (status == "Rejected") {
      var time = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Paris",
      });
      var finaltime = new Date(time);
      var formattedTime =
        finaltime.getFullYear() +
        "-" +
        addZeroPrefix(finaltime.getMonth() + 1) +
        "-" +
        addZeroPrefix(finaltime.getDate()) +
        " " +
        addZeroPrefix(finaltime.getHours()) +
        ":" +
        addZeroPrefix(finaltime.getMinutes()) +
        ":" +
        addZeroPrefix(finaltime.getSeconds()) +
        "." +
        finaltime.getMilliseconds();

      var JSONtoUpdate = {
        global_status: "Requested.OwnerRejected",
        reservation_status: "Rejected",
        cancellation_date: formattedTime,
        cancellation_reason: value,
      };
      console.log("JSONtoUpdate", JSONtoUpdate);
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setTimeout(() => {
              toast.error("Reservation Rejected");
            }, 500);
            console.log(response);
            setShowModal(false);
          });
      } catch (e) {
        console.error(e);
        setShowModal(false);
        setTimeout(() => {
          toast.error("Reservation Failed");
        }, 500);
      }
    } else if (status == "Cancelled") {
      var JSONtoUpdate = {
        global_status: "Cancelled.Owner",
        reservation_status: "Cancelled",
        cancelled_by: "Cancelled By Owner",
        // price_breakdown_owner: [
        //   {
        //     stage: "Cancelled.Owner",
        //     advertised_day_rate:
        //       allReservation.reservation?.price_break_down_renter[0]
        //         ?.rent_per_day,
        //     service_fees: serivceFee,
        //     total: totalAfterCancellationFee + total,
        //     parentfield: "price_breakdown_owner",
        //     parenttype: "Reservation",
        //     doctype: "Price Breakdown Owner",
        //   },
        // ],
      };
      console.log("JSONtoUpdate", JSONtoUpdate);
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setTimeout(() => {
              toast.success("Reservation Cancelled");
            }, 500);
            console.log(response);
            setShowModal(false);
          });
      } catch (e) {
        console.error(e);
        setShowModal(false);
        setTimeout(() => {
          toast.error("Reservation Cancelation Failed");
        }, 500);
      }
    }
  };
  const handlePDFDownload = async () => {
    setLoaderForButton(true);
    try {
      await axios
        .get(
          `https://api.rentmytruck.co/api/method/frappe.utils.print_format.download_pdf?doctype=Reservation Contract&name=${reservationID}&format=Reservation Contract Document`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          console.log("pdf", response.data);

          const url = window.URL.createObjectURL(response.data);

          // Create a temporary link and click it to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "file.pdf";
          document.body.appendChild(a);
          a.click();

          // Clean up the temporary URL and link
          window.URL.revokeObjectURL(url);
          a.remove();
          toast.success("Contract Downloaded");
          setLoaderForButton(false);

          // setReservationTimeline(response);
        });
    } catch (e) {
      console.error(e);
      toast.error("Contract Download Failed");
      setLoaderForButton(false);
    }
  };

  const handleExtensionChange = async (e) => {
    console.log("check", check);
    console.log("target", e.target.checked);
    if (e.target.checked == false) {
      setCheck(false);
      console.log(check);
      var JSONtoUpdate = {
        auto_approve_extension_request: 0,
      };
      console.log("JSONtoUpdate", JSONtoUpdate);
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setTimeout(() => {
              toast.success("Auto Approval Extension Disabled");
            }, 500);
            console.log(response);
            setShowModal(false);
          });
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          toast.error("Auto Approval Extenion Failed");
        }, 500);
      }
    } else {
      setCheck(true);
      console.log(check);
      var JSONtoUpdate = {
        auto_approve_extension_request: 1,
      };
      console.log("JSONtoUpdate", JSONtoUpdate);
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setTimeout(() => {
              toast.success("Auto Approval Extension Enabled");
            }, 500);
            console.log(response);
            setShowModal(false);
          });
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          toast.error("Auto Approval Extenion Failed");
        }, 500);
      }
    }
  };

  return (
    <>
      <Toaster richColors />
      {loading ? (
        <ReservationDetailPageSkeleton />
      ) : (
        <div className="min-h-full">
          <main className="mt-0 ">
            <div className="mx-0 w-full lg:px-0 ">
              <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
                <div className="grid grid-cols- gap-4 lg:col-span-2">
                  <div className="grid grid-cols- gap-4 lg:col-span-2 mb-3">
                    {/* Welcome panel */}
                    <section aria-labelledby="profile-overview-title">
                      <Tab.Group
                        as="div"
                        className="flex flex-col-reverse lg:pr-4"
                      >
                        {/* Image selector */}
                        <div className="mx-auto lg:mt-6 mt-4  w-full max-w-2xl sm:block lg:max-w-none">
                          <Tab.List className="grid grid-cols-4 lg:gap-6 gap-3">
                            {allReservation?.vehicle?.attach_images?.map(
                              (image) => (
                                <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 ">
                                  {({ selected }) => (
                                    <>
                                      {/* <span className="sr-only">hello</span> */}
                                      <span className="absolute inset-0 overflow-hidden rounded-md">
                                        <img
                                          src={window.$backendURL + image.image}
                                          alt=""
                                          className="h-full w-full object-contain"
                                        />
                                      </span>
                                      <span
                                        className={
                                          selected
                                            ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                            : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                        }
                                        aria-hidden="true"
                                      />
                                    </>
                                  )}
                                </Tab>
                              )
                            )}
                          </Tab.List>
                        </div>

                        <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                          {allReservation?.vehicle?.attach_images?.map(
                            (image) => (
                              <Tab.Panel>
                                <img
                                  src={window.$backendURL + image.image}
                                  alt=""
                                  className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                                />
                              </Tab.Panel>
                            )
                          )}
                        </Tab.Panels>
                      </Tab.Group>
                    </section>

                    {/* Actions panel */}
                    <section aria-labelledby="quick-links-title">
                      <div className="overflow-hidden rounded-lg py-2">
                        <div className="flex justify-between flex-col">
                          <div className="flex items-center ">
                            <h2
                              className="text-2xl font-semibold text-gray-900"
                              id="announcements-title"
                            >
                              {allReservation?.vehicle?.make +
                                " " +
                                allReservation?.vehicle?.model}
                            </h2>
                          </div>

                          {/* <div className="flex my-3 items-center gap-4">
                            <div className="text-sm font-semibold">4.5</div>
                            <div className="text-sm font-semibold w-6 h-6">
                              <StarIcon />
                            </div>

                            <div>
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                23 Trips
                              </span>
                            </div>
                            <div className="w-6 h-6">
                              <MapPinIcon />
                            </div>
                            <div className="text-sm font-semibold">
                              2.3 miles
                            </div>
                          </div> */}

                          <div className="mt-4 mb-2 lg:w-2/4 border-2 rounded-xl p-3 sm:w-full border-slate-300">
                            <div className="flex my-4 items-center justify-between">
                              <div className="flex gap-1 items-center">
                                <div className="text-sm font-semibold w-6 h-6">
                                  <TruckIcon className="w-6 h-6" />
                                </div>
                                <div className="text-sm  text-gray-900">
                                  {allReservation?.vehicle?.year}
                                </div>
                              </div>
                              <div className="flex gap-1 items-center">
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    dataname="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" />
                                  </svg>
                                </div>
                                <div className="text-sm  text-gray-900">
                                  {allReservation?.vehicle?.fuel_type}
                                </div>
                              </div>
                              <div className="flex gap-1 items-center">
                                <div className="w-6 h-6">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    dataname="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M23.5,23h-3.5V4.5c0-2.481-2.019-4.5-4.5-4.5h-7c-2.481,0-4.5,2.019-4.5,4.5V23H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5H23.5c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5ZM5,4.5c0-1.93,1.57-3.5,3.5-3.5h7c1.93,0,3.5,1.57,3.5,3.5V23H5V4.5Zm12,7.5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z" />
                                  </svg>
                                </div>
                                <div className="text-sm  text-gray-900">
                                  {allReservation?.vehicle?.lift_gate}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3">
                            <h1 className="text-lg font-semibold text-gray-800">
                              Description:
                            </h1>
                            <p className="text-sm text-slate-600 py-2">
                              {allReservation?.vehicle?.description}
                            </p>
                          </div>
                        </div>

                        {/* Location  */}
                        <div className="flex  flex-col mt-3">
                          <div className=" text-lg font-semibold  text-gray-800">
                            Location:
                          </div>
                          <div className="text-sm font-normal py-2 text-slate-600">
                            {"Address: " + allReservation?.location.address}
                          </div>
                        </div>
                        {/* <img
                      className="w-full rounded-lg h-60 sm:h-96"
                      src="https://images.unsplash.com/photo-1569336415962-a4bd9f69cd83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hcHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    /> */}
                        <div className="lg:pr-3">
                          <iframe
                            className="w-full rounded-lg h-60 sm:h-96"
                            marginheight="0"
                            marginwidth="0"
                            src={`https://maps.google.com/maps?q=${lat},${long}&hl=en&z=14&amp&output=embed`}
                          ></iframe>
                        </div>
                        {/* <div className="flex flex-col mt-6 ">
                        <div className="text-lg font-semibold my-2 ">
                          Business Hours
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                          {allReservation?.owner?.business_hours}
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                        Saturday 8:00am at 8:00pm
                      </div>
                      </div> */}
                        <div className="flex flex-col my-4">
                          <div className="text-lg font-semibold my-2">
                            Renter Information
                          </div>
                          <div className="text-md text-gray-900 font-medium">
                            <a
                              href={
                                window.$FWDurl +
                                `/publically-facing-profile/${allReservation?.renter?.name}`
                              }
                              className="underline hover:text-gray-600 text-lg"
                              target="_blank"
                            >
                              {allReservation?.renter?.business_name}
                            </a>
                          </div>

                          {/* <div className="text-sm text-gray-500 font-medium">
                          <span className="font-semibold text-black">
                            Business Number: &nbsp;
                          </span>{" "}
                          {allReservation?.owner?.business_number}
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                          <span className="font-semibold text-black">
                            Business Address: &nbsp;
                          </span>{" "}
                          {allReservation?.owner?.business_address}
                        </div> */}
                        </div>

                        {allReservation?.reservation?.global_status !=
                          "Requested.Requested" ||
                        allReservation?.reservation?.global_status !=
                          "Requested.OwnerApproved" ||
                        allReservation?.reservation?.global_status !=
                          "Requested.COIuploaded" ? (
                          <div>
                            <Link to={`/ownerChat/${reservationID}`}>
                              <button className=" relative text-center rounded-md mt-2 w-40 h-11 border-2 bg-purple-500 text-white border-purple-500 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white">
                                Chat with Renter
                                {filterMessage?.length > 0 && (
                                  <span className="rounded-full px-1.5 text-base font-semibold bg-red-600 absolute -top-2.5 -right-1.5">
                                    {filterMessage?.length}
                                  </span>
                                )}
                              </button>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </section>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 pb-3">
                  <section aria-labelledby="announcements-title">
                    <div className="overflow-hidden rounded-lg bg-white ">
                      <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 mb-5">
                        <h2
                          className="text-xl flex gap-1 text-gray-900 mt-2"
                          id="announcements-title"
                        >
                          <span className="font-normal">
                            Reservation&nbsp;{" "}
                          </span>
                          <p className="font-thin">
                            {"#" + reservationID.toUpperCase()}
                          </p>
                        </h2>
                        <div className="flex items-center justify-between">
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {allReservation?.reservation?.reservation_status}
                          </span>
                          <span>
                            {(allReservation?.reservation?.global_status ==
                              "Requested.Requested" && (
                              <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                Waiting for Owners Approval{" "}
                              </span>
                            )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.OwnerApproved" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for COI{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.OwnerRejected" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Rejected by Owner{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.COIuploaded" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  COI Uploaded{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.RenterApproved" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Reservation Approved by Renter{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.RenterRejected" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Rejected by Renter{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Cancelled" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Cancelled{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Active.Waiting for Pickup" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Pickup
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Active.Active" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Pickup Completed
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Completed.Rated" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Reconcillation
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Completed.Returned" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Rating
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Completed.Reconcilled" && (
                                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                                  Reconcillation Completed
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Active.Time Extension Requested" && (
                                <span className="inline-flex rounded-full bg-purple-100 px-2 text-xs font-semibold leading-5 text-purple-800">
                                  Waiting For Owner's Extension Approval
                                </span>
                              ))}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-semibold mb-3">
                            Owner Information
                          </span>
                          <span className="text-gray-500">
                            {allReservation?.owner?.business_name}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-semibold mb-3">
                            Driver Information
                          </span>
                          <div className="flex items-center justify-between">
                            <span className="text-gray-500">
                              {allReservation?.reservation?.first_name +
                                " " +
                                allReservation?.reservation?.last_name}
                            </span>

                            <span
                              className="bg-purple-500 px-2 py-1 text-xs rounded-md text-white font-medium cursor-pointer"
                              onClick={() => {
                                fetchDriverProfile(
                                  allReservation?.reservation?.driver
                                );
                                setOpenDriverProfile(true);
                              }}
                            >
                              Show Profile
                            </span>
                          </div>

                          <Transition.Root
                            show={openDriverProfile}
                            as={Fragment}
                          >
                            <Dialog
                              className="relative z-10"
                              onClose={setOpenDriverProfile}
                            >
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                              </Transition.Child>

                              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                  >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                          type="button"
                                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setOpenDriverProfile(false)
                                          }
                                        >
                                          <span className="sr-only">Close</span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                      <div className="">
                                        <h1 className="text-xl font-semibold">
                                          Driver Profile
                                        </h1>
                                        <div className="flex flex-col items-start pt-6  divide-y divide-gray-200">
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              Name:{" "}
                                            </span>
                                            <span className="text-gray-500">
                                              {driverProfile?.first_name +
                                                " " +
                                                driverProfile?.last_name}
                                            </span>
                                          </div>
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              Contact Number:{" "}
                                            </span>
                                            <span className="text-gray-500">
                                              {driverProfile?.contact_number}
                                            </span>
                                          </div>
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              License Number:{" "}
                                            </span>
                                            <span className="text-gray-500">
                                              {driverProfile?.license_number}
                                            </span>
                                          </div>
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              License State:{" "}
                                            </span>
                                            <span className="text-gray-500">
                                              {driverProfile?.license_state}
                                            </span>
                                          </div>
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              Birthday:{" "}
                                            </span>
                                            <span className="text-gray-500">
                                              {driverProfile?.birthday}
                                            </span>
                                          </div>
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              Verified:{" "}
                                            </span>
                                            {driverProfile?.is_verified == 1 ? (
                                              <CheckCircleIcon className="h-6 w-6 text-green-500" />
                                            ) : (
                                              <XCircleIcon className="w-6 h-6 text-red-500" />
                                            )}
                                          </div>
                                          {/* <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              License Front:{" "}
                                            </span>
                                            <a
                                              href={
                                                window.$backendURL +
                                                driverProfile?.license_front_picture
                                              }
                                              target="_blank"
                                              className="bg-purple-500 rounded-md py-1 px-2 text-sm text-white font-medium"
                                            >
                                              Show Front Picture
                                            </a>
                                          </div>
                                          <div className="flex items-center justify-between w-full py-2">
                                            <span className="font-semibold">
                                              License Back:{" "}
                                            </span>
                                            <a
                                              href={
                                                window.$backendURL +
                                                driverProfile?.license_back_picture
                                              }
                                              target="_blank"
                                              className="bg-purple-500 rounded-md py-1 px-2 text-sm text-white font-medium"
                                            >
                                              Show Back Picture
                                            </a>
                                          </div> */}
                                        </div>
                                      </div>
                                      {/* <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                          <ExclamationTriangleIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                          <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                          >
                                            Deactivate account
                                          </Dialog.Title>
                                          <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                              Are you sure you want to
                                              deactivate your account? All of
                                              your data will be permanently
                                              removed from our servers forever.
                                              This action cannot be undone.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                          type="button"
                                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                          onClick={() =>
                                            setOpenDriverProfile(false)
                                          }
                                        >
                                          Deactivate
                                        </button>
                                        <button
                                          type="button"
                                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                          onClick={() =>
                                            setOpenDriverProfile(false)
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div> */}
                                    </Dialog.Panel>
                                  </Transition.Child>
                                </div>
                              </div>
                            </Dialog>
                          </Transition.Root>
                        </div>
                        <div className="flex justify-between items-center mt-3">
                          <div className="flex flex-col gap-1">
                            <span className=" font-semibold ">Pickup</span>
                            <span className="text-slate-500">
                              {moment(
                                allReservation?.reservation
                                  ?.reservation_start_date
                              ).format("ll") +
                                " " +
                                moment(
                                  allReservation?.reservation
                                    ?.requested_pickup_and_dropoff_time,
                                  "HH:mm:ss"
                                ).format("LT")}
                            </span>
                          </div>
                          <div className="flex flex-col gap-1">
                            <span className="font-semibold">Return</span>
                            <span className="text-slate-500">
                              {moment(
                                allReservation?.reservation
                                  ?.reservation_end_date
                              ).format("ll") +
                                " " +
                                moment(
                                  allReservation?.reservation
                                    ?.requested_pickup_and_dropoff_time,
                                  "HH:mm:ss"
                                ).format("LT")}
                            </span>
                          </div>
                        </div>
                        
                        {allReservation?.reservation?.global_status ===
                          "Completed.Returned" || allReservation?.reservation?.global_status ===
                          "Completed.Rated" || allReservation?.reservation?.global_status ===
                          "Completed.Reconcilled" ?
                        <div className="flex justify-between items-center mt-3">
                          <div className="flex flex-col gap-1">
                            <span className=" font-semibold ">Actual Return Date</span>
                            <span className="text-slate-500">
                              {moment(
                                allReservation?.reservation
                                  ?.actual_return_date
                              ).format("lll")}
                            </span>
                          </div>
                          
                        </div> : ""}

                        {allReservation?.reservation?.global_status ==
                        "Completed.Return" &&
                        <section className="border border-purple-500 rounded-md">
                          <div className="overflow-hidden rounded-lg bg-white ">
                            <div className=" flex flex-col justify-between gap-y-4 p-6">
                              <h1 className="text-xl font-semibold">
                                Rating & Reviews
                              </h1>
                              <form onSubmit={handleReviewsUpload}>
                                <div className="mb-4">
                                  <label className="block text-gray-700 mb-2">
                                    Rating
                                  </label>
                                  <div className="flex">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                      <button
                                        type="button"
                                        key={star}
                                        className="w-8 h-8"
                                        onClick={() => handleSetRating(star)}
                                      >
                                        <StarIcon
                                          className={`w-8 h-8 ${
                                            rating >= star
                                              ? "text-yellow-500"
                                              : "text-gray-400"
                                          }`}
                                        />
                                      </button>
                                    ))}
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <label className="block text-gray-700 mb-2">
                                    Review
                                  </label>
                                  <textarea
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    value={review}
                                    onChange={(e) => setReview(e.target.value)}
                                    rows="4"
                                  />
                                </div>
                                <button
                                  type="submit"
                                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                          </div>
                        </section>}

                        {allReservation?.reservation?.global_status ==
                        "Completed.Reconcilled" ? (
                          <div className=" flex flex-col justify-between gap-y-4 -mx-2">
                            <div
                              className="flex flex-col lg:p-5 p-3 border-2 mt-2 rounded-lg bg-gray-50"
                              
                            >
                              <div
                                className="text-left font-semibold text-lg pb-2 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                Estimated Income
                              </div>
                              <div
                                className="py-4 border-b-2"
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Rent Per Day
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.vehicle?.rent_per_day}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2 font-semibold">
                                    Total{" "}
                                    {`(for ${allReservation?.reservation?.initial_rental_days} days)`}
                                  </div>
                                  <div className="flex justify-center items-center font-semibold">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.owner_initial_total_rental_price}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="py-4   border-b-2  "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Service Fee
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.owner_final_split_fee}
                                  </div>
                                </div>

                                {/* <div className="flex  justify-between   ">
                                <div className="text-left font-bold my-2">
                                  Total Fee
                                </div>
                                <div className="flex justify-center font-bold items-center">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.owner_initial_total_fee}
                                </div>
                              </div> */}
                              </div>

                              <div
                                className="flex py-4 flex-col gap-y-1 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <h1 className="font-semibold">Mileage</h1>
                                <div className="flex justify-between items-start pb-1">
                                  <div className="flex flex-col items-start pr-3">
                                    <div className="flex items-top gap-1">
                                      Prepaid Mileage
                                      {/* <Tooltip
                                  data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${allReservation?.vehicle?.vehicle_type}, Rent My Truck charges ${allReservation?.per_day_miles_allowed} miles per day at $${allReservation?.allowed_cost_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                  />{" "} */}
                                    </div>
                                    <span className=" font-light text-xs">{`${allReservation?.reservation?.allocated_miles_per_vehicle_type} miles per day included ($0.02 per extra mile)`}</span>
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_prepaid_mileage_cost}
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>Prepaid Miles Consumed</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.prepaid_miles_consumed
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>Prepaid Miles Balance</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.prepaid_miles_balance
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>Additional Miles Consumed</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.additional_miles_consumed
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between pb-3">
                                  <div>Additional Miles Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.additional_miles_cost}
                                  </div>
                                </div>
                                <div className="flex justify-between font-semibold border-t-2 pt-3">
                                  <div className="">Total Miles Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.total_miles_cost}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col gap-y-1 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <h1 className="font-semibold">Reefer</h1>
                                <div className="flex justify-between">
                                  <div>Reefer Consumed Hours</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.reefer_consumed_hours
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between pb-3">
                                  <div>Reefer Per Hour Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.reefer_per_hour_cost}
                                  </div>
                                </div>
                                <div className="flex justify-between font-semibold border-t-2 pt-3">
                                  <div className="">Reefer Total Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.reefer_total_cost}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col gap-y-1 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <h1 className="font-semibold">Violations</h1>
                                <div className="flex justify-between">
                                  <div>Late Return Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.late_return_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div>Late Return Days</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.late_return_days_number
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Cleaning Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.cleaning_fee_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Smoking Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.smoking_fee_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">
                                    Failed to Return At Dropoff Violation
                                  </div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.failed_to_return_at_dropoff_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Fuel Refill Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.fuel_refill_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Out of State Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.out_of_state_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between pb-3">
                                  <div className="">
                                    Fuel Refill Gallons Needed
                                  </div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.fuel_refill_gallons_needed
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between font-semibold border-t-2 pt-3">
                                  <div className="">Violations Total</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.violations_total}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col  border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex justify-between">
                                  <div className="flex flex-col">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Deposit
                                    </div>
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_deposit}
                                  </div>
                                </div>

                                <div className="flex justify-between pb-3">
                                  <div className="flex flex-col">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Deposit Deduction
                                    </div>
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.deposit_deduction}
                                  </div>
                                </div>

                                <div className="flex justify-between border-t-2 pt-2">
                                  <div className="flex flex-col font-semibold">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Deposit Refund
                                    </div>
                                  </div>
                                  <div className=" flex justify-center items-center font-semibold">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.deposit_refund}
                                  </div>
                                </div>
                              </div>
                              <div className="flex py-4 justify-between   border-gray-200 border-b-2  ">
                                <div className="text-left font-bold">Total</div>
                                <div className="flex justify-center items-center font-bold">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.owner_final_total_payout}
                                </div>
                              </div>

                              <div className="flex py-4  justify-between border-gray-200 ">
                                {allReservation?.reservation?.global_status ===
                                  "Requested.COIuploaded" && (
                                  <>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("Approved")
                                      }
                                      className="rounded-md border w-full h-11 border-transparent button py-2 px-2 mr-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                                    >
                                      Approve
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("Rejected")
                                      }
                                      className="rounded-md  w-full h-11 border-2 text-red-700 border-red-700 py-2 px-2 ml-2 text-sm font-medium shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" flex flex-col justify-between gap-y-4">
                            <div
                              className="flex flex-col p-5 border-2 mt-4 rounded-lg bg-gray-50"
                              
                            >
                              <div
                                className="text-left font-semibold text-lg pb-2 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                Estimated Income
                              </div>
                              <div
                                className="py-4 border-b-2"
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Rent Per Day
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.vehicle?.rent_per_day}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Total{" "}
                                    {`(for ${allReservation?.reservation?.initial_rental_days} days)`}
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.owner_initial_total_rental_price}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="py-4   border-b-2  "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Service Fee
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.owner_initial_split_fee}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="flex   py-4 justify-between  border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex flex-col">
                                  <div className="text-left my-2 flex items-top gap-1">
                                    Prepaid Mileage
                                    <Tooltip
                                    // data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${allReservation?.vehicle?.vehicle_type}, Rent My Truck charges ${allReservation?.per_day_miles_allowed} miles per day at $${allReservation?.allowed_cost_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                    />{" "}
                                  </div>
                                  {/* <span className=" font-light text-xs">{`${allReservation?.reservation?.price_break_down_renter[0]?.per_day_miles_allowed} miles per day included ($${allReservation?.reservation?.price_break_down_renter[0]?.overboard_per_mile_cost} per extra mile)`}</span> */}
                                </div>
                                <div className=" flex justify-center items-center">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.owner_initial_prepaid_mileage_payout}
                                </div>
                              </div>
                              {allReservation?.vehicle?.includes_reefer == 1 &&
                              <div
                                className="flex   py-4 justify-between  border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex flex-col">
                                  <div className="text-left my-2 flex items-top gap-1">
                                    Reefer Cost
                                    <Tooltip
                                    data={`Reefer Cost = $1`}
                                    />{" "}
                                  </div>
                                  {/* <span className=" font-light text-xs">{`${allReservation?.reservation?.price_break_down_renter[0]?.per_day_miles_allowed} miles per day included ($${allReservation?.reservation?.price_break_down_renter[0]?.overboard_per_mile_cost} per extra mile)`}</span> */}
                                </div>
                                <div className=" flex justify-center items-center">
                                  TBD
                                </div>
                              </div>}
                              <div className="flex  py-4 justify-between  font-bold border-gray-200 border-b-2  ">
                                <div className="text-left ">Total</div>
                                <div className="flex justify-center items-center">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.owner_initial_total_payout}
                                </div>
                              </div>
                              <div className="flex items-center justify-center  py-4 font-semibold border-gray-200 border-b-2  ">
                                <button
                                  className="hover:underline bg-purple-500 hover:bg-purple-600 text-white w-full  py-2 rounded-lg"
                                  onClick={() =>
                                    document
                                      .getElementById("my_modal_4")
                                      .showModal()
                                  }
                                >
                                  Add Notes
                                </button>
                              </div>

                              <dialog id="my_modal_4" className="modal">
                                <div className="modal-box">
                                  <h3 className="font-bold text-lg">Notes</h3>
                                  <p className="py-4">
                                    Add Notes here for the Renter.
                                  </p>
                                  <div>
                                    <label
                                      for="message"
                                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                      Your message
                                    </label>
                                    <textarea
                                      id="message"
                                      onChange={(e) =>
                                        setOwnerNotes(e.target.value)
                                      }
                                      value={ownerNotes}
                                      rows="4"
                                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500"
                                      placeholder="Write your notes here..."
                                    ></textarea>
                                  </div>
                                  <div className="modal-action ">
                                    <form method="dialog">
                                      <div className="flex gap-x-2">
                                        <button
                                          className="btn bg-purple-500 hover:bg-purple-600 text-white"
                                          onClick={handleOwnerNotes}
                                        >
                                          Add
                                        </button>
                                        <button className="btn">Close</button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </dialog>
                              {allReservation?.reservation?.global_status ==
                                "Requested.RenterApproved" && (
                                <div className="mt-3  rounded-md ">
                                  <div className="flex gap-2 items-center">
                                    <input
                                      type="checkbox"
                                      name="auto_approve_extension_request"
                                      id="auto_approve_extension_request"
                                      onChange={(e) => handleExtensionChange(e)}
                                      checked={check}
                                      className="block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                                    />
                                    <label
                                      htmlFor="auto_approve_extension_request"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Auto Approval Extension
                                    </label>
                                  </div>
                                </div>
                              )}

                              {allReservation?.reservation?.global_status ==
                                "Requested.Requested" && (
                                <div className="my-4 gap-2 flex justify-between">
                                  <button
                                    onClick={() =>
                                      handleReviewUpdate("Approved")
                                    }
                                    className="rounded-md border w-full h-11 border-transparent button py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                                  >
                                    Approve
                                  </button>
                                  <button
                                    onClick={() => handlePopup()}
                                    className="rounded-md  w-full h-11 border-2 text-red-700 border-red-700 py-2 px-2 text-sm font-medium shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}

                              {showModal ? (
                                <>
                                  <form
                                    onSubmit={
                                      RejectedReservationReason.handleSubmit
                                    }
                                  >
                                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                      <div className="relative w-2/3 my-6 mx-auto max-w-3xl">
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                          <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                                            <h3 className="text-1xl font-semibold">
                                              Reservation
                                            </h3>
                                            <button
                                              className="flex items-center p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                              onClick={() =>
                                                setShowModal(false)
                                              }
                                            >
                                              <span className=" flex items-center bg-transparent text-black  h-6 w-6 text-2xl  outline-none focus:outline-none">
                                                ×
                                              </span>
                                            </button>
                                          </div>

                                          <div className="p-5 ">
                                            <label
                                              htmlFor="about"
                                              className="block text-md font-medium leading-6 text-gray-900"
                                            >
                                              Tell a reason to cancel the
                                              reservation? (There will be
                                              additional penalties for
                                              cancelling this reservation.)
                                            </label>
                                            <div className="mt-3">
                                              <textarea
                                                id="cancellation_reason"
                                                name="cancellation_reason"
                                                rows={4}
                                                onChange={
                                                  RejectedReservationReason.handleChange
                                                }
                                                value={
                                                  RejectedReservationReason
                                                    .values.cancellation_reason
                                                }
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />
                                            </div>
                                          </div>

                                          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                            <button
                                              className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                              type="button"
                                              onClick={() =>
                                                setShowModal(false)
                                              }
                                            >
                                              Close
                                            </button>
                                            <button
                                              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                              type="submit"
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                  </form>
                                </>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>

                  {allReservation?.reservation?.global_status ===
                    "Cancelled.Renter" ||
                  allReservation?.reservation?.global_status ===
                    "Cancelled.Owner" ||
                  allReservation?.reservation?.global_status === "Canceled" ? (
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Cancellation Penalties
                          </h1>

                          <div className="flex items-center justify-between">
                            <span className="text-base font-medium">
                              Reservation Cancelled:
                            </span>
                            <span className="text-sm bg-red-100 text-red-700 px-2 py-1 rounded-full">
                              {allReservation?.reservation?.cancelled_by}
                            </span>
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="text-base font-medium">
                              Cancellation Fee:
                            </span>
                            <span className="text-sm bg-red-100 text-red-700 px-2 py-1 rounded-full">
                              {"$" +
                                allReservation?.reservation
                                  ?.cancellation_fee_owner}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) :  allReservation?.reservation?.global_status == "Requested.Requested" ? "" : (
                    
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <>
                            <button
                              onClick={() => setCancelReservationModal(true)}
                              className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                            >
                              Cancel Reservation
                            </button>{" "}
                          </>
                        </div>
                      </div>
                    </section>
                  )}

                  {cancelReservationModal && (
                    <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className=" w-1/3  my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                          <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-2xl font-semibold">
                              Cancel Reservation
                            </h3>
                            <button
                              className="flex items-center p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => setCancelReservationModal(false)}
                            >
                              <span className=" flex items-center bg-transparent text-black  h-6 w-6 text-2xl  outline-none focus:outline-none">
                                ×
                              </span>
                            </button>
                          </div>

                          <section>
                            <div className="rounded-lg bg-white ">
                              <div className=" flex pl-4 pr-2 py-5">
                                <span className="inline-flex text-xl text-red-500 font-medium mb-1">
                                  Do you really want to cancel reservation?
                                </span>
                                <div className="inline-flex text-xl ml-1 mt-0.5">
                                  <Tooltip data="5% fee is charged on cancelling the reservation" />
                                </div>
                              </div>
                            </div>
                          </section>

                          {/* footer*/}
                          <div className="flex items-center justify-end p-6 rounded-b">
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-1"
                              type="button"
                              onClick={() => setCancelReservationModal(false)}
                            >
                              No
                            </button>
                            <button
                              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-1"
                              onClick={handleCancelReservation}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {allReservation?.reservation?.global_status ===
                    "Requested.COIuploaded" ||
                  allReservation?.reservation?.global_status ==
                    "Requested.RenterApproved" ? (
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Certificate Of Insurance
                          </h1>
                          {/* <div className="flex flex-col">
                            <div className="flex items-center justify-between mb-3">
                              <span className="text-gray-800">
                                Company Name:
                              </span>
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                {allReservation?.reservation?.coi_company_name}
                              </span>
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <span className="text-gray-800">
                                Company Number:
                              </span>
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                {
                                  allReservation?.reservation
                                    ?.coi_company_number
                                }
                              </span>
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <span className="text-gray-800">
                                Company Policy Number:
                              </span>
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                {allReservation?.reservation?.coi_policy_number}
                              </span>
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <span className="text-gray-800">
                                COI Provider Email:
                              </span>
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                {
                                  allReservation?.reservation
                                    ?.coi_provider_email_address
                                }
                              </span>
                            </div>
                            
                          </div> */}
                          <div className="flex items-center justify-center mb-2">
                            {allReservation?.reservation
                              ?.coi_uploaded_document != "" ||
                            allReservation?.reservation
                              ?.coi_uploaded_document != null ? (
                              <a
                                target="_blank"
                                className="text-center rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                                href={
                                  window.$backendURL +
                                  allReservation?.reservation
                                    ?.coi_uploaded_document
                                }
                              >
                                View Certificate of Insurance
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  <section>
                    <div className="overflow-hidden rounded-lg bg-white ">
                      <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                        {/* {allReservation?.reservation?.global_status ==
                          "Active.Active" && (
                          <button
                            onClick={() => setShowModal(true)}
                            className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                          >
                            View Reservation Images
                          </button>
                        )} */}

{allReservation?.reservation?.global_status ==
                          "Requested.Requested" ||
                        allReservation?.reservation?.global_status ==
                          "Requested.OwnerApproved" ? "" : (
                          <button
                            className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                            id="announcements-title"
                            onClick={handlePDFDownload}
                          >
                            
                            {loaderForButton
                              ? "Downloading..."
                              : "Download Contract"}
                          </button>
                        ) }
                      </div>
                    </div>
                  </section>

                  {showCarouselModal ? (
                    <>
                      <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className=" w-full  my-6 mx-auto">
                          <div className="border-0 rounded-lg shadow-lg h-[860px] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                              <h3 className="text-2xl font-semibold">
                                Reservation Images
                              </h3>
                              <button
                                className="flex items-center p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowCarouselModal(false)}
                              >
                                <span className=" flex items-center bg-transparent text-black  h-6 w-6 text-2xl  outline-none focus:outline-none">
                                  ×
                                </span>
                              </button>
                            </div>
                            <section aria-labelledby="announcements-title">
                              <div className="rounded-lg bg-gray-100 ">
                                <div className="flex flex-col">
                                  <div className="flex m-auto">
                                    <OwnerCarousel images={reservationImages} />
                                  </div>
                                </div>
                              </div>
                            </section>
                            {/*footer*/}
                            {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => setShowModal(false)}
                                >
                                  Close
                                </button>
                                <button
                                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
                    </>
                  ) : null}

                  {allReservation?.reservation?.global_status ==
                  "Active.Waiting for Pickup" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Pickup
                          </h1>

                          <div className="flex flex-col">
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() => setShowInspectionDrawer(true)}
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Inspection
                              </button>{" "}
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() =>
                                  setShowReservationImagesDrwaer(true)
                                }
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Upload Reservation Images
                              </button>{" "}
                            </div>
                            <div className="flex items-center mb-4">
                              <input
                                id="owner_pickup_confirm"
                                type="checkbox"
                                checked={ownerPickupConfirms}
                                onChange={(e) =>
                                  handlePickupConfirmsChange(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="owner_pickup_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Owner Pickup Confirms
                              </label>
                            </div>
                            {/* <div className="flex items-center mb-4">
                              <input
                                id="owner_damage_inspection_confirm"
                                type="checkbox"
                                checked={ownerDamageInspection}
                                onChange={(e) =>
                                  handleDamageInspection(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="owner_damage_inspection_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Damage Inspection Confirm
                              </label>
                            </div> */}

                            {/* <div className="flex items-center mb-4">
                              <input
                                id="owner_dropoff_confirm"
                                type="checkbox"
                                checked={ownerDropoffConfirms}
                                onChange={(e) =>
                                  handleDropoffConfirmsChange(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="owner_dropoff_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Owner Drop-off Confirms
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  {allReservation?.reservation?.global_status ==
                  "Active.Active" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Drop-off
                          </h1>

                          <div className="flex flex-col">
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() => setShowInspectionDrawer(true)}
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Inspection
                              </button>{" "}
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() =>
                                  setShowReservationImagesDrwaer(true)
                                }
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Upload Reservation Images
                              </button>{" "}
                            </div>

                            <div className="flex items-center mb-4">
                              <input
                                id="owner_damage_inspection_confirm"
                                type="checkbox"
                                checked={ownerDamageInspection}
                                onChange={(e) =>
                                  handleDamageInspection(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="owner_damage_inspection_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Damage Inspection Confirm
                              </label>
                            </div>

                            <div className="flex items-center mb-4">
                              <input
                                id="owner_dropoff_confirm"
                                type="checkbox"
                                checked={ownerDropoffConfirms}
                                onChange={(e) =>
                                  handleDropoffConfirmsChange(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="owner_dropoff_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Owner Drop-off Confirms
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  {/* {allReservation?.reservation?.global_status ==
                    "Requested.RenterApproved" ||
                  allReservation?.reservation?.global_status ==
                    "Completed.Returned" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Owner Damage Inspection Confirmation
                          </h1>
                          <div className="flex flex-col">
                            
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )} */}

                  {/* {allReservation?.reservation?.global_status ==
                    "Requested.RenterApproved" && (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">Inspection</h1>
                          <div className="flex flex-col">
                           
                          </div>
                        </div>
                      </div>
                    </section>
                  )} */}

                  <Transition.Root show={showInspectionDrawer} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={setShowInspectionDrawer}
                    >
                      <div className="fixed inset-0" />

                      <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                              as={Fragment}
                              enter="transform transition ease-in-out duration-700"
                              enterFrom="translate-x-full"
                              enterTo="translate-x-0"
                              leave="transform transition ease-in-out duration-700"
                              leaveFrom="translate-x-0"
                              leaveTo="translate-x-full"
                            >
                              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                  <div className="px-4 sm:px-6 border-b pb-4">
                                    <div className="flex items-center justify-between">
                                      <Dialog.Title className="text-xl font-semibold leading-6 text-gray-900">
                                        Inspection
                                      </Dialog.Title>
                                      <div className="ml-3 flex h-7 items-center">
                                        <button
                                          type="button"
                                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setShowInspectionDrawer(false)
                                          }
                                        >
                                          <span className="absolute -inset-2.5" />
                                          <span className="sr-only">
                                            Close panel
                                          </span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                    {allReservation?.reservation
                                      ?.global_status ==
                                      "Active.Waiting for Pickup" && (
                                      <>
                                        <h2 className="text-xl  font-semibold">
                                          Pickup Inspection
                                        </h2>
                                        <PcikupInsepectionAccordion
                                          Reservation={allReservation}
                                        />
                                      </>
                                    )}
                                    {allReservation?.reservation
                                      ?.global_status == "Active.Active" && (
                                      <>
                                        <h2 className="text-xl mt-10 font-semibold">
                                          Drop-off Inspection
                                        </h2>
                                        <DropoffInsepectionAccordian
                                          Reservation={allReservation}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  {/* {allReservation?.reservation?.global_status ==
                    "Requested.RenterApproved" ||
                  allReservation?.reservation?.global_status ==
                    "Active.Active" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Images
                          </h1>
                          <div className="flex flex-col">
                            
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )} */}

                  <Transition.Root
                    show={showReservationImagesDrawer}
                    as={Fragment}
                  >
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={setShowReservationImagesDrwaer}
                    >
                      <div className="fixed inset-0" />

                      <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                              as={Fragment}
                              enter="transform transition ease-in-out duration-700"
                              enterFrom="translate-x-full"
                              enterTo="translate-x-0"
                              leave="transform transition ease-in-out duration-700"
                              leaveFrom="translate-x-0"
                              leaveTo="translate-x-full"
                            >
                              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                  <div className="px-4 sm:px-6 border-b pb-4">
                                    <div className="flex items-center justify-between">
                                      <Dialog.Title className="text-xl font-semibold leading-6 text-gray-900">
                                        Reservation Images
                                      </Dialog.Title>
                                      <div className="ml-3 flex h-7 items-center">
                                        <button
                                          type="button"
                                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setShowReservationImagesDrwaer(
                                              false
                                            )
                                          }
                                        >
                                          <span className="absolute -inset-2.5" />
                                          <span className="sr-only">
                                            Close panel
                                          </span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative mt-2 flex-1 px-4 sm:px-6 ">
                                    {allReservation?.reservation
                                      ?.global_status ==
                                      "Active.Waiting for Pickup" && (
                                      <>
                                        <h2 className="text-xl  font-semibold">
                                          Pickup Images
                                        </h2>
                                        <ReservationPickupImages />
                                      </>
                                    )}

                                    {allReservation?.reservation
                                      ?.global_status == "Active.Active" && (
                                      <>
                                        <h2 className="text-xl mt-10 font-semibold ">
                                          Drop-off Images
                                        </h2>
                                        <ReservationDropOffImages />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  <section aria-labelledby="recent-hires-title">
                    <div className="overflow-hidden rounded-lg ">
                      <div className="lg:p-6 p-4 bg-white">
                        <h3 className="text-xl font-semibold mb-6">
                          Reservation Timeline
                        </h3>

                        {reservationTimeline?.map((event, eventIdx) => (
                          <ol>
                            <li className="border-l-2 border-purple-600">
                              <div className="flex">
                                <div className="bg-purple-600 w-6 h-6 cursor-pointer flex items-center justify-center rounded-full lg:-ml-[9.5px] -ml-[12.5px]">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    className="text-white w-3 h-3"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                                    ></path>
                                  </svg>
                                </div>
                                <div className="block lg:p-6 px-3 py-5 rounded-xl shadow-lg bg-gray-100 max-w-md ml-6 mb-10 lg:w-[28rem] w-[20rem]">
                                  <div className="flex items-center justify-between">
                                    <span className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out lg:text-sm text-[14px]">
                                      {event?.message}
                                    </span>
                                    <span className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out lg:text-sm text-xs">
                                      {moment(event?.creation).format("lll")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ol>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default ReservationDetailPage;
