// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAnalytics} from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBGxhfY4u8GtXwX-JpB2xRcaod4K5icAQk",
  authDomain: "truckrent-b1b8e.firebaseapp.com",
  projectId: "truckrent-b1b8e",
  storageBucket: "truckrent-b1b8e.appspot.com",
  messagingSenderId: "967829505884",
  appId: "1:967829505884:web:d1c64838bb143845dde399"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
const analytics = getAnalytics(app);