import React, { useRef, Fragment } from "react";
import { CurrencyDollarIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
// import VehicleAddForm from "./VehicleAddForm";
import Trash from "@heroicons/react/24/outline/TrashIcon";
import Upload from "@heroicons/react/24/outline/CloudArrowUpIcon";
import LoadingIcons from "react-loading-icons";
import { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useEffect } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { StarIcon, MapPinIcon, TruckIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "../../Global/SmallComponents/Tooltip";
import VehicleCardSkeleton from "../../Global/Skeleton/VehicleCardSkeleton";
import { Toaster, toast } from "sonner";
import Select from "react-tailwindcss-select";
import { loadStripe } from "@stripe/stripe-js";
import LoaderSpinner from "../../Global/Skeleton/LoaderSpinner";
import InputMask from "react-input-mask";
import { PencilOutline } from "heroicons-react";
import * as Yup from "yup";

const Vehicles = () => {
  const options = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virgini", label: "West Virgini" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [apiFilters, setApiFilters] = useState(
    `["user","=","${userEmail}"],["is_deleted","=","0"]`
  );
  const [open, setOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState();
  const [vehcileDeleted, setVehicleDeleted] = useState(false);
  // const [allowedStates, setAllowedStates] = useState([]);
  const [currProfile, setCurrProfile] = useState(null);
  const [initialStates, setInitialStates] = useState([]);
  const [stripe, setStripe] = useState(null);
  const [stripeResponse, setStripeResponse] = useState(null);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const initializeStripe = async () => {
      const stripeObject = await loadStripe(
        "pk_live_51McDWdLUqlbLOiA4OFtWtx9aK2FQVZ3WaGYoRBLjfiVUOFMHaJhr53SqWZlsHsKqxbLHBu2Z6W58L9sUBT1yYKEF00x1WJTl0W"
      );
      setStripe(stripeObject);
    };

    initializeStripe();
  }, []);

  const handleVerification = async (event) => {
    // event.preventDefault();
    setLoader(true);

    if (!stripe) {
      return;
    }

    try {
      // Call your backend to create the VerificationSession.
      const response = await axios.post(
        `${window.$backendURL}/api/method/truckrent.api.get_client_secret`,
        {
          business_profile: currProfile.name,
          type: "Company",
        }
      );
      console.log("stripeResponse", response.data.data.key);
      const session = await response;
      console.log("stripeResponse", session);

      setTimeout(() => {
        setLoader(false);
      }, 2000);
      // Show the verification modal.
      const { error } = await stripe.verifyIdentity(
        session.data.data.key.client_secret
      );

      if (error) {
        toast.error("Verfication Submitted Failed");
        console.error("[error]", error);
      } else {
        toast.success("Verfication Submitted");
        setStripeResponse("Verification submitted!");
        window.location.reload();
        console.log("Verification submitted!");
      }
    } catch (error) {
      toast.error("Error creating VerificationSession");
      console.error("Error creating VerificationSession:", error);
    }
  };

  const handleChange = (value) => {
    setInitialStates(value);
    // setAllowedStates(convertToObjectsState(value));

    console.log("value:", value);
    // console.log("value:", allowedStates);
  };

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            setCurrProfile(response.data.data);
            console.log(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getProfileOwner();
  }, []);

  const cancelButtonRef = useRef(null);
  // const [apiFilters, setApiFilters] = useState(`[]`);
  const handleImageChange = (event) => {
    const newImageFiles = Array.from(event.target.files);
    setImageFiles(newImageFiles);

    const newImages = newImageFiles.map((file) => URL.createObjectURL(file));
    console.log(newImages);
    setImages(images.concat(newImages));
    console.log(images);
  };

  const handleDeleteImage = (index) => {
    setImages(images?.filter((image, i) => i !== index));
    setImageFiles(imageFiles?.filter((file, i) => i !== index));
  };

  const [isAddingVehicle, setIsAddingVehicle] = useState(false);
  const [isOnVehicleListing, setIsOnVehicleListing] = useState(true);
  const [vehicles, setVehicles] = useState(null);
  const [totalFeatures, setTotalFeatures] = useState(null);
  const [totalLocations, setTotalLocations] = useState(null);
  const [healthFileURL, setHealthFileURL] = useState(null);
  const [faultCodeFileURL, setFaultCodeFileURL] = useState(null);
  const [regDocumentURL, setRegDocumentURL] = useState(null);
  const uploadHealthInspectionHandle = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setHealthFileURL(response.data.message.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };
  const uploadFaultCodeHandle = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadFaultCodeFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setFaultCodeFileURL(response.data.message.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadFaultCodeFile();
  };
  const uploadRegDocumentHandle = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadFaultCodeFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setRegDocumentURL(response.data.message.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadFaultCodeFile();
  };
  var featureJSON = [];

  const handleFeatureCheckbox = (e) => {
    if (featureJSON.includes(totalFeatures[e].name)) {
      featureJSON = featureJSON.filter((z) => z !== totalFeatures[e].name);
    } else {
      featureJSON.push(totalFeatures[e].name);
    }
    console.log(featureJSON.length);
  };

  var featureJSONToAdd = [];

  function convertToObjects(array) {
    let objectArray = [];
    for (let i = 0; i < array.length; i++) {
      objectArray.push({
        parentfield: "feature",
        parenttype: "Vehicle",
        features: array[i],
        doctype: "Vehicle Features Child",
      });
    }
    return objectArray;
  }

  function convertToObjectsImg(array) {
    let objectArray = [];
    for (let i = 0; i < array.length; i++) {
      objectArray.push({
        parentfield: "attach_images",
        parenttype: "Vehicle",
        image: array[i],
        doctype: "Vehicle Images",
      });
    }

    return objectArray;
  }

  //Vehicle State Manage
  function convertToObjectsState(array) {
    let stateArray = [];
    for (let i = 0; i < array.length; i++) {
      stateArray.push({
        parentfield: "allowed_states",
        parenttype: "Vehicle",
        state_name: array[i].value,
        doctype: "Allowed States",
      });
    }

    return stateArray;
  }

  // Formik For Filters
  const applyFilterForm = useFormik({
    initialValues: {
      filter_liftGate: "",
      filter_make: "",
      filter_model: "",
      filter_status: "",
    },
    onSubmit: async (values) => {
      setApiFilters(`["user","=","${userEmail}"]`);
      if (values.filter_liftGate) {
        if (values.filter_liftGate.length > 0) {
          setApiFilters((prevState) => [
            prevState + `,["lift_gate","=","${values.filter_liftGate}"]`,
          ]);
        }
      }
      if (values.filter_make) {
        if (values.filter_make.length > 0) {
          setApiFilters((prevState) => [
            prevState + `,["make","=","${values.filter_make}"]`,
          ]);
        }
      }
      if (values.filter_model) {
        if (values.filter_model.length > 0) {
          setApiFilters((prevState) => [
            prevState + `,["model","=","${values.filter_model}"]`,
          ]);
        }
      }
      if (values.filter_status) {
        if (values.filter_status.length > 0) {
          setApiFilters((prevState) => [
            prevState + `,["approval_status","=","${values.filter_status}"]`,
          ]);
        }
      }
      console.log(values);
    },
    enableReinitialize: true,
  });

  const makeRef = useRef(null);

  const [formErrors, setFormErrors] = useState({});

  const firstErrorRef = useRef(null);

  const VehicleAddForm = useFormik({
    initialValues: {
      make: "",
      model: "",
      plate_state: "",
      plate_number: "",
      registeration_number: "",
      year: 0,
      vin: "",
      description: "",
      vehicle_type: "",
      body_type: "",
      door_type: "",
      lift_gate: "",
      rent_per_day: 0,
      min_rental_days_out: 0,
      unit_number: 0,
      equipment_price: 0,
      usage_restrictions: "",
      fuel_type: "",
      pick_up: "",
      vehicle_registration_expiration_date: "",
      availability_status: "",
    },
    onSubmit: async (values) => {
      // for (const key of Object.keys(values)) {
      //   if (!values[key]) {
      //     // If any field is empty, prevent form submission
      //     return;
      //   }
      // }

      const isValid = validateForm(values);

      if (!isValid) {
        // Focus on the first invalid input field
        if (firstErrorRef.current) {
          firstErrorRef.current.focus();
        }
        return; // Prevent form submission if there are errors
      }

      // If all fields are filled out, proceed with form submission
      console.log(values);
      console.log(values);
      var imgObj = [];
      var imgObjtoAdd;
      // if (values.featuresGroup.length > 0) {
      //   var featureObj = convertToObjects(values.featuresGroup);
      // }

      var eTruckVar = 0;
      var crewCab = 0;
      var multiTemp = 0;
      var storageOnly = 0;
      var ramp = 0;
      var dockHeight = 0;
      var APU = 0;
      var hasIFTA = 0;
      var requiredCDL = 0;
      var canTravel = 0;
      var requiresDot = 0;

      if (values.e_truck) {
        if (values.e_truck.length > 0) {
          if (values.e_truck[0] == "on") {
            eTruckVar = 1;
          }
        }
      }
      if (values.ramp) {
        if (values.ramp.length > 0) {
          if (values.ramp[0] == "on") {
            ramp = 1;
          }
        }
      }
      if (values.crew_cab) {
        if (values.crew_cab.length > 0) {
          if (values.crew_cab[0] == "on") {
            crewCab = 1;
          }
        }
      }
      if (values.dock_height) {
        if (values.dock_height.length > 0) {
          if (values.dock_height[0] == "on") {
            dockHeight = 1;
          }
        }
      }
      if (values.multi_temp) {
        if (values.multi_temp.length > 0) {
          if (values.multi_temp[0] == "on") {
            multiTemp = 1;
          }
        }
      }
      if (values.apu) {
        if (values.apu.length > 0) {
          if (values.apu[0] == "on") {
            APU = 1;
          }
        }
      }
      if (values.storage_only) {
        if (values.storage_only.length > 0) {
          if (values.storage_only[0] == "on") {
            storageOnly = 1;
          }
        }
      }
      if (values.has_ifta) {
        if (values.has_ifta.length > 0) {
          if (values.has_ifta[0] == "on") {
            hasIFTA = 1;
          }
        }
      }
      if (values.require_cdl) {
        if (values.require_cdl.length > 0) {
          if (values.require_cdl[0] == "on") {
            requiredCDL = 1;
          }
        }
      }
      if (values.can_travel) {
        if (values.can_travel.length > 0) {
          if (values.can_travel[0] == "on") {
            canTravel = 1;
          }
        }
      }
      if (values.requires_dot) {
        if (values.requires_dot.length > 0) {
          if (values.requires_dot[0] == "on") {
            requiresDot = 1;
          }
        }
      }
      var addVehicleJSON;
      if (images.length >= 2) {
      const handleUploadedFiles = async () => {
        if (imageFiles) {
          for (let index = 0; index < imageFiles.length; index++) {
            console.log(imageFiles[index]);
            const file = imageFiles[index];
            const formData = new FormData();
            formData.append("file", file);

            const uploadVehicleImage = async () => {
              try {
                await axios
                  .post(
                    `${window.$backendURL}/api/method/upload_file`,
                    formData,
                    config
                  )
                  .then((response) => {
                    console.log(response.data.message.file_url);
                    imgObj.push(response.data.message.file_url);
                    if (imgObj) {
                      imgObjtoAdd = convertToObjectsImg(imgObj);
                    }
                  });
              } catch (e) {
                console.log(e);
              }
            };
            await uploadVehicleImage();
          }
          addVehicleJSON = {
            user: userEmail,
            make: values?.make,
            model: values?.model,
            plate_state: values?.plate_state,
            plate_number: values?.plate_number,
            // registration_number: values?.registeration_number,
            year: values?.year,
            vin: values?.vin,
            description: values?.description,
            vehicle_type: values?.vehicle_type,
            vehicle_owner: localStorage.getItem("userID"),
            lift_gate: values?.lift_gate,
            equipment_price: values?.equipment_price,
            body_type: values?.body_type,
            door_type: values?.door_type,
            fuel_type: values?.fuel_type,
            fuel_milage: values?.fuel_milage,
            // feature: values?.extra_features,
            health_inspection: values?.health_inspection,
            health_file: healthFileURL,
            e_truck: eTruckVar,
            ramp: ramp,
            crew_cab: crewCab,
            dock_height: dockHeight,
            multi_temp: multiTemp,
            apu: APU,
            storage_only: storageOnly,
            has_ifta: hasIFTA,
            require_cdl: requiredCDL,
            can_travel_out_of_state: canTravel,
            requires_dot: requiresDot,
            // feature: featureObj,
            attach_images: imgObjtoAdd,
            drop_off: values?.drop_off,
            pick_up: values?.pick_up,
            rent_per_day: values?.rent_per_day,
            min_rental_days_out: values?.min_rental_days_out,
            usage_restrictions: values?.usage_restrictions,
            // usage_restrictions: values?.usage_restrictions,
            fault_code: values?.fault_code,
            fault_attach: faultCodeFileURL,
            availability_status: values?.availability_status,
            vehicle_registration_expiration_date:
            values?.vehicle_registration_expiration_date,
            vehicle_registration_document: regDocumentURL,
            unit_number: values?.unit_number,
            allowed_states: convertToObjectsState(initialStates),
          };
        }
        
      };
      
      const AddVehicleApi = async () => {
        console.log("addVehicleJSON",addVehicleJSON)
        try {
          //   setLoading(true);
          await axios
            .post(
              `${window.$backendURL}/api/resource/Vehicle`,
              addVehicleJSON,
              config
            )
            .then((response) => {
              // console.log(response.data.data);
              console.log("response add vehicle", response.data.data);
              toast.success("Vehicle Added Successfully");
              setTimeout(() => {
                navigate(`/vehicle/${response?.data?.data?.name}`);
              }, 500);
            })
            .catch((e) => {
              toast.error(e?.response?.data?.exception);
              console.error(e);
            });
          //   setLoading(false);
        } catch (e) {
          toast.error(e?.response?.data?.exception);
          console.error(e);
        }
      };
      setLoading(true);
      await handleUploadedFiles();
      await AddVehicleApi();
      setLoading(false);
    }
    else{
      console.log(imageFiles);
      toast.error("Add more than one vehicle image")
    }
    },
    enableReinitialize: true,
  });

  const validateForm = (values) => {
    const requiredFields = [
      "make",
      "vehicle_type",
      "year",
      "rent_per_day",
      "vin",
      "equipment_price",
      "pick_up",
      
      
    ];
    const errors = {};

    requiredFields.forEach((field) => {
      if (!values[field]) {
        
        errors[field] = "This field is required";
      }
      
    });


    if (values.vehicle_type === "Trailers" && values.body_type !== "Flatbed" && !values?.door_type){
      errors.door_type = "This field is required for non-flatbed trailers";
    }
    else if (values.vehicle_type === "Trailers" &&  !values?.unit_number){
      errors.unit_number = "This field is required for trailers";
    }
    

    setFormErrors(errors);

    // Keep track of the first invalid input field
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorInput = document.querySelector(`[name=${firstErrorKey}]`);
      if (firstErrorInput) {
        firstErrorRef.current = firstErrorInput;
      }
    }

    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const [arrModel, setArrModel] = useState();
  const [arrLiftGate, setArrLiftGate] = useState();
  const [arrMake, setArrMake] = useState();
  const field1Ref = useRef(null);

  const deleteSelectedVehicle = () => {
    var JSON = {
      is_deleted: 1,
    };
    axios
      .put(
        `${window.$backendURL}/api/resource/Vehicle/${vehicleToDelete}`,
        JSON,
        config
      )
      .then((res) => {
        toast.success("Vehicle Deleted Successfully");
        setVehicleToDelete("");
        setVehicleDeleted(!vehcileDeleted);
      })
      .catch((error) => {
        toast.error("Failed to delete Vehicle");
      });
  };

  // Vehicle Api to Map
  useEffect(() => {
    const GetAllVehicles = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-vehicles?filters=[${apiFilters}]`,
            config
          )
          .then((response) => {
            let arrModel = [];
            let arrLiftGate = [];
            let arrMake = [];

            for (let index = 0; index < response.data.data.length; index++) {
              if (!arrModel.includes(response.data.data[index].model)) {
                arrModel.push(response.data.data[index].model);
              }
              if (!arrLiftGate.includes(response.data.data[index].lift_gate)) {
                arrLiftGate.push(response.data.data[index].lift_gate);
              }
              if (!arrMake.includes(response.data.data[index].make)) {
                arrMake.push(response.data.data[index].make);
              }
            }
            setArrModel(arrModel);
            setArrLiftGate(arrLiftGate);
            setArrMake(arrMake);
          });
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetAllVehicles();
  }, [isOnVehicleListing]);

  // Vehicle Listing
  useEffect(() => {
    const GetAllVehicles = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-vehicles?filters=[${apiFilters}]`,
            config
          )
          .then((response) => {
            setVehicles(response.data.data);
            console.log(response.data.data);
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetAllVehicles();
  }, [isOnVehicleListing, apiFilters, vehcileDeleted]);
  // useEffect(() => {
  //   const GetVehiclesFeatures = async () => {
  //     try {
  //       await axios
  //         .get(
  //           `${window.$backendURL}/api/resource/Vehicle Features?fields=["*"]`,
  //           config
  //         )
  //         .then((response) => {
  //           setTotalFeatures(response.data.data);
  //         });
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };
  //   GetVehiclesFeatures();
  // }, []);
  useEffect(() => {
    const GetAllLocations = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Location?fields=["*"]&filters=[["user","=","${userEmail}"]]`,
            config
          )
          .then((response) => {
            setTotalLocations(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    GetAllLocations();
  }, []);

  return (
    <>
      <div className="min-h-screen">
        <Toaster richColors />
        <div className=" flex justify-between mb-4 mx-auto ">
          {isAddingVehicle ? (
            <>
              <button
                className="font-semibold flex flex-row gap-2"
                onClick={() => {
                  setIsAddingVehicle(false);
                  setIsOnVehicleListing(true);
                }}
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>My Fleet</span>
              </button>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-semibold text-gray-900">My Fleet</h1>{" "}
              <button
                onClick={() => {
                  setIsAddingVehicle(true);
                  setIsOnVehicleListing(false);
                }}
                className="flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
              >
                <div>
                  <PlusIcon className="w-5 text-white h-5" />
                </div>

                <div>Add Vehicle</div>
              </button>
            </>
          )}
        </div>

        {isOnVehicleListing && (
          <>
            <div className="flex flex-col justify-center sm:">
              <form onSubmit={applyFilterForm.handleSubmit}>
                <div className="mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Filters
                  </h3>
                  <dl className="mt-1 grid grid-cols-1 gap-2 sm:grid-cols-5">
                    <div className=" rounded-lg pb-5">
                      <div className="truncate text-sm font-medium text-gray-500">
                        <div>
                          <label
                            htmlFor="filter_liftGate"
                            className="sr-only"
                          ></label>
                          <select
                            type="text"
                            id="filter_liftGate"
                            name="filter_liftGate"
                            onChange={applyFilterForm.handleChange}
                            value={applyFilterForm.values.filter_liftGate}
                            className="rounded-lg w-full border-gray-300 block border-2 focus:outline-none  focus:border-purple-500 focus:ring-purple-500"
                            placeholder="Search By Name"
                          >
                            <option value="">Select Lift Gate</option>
                            {arrLiftGate?.map((vehicle, index) => {
                              return (
                                <option key={vehicle} value={vehicle}>
                                  {vehicle}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className=" rounded-lg pb-5">
                      <div className="truncate text-sm font-medium text-gray-500">
                        <div>
                          <label
                            htmlFor="filter_model"
                            className="sr-only"
                          ></label>
                          <select
                            type="text"
                            id="filter_model"
                            name="filter_model"
                            onChange={applyFilterForm.handleChange}
                            value={applyFilterForm.values.filter_model}
                            className="rounded-lg w-full border-gray-300 block border-2 focus:outline-none  focus:border-purple-500 focus:ring-purple-500"
                            placeholder="Model"
                          >
                            <option value="">Select Model</option>
                            {arrModel?.map((vehicle, index) => {
                              return (
                                <option key={vehicle} value={vehicle}>
                                  {vehicle}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className=" rounded-lg pb-5">
                      <div className="truncate text-sm font-medium text-gray-500">
                        <div>
                          <label
                            htmlFor="filter_make"
                            className="sr-only"
                          ></label>
                          <select
                            type="text"
                            id="filter_make"
                            name="filter_make"
                            onChange={applyFilterForm.handleChange}
                            value={applyFilterForm.values.filter_make}
                            className="rounded-lg w-full border-gray-300 block border-2 focus:outline-none  focus:border-purple-500 focus:ring-purple-500"
                            placeholder="Model"
                          >
                            <option value="">Select Make</option>
                            {arrMake?.map((vehicle, index) => {
                              return (
                                <option key={vehicle} value={vehicle}>
                                  {vehicle}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className=" rounded-lg pb-5">
                      <div className="truncate text-sm font-medium text-gray-500">
                        <div>
                          <label
                            htmlFor="filter_status"
                            className="sr-only"
                          ></label>
                          <select
                            type="text"
                            id="filter_status"
                            name="filter_status"
                            onChange={applyFilterForm.handleChange}
                            value={applyFilterForm.values.filter_status}
                            className="rounded-lg w-full border-gray-300 block border-2 focus:outline-none  focus:border-purple-500 focus:ring-purple-500"
                            placeholder="Model"
                          >
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Verified">Verified</option>
                            <option value="Rejected">Rejected </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className=" rounded-lg pb-5">
                      <div className="truncate text-sm font-medium text-gray-500">
                        <div className="flex gap-1">
                          <button
                            type="submit"
                            className="rounded-md border w-full h-11 border-transparent button py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          >
                            Apply Filters
                          </button>
                          <button
                            onClick={() => {
                              setApiFilters(`["user","=","${userEmail}"]`);
                              applyFilterForm.resetForm();
                            }}
                            className="text-purple-800 w-full focus:outline-none rounded-lg border border-purple-800 hover:bg-purple-100 hover:text-purple-800"
                          >
                            Clear Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </dl>
                </div>
              </form>
              <div className="mx-auto w-full">
                {vehicles?.length == 0 && "No Vehicles Found"}
                <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-3 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-3">
                  {loading ? (
                    <>
                      <VehicleCardSkeleton />
                      <VehicleCardSkeleton />
                      <VehicleCardSkeleton />
                      <VehicleCardSkeleton />
                      <VehicleCardSkeleton />
                      <VehicleCardSkeleton />
                    </>
                  ) : (
                    vehicles?.map((vehicle) => (
                      <div
                        key={vehicle.name}
                        className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                      >
                        <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none h-64">
                          <Link to={"/vehicle/" + vehicle.name}>
                            <img
                              src={
                                vehicle?.attach_images[0]?.image === undefined
                                  ? "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                                  : window.$backendURL +
                                    vehicle?.attach_images[0]?.image
                              }
                              alt="No Image Attached"
                              className="min-h-64 w-full object-cover object-center h-full sm:h-full sm:w-full"
                            />
                          </Link>
                        </div>
                        <div className="flex flex-1 flex-col justify-between space-y-2 p-4">
                          <div className="flex items-start flex-row justify-between ">
                            <span className=" font-medium text-xl">
                              {" "}
                              {vehicle?.make +
                                " " +
                                vehicle?.model +
                                " " +
                                vehicle?.year}
                            </span>
                            <span
                              className={
                                "inline-flex h-6 rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800  sm:ml-2 items-center"
                              }
                            >
                              {vehicle?.vehicle_type}
                            </span>
                          </div>
                          <div className="flex flex-row gap-1 items-center mt-4">
                            <span className=" font-semibold text-gray-700 items-center">
                              ${vehicle?.rent_per_day} / Day
                            </span>
                          </div>
                          <div className="flex flex-row gap-1 items-center mt-4">
                            <MapPinIcon className="w-5 text-gray-700" />
                            <p className="text-base font-normal text-gray-700 items-center w-80 truncate">
                              {vehicle?.pick_up[0]?.address}
                            </p>
                          </div>

                          <div className="pb-4">
                            {/* <div className="flex items-center  ">
                          <div className="flex justify-between gap-2">
                            <span className=" font-light">4.5</span>
                            <StarIcon className="h-5 w-5" />
                          </div>
                          <span className="ml-2 inline-flex rounded-full bg-purple-100 px-2  text-sm leading-5 text-purple-800 font-light items-center">
                            23 Trips
                          </span>
                          <span className={vehicle?.availability_status=="Available" ? "ml-2 inline-flex rounded-full bg-blue-100 px-2  text-sm leading-5 text-blue-800 font-light items-center":"ml-2 inline-flex rounded-full bg-red-100 px-2  text-sm leading-5 text-red-800 font-light items-center"}>
                            {vehicle?.availability_status}
                          </span>
                        </div> */}

                            <div className="flex items-center gap-2 lg:gap-2">
                              <div className="flex gap-1 px-2 py-1 bg-purple-100 rounded-full">
                                {" "}
                                {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              id="Layer_1"
                              dataname="Layer 1"
                              viewBox="0 0 28 28"
                            >
                              <path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" />
                            </svg> */}
                                <span className=" inline-flex text-purple-500  px-0.5 leading-5 text-base text-center font-light  items-center">
                                  {vehicle?.fuel_type}
                                </span>
                              </div>
                              <div className="flex gap-1 px-2 py-1 bg-purple-100 rounded-full">
                                {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              dataname="Layer 1"
                              viewBox="0 0 26 26"
                              width="22"
                            >
                              <path d="M23.5,23h-3.5V4.5c0-2.481-2.019-4.5-4.5-4.5h-7c-2.481,0-4.5,2.019-4.5,4.5V23H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5H23.5c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5ZM5,4.5c0-1.93,1.57-3.5,3.5-3.5h7c1.93,0,3.5,1.57,3.5,3.5V23H5V4.5Zm12,7.5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z" />
                            </svg> */}
                                <span className=" inline-flex text-purple-500  px-0.5 text-base leading-5 text-center font-light items-center">
                                  {vehicle?.lift_gate}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row md:items-center mt-3 w-full">
                            <Link
                              to={"/vehicleAvailability/" + vehicle.name}
                              className=" h-12 text-sm flex justify-center items-center w-full font-bold text-purple-800 focus:outline-none bg-white rounded-lg border border-purple-800 hover:bg-purple-100 hover:text-purple-800 md:mr-2 md:mb-0 mb-2"
                            >
                              Change Availability
                            </Link>
                            <Link
                              to={"/editVehicle/" + vehicle.name}
                              className=" h-12 text-sm flex justify-center items-center px-4 font-bold text-purple-800 focus:outline-none bg-white rounded-lg border border-purple-800 hover:bg-purple-100 hover:text-purple-800 md:mr-2 md:mb-0 mb-2"
                            >
                              <span>
                                <PencilOutline className="w-5 h-5 text-purple-800 " />
                              </span>
                            </Link>
                            <button
                              onClick={() => {
                                setOpen(true);
                                setVehicleToDelete(vehicle.name);
                              }}
                              className=" h-12 text-sm  flex items-center px-4 justify-center font-bold text-red-700 focus:outline-none bg-white rounded-lg border border-red-700 hover:bg-red-100 hover:text-black-700 md:mb-0 mb-2"
                            >
                              <TrashIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>

                          {/* <p className="text-sm text-gray-500">{product.description}</p> */}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {currProfile?.is_verified === 1 && !isOnVehicleListing ? (
          <>
            <form onSubmit={VehicleAddForm.handleSubmit}>
              <div className=" px-4 py-5  sm:p-6">
                <div className="flex mb-4">
                  <Link to="/Locations">
                    <p className=" bg-red-200 text-base text-red-800 px-2.5 py-1 rounded-lg">
                      <strong className=" text-gray-900">Note:</strong> Make
                      sure you have first added locations before you add a
                      vehicle. To add a location{" "}
                      <span className="text-blue-600 underline">
                        Click here
                      </span>
                    </p>{" "}
                  </Link>
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-6 pt-4">
                  <div className="md:col-span-1">
                    <h3 className="text-xl font-semibold leading-6 text-gray-900">
                      Add Vehicle Info
                    </h3>
                  </div>

                  <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="make"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Make{" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <div className="mt-1  rounded-md shadow-sm flex ">
                          <input
                            type="text"
                            name="make"
                            id="make"
                            ref={firstErrorRef}
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.make}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            // placeholder="Make"
                          />
                        </div>
                        {formErrors.make && (
                          <div style={{ color: "red" }}>{formErrors.make}</div>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="model"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Model
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="model"
                            id="model"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.model}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="vehicle_type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Vehicle Type{" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            type="text"
                            name="vehicle_type"
                            id="vehicle_type"
                            onChange={(e) => {
                              VehicleAddForm.handleChange(e);
                              VehicleAddForm.setFieldValue("door_type", "");
                              VehicleAddForm.setFieldValue("unit_number", "");
                              VehicleAddForm.setFieldValue("body_type", "");
                            }}
                            value={VehicleAddForm.values.vehicle_type}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          >
                            <option value="">Select</option>
                            <option value="Tractor">Tractor</option>
                            <option value="Day Cab">Day Cab</option>
                            <option value="E-Van">E-Van</option>
                            <option value="Single Tandem">Single Tandem</option>
                            <option value="Tractor Sleeper">
                              Tractor Sleeper
                            </option>
                            <option value="Box Truck">Box Truck</option>
                            <option value="Van Pickup">Van Pickup</option>
                            <option value="Van Cargo">Van Cargo</option>
                            <option value="Truck">Truck</option>
                            <option value="Trailers">Trailers</option>
                            <option value="Refrigerated Box Truck">
                              Refrigerated Box Truck
                            </option>
                          </select>
                        </div>
                        {formErrors.vehicle_type && (
                          <div style={{ color: "red" }}>
                            {formErrors.vehicle_type}
                          </div>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="body_type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Trailer Type
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            type="text"
                            name="body_type"
                            id="body_type"
                            disabled={
                              VehicleAddForm.values.vehicle_type !==
                                "Trailers" && true
                            }
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.body_type}
                            className={`block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm ${
                              VehicleAddForm.values.vehicle_type !==
                                "Trailers" && "cursor-not-allowed"
                            }`}
                            placeholder=""
                          >
                            <option value="">Select</option>
                            <option value="Dry Van">Dry Van</option>
                            <option value="Reefer">Reefer</option>
                            <option value="Flatbed">Flatbed</option>
                            <option value="Step Deck">Step Deck</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="door_type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Door Type{" "}
                          {VehicleAddForm.values.vehicle_type === "Trailers" &&
                          VehicleAddForm.values.body_type !== "Flatbed" ? (
                            <span className="text-red-500 font-semibold">
                              *
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            type="text"
                            name="door_type"
                            id="door_type"
                            disabled={
                              VehicleAddForm.values.vehicle_type !==
                                "Trailers" ||
                              VehicleAddForm.values.body_type === "Flatbed"
                                ? true
                                : false
                            }
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.door_type}
                            className={`block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm ${
                              VehicleAddForm.values.vehicle_type !==
                                "Trailers" ||
                              VehicleAddForm.values.body_type === "Flatbed"
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            placeholder=""
                          >
                            <option value="">Select</option>
                            <option value="Roll Up">Roll Up</option>
                            <option value="Swing">Swing</option>
                          </select>
                        </div>
                        {formErrors.door_type && (
                              <div style={{ color: "red" }}>
                                {formErrors.door_type}
                              </div>
                            )
                          }
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="year"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Year{" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="number"
                            max="9999"
                            name="year"
                            id="year"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.year}
                            min="0"
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          />
                        </div>
                        {formErrors.year && (
                          <div style={{ color: "red" }}>
                            {formErrors.year}
                          </div>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="unit_number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ID Number/Unit Number{" "}
                          {VehicleAddForm.values.vehicle_type == "Trailers" && (
                            <span className="text-red-500 font-semibold">
                              *
                            </span>
                          )}
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="number"
                            name="unit_number"
                            id="unit_number"
                            disabled={
                              VehicleAddForm.values.vehicle_type !==
                                "Trailers" && true
                            }
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.unit_number}
                            min="0"
                            className={`block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm ${
                              VehicleAddForm.values.vehicle_type !==
                                "Trailers" && "cursor-not-allowed"
                            }`}
                          />
                        </div>
                        {formErrors.unit_number && (
                          <div style={{ color: "red" }}>
                            {formErrors.unit_number}
                          </div>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="plate_state"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plate State
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            type="text"
                            name="plate_state"
                            id="plate_state"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.plate_state}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          >
                            <option value="">Select</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">
                              North Carolina
                            </option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">
                              South Carolina
                            </option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="allowed_states"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Disallowed States
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Select
                            value={initialStates}
                            placeholder="Select"
                            onChange={(e) => handleChange(e)}
                            options={options}
                            isMultiple={true}
                            isClearable={true}
                            primaryColor={"purple"}
                            required
                            className="block w-full flex-1  rounded border-purple-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="plate_number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plate Number
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="plate_number"
                            id="plate_number"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.plate_number}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            // placeholder="Plate Number"
                          />
                        </div>
                      </div>
                      {/* <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="registeration_number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ID Number <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="registeration_number"
                            id="registeration_number"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.registeration_number}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          />
                        </div>
                        {VehicleAddForm.touched.registeration_number &&
                        VehicleAddForm.errors.registeration_number ? (
                          <div className="error text-red-500 text-sm">
                            {VehicleAddForm.errors.registeration_number}
                          </div>
                        ) : null}
                      </div> */}

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="vin"
                          className="block text-sm font-medium text-gray-700"
                        >
                          VIN{" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="vin"
                            id="vin"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.vin}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          />
                        </div>
                        {formErrors.vin && (
                          <div style={{ color: "red" }}>{formErrors.vin}</div>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <textarea
                            type="text"
                            name="description"
                            id="description"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.description}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="lift_gate"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Lift Gate
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            type="text"
                            name="lift_gate"
                            id="lift_gate"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.lift_gate}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          >
                            <option value="">Select</option>
                            <option value="No Lift Gate">No Lift Gate</option>
                            <option value="Rail Gate">Rail Gate</option>
                            <option value="Truck Under">Truck Under</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="rent_per_day"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rent per day ($){" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="number"
                            name="rent_per_day"
                            id="rent_per_day"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.rent_per_day}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                            placeholder=""
                          />
                        </div>
                        {formErrors.rent_per_day && (
                          <div style={{ color: "red" }}>
                            {formErrors.rent_per_day}
                          </div>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="min_rental_days_out"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Minimum Rental Days Out{" "}
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="number"
                            name="min_rental_days_out"
                            id="min_rental_days_out"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.min_rental_days_out}
                            min="0"
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="flex flex-col items-start">
                        <label
                          htmlFor="equipment_price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Equipment Value ($){" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                          <span className="text-xs font-semibold">Note: "Trailers are covered for stated value. Trucks/Tractors are covered for Actual Cash Value (ACV) meaning "market value".</span>
                          </div>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="number"
                            name="equipment_price"
                            id="equipment_price"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.equipment_price}
                            min="0"
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                        </div>
                        {formErrors.equipment_price && (
                          <div style={{ color: "red" }}>
                            {formErrors.equipment_price}
                          </div>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="usage_restrictions"
                          className="flex gap-1 text-sm font-medium text-gray-700"
                        >
                          <span> Usage Restriction</span>{" "}
                          <Tooltip data="Let potential renters know what they can and can't do with your vehicle." />
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <textarea
                            type="text"
                            name="usage_restrictions"
                            id="usage_restrictions"
                            onChange={VehicleAddForm.handleChange}
                            value={VehicleAddForm.values.usage_restrictions}
                            className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Fuel Type
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="fuel_type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fuel Type
                        </label>
                        <select
                          type="text"
                          name="fuel_type"
                          id="fuel_type"
                          onChange={VehicleAddForm.handleChange}
                          value={VehicleAddForm.values.fuel_type}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        >
                          <option value="">Select</option>
                          <option value="Diesel">Diesel</option>
                          <option value="Gasoline">Gasoline</option>
                          <option value="Natural Gas">Natural Gas</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="Electric">Electric</option>
                        </select>
                      </div>

                      {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="fuel_milage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fuel Milage <span>(mpg)</span>{" "}
                        
                      </label>
                      <input
                        type="text"
                        name="fuel_milage"
                        id="fuel_milage"
                        onChange={VehicleAddForm.handleChange}
                        value={VehicleAddForm.values.fuel_milage}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div> */}
                    </div>
                  </div>
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Location
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="pick_up"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pick Up/Drop Off{" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                        <select
                          type="text"
                          name="pick_up"
                          id="pick_up"
                          onChange={VehicleAddForm.handleChange}
                          value={VehicleAddForm.values.pick_up}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        >
                          <option value="">Select</option>
                          {totalLocations?.map((location, index) => {
                            return (
                              <option key={location.name} value={location.name}>
                                {location.location_name}
                              </option>
                            );
                          })}
                        </select>

                        {formErrors.pick_up && (
                          <div style={{ color: "red" }}>
                            {formErrors.pick_up}
                          </div>
                        )}
                      </div>
                      {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="drop_off"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Drop Off 
                      </label>
                      <select
                        type="text"
                        name="drop_off"
                        id="drop_off"
                        onChange={VehicleAddForm.handleChange}
                        value={VehicleAddForm.values.drop_off}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      >
                        <option value="">Select</option>
                        {totalLocations?.map((location, index) => {
                          return (
                            <option key={location.name} value={location.name}>
                              {location.location_name}
                            </option>
                          );
                        })}
                      </select>
                    </div> */}
                    </div>
                  </div>
                </div>

                {/* <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="md:col-span-2">
                  <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                    Add Any Extra Features
                  </h3>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  {totalFeatures?.map((features, index) => {
                    return (
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="featuresGroup"
                            id="featuresGroup"
                            onChange={VehicleAddForm.handleChange}
                            value={features.name}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="featuresGroup"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {features.feature_name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                
              </div> */}

                {/* <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="flex">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Health Check (Recomended DOT inspection)
                      <span className="text-sm bg-green-100 rounded-full px-3 py-0.5  text-green-800 ml-2">
                        Verify in last 12 months
                      </span>
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="health_inspection"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Health Inspection
                        </label>
                        <input
                          type="text"
                          name="health_inspection"
                          id="health_inspection"
                          onChange={VehicleAddForm.handleChange}
                          value={VehicleAddForm.values.health_inspection}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="file-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Health Inspection Document
                        </label>
                        <input
                          type="file"
                          name="file-input"
                          id="file-input"
                          onChange={(e) => {
                            uploadHealthInspectionHandle(e);
                          }}
                          className="block w-full border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Fault Code (Optional)
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="fault_code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fault Code
                        </label>
                        <input
                          type="text"
                          name="fault_code"
                          id="fault_code"
                          onChange={VehicleAddForm.handleChange}
                          value={VehicleAddForm.values.fault_code}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="fault_attach"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fault Code Document
                        </label>
                        <input
                          type="file"
                          name="fault_attach"
                          id="fault_attach"
                          onChange={(e) => {
                            uploadFaultCodeHandle(e);
                          }}
                          className="block w-full border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Vehicle Documents
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="vehicle_registration_expiration_date"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Registration Expiry Date
                        </label>
                        <input
                          type="date"
                          name="vehicle_registration_expiration_date"
                          id="vehicle_registration_expiration_date"
                          onChange={VehicleAddForm.handleChange}
                          value={
                            VehicleAddForm.values
                              .vehicle_registration_expiration_date
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="vehicle_registration_document"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Registration Document
                        </label>
                        <input
                          type="file"
                          name="vehicle_registration_document"
                          id="vehicle_registration_document"
                          onChange={(e) => {
                            uploadRegDocumentHandle(e);
                          }}
                          className="block w-full border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-600 file:text-white file:mr-4
file:py-3 file:px-4"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Vehicle Specifications (Select all that apply)
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="e_truck"
                            id="e_truck"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="e_truck"
                            className="block text-sm font-medium text-gray-700"
                          >
                            E-Truck
                          </label>
                        </div>
                      </div>

                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="ramp"
                            id="ramp"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="ramp"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ramp
                          </label>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="crew_cab"
                            id="crew_cab"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="crew_cab"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Crew Cab
                          </label>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="dock_height"
                            id="dock_height"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="dock_height"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Dock Height
                          </label>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="multi_temp"
                            id="multi_temp"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="multi_temp"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Multi - Temp
                          </label>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="apu"
                            id="apu"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="apu"
                            className="block text-sm font-medium text-gray-700"
                          >
                            APU
                          </label>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="storage_only"
                            id="storage_only"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="storage_only"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Storage Only
                          </label>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="has_ifta"
                            id="has_ifta"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="has_ifta"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Has IFTA
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Reservation Requirements
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="require_cdl"
                            id="require_cdl"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="require_cdl"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Required CDL
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="requires_dot"
                            id="requires_dot"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="requires_dot"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Requires DOT
                          </label>
                        </div>
                      </div>

                      <div className="col-span-3 sm:col-span-1">
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="checkbox"
                            name="can_travel"
                            id="can_travel"
                            onChange={VehicleAddForm.handleChange}
                            className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          />
                          <label
                            htmlFor="can_travel"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Can travel out of state
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Availability Status
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <label
                          htmlFor="availability_status"
                          className="flex gap-1 text-sm font-medium text-gray-700"
                        >
                          <span>Availability</span>{" "}
                          <Tooltip data="Unlisting your vehicle will remove it from the search results" />
                        </label>
                        <select
                          type="text"
                          name="availability_status"
                          id="availability_status"
                          onChange={VehicleAddForm.handleChange}
                          value={VehicleAddForm.values.availability_status}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                        >
                          <option value="">Select</option>
                          <option value="Available">Available</option>
                          <option value="Unavailable">Unavailable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Gallery Of Photos */}
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                      Upload Vehicle Photos
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="file-input-img"
                        className="flex flex-col justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-36  border-dotted"
                      >
                        <Upload className="h-8 w-8" />
                        <span>Upload 4 Photos of Vehicle</span>
                        <span>{"(Front, Back, Left, Right)"}</span>
                      </label>
                      <input
                        accept="image/*"
                        type="file"
                        name="file-input-img"
                        id="file-input-img"
                        multiple
                        onChange={handleImageChange}
                        className="block w-full border sr-only border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                      />
                    </div>

                    <section
                      aria-labelledby="profile-overview-title"
                      className="pt-4"
                    >
                      <div className="">
                        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                          {images?.map((image, index) => (
                            <div
                              key={index}
                              className="relative flex justify-center items-center"
                            >
                              <div className="flex justify-center  bg-purple-200 max-h-48 rounded-lg">
                                <img
                                  src={image}
                                  alt={`Image ${index + 1}`}
                                  className="max-h-64 rounded-md"
                                />
                              </div>
                              <button
                                type="button"
                                onClick={() => handleDeleteImage(index)}
                                className=" absolute -top-2 -right-3 flex justify-center items-center  h-8 w-8 rounded-full bg-red-600 "
                              >
                                <Trash
                                  className="h-6 w-6"
                                  style={{ color: "white" }}
                                />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="flex justify-end pt-5 pr-8">
                        {loading ? (
                          <>
                            <div className="rounded-md flex justify-center items-center gap-4 border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none ">
                              <LoadingIcons.BallTriangle className="h-6 w-6" />
                            </div>
                          </>
                        ) : (
                          <button
                            type="submit"
                            className="rounded-md border border-transparent  button py-2.5 px-6 text-md font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          >
                            Add Vehicle
                          </button>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          ""
        )}

        {currProfile?.is_verified === 0 && !isOnVehicleListing ? (
          <>
            {loader ? (
              <LoaderSpinner />
            ) : (
              <div className="flex flex-col items-center justify-center text-center my-64">
                <div className="flex flex-col items-center justify-center">
                  <h2 className="text-3xl font-semibold ">
                    Verify Your Identity
                  </h2>
                  <p className="text-xl my-4 font-medium">
                    You have to verify your Business Profile
                    Identification/Verification with Stripe before Adding the
                    Vehicle.
                  </p>
                  <button
                    className="bg-purple-500 w-1/2 hover:bg-purple-600 px-3 py-2 text-white rounded-xl text-xl shadow-sm"
                    role="link"
                    disabled={!stripe}
                    onClick={handleVerification}
                  >
                    Click here to start verification
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <Trash
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete Vehicle
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure to delete this vehicle.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          setOpen(false);
                          deleteSelectedVehicle();
                        }}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default Vehicles;
