import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useCompanyProfile } from '../../../Context/CompanyProfile';
import LoadingIcons from 'react-loading-icons';
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const ReturnStripe = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
    const CompanyProfile = useCompanyProfile()
    useEffect(() => {
        const getReturnStatus = async () => {
            var json={
                stripe_account:CompanyProfile?.stripe_account
            }
          try {
            // setLoading(true);
            await axios
              .post(
                `${window.$backendURL}/api/method/truckrent.api.return_status_check`,
                json
              )
              .then((response) => {
                if (response.data.data==false) {
                    toast.error("Account not connected")
                }else{
                  if(localStorage.role == "Owner Business"){
                    navigate("/setting")
                  }
                  else{
                    localStorage.setItem("role", "Owner Business")
                    navigate("/setting")
                  }
                }
                console.log(response.data.data);
                setLoading(false);
              });
          } catch (e) {
            console.error(e);
          }
        };
    
        getReturnStatus();
      }, [CompanyProfile]);

      //   Create Express Account
const handleReturnFalseCase = async () => {
    var json = {
        stripe_account_id: CompanyProfile?.stripe_account,
    };
    
    try {
      setLoadingCreateAccount(true);
      await axios
        .post(`${window.$backendURL}/api/method/truckrent.api.handle_return_url_stripe`, json)
        .then((response) => {
          // console.log(response.data.data);
          window.open(response.data.data, "_blank");
          setLoadingCreateAccount(false);
        });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className='h-screen'>
    <Toaster richColors/>
        {loading ? <div className='flex justify-center items-center h-full'> <LoadingIcons.TailSpin className=" rounded-lg h-32 w-32 bg-purple-500 px-4 py-8" /> </div>:<>
        <div className="flex flex-col gap-4 h-screen justify-center">
        <div>

           <h1 className="text-xl flex justify-center items-center font-boldr">
                Stripe Account Not Connected Properly
           </h1>
        </div>
        <div className="flex justify-center items-center">
        {loadingCreateAccount ? (
              <button
                disabled
                className=" rounded-md cursor-not-allowed border border-transparent bg-gray-600 py-2 px-8 text-lg font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
              >
                <LoadingIcons.TailSpin className="h-8 w-8" />
              </button>
            ) : (
                <button onClick={handleReturnFalseCase} className="rounded-md border border-transparent button py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">Complete Stripe Connect OnBoarding</button>

            )}
        </div>
        </div>
        </>}
    </div>
  )
}

export default ReturnStripe