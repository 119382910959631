import React from "react";

const HomePageCardsSkeleton = () => {
  return (
    <div className="w-full flex flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white">
      <div className="aspect-w-3 aspect-h-3 bg-gray-200 sm:aspect-none h-64 animate-pulse"></div>
      <div className="flex flex-1 flex-col justify-between space-y-2 p-4">
        <div className="animate-pulse h-6 w-1/2 bg-gray-200 rounded"></div>
        <div className="animate-pulse h-4 w-1/4 bg-gray-200 rounded"></div>
        <div className="animate-pulse h-4 w-1/2 bg-gray-200 rounded"></div>
        <div className="animate-pulse h-4 w-1/4 bg-gray-200 rounded"></div>
        <div className="animate-pulse h-4 w-1/2 bg-gray-200 rounded"></div>
        <div className="animate-pulse h-4 w-1/4 bg-gray-200 rounded"></div>
        <div className="animate-pulse h-8 w-full bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};

export default HomePageCardsSkeleton;
