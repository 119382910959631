import axios from "axios";
import * as qs from "qs";
import jwt_decode from "jwt-decode";
import { reset } from "./authSlice";
import { useEffect } from "react";

export async function setLocalStorage() {
  if (localStorage.user) {
    var idToken = JSON.parse(localStorage.user);
    var toDecode = idToken.id_token;
    var Decoded = jwt_decode(toDecode);
    // console.log(Decoded);
    localStorage.setItem("user_email", Decoded.email);
    localStorage.setItem(
      "user_name",
      Decoded.given_name + " " + Decoded.family_name
    );
    localStorage.setItem("picture", Decoded.picture);
    // localStorage.setItem("role", "Renter");
  }
  if (localStorage.role) {
  } else {
    localStorage.setItem("role", "Owner Business");
  }
}

export async function reFreshToken() {
  var rTokenParse = JSON.parse(localStorage.user);
  var rToken = rTokenParse.refresh_token;
  try {
    const response = await axios.post(
      `${window.$backendURL}/api/method/frappe.integrations.oauth2.get_token`,
      qs.stringify({
        grant_type: "refresh_token",
        refresh_token: rToken,
        client_id: window.$ClientID,
        scope: "all openid",
      })
    );
    console.log("Refresh>>", response);

    if (response.status === 200) {
      var timeIn = Date.now();
      var userAfterRefresh = response.data;
      var fetchIdTokenBeforeParse = localStorage.user;
      var fetchIdTokenAfterParse = JSON.parse(fetchIdTokenBeforeParse);
      var fetchIdToken = fetchIdTokenAfterParse.id_token;
      userAfterRefresh.id_token = fetchIdToken;
      localStorage.setItem("user", JSON.stringify(userAfterRefresh));
      localStorage.setItem("date", timeIn);
      // dispatch(login(JSON.stringify(userAfterRefresh)));
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("date");
      reset();
    }
  } catch (err) {
    console.log(err);
  }

}

