import React from 'react'
import { useState } from "react";

import {
  TruckIcon,
  HomeIcon,
  CogIcon,
  MapPinIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";


import { Link } from 'react-router-dom';

const SidebarOwner = () => {
    // const [isOnDashboard, setIsOnDashboard] = useState(false);
    // const [isOnVehicle, setIsOnVehicle] = useState(false);
    // const [isOnLocations, setIsOnLocations] = useState(false);
    // const [isOnReservations, setIsOnReservations] = useState(false);


    const href  = [
    //   {
    //   Name: "Dashboard",
    //   to: "/Dashboard",
    //   Icon: HomeIcon,
    // },
    {
      Name: "Vehicles",
      to: "/VehicleListing",
      Icon: TruckIcon,
    },
    {
      Name: "Reservations",
      to: "/reservations_all",
      Icon: CalendarDaysIcon,
    },
    {
      Name: "Locations",
      to: "/Locations",
      Icon: MapPinIcon,
    },
  ]
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
  return (
    <div className="flex flex-col justify-between h-full">
                      <nav className="space-y-1 px-2">
                      {href.map((item, index) => (<Link key={item.Name}
                          // onClick={() => {
                          //   setIsOnDashboard(true);
                          //   setIsOnLocations(false);
                          //   setIsOnVehicle(false);
                          //   setIsOnReservations(false)
                          // }}
                          to={item.to}
                        >
                          <button
                            className={classNames(
                             window.location.pathname.includes(item.to)
                                ? "nav-button-active-color border-purple-500 w-full nav-button-active-text-color"
                                : "text-gray-600 hover:bg-purple-50 w-full border-transparent hover:text-purple-800",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md border-l-4"
                            )}
                          >
                            <item.Icon
                              className={classNames(
                                window.location.pathname.includes(item.to)
                                  ? "nav-button-active-text-color"
                                  : "text-gray-400 group-hover:text-purple-800",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.Name}
                          </button>
                        </Link>))}
                        {/* <Link
                          onClick={() => {
                            setIsOnDashboard(false);
                            setIsOnLocations(false);
                            setIsOnVehicle(true);
                            setIsOnReservations(false)
                          }}
                          to="/VehicleListing"
                        >
                          <button
                            className={classNames(
                              isOnVehicle || window.location.pathname.includes("/VehicleListing")
                              ? "nav-button-active-color border-purple-500 w-full nav-button-active-text-color"
                                : "text-gray-600 hover:bg-purple-50 w-full border-transparent hover:text-purple-800",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md border-l-4 mt-1" 
                            )}
                          >
                            <TruckIcon
                              className={classNames(
                                isOnVehicle || window.location.pathname.includes("/VechileListing")
                                  ? "nav-button-active-text-color"
                                  : "text-gray-400 group-hover:text-purple-800",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            Vehicles
                          </button>
                        </Link>
                        <Link
                          onClick={() => {
                            setIsOnDashboard(false);
                            setIsOnLocations(false);
                            setIsOnVehicle(false);
                            setIsOnReservations(true)
                          }}
                          to="/reservations_all"
                        >
                          <button
                            className={classNames(
                              isOnReservations || window.location.pathname.includes("/reservations_all")
                              ? "nav-button-active-color border-purple-500 w-full nav-button-active-text-color"
                                : "text-gray-600 hover:bg-purple-50 w-full border-transparent hover:text-purple-800",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md border-l-4 mt-1" 
                            )}
                          >
                            <CalendarDaysIcon
                              className={classNames(
                                isOnReservations || window.location.pathname.includes("/reservations_all")
                                  ? "nav-button-active-text-color"
                                  : "text-gray-400 group-hover:text-purple-800",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            Reservations
                          </button>
                        </Link>
                        
                        <Link
                          onClick={() => {
                            setIsOnDashboard(false);
                            setIsOnLocations(true);
                            setIsOnVehicle(false);
                            setIsOnReservations(false)
                          }}
                          to="/Locations"
                        >
                          <button
                            className={classNames(
                              isOnLocations || window.location.pathname.includes("/Locations")
                              ? "nav-button-active-color border-purple-500 w-full nav-button-active-text-color"
                                : "text-gray-600 hover:bg-purple-50 w-full border-transparent hover:text-purple-800",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md border-l-4 mt-1" 
                            )}
                          >
                            <MapPinIcon
                              className={classNames(
                                isOnLocations || window.location.pathname.includes("/Locations")
                                  ? "nav-button-active-text-color"
                                  : "text-gray-400 group-hover:text-purple-800",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            Locations
                          </button>
                        </Link> */}
                      </nav>
                      
                    </div>
  )
}

export default SidebarOwner