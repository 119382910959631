import React from 'react'
import Cover from "../../img/signinCover.png";
import "./ForgotPass.css";

const ForgotPass = () => {
  return (
    <>
      
      <div className="relative flex min-h-full">
        <div
          className="flex flex-1 min-h-screen flex-col justify-center py-12 px-4 sm:px-6  lg:px-20 xl:px-24"
        >
          <div className="mx-auto form w-full max-w-sm lg:w-96 rounded-lg">
            <div className="px-10 py-10">
              <div>
                {/* <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              /> */}
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                  Forgot Password
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Enter your email below, you will receive an email with rest link.
                </p>
              </div>

              <div className="mt-8">
                

                <div className="mt-6">
                  <form action="#" method="POST" className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-purple-700 sm:text-sm"
                        />
                      </div>
                    </div>

                    

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={Cover}
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default ForgotPass