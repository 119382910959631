import React, { useEffect, useRef, useState } from "react";
import { db } from "../../Global/Firebase/firebase";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import {
  addDoc,
  doc,
  collection,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  getDocs,
  getDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { query, onSnapshot, orderBy, limit } from "firebase/firestore";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { toast } from "sonner";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import imagePlaceholdeer from "../../../img/user.png";
import axios from "axios";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const OwnerChat = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const { reservationID } = useParams();
  const [isShown, setIsShown] = useState(false);
  const [message, setMessage] = useState([]);
  const [userID, setUserID] = useState("");
  const [value, setValue] = useState("");
  const parentDocRef = doc(db, "RentMyTruck", `${reservationID}`);
  const subcollectionRef = collection(parentDocRef, "Message");
  const toScroll = useRef(null);
  const [liveLocation, setLiveLocation] = useState(null);
  const [openfileUpload, setOpenFileUpload] = useState(false);
  const [file, setFile] = useState("");
  const [longitude, setLongitude] = useState(null)
  const [latitude, setLatitude] = useState(null)

  useEffect(() => {
    try {
      axios.get(`${window.$backendURL}/api/method/reservation-contract-details?id=${reservationID}`, config).then((res) =>{
        console.log(res?.data?.data);
        const [latitude, longitude] = res?.data?.data?.location?.coordinates.split(',');
        setLongitude(longitude)
        setLatitude(latitude)
      })
    } catch (error) {
      console.log(error);
    }
  },[])

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const collectionExists = await checkCollectionExistence(reservationID);
        const collectionExists2 = await checkCollectionExistence2(
          reservationID
        );
        if (collectionExists) {
          // Collection exists, perform update
          await updateDoc(doc(collection(db, "OwnerLastSeen"), reservationID), {
            last_seen: serverTimestamp(),
          });
          if (!collectionExists2) {
            await setDoc(doc(collection(db, "RenterLastSeen"), reservationID), {
              last_seen: serverTimestamp(),
            });
          }
        } else {
          // Collection does not exist, perform create
          await setDoc(doc(collection(db, "OwnerLastSeen"), reservationID), {
            last_seen: serverTimestamp(),
          });
          if (!collectionExists2) {
            await setDoc(doc(collection(db, "RenterLastSeen"), reservationID), {
              last_seen: serverTimestamp(),
            });
          }
        }
      } catch (error) {
        console.error("Error updating/creating collection:", error);
      }
    };
    fetchRequest();
  }, []);

  const checkCollectionExistence = async () => {
    const eventDocRef = doc(collection(db, "OwnerLastSeen"), reservationID);
    const docSnapshot = await getDoc(eventDocRef);
    return docSnapshot.exists();
  };

  const checkCollectionExistence2 = async () => {
    const eventDocRef = doc(collection(db, "RenterLastSeen"), reservationID);
    const docSnapshot = await getDoc(eventDocRef);
    return docSnapshot.exists();
  };

  const handleEmoji = () => {
    setIsShown((current) => !current);
  };

  const scrollToBottom = () => {
    document.getElementById("toscroll")?.scrollTo({
      top: document.getElementById("toscroll").scrollHeight,
      behavior: "smooth",
    });
  };

  const onClick = (emojiData) => {
    setValue(value + emojiData.emoji);
    console.log(reservationID);
  };
  const handleInput = (e) => {
    setValue(e.target.value);
    console.log(value);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (value?.trim() === "") {
      toast.error("Enter valid message!");
      return;
    }
    try {
      await addDoc(subcollectionRef, {
        id: localStorage.getItem("userID"),
        content: value,
        dateTime: serverTimestamp(),
        name: localStorage.getItem("user_name"),
        picture: localStorage.getItem("picture"),
        is_file: false,
        is_location: false
      });
      scrollToBottom();
      setValue("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeySendMessage = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (value?.trim() === "") {
        toast.error("Enter valid message!");
        return;
      }

      try {
        await addDoc(subcollectionRef, {
          id: localStorage.getItem("userID"),
          content: value,
          dateTime: serverTimestamp(),
          name: localStorage.getItem("user_name"),
          picture: localStorage.getItem("picture"),
          is_file: false,
        is_location: false
        });
        scrollToBottom();
        setValue("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  useEffect(() => {
    setUserID(localStorage.getItem("userID"));
    const q = query(
      collection(db, `RentMyTruck/${reservationID}/Message`),
      orderBy("dateTime")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
      });
      console.log("message", messages);
      setMessage(messages);
    });

    return () => unsubscribe;
  }, []);

  console.log(message);

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            console.log(response);
            setOpenFileUpload(true);
            setFile(response?.data?.message);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleSendFile = async () => {
    try {
      await addDoc(subcollectionRef, {
        id: localStorage.getItem("userID"),
        dateTime: serverTimestamp(),
        name: localStorage.getItem("user_name"),
        picture: localStorage.getItem("picture"),
        content: file?.file_url,
        is_file: true,
        is_location: false
      });
      scrollToBottom();
      setValue("");
      setOpenFileUpload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendLiveLocation = async () => {
    try {
      

      const googleMapsUrl = `${latitude},${longitude}`;

      // Send the live location message
      await addDoc(subcollectionRef, {
        id: localStorage.getItem("userID"),
        dateTime: serverTimestamp(),
        name: localStorage.getItem("user_name"),
        picture: localStorage.getItem("picture"),
        content: googleMapsUrl,
        is_file: false,
        is_location: true
      });

      // Scroll to the bottom of the chat
      scrollToBottom();

      // Clear the input field and live location state
      setValue("");
      setLiveLocation(null);
    } catch (error) {
      console.error("Error getting user's location:", error);
      toast.error("Failed to get user's location.");
    }
  };

  return (
    <>
      <div className="text-2xl font-bold ml-6 mb-2">
        <h2>Chat</h2>
      </div>
      <div className="flex h-[82vh] antialiased text-gray-800">
        <div className="flex mx-auto flex-row h-[80vh] w-[100%] overflow-x-hidden">
          {/* <div className="flex flex-col py-3 px-3 w-64 bg-white flex-shrink-0 rounded-lg">
            <div className="flex flex-row items-center justify-center h-12 w-full">
          <div
            className="flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              ></path>
            </svg>
          </div>
          <div className="ml-2 font-bold text-2xl">QuickChat</div>
        </div>
            <div className="flex flex-col items-center bg-purple-100 border border-gray-200  w-full py-6 px-4 rounded-lg">
              <div className="h-20 w-20 rounded-full border overflow-hidden">
                <img
                  src="https://avatars3.githubusercontent.com/u/2763884?s=128"
                  alt="Avatar"
                  className="h-full w-full"
                />
              </div>
              <div className="text-sm font-semibold mt-2">Aminos Co.</div>
              <div className="text-xs text-gray-500">Lead UI/UX Designer</div>
              <div className="flex flex-row items-center mt-3">
            <div
              className="flex flex-col justify-center h-4 w-8 bg-indigo-500 rounded-full"
            >
              <div className="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
            </div>
            <div className="leading-none ml-1 text-xs">Active</div>
          </div>
            </div>
            <div className="flex flex-col mt-8">
              <div className="flex flex-row items-center justify-between text-xs">
                <span className="font-bold">Active Conversations</span>
                <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">
                  4
                </span>
              </div>
              <div className="flex flex-col space-y-1 mt-4 -mx-2 h-48 overflow-y-auto">
                <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                  <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                    H
                  </div>
                  <div className="ml-2 text-sm font-semibold">Henry Boyd</div>
                </button>
                <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                  <div className="flex items-center justify-center h-8 w-8 bg-gray-200 rounded-full">
                    M
                  </div>
                  <div className="ml-2 text-sm font-semibold">Marta Curtis</div>
                  <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                    2
                  </div>
                </button>
                <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                  <div className="flex items-center justify-center h-8 w-8 bg-orange-200 rounded-full">
                    P
                  </div>
                  <div className="ml-2 text-sm font-semibold">
                    Philip Tucker
                  </div>
                </button>
                <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                  <div className="flex items-center justify-center h-8 w-8 bg-pink-200 rounded-full">
                    C
                  </div>
                  <div className="ml-2 text-sm font-semibold">
                    Christine Reid
                  </div>
                </button>
                <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                  <div className="flex items-center justify-center h-8 w-8 bg-purple-200 rounded-full">
                    J
                  </div>
                  <div className="ml-2 text-sm font-semibold">Jerry Guzman</div>
                </button>
              </div>
              <div className="flex flex-row items-center justify-between text-xs mt-6">
                <span className="font-bold">Archivied</span>
                <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">
                  7
                </span>
              </div>
              <div className="flex flex-col space-y-1 mt-4 -mx-2">
                <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                  <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                    H
                  </div>
                  <div className="ml-2 text-sm font-semibold">Henry Boyd</div>
                </button>
              </div>
            </div>
          </div> */}
          {/* Chat Start */}
          <div className="flex flex-col flex-auto">
            <div
              className="h-full p-6 overflow-y-scroll bg-gray-200 mx-5 rounded-xl"
              ref={toScroll}
              id="toscroll"
            >
              {message?.map((msg, index) => (
                <div
                  className={
                    msg?.id == userID ? "chat chat-end" : "chat chat-start"
                  }
                >
                  <div className="chat-image avatar">
                    <div className="w-10 rounded-full">
                      <img
                        src={
                          msg?.picture !== "null"
                            ? msg?.picture
                            : imagePlaceholdeer
                        }
                      />
                    </div>
                  </div>
                  <div className="chat-header">{msg?.name}</div>
                  <div
                    className={
                      msg?.id === userID ? "chat-bubble c-color" : "chat-bubble"
                    }
                  >
                    {!msg.is_file && !msg.is_location ? (
                      msg?.content
                    ) : msg.is_file ? (
                      <img
                        src={window.$backendURL + msg?.content}
                        alt="image"
                        className="h-40"
                      />
                    ) : (
                      <div>
                        <iframe
                            className="w-full rounded-lg h-60 sm:h-96"
                            marginheight="0"
                            marginwidth="0"
                            src={`https://maps.google.com/maps?q=${msg.content}&hl=en&z=14&amp&output=embed`}
                          ></iframe>
                      </div>
                    )}
                  </div>
                  <div className="chat-footer opacity-50">
                    {moment.unix(msg?.dateTime?.seconds).format("LT") ==
                    "Invalid date"
                      ? "Just Now"
                      : moment.unix(msg?.dateTime?.seconds).format("LT")}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center h-16 mt-3  rounded-xl bg-white w-[97.5%] ml-[1.3rem] pl-4">
              <div className="flex items-center gap-x-2">
                <div class="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    class="flex items-center justify-center text-gray-400 hover:text-gray-600 cursor-pointer"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      ></path>
                    </svg>

                    <input
                      id="dropzone-file"
                      type="file"
                      class="hidden"
                      accept="image/*"
                      onChange={handleUploadFile}
                    />
                  </label>
                </div>
                <span
                  className="cursor-pointer"
                  onClick={handleSendLiveLocation}
                >
                  <MapPinIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                </span>
                {/* <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    ></path>
                  </svg>
                </button> */}
              </div>
              <div className="flex-grow ml-2 mr-2">
                <div className="relative w-full">
                  {isShown && (
                    <div className="emoji-picker">
                      <EmojiPicker
                        onEmojiClick={onClick}
                        autoFocusSearch={false}
                        defaultSkinTone={SkinTones.MEDIUM}
                        emojiStyle={EmojiStyle.apple}
                        categories={[
                          {
                            name: "Smiles & Emotions",
                            category: Categories.SMILEYS_PEOPLE,
                          },
                          {
                            name: "Fun and Games",
                            category: Categories.ACTIVITIES,
                          },
                          {
                            name: "Flags",
                            category: Categories.FLAGS,
                          },
                          {
                            name: "Yum Yum",
                            category: Categories.FOOD_DRINK,
                          },
                        ]}
                      />
                    </div>
                  )}
                  <input
                    type="text"
                    onChange={handleInput}
                    onKeyDown={(e) => handleKeySendMessage(e)}
                    value={value}
                    className="flex w-full border rounded-xl border-purple-500 focus:outline-none focus:border-purple-400 pl-4 h-10"
                  />
                  <button
                    className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600"
                    onClick={handleEmoji}
                  >
                    <span className="">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
              <div className="mr-2">
                <button
                  className="flex items-center justify-center bg-purple-500 hover:bg-purple-600 rounded-full text-white px-2 py-2 flex-shrink-0"
                  onClick={handleSendMessage}
                >
                  <span className="">
                    <svg
                      className="w-6 h-6 transform rotate-45 -mt-px"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={openfileUpload} as={Fragment}>
        <Dialog className="relative z-10" onClose={setOpenFileUpload}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <h1 className="text-xl font-medium pb-2">File</h1>
                    <span className="bg-indigo-100 text-indigo-700 rounded-full px-2 py-1 text-sm">
                      {file?.file_name}
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2 mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-indigo-600 px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpenFileUpload(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => handleSendFile()}
                    >
                      Send
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default OwnerChat;
