import React, { useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import BarSkeleton from "../../Global/Skeleton/BarSkeleton";
import { setLocalStorage } from "../../../auth/authCheck";

const LocationList = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const [locations, setLocations] = useState([]);
  const [locationsFiltered, setLocationsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    // Call your function with the updated value here
    const filteredResults = locations?.filter((item) =>
      item?.formatted_address?.toLowerCase().includes(newValue.toLowerCase())
    );
    setLocationsFiltered(filteredResults);
    console.log(filteredResults);
  };

 

  useEffect(() => {
    const GetLocations = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/resource/Location?fields=["*"]&filters=[["user","=","${userEmail}"]]`,
            config
          )
          .then((response) => {
            console.log(response?.data?.data);
            setLocations(response.data.data);
            setLocationsFiltered(response.data.data);
            setLoading(false);
          });
      } catch (e) {
        console.error(e);
      }
    };
    GetLocations();
  }, []);

  return (
    <div className="h-screen flex flex-col">
    <div className="flex justify-between mb-4 mx-auto w-full">
      <h1 className="text-2xl font-semibold text-gray-900">Locations</h1>
      <Link to="/AddLocation">
        <button className="flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
          <div>
            <PlusIcon className="w-5 text-white h-5" />
          </div>
  
          <div>Add Location</div>
        </button>
      </Link>
    </div>
    <div className="flex-grow overflow-y-auto">
      <div className="overflow-hidden sm:rounded-md ">
        <div className="mt-4">
          {locations?.length === 0 && (
            <span className="text-gray-600">Please click on the "Add Location" button to add your first location</span>
          )}
          {loading ? (
            <BarSkeleton />
          ) : (
            locations?.map((application) => (
              <div key={application.name}>
                <div className="block hover:bg-gray-100 mb-4 bg-white rounded-xl ">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="min-w-0 flex-1  md:grid md:grid-cols-2 md:gap-4 ">
                        <div>
                          <p className="truncate text-base sm:text-lg font-semibold text-gray-600">
                            {application?.location_name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <MapPinIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="truncate">
                              {application?.address}
                            </span>
                          </p>
                        </div>
                        <div className="flex items-center mt-4 md:mt-0">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-1">
                              <PhoneIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <p className="text-sm text-gray-900">
                                {application.phone_no}
                              </p>
                            </div>
                            {/* <p className="inline-flex rounded-full bg-green-100 px-4 text-xs font-semibold leading-5 text-green-800 items-center">
                              {application.status}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link to={"/EditLocation/" + application.name}>
                      <button className="ml-4 flex gap-1 justify-between rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
                        <PencilIcon className="w-5" /> Edit
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default LocationList;
