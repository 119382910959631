import React, { useEffect, useState } from "react";
import Cover from "../../img/truck renter image copy 11.png";
import "../Signin/Signin.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as qs from "qs";
import axios from "axios";
import { login } from "../../auth/authSlice";
import App from "../../App";
import jwt_decode from "jwt-decode";
import { setLocalStorage } from "../../auth/authCheck";

const Login = () => {
  const nevigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  var domainLink = window.location.href;
  const firstSplit = domainLink?.split("code=");
  const secondSplit = firstSplit[1]?.split("&");
  const token = secondSplit[0];

  useEffect(() => {
    isLoggedin();
  }, []);

  async function isLoggedin() {
    const response = await axios
      .post(
        `${window.$backendURL}/api/method/frappe.integrations.oauth2.get_token`,
        qs.stringify({
          grant_type: "authorization_code",
          code: token,
          redirect_uri: window.$FWDurl + "/login",
          client_id: window.$ClientID,
          scope: "all openid",
        })
      )
      .then((res) => {
        console.log(res?.data);
        var timeIn = Date.now();
        localStorage.setItem("date", timeIn);
        
        dispatch(login(JSON.stringify(res?.data)));

        if (localStorage.user) {
          setLocalStorage();
          nevigate("/Locations");
        }
        
      });
  }

  return (
    <>
      <div className="relative flex min-h-full">
        <div className="flex flex-2 min-h-screen flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto bg-gray-100 w-full max-w-sm lg:w-96 rounded-lg">
            <div className="px-10 py-10">
              <div>
                <div className="mb-3 flex items-center justify-center">
                  <img
                    src="https://rentmytruck.co/wp-content/uploads/2023/01/WhatsApp-Image-2023-01-10-at-9.12.35-PM1-copy.png"
                    alt="Logo"
                    className="w-3/4 "
                  />
                </div>
                <div className=" flex justify-center items-center">
                  <div className="animate-spin rounded-full h-16 w-16 border-r-violet-900 border-4 border-purple-100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden flex-1 lg:block">
          <img className="h-full w-full " src={Cover} alt="" />
        </div>
      </div>
    </>
  );
};

export default Login;
