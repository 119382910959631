import React, { useEffect, useState } from "react";
import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
  TruckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { PencilOutline, Upload } from "heroicons-react";
import LoadingIcons from "react-loading-icons";
import { db } from "../../Global/Firebase/firebase";
import {
  addDoc,
  doc,
  collection,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  getDocs,
  getDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { query, onSnapshot, orderBy, limit } from "firebase/firestore";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { StarIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { DateRange } from "react-date-range";
import TextField from "@mui/material/TextField";

import axios, { all } from "axios";
import { json, Link, useParams } from "react-router-dom";
import Tooltip from "../../Global/SmallComponents/Tooltip";
import moment from "moment";
import { toast, Toaster } from "sonner";
import Carousel from "../../Global/SmallComponents/OwnerCarousel";
import CCarousel from "../../Global/SmallComponents/OwnerCarousel";
import ReservationDetailPageSkeleton from "../../Global/Skeleton/ReservationDetailPageSkeleton";
import { Dialog, Menu, Tab, Transition } from "@headlessui/react";
import OwnerCarousel from "../../Global/SmallComponents/OwnerCarousel";
import RenterCarousel from "../../Global/SmallComponents/RenterCarousel";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Accordion from "./PickupInspectionAccordian";
import DropoffInsepectionAccordian from "./DropoffInsepectionAccordian";
import PickupInspectionAccordian from "./PickupInspectionAccordian";
import ReservationImages from "./ReservationPickupImages";
import ReservationPickupImages from "./ReservationPickupImages";
import ReservationDropOffImages from "./ReservationDropOffImages";
const noimgfound = [
  {
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
    caption: "No Image Uploaded",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ReservationDetails = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { reservationID } = useParams();
  const [allReservation, setAllReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalRent, setTotalrent] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [reviewStatusUpdated, setReviewStatusUpdated] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [totalPrePaidMileage, setTotalPrePaidMileage] = useState(0);
  const [tax, setTax] = useState(0);
  const [serivceFee, setServiceFee] = useState(0);
  const [roadSide, setRoadSide] = useState(0);
  const [total, setTotal] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [loaderForButtonPdf, setLoaderForButtonPDF] = useState(false);
  const [cancelReservationModal, setCancelReservationModal] = useState(false);
  const [totalAfterCancellationFee, setTotalAfterCancellationFee] = useState(0);
  const [coi, setCoi] = useState(false);
  const [coiFileURL, setCOIFileURL] = useState(null);
  // const [globalStatus, setGlobalStatus] = useState(null);
  const [loaderForButton, setLoaderForButton] = useState(false);
  const [reservationTimeline, setReservationTimeline] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reservationImages, setReservationImages] = useState([]);
  const [coiName, setCOIName] = useState("");
  const [coiProviderEmail, setCOIProviderEmail] = useState("");
  const [coiNumber, setCOINumber] = useState("");
  const [coiPhoneNo, setCOIPhoneNo] = useState(null);
  const [ownerNotes, setOwnerNotes] = useState("");
  const [renterPickupConfirms, setRenterPickupConfirms] = useState(null);
  const [renterDropoffConfirms, setRenterDropoffConfirms] = useState(null);
  const [renterVinylSticker, setRenterVinylSticker] = useState(null);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(null);

  const [coiEditForm, setCoiEditForm] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  console.log(state);
  useEffect(() => {
    const getReservations = async () => {
      setLoading(true);
      try {
        await axios
          .get(
            `${window.$backendURL}/api/method/reservation-contract-details?id=${reservationID}`,
            config
          )
          .then((response) => {
            console.log(response.data?.data);
            setAllReservation(response.data?.data);
            setLat(response.data.data.location.coordinates?.split(",")[0]);
            setLong(response.data.data.location.coordinates?.split(",")[1]);
            setLoading(false);
            setReview(response.data.data.reservation.renter_reviews);
            setRating(response.data.data.reservation.renter_rating * 5);
            console.log(
              response.data?.data?.reservation?.renter_pickup_confirm
            );
            setRenterPickupConfirms(
              response.data?.data?.reservation?.renter_pickup_confirm == 1
                ? true
                : false
            );
            setRenterDropoffConfirms(
              response.data?.data?.reservation?.renter_dropoff_confirm == 1
                ? true
                : false
            );
            setRenterVinylSticker(
              response.data?.data?.reservation?.renter_vinyl_sticker_visibile ==
                1
                ? true
                : false
            );
            console.log(renterPickupConfirms);

            setCOIProviderEmail(
              response.data.data.reservation?.coi_provider_email_address
            );
            setCOIName(response.data.data.reservation?.coi_company_name);
            setCOINumber(response.data.data.reservation?.coi_policy_number);
            setCOIPhoneNo(response.data.data.reservation?.coi_company_number);

            // setOwnerNotes(response.data?.data?.reservation?.notes_from_owner);
            // setTimeout(() => {
            // }, 1000);
            // // console.log(">>>>",response.data.data[0]);

            // // setGlobalStatus(response.data.data.reservation.global_status)
            // var Difference_In_Time =
            //   new Date(
            //     response.data.data?.reservation?.reservation_end_date
            //   ).getTime() -
            //   new Date(
            //     response.data.data?.reservation?.reservation_start_date
            //   ).getTime();
            // var Difference_In_Days =
            //   Difference_In_Time / (1000 * 3600 * 24) + 1;
            // setTotalDays();
            // setTotalrent(
            //   Math.floor(Difference_In_Days) *
            //     response.data.data?.reservation?.price_break_down_renter[0]
            //       ?.rent_per_day
            // );

            // setTotalPrePaidMileage(
            //   Math.floor(
            //     response.data.data?.reservation?.price_break_down_renter[0]
            //       ?.per_day_miles_allowed *
            //       0.3 *
            //       response.data.data?.reservation?.price_break_down_renter[0]
            //         ?.allowed_cost_per_mile
            //   )
            // );
            // setServiceFee(totalRent * 0.125);
            // setProcessingFee(totalRent * 0.03);
            // setTotalFee(serivceFee + processingFee);
            // setDeposit(totalRent * 0.3);
            // // setTax(500);
            // // setRoadSide(500);

            // // setServiceFee(Math.floor((totalPrePaidMileage + totalRent) * 0.125))

            // setTotal(totalRent + totalFee + totalPrePaidMileage + deposit);
            // setTotalAfterCancellationFee(total * 0.05);
            // //service fee is still not add
            // //set reservation images
            // const images = [];

            const combinedArray = [
              ...response.data.data.reservation.renter_pickup_pictures,
              ...response.data.data.reservation.renter_dropoff_images,
              ...response.data.data.reservation.owner_pickup_pictures,
              ...response.data.data.reservation.owner_dropoff_attach,
            ];
            console.log("combinedArray", combinedArray);
            setReservationImages(combinedArray);

            // // const arr2 = allReservation?.reservation?.owner_pickup_images?.map(
            // //   (img, id) =>
            // //     images.push({
            // //       image: `https://api.rentmytruck.co${img.image}`,
            // //       caption: `<div>
            // //       Taken by Owner at Pickup (${img?.type})
            // //       <br/>
            // //       ${moment(img.creation).format("LLL")}
            // //     </div>`,
            // //     type: img.type,
            // //     })
            // // );
            // // const arr3 =
            // //   allReservation?.reservation?.renter_dropoff_images?.map(
            // //     (img, id) =>
            // //       images.push({
            // //         image: `https://api.rentmytruck.co${img.image}`,
            // //         caption: `<div>
            // //       Taken by Renter at Dropoff (${img?.type})
            // //       <br/>
            // //       ${moment(img.creation).format("LLL")}
            // //     </div>`,
            // //     type: img.type,
            // //       })
            // //   );
            // // const arr4 = allReservation?.reservation?.owner_dropoff_images?.map(
            // //   (img, id) =>
            // //     images.push({
            // //       image: `https://api.rentmytruck.co${img.image}`,
            // //       caption: `<div>
            // //       Taken by Owner at Dropoff (${img?.type})
            // //       <br/>
            // //       ${moment(img.creation).format("LLL")}
            // //     </div>`,
            // //     type: img.type,
            // //     })
            // // );
            // if (
            //   allReservation?.reservation?.renter_pickup_images?.length == 0
            // ) {
            //   images.push({
            //     image:
            //       "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
            //     caption: `<div>No Image</div>`,
            //   });
            // } else {
            //   const arr1 =
            //     allReservation?.reservation?.renter_pickup_images?.map(
            //       (img, id) =>
            //         images.push({
            //           image: `https://api.rentmytruck.co${img.image}`,
            //           caption: `<div>
            //         Taken by Renter at Pickup (${img?.type})
            //         <br/>
            //         ${moment(img.creation).format("LLL")}
            //       </div>`,
            //           type: img.type,
            //         })
            //     );
            // }
            // if (allReservation?.reservation?.owner_pickup_images?.length == 0) {
            //   images.push({
            //     image:
            //       "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
            //     caption: `<div>No Image</div>`,
            //   });
            // } else {
            //   const arr2 =
            //     allReservation?.reservation?.owner_pickup_images?.map(
            //       (img, id) =>
            //         images.push({
            //           image: `https://api.rentmytruck.co${img.image}`,
            //           caption: `<div>
            //       Taken by Owner at Pickup (${img?.type})
            //       <br/>
            //       ${moment(img.creation).format("LLL")}
            //     </div>`,
            //           type: img.type,
            //         })
            //     );
            // }
            // if (
            //   allReservation?.reservation?.renter_dropoff_images?.length == 0
            // ) {
            //   images.push({
            //     image:
            //       "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
            //     caption: `<div>No Image</div>`,
            //   });
            // } else {
            //   const arr3 =
            //     allReservation?.reservation?.renter_dropoff_images?.map(
            //       (img, id) =>
            //         images.push({
            //           image: `https://api.rentmytruck.co${img.image}`,
            //           caption: `<div>
            //       Taken by Renter at Dropoff (${img?.type})
            //       <br/>
            //       ${moment(img.creation).format("LLL")}
            //     </div>`,
            //           type: img.type,
            //         })
            //     );
            // }
            // if (
            //   allReservation?.reservation?.owner_dropoff_images?.length == 0
            // ) {
            //   images.push({
            //     image:
            //       "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png",
            //     caption: `<div>No Image</div>`,
            //   });
            // } else {
            //   const arr4 =
            //     allReservation?.reservation?.owner_dropoff_images?.map(
            //       (img, id) =>
            //         images.push({
            //           image: `https://api.rentmytruck.co${img.image}`,
            //           caption: `<div>
            //           Taken by Owner at Dropoff (${img?.type})
            //           <br/>
            //           ${moment(img.creation).format("LLL")}
            //         </div>`,
            //           type: img.type,
            //         })
            //     );
            // }

            // // if (images.length == 0) {
            // //   images.push(noimgfound);
            // // }

            // setReservationImages(images);
            // console.log(images);
            // console.log(noimgfound);
            // console.log(reservationImages);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getReservations();
  }, [
    reviewStatusUpdated,
    serivceFee,
    processingFee,
    deposit,
    totalFee,
    totalPrePaidMileage,
  ]);

  const [message, setMessage] = useState([]);
  const [lastSeen, setLastSeen] = useState(null);
  const [showInspectionDrawer, setShowInspectionDrawer] = useState(false);
  const [showReservationImagesDrawer, setShowReservationImagesDrwaer] =
    useState(false);

  useEffect(() => {
    const q = query(
      collection(db, `RentMyTruck/${reservationID}/Message`),
      orderBy("dateTime")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
      });
      console.log("message", messages);
      setMessage(messages);
    });

    return () => unsubscribe;
  }, []);

  useEffect(() => {
    const fetchCollectionData = async () => {
      try {
        const docSnapshot = await getDoc(
          doc(collection(db, "RenterLastSeen"), reservationID)
        );
        if (docSnapshot.exists()) {
          const collectionData = docSnapshot.data();
          const formattedDate = moment
            .unix(collectionData?.last_seen?.seconds)
            .format("lll");
          setLastSeen(formattedDate);
          console.log("Collection data:", formattedDate);
        } else {
          console.log("Collection does not exist.");
        }
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    fetchCollectionData();
  }, []);

  console.log(lastSeen);

  const filterMessage = message?.filter(
    (msg) => moment.unix(msg?.dateTime?.seconds).format("lll") > lastSeen
  );
  console.log("filterMessage", filterMessage);

  useEffect(() => {
    const getTimlines = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Reservation Contract Timeline?fields=["*"]&filters=[["reservation","=","${reservationID}"]]&order_by=creation asc`,
            config
          )
          .then((response) => {
            console.log("Reservation timline", response.data.data);
            setReservationTimeline(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getTimlines();
  }, []);

  const handleCancelReservation = () => {
    handleReviewUpdate("Cancelled");
    setCancelReservationModal(false);
  };

  const handleSetRating = (star) => {
    setRating(star);
  };

  const handleUploadCoi = async (e) => {
    setLoaderForButton(true);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadCOIFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setCOIFileURL(response.data.message?.file_url);
            console.log(coiFileURL);

            setLoaderForButton(false);
            setTimeout(() => {
              setCoi(true);
            }, 1000);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadCOIFile();
  };

  const handleExtensionDate = async (e) => {
    var JSONtoUpdate = {
      global_status: "Active.Time Extension Requested",
      reservation_status: "Active",
      no_of_days_for_extenshion: e.target.value,
    };

    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          setReviewStatusUpdated(!reviewStatusUpdated);
          setTimeout(() => {
            toast.success("Reservation Time Extension Requested");
          }, 500);
          console.log(response);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Time Extension Failed");
      }, 500);
    }
  };

  const handleReviewUpdate = async (status, url) => {
    if (status == "Approved") {
      var JSONtoUpdate = {
        // review_status:"Reviewed",
        global_status: "Requested.RenterApproved",
        reservation_status: "Approved",
        // coi_attach: coiFileURL,
      };

      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setCoi(false);
            setTimeout(() => {
              toast.success("Reservation Approved");
            }, 500);
            console.log(response);
          });
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          toast.error("Reservation Failed");
        }, 500);
      }
    } else if (status == "COI") {
      
      var JSONtoUpdate = {
        // review_status:"Reviewed",
        global_status: "Requested.COIuploaded",
        coi_provider_email_address: coiProviderEmail,
        // coi_uploaded_document: url,
        coi_company_name: coiName,
        coi_policy_number: coiNumber,
        // coi_start_date: moment(state[0].startDate).format("YYYY-MM-DD"),
        // coi_end_date: moment(state[0].endDate).format("YYYY-MM-DD"),
        coi_company_number: coiPhoneNo,
      };

      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setCoi(false);
            setTimeout(() => {
              toast.success("COI Uploaded");
              setCoiEditForm(false);
            }, 500);
            console.log(response);
          });
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          toast.error("COI Uploaded Failed");
        }, 500);
      }
    } else if (status == "Cancelled") {
      var JSONtoUpdate = {
        global_status: "Cancelled.Renter",
        reservation_status: "Cancelled",
        cancelled_by: "Cancelled By Renter",
        // price_break_down_renter: [
        //   {
        //     stage: "Cancelled.Renter",
        //     rent_per_day:
        //       allReservation?.reservation?.price_break_down_renter[0]
        //         ?.rent_per_day,
        //     per_day_miles_allowed:
        //       allReservation?.reservation?.price_break_down_renter[0]
        //         ?.per_day_miles_allowed,
        //     allowed_cost_per_mile:
        //       allReservation?.reservation?.price_break_down_renter[0]
        //         ?.allowed_cost_per_mile,
        //     overboard_per_mile_cost:
        //       allReservation?.reservation?.price_break_down_renter[0]
        //         ?.overboard_per_mile_cost,
        //     service_fees: serivceFee,
        //     total: totalAfterCancellationFee + total,
        //     deposit: deposit,
        //     parentfield: "price_break_down_renter",
        //     parenttype: "Reservation",
        //     doctype: "Price Breakdown Renter",
        //   },
        // ],
      };
      console.log("JSONtoUpdate", JSONtoUpdate);
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            setTimeout(() => {
              toast.success("Reservation Cancelled");
            }, 500);
            console.log(response);
            setShowModal(false);
          });
      } catch (e) {
        console.error(e);
        setShowModal(false);
        setTimeout(() => {
          toast.error("Reservation Cancelation Failed");
        }, 500);
      }
    } else {
      var JSONtoUpdate = {
        global_status: "Requested.RenterRejected",
        reservation_status: "Rejected",
      };
      try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSONtoUpdate,
            config
          )
          .then((response) => {
            setReviewStatusUpdated(!reviewStatusUpdated);
            console.log(response);
            setTimeout(() => {
              toast.error("Reservation Rejected");
            }, 500);
          });
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          toast.error("Reservation Failed");
        }, 500);
      }
    }
  };

  const handleReviewsUpload = async (e) => {
    e.preventDefault();

    var JSONtoUpdate = {
      renter_rating: rating / 5,
      renter_reviews: review,
      reservation_status: "Rated",
      global_status: "Completed.Rated",
    };

    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          setReviewStatusUpdated(!reviewStatusUpdated);
          setTimeout(() => {
            toast.success("Rating & Reviews Submitted");
          }, 500);
          console.log(response);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Rating & Reviews Failed to Submit");
      }, 500);
    }
  };

  const handlePickupConfirmsChange = async (e) => {
    setRenterPickupConfirms((prev) => !prev);

    var JSONtoUpdate = {
      renter_pickup_confirm: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Renter Reservation Pickup Confirms");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const handleDropoffConfirmsChange = async (e) => {
    setRenterDropoffConfirms((prev) => !prev);

    var JSONtoUpdate = {
      renter_dropoff_confirm: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Renter Reservation Drop-off Confirms");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const [vehicleReturnedClean, setVehicleReturnedClean] = useState(false);

  const handleVehicleReturnClean = async (e) => {
    setVehicleReturnedClean(!vehicleReturnedClean);

    var JSONtoUpdate = {
      vehicle_returned_clean: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Vehicle Returned Clean");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const handleVinylStickerChange = async (e) => {
    setRenterVinylSticker((prev) => !prev);

    var JSONtoUpdate = {
      renter_vinyl_sticker_visibile: e == true ? 1 : 0,
    };
    console.log(JSONtoUpdate);
    try {
      await axios
        .put(
          `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
          JSONtoUpdate,
          config
        )
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            toast.success("Renter Reservation Vinyl Sticker Applied");
          }, 500);
        });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        toast.error("Reservation Failed");
      }, 500);
    }
  };

  const handlePDFDownload = async () => {
    setLoaderForButtonPDF(true);
    try {
      await axios
        .get(
          `https://api.rentmytruck.co/api/method/frappe.utils.print_format.download_pdf?doctype=Reservation Contract&name=${reservationID}&format=Reservation Contract Document`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          }
        )
        .then((response) => {
          console.log("pdf", response.data);

          const url = window.URL.createObjectURL(response.data);

          // Create a temporary link and click it to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "file.pdf";
          document.body.appendChild(a);
          a.click();

          // Clean up the temporary URL and link
          window.URL.revokeObjectURL(url);
          a.remove();
          toast.success("Contract Downloaded");
          setLoaderForButtonPDF(false);

          // setReservationTimeline(response);
        });
    } catch (e) {
      console.error(e);
      toast.error("Contract Download Failed");
      setLoaderForButtonPDF(false);
    }
  };

  return (
    <>
      <Toaster richColors />
      {loading ? (
        <ReservationDetailPageSkeleton />
      ) : (
        <div className="min-h-full">
          <main className="mt-0 ">
            <div className="mx-0 w-full lg:px-4 ">
              <div className="grid grid-row-1 items-start gap-1 lg:grid-cols-3 ">
                {/* Left column */}
                <div className="grid grid-cols- gap-4 lg:col-span-2 mb-3">
                  {/* Welcome panel */}
                  <section aria-labelledby="profile-overview-title">
                    <Tab.Group
                      as="div"
                      className="flex flex-col-reverse lg:pr-4"
                    >
                      {/* Image selector */}
                      <div className="mx-auto lg:mt-6 mt-4  w-full max-w-2xl sm:block lg:max-w-none">
                        <Tab.List className="grid grid-cols-4 lg:gap-6 gap-3">
                          {allReservation?.vehicle?.attach_images?.map(
                            (image) => (
                              <Tab className="relative flex lg:h-44 h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 ">
                                {({ selected }) => (
                                  <>
                                    {/* <span className="sr-only">hello</span> */}
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                      <img
                                        src={window.$backendURL + image.image}
                                        alt=""
                                        className="h-full w-full object-contain"
                                      />
                                    </span>
                                    <span
                                      className={
                                        selected
                                          ? "ring-purple-500 pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                          : "ring-transparent pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                      }
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </Tab>
                            )
                          )}
                        </Tab.List>
                      </div>

                      <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                        {allReservation?.vehicle?.attach_images?.map(
                          (image) => (
                            <Tab.Panel>
                              <img
                                src={window.$backendURL + image.image}
                                alt=""
                                className="w-full object-contain bg-white rounded-lg lg:h-[550px] h-[320px]"
                              />
                            </Tab.Panel>
                          )
                        )}
                      </Tab.Panels>
                    </Tab.Group>
                  </section>

                  {/* Actions panel */}
                  <section aria-labelledby="quick-links-title">
                    <div className="overflow-hidden rounded-lg py-2">
                      <div className="flex justify-between flex-col">
                        <div className="flex items-center ">
                          <h2
                            className="text-2xl font-semibold text-gray-900"
                            id="announcements-title"
                          >
                            {allReservation?.vehicle?.make +
                              " " +
                              allReservation?.vehicle?.model}
                          </h2>
                        </div>

                        {/* <div className="flex my-3 items-center gap-4">
                          <div className="text-sm font-semibold">4.5</div>
                          <div className="text-sm font-semibold w-6 h-6">
                            <StarIcon />
                          </div>

                          <div>
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              23 Trips
                            </span>
                          </div>
                          <div className="w-6 h-6">
                            <MapPinIcon />
                          </div>
                          <div className="text-sm font-semibold">2.3 miles</div>
                        </div> */}

                        <div className="mt-4 mb-2 lg:w-2/4 border-2 rounded-xl p-3 sm:w-full border-slate-300">
                          <div className="flex my-4 items-center justify-between">
                            <div className="flex gap-1 items-center">
                              <div className="text-sm font-semibold w-6 h-6">
                                <TruckIcon className="w-6 h-6" />
                              </div>
                              <div className="text-sm  text-gray-900">
                                {allReservation?.vehicle?.year}
                              </div>
                            </div>
                            <div className="flex gap-1 items-center">
                              <div className="w-6 h-6 flex justify-center items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="Layer_1"
                                  dataname="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" />
                                </svg>
                              </div>
                              <div className="text-sm  text-gray-900">
                                {allReservation?.vehicle?.fuel_type}
                              </div>
                            </div>
                            <div className="flex gap-1 items-center">
                              <div className="w-6 h-6">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="Layer_1"
                                  dataname="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M23.5,23h-3.5V4.5c0-2.481-2.019-4.5-4.5-4.5h-7c-2.481,0-4.5,2.019-4.5,4.5V23H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5H23.5c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5ZM5,4.5c0-1.93,1.57-3.5,3.5-3.5h7c1.93,0,3.5,1.57,3.5,3.5V23H5V4.5Zm12,7.5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z" />
                                </svg>
                              </div>
                              <div className="text-sm  text-gray-900">
                                {allReservation?.vehicle?.lift_gate}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          <h1 className="text-lg font-semibold text-gray-800">
                            Description:
                          </h1>
                          <p className="text-sm text-slate-600 py-2">
                            {allReservation?.vehicle?.description}
                          </p>
                        </div>
                      </div>

                      {/* Location  */}
                      <div className="flex  flex-col mt-3">
                        <div className=" text-lg font-semibold  text-gray-800">
                          Location:
                        </div>
                        <div className="text-sm font-normal py-2 text-slate-600">
                          {"Address: " + allReservation?.location.address}
                        </div>
                      </div>
                      {/* <img
                      className="w-full rounded-lg h-60 sm:h-96"
                      src="https://images.unsplash.com/photo-1569336415962-a4bd9f69cd83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hcHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    /> */}
                      <div className="lg:pr-3">
                        <iframe
                          className="w-full rounded-lg h-60 sm:h-96"
                          marginheight="0"
                          marginwidth="0"
                          src={`https://maps.google.com/maps?q=${lat},${long}&hl=en&z=14&amp&output=embed`}
                        ></iframe>
                      </div>
                      {/* <div className="flex flex-col mt-6 ">
                        <div className="text-lg font-semibold my-2 ">
                          Business Hours
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                          {allReservation?.owner?.business_hours}
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                        Saturday 8:00am at 8:00pm
                      </div>
                      </div> */}
                      <div className="flex flex-col mt-3">
                        <div className="text-lg  font-semibold text-gray-800">
                          Owner Information:
                        </div>
                        <div className="text-base py-2 text-slate-500 font-medium">
                          <a
                            href={
                              window.$FWDurl +
                              `/publically-facing-profile/${allReservation?.owner?.name}`
                            }
                            className="underline hover:text-slate-600"
                            target="_blank"
                          >
                            {allReservation?.owner?.business_name}
                          </a>
                        </div>

                        {/* <div className="text-sm text-gray-500 font-medium">
                          <span className="font-semibold text-black">
                            Business Number: &nbsp;
                          </span>{" "}
                          {allReservation?.owner?.business_number}
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                          <span className="font-semibold text-black">
                            Business Address: &nbsp;
                          </span>{" "}
                          {allReservation?.owner?.business_address}
                        </div> */}
                      </div>
                      <div className="flex flex-col mt-3">
                        <div className="text-lg text-gray-800 font-semibold">
                          Important Notes:
                        </div>
                        <div className="text-sm text-slate-500 py-2">
                          <span className="hover:text-slate-600 text-lg ">
                            {allReservation?.reservation?.notes_from_owner}
                          </span>
                        </div>
                      </div>

                      {allReservation?.reservation?.global_status !=
                        "Requested.Requested" ||
                      allReservation?.reservation?.global_status !=
                        "Requested.OwnerApproved" ||
                      allReservation?.reservation?.global_status !=
                        "Requested.COIuploaded" ? (
                        <div>
                          <Link to={`/Chat/${reservationID}`}>
                            <button className="relative text-center rounded-md mt-2 w-40 h-11 border-2 bg-purple-500 text-white border-purple-500 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white">
                              Chat with Owner
                              {filterMessage?.length > 0 && (
                                <span className="rounded-full px-1.5 text-base font-semibold bg-red-600 absolute -top-2.5 -right-1.5">
                                  {filterMessage?.length}
                                </span>
                              )}
                            </button>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}

                      <div>
                        <Link to={`/renter-support-ticket/${reservationID}`}>
                          <button className="relative text-center rounded-md mt-2 w-40 h-11 border-2 bg-purple-500 text-white border-purple-500 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white">
                            Support
                          </button>
                        </Link>
                      </div>
                    </div>
                  </section>
                </div>

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4 ">
                  {/* Announcements */}
                  <section aria-labelledby="announcements-title">
                    <div className="overflow-hidden rounded-lg bg-white ">
                      <div className=" flex flex-col justify-between gap-y-4 lg:px-5 lg:py-4 px-4 py-2 pb-4">
                        <div className="flex gap-1 justify-between mt-2 items-center">
                          <h2
                            className="text-xl text-gray-900 "
                            id="announcements-title"
                          >
                            <span className="font-semibold">
                              Reservation&nbsp;{" "}
                            </span>
                          </h2>
                          <p className="font-thin">
                            {"#" + reservationID.toUpperCase()}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {allReservation?.reservation?.reservation_status}
                          </span>
                          <span>
                            {(allReservation?.reservation?.global_status ==
                              "Requested.Requested" && (
                              <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                Waiting for Owners Approval{" "}
                              </span>
                            )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.OwnerApproved" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for COI{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.OwnerRejected" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Rejected by Owner{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.COIuploaded" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  COI Uploaded{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.RenterApproved" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Reservation Approved by Renter{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Requested.RenterRejected" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Rejected by Renter{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Cancelled" && (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Reservation Cancelled{" "}
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Active.Waiting for Pickup" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Pickup
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Active.Active" && (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Pickup Completed
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Completed.Rated" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Reconcillation
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Completed.Returned" && (
                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                  Waiting for Rating
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Completed.Reconcilled" && (
                                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                                  Reconcillation Completed
                                </span>
                              )) ||
                              (allReservation?.reservation?.global_status ==
                                "Active.Time Extension Requested" && (
                                <span className="inline-flex rounded-full bg-purple-100 px-2 text-xs font-semibold leading-5 text-purple-800">
                                  Waiting For Owner's Extension Approval
                                </span>
                              ))}
                          </span>
                        </div>
                        <div className="flex flex-col mt-1">
                          <span className="font-semibold py-2">
                            Owner Information
                          </span>
                          <span className="text-slate-500">
                            {allReservation?.owner?.business_name}
                          </span>
                        </div>
                        <div className="flex flex-col mt-1">
                          <span className="font-semibold py-2">
                            Driver Information
                          </span>
                          <span className="text-slate-500">
                            {allReservation?.reservation?.first_name +
                              " " +
                              allReservation?.reservation?.last_name}
                          </span>
                          {/* <span className="text-gray-500">
                            {"License No:" + " " + allReservation?.reservation?.driver_license_number}
                          </span> */}
                        </div>
                        <div className="flex justify-between items-center mt-3">
                          <div className="flex flex-col gap-1">
                            <span className=" font-semibold ">Pickup</span>
                            <span className="text-slate-500">
                              {moment(
                                allReservation?.reservation
                                  ?.reservation_start_date
                              ).format("ll") +
                                " " +
                                moment(
                                  allReservation?.reservation
                                    ?.requested_pickup_and_dropoff_time,
                                  "HH:mm:ss"
                                ).format("LT")}
                            </span>
                          </div>
                          <div className="flex flex-col gap-1">
                            <span className="font-semibold">Return</span>
                            <span className="text-slate-500">
                              {moment(
                                allReservation?.reservation
                                  ?.reservation_end_date
                              ).format("ll") +
                                " " +
                                moment(
                                  allReservation?.reservation
                                    ?.requested_pickup_and_dropoff_time,
                                  "HH:mm:ss"
                                ).format("LT")}
                            </span>
                          </div>
                        </div>
                        {allReservation?.reservation?.global_status ===
                          "Completed.Returned" ||
                        allReservation?.reservation?.global_status ===
                          "Completed.Rated" ||
                        allReservation?.reservation?.global_status ===
                          "Completed.Reconcilled" ? (
                          <div className="flex justify-between items-center mt-3">
                            <div className="flex flex-col gap-1">
                              <span className=" font-semibold ">
                                Actual Return Date
                              </span>
                              <span className="text-slate-500">
                                {moment(
                                  allReservation?.reservation
                                    ?.actual_return_date
                                ).format("lll")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {allReservation?.reservation?.global_status ===
                          "Requested.OwnerApproved" && (
                          <section className="border border-gray-200 rounded-md">
                            <div className="overflow-hidden rounded-lg bg-white ">
                              <div className=" flex flex-col justify-between gap-y-4 p-6">
                                <h1 className="text-xl font-semibold">
                                  Certificate Of Insurance
                                </h1>
                                <span className="text-sm font-semibold">
                                  Note: This is where we save you money. We're
                                  not going to force you to purchase overpriced
                                  insurance from us
                                </span>

                                <>
                                  <div className="flex py-2 flex-col justify-between">
                                    {/* <label
                                htmlFor="coi_policy_number"
                                className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                              >
                                Enter Policy Start & End Date
                              </label>
                              <div className="flex  sm:flex-row my-2">
                                <DateRange
                                  editableDateInputs={true}
                                  onChange={(item) =>
                                    setState([item.selection])
                                  }
                                  required
                                  moveRangeOnFirstSelection={false}
                                  ranges={state}
                                  rangeColors={["#EA446A"]}
                                  minDate={new Date()}
                                  shownDate={new Date()}
                                  // disabledDates={allUnAvailDates}
                                />
                              </div> */}
                                    <label
                                      htmlFor="coi_provider_email_address"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      COI Provider Email Address
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="email"
                                        name="coi_provider_email_address"
                                        id="coi_provider_email_address"
                                        value={coiProviderEmail}
                                        onChange={(e) => {
                                          setCOIProviderEmail(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <label
                                      htmlFor="coi_company_name"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      Enter Insurance Company Name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="coi_company_name"
                                        id="coi_company_name"
                                        value={coiName}
                                        onChange={(e) => {
                                          setCOIName(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <label
                                      htmlFor="coi_policy_number"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      Enter Policy Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="coi_policy_number"
                                        id="coi_policy_number"
                                        value={coiNumber}
                                        onChange={(e) => {
                                          setCOINumber(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>

                                    <label
                                      htmlFor="coi_company_number"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      Enter Insurance Agent/Carrier Contact
                                      Phone Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="tel"
                                        name="coi_company_number"
                                        id="coi_company_number"
                                        value={coiPhoneNo}
                                        onChange={(e) => {
                                          setCOIPhoneNo(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>

                                    {/* <div className="flex py-3 justify-between border-gray-200 border-b-2  ">
                                {coiFileURL == null ? (
                                  <>
                                    <div className="flex justify-between items-center border-purple-600 border rounded-md">
                                      <label
                                        htmlFor="file-upload"
                                        className=" cursor-pointer rounded-md font-semibold text-purple-600 focus-within:outline-none px-4 py-2.5 focus-within:ring-2 focus-within:ring-purple-600 focus-within:ring-offset-2 hover:text-purple-500"
                                      >
                                        <span>Upload COI</span>
                                        <input
                                          id="file-upload"
                                          name="file-upload"
                                          type="file"
                                          className="sr-only"
                                          onChange={(e) => handleUploadCoi(e)}
                                        />
                                      </label>
                                    </div>
                                    {loaderForButton && (
                                      // <div className="w-full mt-4 flex items-center justify-center">
                                      // {" "}
                                      <button
                                        disabled
                                        type="button"
                                        className="text-white bg-purple-700 hover:bg-purple-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center mr-2 flex justify-center items-center"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          role="status"
                                          className="flex w-4 h-4 mr-3 text-white animate-spin"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#E5E7EB"
                                          />
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                        Uploading...
                                      </button>
                                      // </div>
                                    )}{" "}
                                  </>
                                ) : (
                                  <div className="flex w-full justify-center items-center">
                                    <span className="bg-purple-100 text-purple-800 px-3 rounded-full">
                                      {coiFileURL}
                                    </span>
                                  </div>
                                )}
                              </div> */}
                                  </div>
                                  <div>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("COI", coiFileURL)
                                      }
                                      className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                                    >
                                      Save COI
                                    </button>
                                  </div>
                                </>
                              </div>
                            </div>
                          </section>
                        )}

                        {coiEditForm && (
                          <section className="border border-gray-200 rounded-md">
                            <div className="overflow-hidden rounded-lg bg-white ">
                              <div className=" flex flex-col justify-between gap-y-4 p-6">
                                <h1 className="text-xl font-semibold">
                                  Certificate Of Insurance
                                </h1>
                                <span className="text-sm font-semibold">
                                  Note: This is where we save you money. We're
                                  not going to force you to purchase overpriced
                                  insurance from us
                                </span>

                                <>
                                  <div className="flex py-2 flex-col justify-between">
                                    <label
                                      htmlFor="coi_provider_email_address"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      COI Provider Email Address
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="email"
                                        name="coi_provider_email_address"
                                        id="coi_provider_email_address"
                                        value={coiProviderEmail}
                                        onChange={(e) => {
                                          setCOIProviderEmail(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <label
                                      htmlFor="coi_company_name"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      Enter Insurance Company Name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="coi_company_name"
                                        id="coi_company_name"
                                        value={coiName}
                                        onChange={(e) => {
                                          setCOIName(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <label
                                      htmlFor="coi_policy_number"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      Enter Policy Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="coi_policy_number"
                                        id="coi_policy_number"
                                        value={coiNumber}
                                        onChange={(e) => {
                                          setCOINumber(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>

                                    <label
                                      htmlFor="coi_company_number"
                                      className="text-md flex items-start justify-start font-medium leading-6 text-gray-900"
                                    >
                                      Enter Insurance Agent/Carrier Contact
                                      Phone Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="tel"
                                        name="coi_company_number"
                                        id="coi_company_number"
                                        value={coiPhoneNo}
                                        onChange={(e) => {
                                          setCOIPhoneNo(e.target.value);
                                        }}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                 {allReservation?.reservation?.global_status ==
                          "Requested.COIuploaded"  ? <div>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("COI", coiFileURL)
                                      }
                                      className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                                    >
                                      Edit COI
                                    </button>
                                  </div>: ""}
                                </>
                              </div>
                            </div>
                          </section>
                        )}

                        {allReservation?.reservation?.global_status !=
                          "Requested.Requested" ||
                        allReservation?.reservation?.global_status !=
                          "Requested.OwnerApproved" ||
                        allReservation?.reservation?.global_status ==
                          "Requested.OwnerRejected" ? (
                          ""
                        ) : (
                          <section className="border border-gray-200 rounded-md">
                            <div className="overflow-hidden rounded-lg bg-white ">
                              <div className=" flex flex-col justify-between gap-y-4 p-6">
                                <div className="flex items-center justify-between">
                                  <h1 className="text-xl font-semibold">
                                    Certificate Of Insurance
                                  </h1>

                                  <button
                                    className="bg-purple-500 text-sm text-white px-2 py-1 rounded-md"
                                    onClick={() => setCoiEditForm(!coiEditForm)}
                                  >
                                    Edit COI
                                  </button>
                                </div>
                                <>
                                  <div className="flex flex-col ">
                                    <div className="flex items-center justify-between mb-3">
                                      <span className="text-gray-800">
                                        Company Name:
                                      </span>
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                        {
                                          allReservation?.reservation
                                            ?.coi_company_name
                                        }
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                      <span className="text-gray-800">
                                        Company Number:
                                      </span>
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                        {
                                          allReservation?.reservation
                                            ?.coi_company_number
                                        }
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                      <span className="text-gray-800">
                                        Company Policy Number:
                                      </span>
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                        {
                                          allReservation?.reservation
                                            ?.coi_policy_number
                                        }
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                      <span className="text-gray-800">
                                        COI Provider Email:
                                      </span>
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                        {
                                          allReservation?.reservation
                                            ?.coi_provider_email_address
                                        }
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                      <span className="text-gray-800">
                                        Insurance Verified:
                                      </span>
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                        {allReservation?.reservation
                                          ?.insurance_verified == 1
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-3">
                                      <span className="text-gray-800">
                                        Insurance Approved:
                                      </span>
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                        {allReservation?.reservation
                                          ?.insurance_approved == 1
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                    {/* <div className="flex items-center justify-between mb-3">
                                <div className="flex flex-col gap-y-1">
                                  <span className=" font-semibold p-2">
                                    Start Date
                                  </span>
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                    {moment(
                                      allReservation?.reservation
                                        ?.coi_start_date
                                    ).format("ll")}
                                  </span>
                                </div>
                                <div className="flex flex-col gap-y-1">
                                  <span className=" font-semibold p-2">
                                    End Date
                                  </span>
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">
                                    {moment(
                                      allReservation?.reservation?.coi_end_date
                                    ).format("ll")}
                                  </span>
                                </div>
                              </div> */}
                                  </div>
                                  <div className="flex items-center justify-center mb-2">
                                    {allReservation?.reservation
                                      ?.coi_uploaded_document ? (
                                      <a
                                        target="_blank"
                                        className="text-center rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                                        href={
                                          window.$backendURL +
                                          allReservation?.reservation
                                            ?.coi_uploaded_document
                                        }
                                      >
                                        View Certificate of Insurance
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              </div>
                            </div>
                          </section>
                        )}

                        {allReservation?.reservation?.global_status ==
                          "Completed.Return" && (
                          <section className="border border-purple-500 rounded-md">
                            <div className="overflow-hidden rounded-lg bg-white ">
                              <div className=" flex flex-col justify-between gap-y-4 p-6">
                                <h1 className="text-xl font-semibold">
                                  Rating & Reviews
                                </h1>
                                <form onSubmit={handleReviewsUpload}>
                                  <div className="mb-4">
                                    <label className="block text-gray-700 mb-2">
                                      Rating
                                    </label>
                                    <div className="flex">
                                      {[1, 2, 3, 4, 5].map((star) => (
                                        <button
                                          type="button"
                                          key={star}
                                          className="w-8 h-8"
                                          onClick={() => handleSetRating(star)}
                                        >
                                          <StarIcon
                                            className={`w-8 h-8 ${
                                              rating >= star
                                                ? "text-yellow-500"
                                                : "text-gray-400"
                                            }`}
                                          />
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <label className="block text-gray-700 mb-2">
                                      Review
                                    </label>
                                    <textarea
                                      className="w-full p-2 border border-gray-300 rounded-md"
                                      value={review}
                                      onChange={(e) =>
                                        setReview(e.target.value)
                                      }
                                      rows="4"
                                    />
                                  </div>
                                  <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                  >
                                    Submit
                                  </button>
                                </form>
                              </div>
                            </div>
                          </section>
                        )}

                        {allReservation?.reservation?.global_status ==
                        "Completed.Reconcilled" ? (
                          <div className=" flex flex-col justify-between gap-y-4 -mx-2">
                            <div className="flex flex-col lg:p-5 p-3 border-2 mt-2 rounded-lg bg-gray-50">
                              <div className="text-left font-semibold text-lg pb-2 border-b-2 ">
                                Cost Breakdown
                              </div>
                              <div
                                className="py-4 border-b-2"
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Rent Per Day
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.vehicle?.rent_per_day}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2 font-semibold">
                                    Total{" "}
                                    {`(for ${allReservation?.reservation?.initial_rental_days} days)`}
                                  </div>
                                  <div className="flex justify-center items-center font-semibold">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_rental_price}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="py-4   border-b-2  "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Service Fee
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_split_fee}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Processing Fee
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_processing_fee}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left font-bold my-2">
                                    Total Fee
                                  </div>
                                  <div className="flex justify-center font-bold items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_total_fee}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col gap-y-1 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <h1 className="font-semibold">Mileage</h1>
                                <div className="flex justify-between items-start pb-1">
                                  <div className="flex flex-col items-start pr-3">
                                    <div className="flex items-top gap-1">
                                      Prepaid Mileage
                                      {/* <Tooltip
                                  data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${allReservation?.vehicle?.vehicle_type}, Rent My Truck charges ${allReservation?.per_day_miles_allowed} miles per day at $${allReservation?.allowed_cost_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                  />{" "} */}
                                    </div>
                                    <span className=" font-light text-xs">{`${allReservation?.reservation?.allocated_miles_per_vehicle_type} miles per day included ($0.02 per extra mile)`}</span>
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_prepaid_mileage_cost}
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>Prepaid Miles Consumed</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.prepaid_miles_consumed
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>Prepaid Miles Balance</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.prepaid_miles_balance
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>Additional Miles Consumed</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.additional_miles_consumed
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between pb-3">
                                  <div>Additional Miles Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.additional_miles_cost}
                                  </div>
                                </div>
                                <div className="flex justify-between font-semibold border-t-2 pt-3">
                                  <div className="">Total Miles Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.total_miles_cost}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col gap-y-1 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <h1 className="font-semibold">Reefer</h1>
                                <div className="flex justify-between">
                                  <div>Reefer Consumed Hours</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.reefer_consumed_hours
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between pb-3">
                                  <div>Reefer Per Hour Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.reefer_per_hour_cost}
                                  </div>
                                </div>
                                <div className="flex justify-between font-semibold border-t-2 pt-3">
                                  <div className="">Reefer Total Cost</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.reefer_total_cost}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col gap-y-1 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <h1 className="font-semibold">Violations</h1>
                                <div className="flex justify-between">
                                  <div>Late Return Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.late_return_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div>Late Return Days</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.late_return_days_number
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Cleaning Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.cleaning_fee_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Smoking Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.smoking_fee_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">
                                    Failed to Return At Dropoff Violation
                                  </div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.failed_to_return_at_dropoff_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Fuel Refill Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.fuel_refill_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between ">
                                  <div className="">Out of State Violation</div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.out_of_state_violation
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between pb-3">
                                  <div className="">
                                    Fuel Refill Gallons Needed
                                  </div>
                                  <div>
                                    {
                                      allReservation?.reservation
                                        ?.fuel_refill_gallons_needed
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-between font-semibold border-t-2 pt-3">
                                  <div className="">Violations Total</div>
                                  <div>
                                    {"$" +
                                      allReservation?.reservation
                                        ?.violations_total}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 flex-col  border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex justify-between">
                                  <div className="flex flex-col">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Deposit
                                    </div>
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_deposit}
                                  </div>
                                </div>

                                <div className="flex justify-between pb-3">
                                  <div className="flex flex-col">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Deposit Deduction
                                    </div>
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.deposit_deduction}
                                  </div>
                                </div>

                                <div className="flex justify-between border-t-2 pt-2">
                                  <div className="flex flex-col font-semibold">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Deposit Refund
                                    </div>
                                  </div>
                                  <div className=" flex justify-center items-center font-semibold">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.deposit_refund}
                                  </div>
                                </div>
                              </div>
                              <div className="flex py-4 justify-between   border-gray-200 border-b-2  ">
                                <div className="text-left font-bold">Total</div>
                                <div className="flex justify-center items-center font-bold">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.renter_final_total_charge}
                                </div>
                              </div>

                              {/* <div className="flex py-4  justify-between border-gray-200 ">
                                {allReservation?.reservation?.global_status ===
                                  "Requested.COIuploaded" && (
                                  <>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("Approved")
                                      }
                                      className="rounded-md border w-full h-11 border-transparent button py-2 px-2 mr-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                                    >
                                      Approve
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("Rejected")
                                      }
                                      className="rounded-md  w-full h-11 border-2 text-red-700 border-red-700 py-2 px-2 ml-2 text-sm font-medium shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                              </div> */}
                            </div>
                          </div>
                        ) : (
                          <div className=" flex flex-col justify-between gap-y-4 -mx-2">
                            <div className="flex flex-col lg:p-5 p-3 border-2 mt-2 rounded-lg bg-gray-50">
                              <div
                                className="text-left font-semibold text-lg pb-2 border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                Cost Breakdown
                              </div>
                              <div
                                className="py-4 border-b-2"
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Rent Per Day
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.vehicle?.rent_per_day}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2 font-semibold">
                                    Total{" "}
                                    {`(for ${allReservation?.reservation?.initial_rental_days} days)`}
                                  </div>
                                  <div className="flex justify-center items-center font-semibold">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_rental_price}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="py-4   border-b-2  "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Service Fee
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_split_fee}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left my-2">
                                    Processing Fee
                                  </div>
                                  <div className="flex justify-center items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_processing_fee}
                                  </div>
                                </div>
                                <div className="flex  justify-between   ">
                                  <div className="text-left font-bold my-2">
                                    Total Fee
                                  </div>
                                  <div className="flex justify-center font-bold items-center">
                                    {"$" +
                                      allReservation?.reservation
                                        ?.renter_initial_total_fee}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="flex py-4 justify-between  border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex flex-col">
                                  <div className="text-left my-2 flex items-top gap-1">
                                    Prepaid Mileage
                                    <Tooltip
                                    // data={`Prepaid mileage is based on an estimated amount of miles for the type of vehicle you are renting. For ${allReservation?.vehicle?.vehicle_type}, Rent My Truck charges ${allReservation?.per_day_miles_allowed} miles per day at $${allReservation?.allowed_cost_per_mile} / mile, plus tax. The final mileage charge will be calculated upon vehicle return and any overpaid mileage will be refunded.`}
                                    />{" "}
                                  </div>
                                  {/* <span className=" font-light text-xs">{`${allReservation?.reservation?.price_break_down_renter[0]?.per_day_miles_allowed} miles per day included ($${allReservation?.reservation?.price_break_down_renter[0]?.overboard_per_mile_cost} per extra mile)`}</span> */}
                                </div>
                                <div className=" flex justify-center items-center">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.renter_initial_prepaid_mileage_cost}
                                </div>
                              </div>
                              {allReservation?.vehicle?.includes_reefer ==
                                1 && (
                                <div
                                  className="flex   py-4 justify-between  border-b-2 "
                                  style={{ borderColor: "#EDEBF3" }}
                                >
                                  <div className="flex flex-col">
                                    <div className="text-left my-2 flex items-top gap-1">
                                      Reefer Cost
                                      <Tooltip data={`Reefer Cost = $1`} />{" "}
                                    </div>
                                    {/* <span className=" font-light text-xs">{`${allReservation?.reservation?.price_break_down_renter[0]?.per_day_miles_allowed} miles per day included ($${allReservation?.reservation?.price_break_down_renter[0]?.overboard_per_mile_cost} per extra mile)`}</span> */}
                                  </div>
                                  <div className=" flex justify-center items-center">
                                    TBD
                                  </div>
                                </div>
                              )}

                              <div
                                className="flex py-4 justify-between  border-b-2 "
                                style={{ borderColor: "#EDEBF3" }}
                              >
                                <div className="flex flex-col">
                                  <div className="text-left my-2 flex items-top gap-1">
                                    Deposit
                                  </div>
                                </div>
                                <div className=" flex justify-center items-center">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.renter_initial_deposit}
                                </div>
                              </div>
                              <div className="flex py-4 justify-between   border-gray-200 border-b-2  ">
                                <div className="text-left font-bold">Total</div>
                                <div className="flex justify-center items-center font-bold">
                                  {"$" +
                                    allReservation?.reservation
                                      ?.renter_initial_total_payable}
                                </div>
                              </div>

                              {/* <div className="flex py-4  justify-between border-gray-200 ">
                                {allReservation?.reservation?.global_status ===
                                  "Requested.COIuploaded" && (
                                  <>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("Approved")
                                      }
                                      className="rounded-md border w-full h-11 border-transparent button py-2 px-2 mr-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                                    >
                                      Approve
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleReviewUpdate("Rejected")
                                      }
                                      className="rounded-md  w-full h-11 border-2 text-red-700 border-red-700 py-2 px-2 ml-2 text-sm font-medium shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                              </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>

                  {allReservation?.reservation?.global_status ===
                    "Cancelled.Renter" ||
                  allReservation?.reservation?.global_status ===
                    "Cancelled.Owner" ||
                  allReservation?.reservation?.global_status === "Canceled" ? (
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Cancellation Penalties
                          </h1>

                          <div className="flex items-center justify-between">
                            <span className="text-base font-medium">
                              Reservation Cancelled:
                            </span>
                            <span className="text-sm bg-red-100 text-red-700 px-2 py-1 rounded-full">
                              {allReservation?.reservation?.cancelled_by}
                            </span>
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="text-base font-medium">
                              Cancellation Fee:
                            </span>
                            <span className="text-sm bg-red-100 text-red-700 px-2 py-1 rounded-full">
                              {"$" +
                                allReservation?.reservation
                                  ?.cancellation_fee_renter}
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : allReservation?.reservation?.global_status ==
                    "Requested.OwnerRejected" ? (
                    ""
                  ) : (
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <>
                            <button
                              onClick={() => setCancelReservationModal(true)}
                              className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                            >
                              Cancel Reservation
                            </button>{" "}
                          </>
                        </div>
                      </div>
                    </section>
                  )}

                  {cancelReservationModal && (
                    <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className=" w-1/3  my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                          <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-2xl font-semibold">
                              Cancel Reservation
                            </h3>
                            <button
                              className="flex items-center p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => setCancelReservationModal(false)}
                            >
                              <span className=" flex items-center bg-transparent text-black  h-6 w-6 text-2xl  outline-none focus:outline-none">
                                ×
                              </span>
                            </button>
                          </div>

                          <section>
                            <div className="rounded-lg bg-white ">
                              <div className=" flex pl-4 pr-2 py-5">
                                <span className="inline-flex text-xl text-red-500 font-medium mb-1">
                                  Do you really want to cancel reservation?
                                </span>
                                <div className="inline-flex text-xl ml-1 mt-0.5">
                                  <Tooltip data="5% fee is charged on cancelling the reservation" />
                                </div>
                              </div>
                              <div className="pb-4 px-4">
                                <label className="block text-gray-700 mb-2 font-semibold">
                                  Cancellation Reason
                                </label>
                                <textarea
                                  className="w-full p-2 border border-gray-300 rounded-md"
                                  // value={review}
                                  // onChange={(e) =>
                                  //   setReview(e.target.value)
                                  // }
                                  rows="4"
                                />
                              </div>
                            </div>
                          </section>

                          {/* footer*/}
                          <div className="flex items-center justify-end p-6 rounded-b">
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-1"
                              type="button"
                              onClick={() => setCancelReservationModal(false)}
                            >
                              No
                            </button>
                            <button
                              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-1"
                              onClick={handleCancelReservation}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <section>
                    <div className="overflow-hidden rounded-lg bg-white ">
                      <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                        {allReservation.reservation
                          .auto_approve_extension_request === 1 && (
                          <div className="w-full flex items-center justify-center">
                            <div className="mt-2">
                              <select
                                onChange={(e) => handleExtensionDate(e)}
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                className="h-11 w-[30rem] rounded-md border-0 py-1.5 pl-48 text-gray-900 shadow-sm ring-1 ring-inset text-sm ring-purple-500 focus:ring-2 focus:ring-inset focus:ring-purple-500 "
                              >
                                <option>Apply Extension</option>
                                <option value="2">2 Days</option>
                                <option value="5">5 Days</option>
                                <option value="7">7 Days</option>
                                <option value="10">10 Days</option>
                                <option value="12">12 Days</option>
                                <option value="15">15 Days</option>
                                <option value="17">17 Days</option>
                                <option value="20">20 Days</option>
                                <option value="22">22 Days</option>
                                <option value="25">25 Days</option>
                                <option value="27">27 Days</option>
                                <option value="30">30 Days</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {/* {allReservation?.reservation?.global_status ==
                          "Active.Active" && (
                          <button
                            onClick={() => setShowModal(true)}
                            className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                          >
                            View Reservation Images
                          </button>
                        )} */}

                        {allReservation?.reservation?.global_status ==
                          "Requested.Requested" ||
                        allReservation?.reservation?.global_status ==
                          "Requested.OwnerApproved" ||
                        allReservation?.reservation?.global_status ==
                          "Requested.OwnerRejected" || allReservation?.reservation?.global_status ==
                          "Requested.RenterApproved" ?  (
                          ""
                        ) : (
                          <button
                            className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                            id="announcements-title"
                            onClick={handlePDFDownload}
                          >
                            {loaderForButtonPdf
                              ? "Downloading..."
                              : "Download Contract"}
                          </button>
                        )}
                      </div>
                    </div>
                  </section>

                  {allReservation?.reservation?.global_status ==
                    "Completed.Returned" && (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Vehicle Returned Clean
                          </h1>
                          <div className="flex items-center mb-4">
                            <input
                              id="vehicle_returned_clean"
                              type="checkbox"
                              checked={vehicleReturnedClean}
                              onChange={(e) =>
                                handleVehicleReturnClean(e.target.checked)
                              }
                              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                            />
                            <label
                              for="vehicle_returned_clean"
                              className="mx-2 text-medium font-medium text-gray-900 "
                            >
                              Vehicle Returned Clean
                            </label>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {allReservation?.reservation?.global_status ==
                    "Completed.Returned" && (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Damage Inspection
                          </h1>
                          <div className="mb-1">
                            <label className="block text-gray-700 mb-1 font-semibold">
                              Description
                            </label>
                            <textarea
                              className="w-full p-2 border border-gray-300 rounded-md"
                              // value={review}
                              // onChange={(e) => setReview(e.target.value)}
                              rows="3"
                            />
                          </div>
                          <div className="w-full">
                            <span className="font-semibold">
                              Damage Inspection Images
                            </span>
                            <label
                              htmlFor="file-input-img1"
                              className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28  border-dotted"
                            >
                              <Upload className="h-8 w-8" />
                              <span>Upload Vehicle Damage Images</span>
                            </label>
                            <input
                              accept="image/*"
                              type="file"
                              multiple
                              name="file-input-img1"
                              id="file-input-img1"
                              // onChange={handleVehicleFrontImageUpload}
                              className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {allReservation?.reservation?.global_status ==
                  "Active.Waiting for Pickup" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Pickup
                          </h1>
                          <div className="flex flex-col ">
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() => setShowInspectionDrawer(true)}
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Vehicle Inspection
                              </button>{" "}
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() =>
                                  setShowReservationImagesDrwaer(true)
                                }
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Document Pickup
                              </button>{" "}
                            </div>
                            <div className="flex items-center mb-4">
                              <input
                                id="renter_vinyl_sticker_visibile"
                                type="checkbox"
                                checked={renterVinylSticker}
                                onChange={(e) =>
                                  handleVinylStickerChange(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="renter_vinyl_sticker_visibile"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Vinyl Sticker
                              </label>
                            </div>
                            <div className="flex items-center mb-4">
                              <input
                                id="renter_pickup_confirm"
                                type="checkbox"
                                checked={renterPickupConfirms}
                                onChange={(e) =>
                                  handlePickupConfirmsChange(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="renter_pickup_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Renter Pickup Confirms
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  {allReservation?.reservation?.global_status ==
                  "Active.Active" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Drop-off
                          </h1>
                          <div className="flex flex-col ">
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() => setShowInspectionDrawer(true)}
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Vehicle Inspection
                              </button>{" "}
                            </div>
                            <div className="flex items-center justify-between mb-3">
                              <button
                                onClick={() =>
                                  setShowReservationImagesDrwaer(true)
                                }
                                className="rounded-md  w-full h-11 border-2 bg-purple-500 text-white border-purple-500 py-2 px-2 text-sm font-medium shadow-sm hover:bg-purple-700 hover:border-purple-700  hover:text-white"
                              >
                                Document Pickup
                              </button>{" "}
                            </div>

                            <div className="flex items-center mb-4">
                              <input
                                id="renter_dropoff_confirm"
                                type="checkbox"
                                checked={renterDropoffConfirms}
                                onChange={(e) =>
                                  handleDropoffConfirmsChange(e.target.checked)
                                }
                                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                              />
                              <label
                                for="renter_dropoff_confirm"
                                className="mx-2 text-medium font-medium text-gray-900 "
                              >
                                Renter Drop-off Confirms
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  {/* {allReservation?.reservation?.global_status ==
                  "Active.Active" ? (
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Vinyl Sticker Applied
                          </h1>
                          <div className="flex flex-col">
                            
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}

                  {allReservation?.reservation?.global_status ==
                    "Active.Active" && (
                    <section>
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">Inspection</h1>
                          <div className="flex flex-col">
                            
                          </div>
                        </div>
                      </div>
                    </section>
                  )} */}

                  <Transition.Root show={showInspectionDrawer} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={setShowInspectionDrawer}
                    >
                      <div className="fixed inset-0" />

                      <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                              as={Fragment}
                              enter="transform transition ease-in-out duration-700"
                              enterFrom="translate-x-full"
                              enterTo="translate-x-0"
                              leave="transform transition ease-in-out duration-700"
                              leaveFrom="translate-x-0"
                              leaveTo="translate-x-full"
                            >
                              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                  <div className="px-4 sm:px-6 border-b pb-4">
                                    <div className="flex items-center justify-between">
                                      <Dialog.Title className="text-xl font-semibold leading-6 text-gray-900">
                                        Inspection
                                      </Dialog.Title>
                                      <div className="ml-3 flex h-7 items-center">
                                        <button
                                          type="button"
                                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setShowInspectionDrawer(false)
                                          }
                                        >
                                          <span className="absolute -inset-2.5" />
                                          <span className="sr-only">
                                            Close panel
                                          </span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                    {allReservation?.reservation
                                      ?.global_status ==
                                      "Active.Waiting for Pickup" && (
                                      <>
                                        <div className="flex flex-col items-start gap-y-2">
                                          <h2 className="text-xl  font-semibold">
                                            Pickup Inspection
                                          </h2>
                                          <span className="font-semibold text-sm bg-red-100 text-red-700 px-2 py-1 rounded-md">
                                            Note: "The deposit will be forfitted
                                            unless this is received at pick up"
                                          </span>
                                        </div>
                                        <PickupInspectionAccordian
                                          allReservation={allReservation}
                                        />
                                      </>
                                    )}
                                    {allReservation?.reservation
                                      ?.global_status == "Active.Active" && (
                                      <>
                                        <h2 className="text-xl mt-10 font-semibold">
                                          Drop-off Inspection
                                        </h2>
                                        <DropoffInsepectionAccordian
                                          allReservation={allReservation}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  {/* {allReservation?.reservation?.global_status ==
                  "Active.Active" ? (
                    <section aria-labelledby="announcements-title">
                      <div className="overflow-hidden rounded-lg bg-white ">
                        <div className=" flex flex-col justify-between gap-y-4 px-5 py-4 ">
                          <h1 className="text-xl font-semibold">
                            Reservation Images
                          </h1>
                          <div className="flex flex-col">
                            
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )} */}

                  <Transition.Root
                    show={showReservationImagesDrawer}
                    as={Fragment}
                  >
                    <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={setShowReservationImagesDrwaer}
                    >
                      <div className="fixed inset-0" />

                      <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                              as={Fragment}
                              enter="transform transition ease-in-out duration-700"
                              enterFrom="translate-x-full"
                              enterTo="translate-x-0"
                              leave="transform transition ease-in-out duration-700"
                              leaveFrom="translate-x-0"
                              leaveTo="translate-x-full"
                            >
                              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                  <div className="px-4 sm:px-6 border-b pb-4">
                                    <div className="flex items-center justify-between">
                                      <Dialog.Title className="text-xl font-semibold leading-6 text-gray-900">
                                        Reservation Images
                                      </Dialog.Title>
                                      <div className="ml-3 flex h-7 items-center">
                                        <button
                                          type="button"
                                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setShowReservationImagesDrwaer(
                                              false
                                            )
                                          }
                                        >
                                          <span className="absolute -inset-2.5" />
                                          <span className="sr-only">
                                            Close panel
                                          </span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="relative mt-2 flex-1 px-4 sm:px-6 ">
                                    {allReservation?.reservation
                                      ?.global_status ==
                                      "Active.Waiting for Pickup" && (
                                      <>
                                        <h2 className="text-xl  font-semibold">
                                          Pickup Images
                                        </h2>
                                        <ReservationPickupImages />
                                      </>
                                    )}

                                    {allReservation?.reservation
                                      ?.global_status == "Active.Active" && (
                                      <>
                                        <h2 className="text-xl mt-10 font-semibold ">
                                          Drop-off Images
                                        </h2>
                                        <ReservationDropOffImages />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  <section>
                    <div className="overflow-hidden rounded-lg ">
                      <div className="lg:p-6 p-4 bg-white">
                        <h3 className="text-xl font-semibold mb-6">
                          Reservation Timeline
                        </h3>

                        {reservationTimeline?.map((event, eventIdx) => (
                          <ol>
                            <li className="border-l-2 border-purple-600">
                              <div className="flex">
                                <div className="bg-purple-600 w-6 h-6 cursor-pointer flex items-center justify-center rounded-full lg:-ml-[9.5px] -ml-[12.5px]">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    className="text-white w-3 h-3"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                                    ></path>
                                  </svg>
                                </div>
                                <div className="block lg:p-6 px-3 py-5 rounded-xl shadow-lg bg-gray-100 max-w-md ml-6 mb-10 lg:w-[28rem] w-[20rem]">
                                  <div className="flex items-center justify-between">
                                    <span className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out lg:text-sm text-[14px]">
                                      {event?.message}
                                    </span>
                                    <span className="font-medium text-purple-600 hover:text-purple-700 focus:text-purple-800 duration-300 transition ease-in-out lg:text-sm text-xs">
                                      {moment(event?.creation).format("lll")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ol>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default ReservationDetails;
