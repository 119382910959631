import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "../src/Pages/Signin/Signin";
import Signup from "./Pages/Signup/Signup";
import ForgotPass from "./Pages/ForgotPass/ForgotPass";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import HomePage from "./Components/HomePage/HomePage";
import HomePageVehicleDetails from "./Components/HomePage/HomePageVehicleDetails";
import PublicallyFacingProfile from "./Components/HomePage/PublicallyFacingProfile";
import { CompanyProfile } from "./Context/CompanyProfile";
import TermsandConditions from "./Components/Global/TermsandConditions/TermsandConditions";

function App() {
  const [loginHappen, setloginHappen] = useState();
  const myfunc = (state) => {
    setloginHappen(state);
  };
  return (
    <CompanyProfile>
      <Router>
        <div>
          <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/termsconditions"
              element={<TermsandConditions />}
            />
            <Route
              path="/homepage-vehicle-details/:id"
              element={<HomePageVehicleDetails />}
            />
            <Route
              path="/publically-facing-profile/:id"
              element={<PublicallyFacingProfile />}
            />
            <Route path="/signin" element={<Signin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgo1tpassword" element={<ForgotPass />} />
          </Routes>
        </div>
      </Router>
    </CompanyProfile>
  );
}

export default App;
