import { PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";
import LoadingIcons from "react-loading-icons";

const ReservationBarSkeleton = () => {
  return (

    <div role="status" className="animate-pulse w-full">
      {/* <div className="px-4 sm:px-6 lg:px-8 min-h-screen"> */}
         
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block rounded-lg bg-white overflow-y-auto overflow-x-auto py-2 align-middle md:px-6 lg:px-8">
                  <table className="min-w-full divide-y  divide-gray-300">
                  <thead className="">
                      <tr className="w-full ">
                      <th
                          scope="col"
                          className="pl-5 pr-10  py-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        >
                          Vehicle Image
                        </th>
                        <th
                          scope="col"
                          className="pl-5 pr-5  py-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="pl-5 pr-5  py-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        >
                         Secondary Status
                        </th>
                        <th scope="col" className="pl-5 pr-3 py-3 text-left text-sm font-semibold text-gray-900">
                        Earning
                        </th>
                        <th scope="col" className="whitespace-nowrap pl-5 pr-3 py-3 text-left text-sm font-semibold text-gray-900">
                        Paid to date
                        </th>
                        <th scope="col" className="whitespace-nowrap pl-5 pr-3 py-3 text-left text-sm font-semibold text-gray-900">
                        Actual Dates
                        </th>
                        <th scope="col" className="pl-5 pr-3 py-5 text-left text-sm font-semibold text-gray-900">
                        Make Model
                        </th>
                        <th scope="col" className="pl-5 pr-5 py-3 text-left text-sm font-semibold text-gray-900">
                        Location
                        </th>
                        <th scope="col" className="pl-5 pr-3 py-3 text-left text-sm font-semibold text-gray-900">
                        Unit
                        </th>
                        <th scope="col" className=" pl-5 pr-3 py-3 text-left text-sm font-semibold text-gray-900">
                        Company
                        </th>
                        <th scope="col" className=" pl-5 pr-3 py-3 text-left text-sm font-semibold text-gray-900">
                        Action
                        </th>
                        
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td className=" whitespace-nowrap px-4 py-4 flex-shrink-0  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                            <img className='w-32 rounded-xl' src="https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png" alt="" />
                          </td>
                          <td className="whitespace-nowrap rounded-xl py-2 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                           <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"><div className="h-4 w-12 bg-green-100 rounded-full dark:bg-gray-300"></div></span>
                          </td>
                          <td className="whitespace-nowrap rounded-xl py-2 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                           <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"><div className="h-4 w-24 bg-green-100 rounded-full dark:bg-gray-300"></div></span>
                          </td>
                          <td className=" truncate whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-32 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-[24rem] bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                          <td className=" whitespace-nowrap py-4 px-4 text-sm text-gray-500 underline"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td>
                        {/* <td className="whitespace-nowrap relative  py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0"><div className="h-4 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div></td> */}
                        </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* </div> */}
            </div>
  )
}

export default ReservationBarSkeleton;
