import React, { useEffect, useRef, useState } from "react";
import LoadingIcons from "react-loading-icons";
import Trash from "@heroicons/react/24/outline/TrashIcon";
import Upload from "@heroicons/react/24/outline/CloudArrowUpIcon";
import { useFormik } from "formik";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Tooltip from "../../Global/SmallComponents/Tooltip";
import { Toaster, toast } from "sonner";
import Select from "react-tailwindcss-select";

const EditVehicle = () => {
  const options = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virgini", label: "West Virgini" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];
  // Configration
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }

  //   States
  const [loading, setLoading] = useState(false);
  const [loadingVehicle, setLoadingVehicle] = useState(true);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [totalFeatures, setTotalFeatures] = useState(null);
  const [totalLocations, setTotalLocations] = useState(null);
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [pictureDeleted, setPictureDeleted] = useState(false);
  const { vehicleID } = useParams();
  const [featuresInVehicle, setFeaturesInVehicle] = useState();
  const [faultCodeFileURL, setFaultCodeFileURL] = useState(null);
  const [regDocumentURL, setRegDocumentURL] = useState(null);
  const [initialStates, setInitialStates] = useState([]);
  const [attachImages, setAttachImages] = useState([]);

  const [healthFileURL, setHealthFileURL] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  console.log("initial state", initialStates);

  let stateArray = [];

  const handleChange = (value) => {
    console.log("onchange", value);
    // stateArray.pop();
    setInitialStates(value);
    // convertStateToArray(value)

    // setAllowedStates(convertToObjectsState(value));

    console.log("value:", value);
    // console.log("value:", allowedStates);
  };

  function convertToObjectsState(array) {
    let stateArray = [];
    for (let i = 0; i < array.length; i++) {
      stateArray.push({
        parentfield: "allowed_states",
        parenttype: "Vehicle",
        state_name: array[i].value,
        doctype: "Allowed States",
      });
    }

    return stateArray;
  }

  // Get Vehicle to edit
  useEffect(() => {
    const GetVehicle = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/resource/Vehicle/${vehicleID}`,
            config
          )
          .then(async (response) => {
            console.log(response.data.data);
            setLoadingVehicle(false);
            setCurrentVehicle(response.data.data);
            setFeaturesInVehicle(response.data.data.features);
            setAttachImages(response.data.data.attach_images);
            var ETruck = false;
            var Ramp = false;
            var CrewCab = false;
            var DockHeight = false;
            var MultiTemp = false;
            var APU = false;
            var StorageOnly = false;
            var HasIFTA = false;
            var RequiredCDL = false;
            var CTOOS = false;
            var requiresDot = false;

            setHealthFileURL(response.data.data?.health_file);
            setFaultCodeFileURL(response.data.data?.fault_attach);
            setRegDocumentURL(
              response.data.data?.vehicle_registration_document
            );

            const loopToAddStates = () => {
              for (
                let i = 0;
                i < response.data.data?.allowed_states.length;
                i++
              ) {
                stateArray.push({
                  value: response.data.data?.allowed_states[i].state_name,
                  label: response.data.data?.allowed_states[i].state_name,
                });
              }
              return stateArray;
            };

            setInitialStates(loopToAddStates());

            // console.log("hello", loopToAddStates());
            // console.log("new", initialStates);

            if (response.data.data.e_truck == 1) {
              ETruck = true;
            }
            if (response.data.data.ramp == 1) {
              Ramp = true;
            }
            if (response.data.data.crew_cab == 1) {
              CrewCab = true;
            }
            if (response.data.data.dock_height == 1) {
              DockHeight = true;
            }
            if (response.data.data.multi_temp == 1) {
              MultiTemp = true;
            }
            if (response.data.data.apu == 1) {
              APU = true;
            }
            if (response.data.data.storage_only == 1) {
              StorageOnly = true;
            }
            if (response.data.data.has_ifta == 1) {
              HasIFTA = true;
            }
            if (response.data.data.require_cdl == 1) {
              RequiredCDL = true;
            }
            if (response.data.data.can_travel_out_of_state == 1) {
              CTOOS = true;
            }
            if (response.data.data.requires_dot == 1) {
              requiresDot = true;
            }
            VehicleEditForm.setValues({
              make: response.data.data?.make,
              model: response.data.data?.model,
              plate_state: response.data.data?.plate_state,
              plate_number: response.data.data?.plate_number,
              registeration_number: response.data.data?.registration_number,
              registeration_year: response.data.data?.year,
              vin: response.data.data?.vin,
              description: response.data.data?.description,
              vehicle_type: response.data.data?.vehicle_type,
              body_type: response.data.data?.body_type,
              door_type: response.data.data?.door_type,
              lift_gate: response.data.data?.lift_gate,
              fuel_type: response.data.data?.fuel_type,
              fuel_milage: response.data.data?.fuel_milage,
              equipment_price: response.data.data?.equipment_price,
              health_inspection: response.data.data?.health_inspection,
              ramp: Ramp,
              e_truck: ETruck,
              crew_cab: CrewCab,
              dock_height: DockHeight,
              multi_temp: MultiTemp,
              apu: APU,
              storage_only: StorageOnly,
              has_ifta: HasIFTA,
              rent_per_day: response.data.data?.rent_per_day,
              require_cdl: RequiredCDL,
              can_travel: CTOOS,
              pick_up: response.data.data?.pick_up,
              drop_off: response.data.data?.drop_off,
              min_rental_days_out: response.data.data?.min_rental_days_out,
              availability_status: response.data.data?.availability_status,
              requires_dot: requiresDot,
              fault_code: response.data.data?.fault_code,
              usage_restrictions: response.data.data?.usage_restrictions,
              vehicle_registration_expiration_date:
                response.data.data?.vehicle_registration_expiration_date,
              unit_number: response.data.data?.unit_number,
              allowed_states: initialStates,

              // features: response.data.data.feature.map((f) => f.features),
            });
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetVehicle();
  }, [pictureDeleted]);

  console.log("images", images);
  console.log("attachImages", attachImages);

  const uploadFaultCodeHandle = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadFaultCodeFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setFaultCodeFileURL(response.data.message.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadFaultCodeFile();
  };

  const uploadRegDocumentHandle = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadFaultCodeFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setRegDocumentURL(response.data.message.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadFaultCodeFile();
  };

  const uploadHealthInspectionHandle = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setHealthFileURL(response.data.message.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleDeleteImagePre =  (image) => {
    // const deleteImage = async () => {
    //   console.log(id);
    //   try {
    //     await axios
    //       .delete(
    //         `${window.$backendURL}/api/resource/Vehicle Images/${id}`,
    //         config
    //       )
    //       .then((response) => {
    //         console.log("Images Delete",response);
    //         setPictureDeleted(!pictureDeleted);
    //       });
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // deleteImage();

    // console.log(attachImages);
    // console.log(image?.name);

    // const filterImages =
    // console.log("filterImages", filterImages);
    setAttachImages(attachImages?.filter((img) => img?.name !== image?.name));
  };

  // Get Features from backend
  useEffect(() => {
    const GetVehiclesFeatures = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Vehicle Features?fields=["*"]`,
            config
          )
          .then((responseFea) => {
            setTotalFeatures(responseFea.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    GetVehiclesFeatures();
  }, []);

  var imgObj = [];
  var imgObjtoAdd;

  const handleImageChange = async (event) => {
    const newImageFiles = Array.from(event.target.files);
    uploadFlightDocument(newImageFiles);

    // setImageFiles(newImageFiles);
    // const newImages = newImageFiles?.map((file) => URL.createObjectURL(file));
    // setImages(images?.concat(newImages));
    // console.log("newImages", newImages);
  };

  function uploadFlightDocument(e) {
    for (let index = 0; index < e.length; index++) {
      console.log("><><<", e[index]);
      const file = e[index];
      const formData = new FormData();
      formData.append("file", file);
      console.log("formData", formData);
      const uploadFlightApi = async () => {
        try {
          await axios
            .post(
              `${window.$backendURL}/api/method/upload_file`,
              formData,
              config
            )
            .then((responseFlight) => {
              console.log("responseFlight", responseFlight);
              imgObj.push(responseFlight.data.message);
              if (imgObj) {
                imgObjtoAdd = convertToObjectsImg(imgObj);
                console.log(imgObjtoAdd);
                var newiamgecancatenat = imgObjtoAdd.concat(attachImages);
                setAttachImages(newiamgecancatenat);
                console.log("newiamgecancatenat", newiamgecancatenat);
              }
            });
        } catch (e) {
          console.log(e);
        }
      };

      uploadFlightApi();
    }
  }

  // const uploadImagesFiles = (imageFiles) =>{
  //   for (let index = 0; index < imageFiles.length; index++) {
  //     console.log(imageFiles[index]);
  //     const file = imageFiles[index];
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     // const uploadVehicleImage = async () => {
  //     //   try {
  //     //     await axios
  //     //       .post(
  //     //         `${window.$backendURL}/api/method/upload_file`,
  //     //         formData,
  //     //         config
  //     //       )
  //     //       .then((response) => {
  //     //         console.log(response.data.message.file_url);
  //     //         imgObj.push(response.data.message.file_url);
  //     //         if (imgObj) {
  //     //           imgObjtoAdd = convertToObjectsImg(imgObj);
  //     //           console.log("imgObjtoAdd", imgObjtoAdd);
  //     //           // updatedImages  = attachImages.concat(imgObjtoAdd)
  //     //           // console.log("updatedImages", updatedImages);
  //     //         }
  //     //       });
  //     //   } catch (e) {
  //     //     console.log(e);
  //     //   }
  //     // };
  //     // await uploadVehicleImage();
  //   }
  // }

  const handleDeleteImage = (index) => {
    setImages(images?.filter((image, i) => i !== index));
    setImageFiles(imageFiles.filter((file, i) => i !== index));
  };

  // Get Locations
  useEffect(() => {
    const GetAllLocations = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/resource/Location?fields=["*"]&filters=[["user","=","${userEmail}"]]`,
            config
          )
          .then((response) => {
            setTotalLocations(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    GetAllLocations();
  }, []);

  function convertToObjects(array) {
    let objectArray = [];
    for (let i = 0; i < array.length; i++) {
      objectArray.push({
        parentfield: "feature",
        parenttype: "Vehicle",
        features: array[i],
        doctype: "Vehicle Features Child",
      });
    }
    return objectArray;
  }

  // function convertToObjectsImg(array) {
  //   console.log(array);
  //   let mainArray = array;
  //   let objectArray = [];

  //   if (currentVehicle?.attach_images.length > 1) {
  //     for (
  //       let index = 0;
  //       index < currentVehicle.attach_images.length;
  //       index++
  //     ) {
  //       mainArray?.push(currentVehicle?.attach_images[index]?.image);
  //       console.log("mainArray",mainArray);
  //     }
  //   }

  //   for (let i = 0; i < mainArray.length; i++) {
  //     objectArray.push({
  //       parentfield: "attach_images",
  //       parenttype: "Vehicle",
  //       image: mainArray[i],
  //       doctype: "Vehicle Images",
  //     });
  //   }
  //   return objectArray;
  // }

  function convertToObjectsImg(array) {
    let objectArray = [];
    for (let i = 0; i < array.length; i++) {
      objectArray.push({
        parentfield: "attach_images",
        parenttype: "Vehicle",
        image: array[i].file_url,
        doctype: "Vehicle Images",
      });
    }

    return objectArray;
  }

  const [formErrors, setFormErrors] = useState({});

  const firstErrorRef = useRef(null);

  // Formik for Edit Vehicle Page
  const VehicleEditForm = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      var imgObj = [];
      var imgObjtoAdd;
      var updatedImages;
      var eTruckVar = 0;
      var crewCab = 0;
      var multiTemp = 0;
      var storageOnly = 0;
      var ramp = 0;
      var dockHeight = 0;
      var APU = 0;
      var hasIFTA = 0;
      var requiredCDL = 0;
      var canTravel = 0;
      var requiresDot = 0;
      if (values.e_truck) {
        if (values.e_truck == true) {
          eTruckVar = 1;
        }
      }
      if (values.ramp) {
        if (values.ramp == true) {
          ramp = 1;
        }
      }
      if (values.crew_cab) {
        if (values.crew_cab == true) {
          crewCab = 1;
        }
      }
      if (values.dock_height) {
        if (values.dock_height == true) {
          dockHeight = 1;
        }
      }
      if (values.multi_temp) {
        if (values.multi_temp == true) {
          multiTemp = 1;
        }
      }
      if (values.apu) {
        if (values.apu == true) {
          APU = 1;
        }
      }
      if (values.storage_only) {
        if (values.storage_only == true) {
          storageOnly = 1;
        }
      }
      if (values.has_ifta) {
        if (values.has_ifta == true) {
          hasIFTA = 1;
        }
      }
      if (values.require_cdl) {
        if (values.require_cdl == true) {
          requiredCDL = 1;
        }
      }
      if (values.can_travel) {
        if (values.can_travel == true) {
          canTravel = 1;
        }
      }
      if (values.requires_dot) {
        if (values.requires_dot == true) {
          requiresDot = 1;
        }
      }
      // if (values.features) {
      //   if (values.features.length > 0) {
      //     var featureObj = convertToObjects(values.features);
      //   }
      // }
      var updateVehicleJSON;

      // const handleUploadedFiles = async () => {
      //   if (imageFiles) {

      updateVehicleJSON = {
        user: userEmail,
        make: values?.make,
        model: values?.model,
        plate_state: values?.plate_state,
        plate_number: values?.plate_number,
        registration_number: values?.registeration_number,
        year: values?.registeration_year,
        vin: values?.vin,
        description: values?.description,
        vehicle_type: values?.vehicle_type,
        equipment_price: values?.equipment_price,
        lift_gate: values?.lift_gate,
        body_type: values?.body_type,
        door_type: values?.door_type,
        fuel_type: values?.fuel_type,
        fuel_milage: values?.fuel_milage,
        feature: values?.extra_features,
        health_inspection: values?.health_inspection,
        rent_per_day: values.rent_per_day,
        health_file: healthFileURL,
        e_truck: eTruckVar,
        ramp: ramp,
        crew_cab: crewCab,
        dock_height: dockHeight,
        multi_temp: multiTemp,
        apu: APU,
        storage_only: storageOnly,
        has_ifta: hasIFTA,
        require_cdl: requiredCDL,
        can_travel_out_of_state: canTravel,
        // feature: featureObj,
        attach_images: attachImages,
        drop_off: values?.drop_off,
        pick_up: values?.pick_up,
        requires_dot: requiresDot,
        fault_code: values?.fault_code,
        usage_restrictions: values?.usage_restrictions,
        min_rental_days_out: values?.min_rental_days_out,
        fault_attach: faultCodeFileURL,
        availability_status: values?.availability_status,
        vehicle_registration_expiration_date:
          values?.vehicle_registration_expiration_date,
        vehicle_registration_document: regDocumentURL,
        unit_number: values?.unit_number,
        allowed_states: convertToObjectsState(initialStates),
      };

      if(attachImages.length >= 2){
        console.log(attachImages.length);

      const updateVehicleApi = async () => {
        console.log("updateVehicleJSON", updateVehicleJSON);
        const isValid = validateForm(values);

        if (!isValid) {
          // Focus on the first invalid input field
          if (firstErrorRef.current) {
            firstErrorRef.current.focus();
          }
          return; // Prevent form submission if there are errors
        }

        try {
          //   setLoading(true);
          await axios
            .put(
              `${window.$backendURL}/api/resource/Vehicle/${vehicleID}`,
              updateVehicleJSON,
              config
            )
            .then((response) => {
              toast.success("Vehicle Updated Successfully");
              console.log(updateVehicleJSON);
              setIsSuccess(true);
              setTimeout(() => {
                setIsSuccess(false);
              }, 2000);
            });
          //   setLoading(false);
        } catch (e) {
          toast.error("Failed to update Vehicle");
          console.error(e);
        }
      };
      setLoading(true);
      // await handleUploadedFiles();
      await updateVehicleApi();
      setLoading(false);
    }
    else{
      toast.error("Add more than 1 vehicle image");
    }
  },
    enableReinitialize: true,
  });

  const validateForm = (values) => {
    const requiredFields = [
      "make",
      "vehicle_type",
      "registeration_year",
      "rent_per_day",
      "vin",
      "equipment_price",
      "pick_up",
      "equipment_price"
    ];
    const errors = {};

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "This field is required";
      }
    });

    if (values.vehicle_type === "Trailers" && values.body_type !== "Flatbed" && !values?.door_type){
      errors.door_type = "This field is required for non-flatbed trailers";
    }
    else if (values.vehicle_type === "Trailers" &&  !values?.unit_number){
      errors.unit_number = "This field is required for trailers";
    }

    setFormErrors(errors);

    // Keep track of the first invalid input field
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorInput = document.querySelector(`[name=${firstErrorKey}]`);
      if (firstErrorInput) {
        firstErrorRef.current = firstErrorInput;
      }
    }

    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  return (
    <>
      <Toaster richColors />
      <Link className="font-semibold flex flex-row gap-2" to="/VehicleListing">
        <svg
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span>Vehicle Listing</span>
      </Link>
      <form onSubmit={VehicleEditForm.handleSubmit} className="mt-4">
        <div className=" px-4 py-5  sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            {loadingVehicle ? (
              <>
                {" "}
                <div role="status" className=" animate-pulse w-full">
                  <div className="flex gap-1">
                    <div className="h-4 w-16 bg-purple-400 rounded-full "></div>
                    <div className="h-4 w-16 bg-purple-400 rounded-full "></div>
                    <div className="h-4 w-16 bg-purple-400 rounded-full "></div>
                  </div>
                </div>
              </>
            ) : (
              <div className=" flex items-center  gap-2 md:col-span-1">
                <h3 className="text-xl font-semibold leading-6 text-gray-900">
                  {currentVehicle?.make +
                    " " +
                    currentVehicle?.model +
                    " " +
                    currentVehicle?.year}
                </h3>
                <span
                  className={
                    currentVehicle?.approval_status == "Verified"
                      ? "inline-flex h-6 rounded-full bg-green-100 px-4 text-xs font-semibold leading-5 text-green-800  sm:ml-2 items-center"
                      : currentVehicle?.approval_status == "Pending"
                      ? "inline-flex h-6 rounded-full bg-orange-100 px-4 text-xs font-semibold leading-5 text-orange-800  sm:ml-2 items-center"
                      : "inline-flex h-6 rounded-full bg-red-100 px-4 text-xs font-semibold leading-5 text-red-800  sm:ml-2 items-center"
                  }
                >
                  {currentVehicle?.approval_status}
                </span>
              </div>
            )}

            <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="make"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Make <span className="text-red-500 font-semibold">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="make"
                      id="make"
                      ref={firstErrorRef}
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.make}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                  {formErrors.make && (
                    <div style={{ color: "red" }}>{formErrors.make}</div>
                  )}
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="model"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Model
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="model"
                      id="model"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.model}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="vehicle_type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Vehicle Type{" "}
                    <span className="text-red-500 font-semibold">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      type="text"
                      name="vehicle_type"
                      id="vehicle_type"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.vehicle_type}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    >
                      <option value="">Select</option>
                      <option value="Day Cab">Day Cab</option>
                      <option value="E-Van">E-Van</option>
                      <option value="Tractor Sleeper">Tractor Sleeper</option>
                      <option value="Tractor Day Cab">Tractor Day Cab</option>
                      <option value="Single Tandem">Single Tandem</option>
                      <option value="Box Truck">Box Truck</option>
                      <option value="Van Cargo">Van Cargo</option>
                      <option value="Truck Pickup">Truck Pickup</option>
                      <option value="Trailers">Trailers</option>
                      <option value="Refrigerated Box Truck">
                        Refrigerated Box Truck
                      </option>
                    </select>
                  </div>
                  {formErrors.vehicle_type && (
                    <div style={{ color: "red" }}>
                      {formErrors.vehicle_type}
                    </div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="body_type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Trailer Type
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      type="text"
                      name="body_type"
                      id="body_type"
                      disabled={
                        VehicleEditForm.values.vehicle_type !== "Trailers" &&
                        true
                      }
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.body_type}
                      className={`block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm ${
                        VehicleEditForm.values.vehicle_type !== "Trailers" &&
                        "cursor-not-allowed"
                      }`}
                    >
                      <option value="">Select</option>
                      <option value="Dry Van">Dry Van</option>
                      <option value="Reefer">Reefer</option>
                      <option value="Flatbed">Flatbed</option>
                      <option value="Step Deck">Step Deck</option>
                    </select>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="door_type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Door Type{" "}
                    {VehicleEditForm.values.vehicle_type === "Trailers" &&
                    VehicleEditForm.values.body_type !== "Flatbed" ? (
                      <span className="text-red-500 font-semibold">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      type="text"
                      name="door_type"
                      id="door_type"
                      disabled={
                        VehicleEditForm.values.vehicle_type !== "Trailers" ||
                        VehicleEditForm.values.body_type === "Flatbed"
                          ? true
                          : false
                      }
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.door_type}
                      className={`block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm ${
                        VehicleEditForm.values.vehicle_type !== "Trailers" ||
                        VehicleEditForm.values.body_type === "Flatbed"
                          ? "cursor-not-allowed"
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      <option value="Roll Up">Roll Up</option>
                      <option value="Swing">Swing</option>
                    </select>
                  </div>
                  {formErrors.door_type && (
                    <div style={{ color: "red" }}>{formErrors.door_type}</div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="registeration_year"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Year <span className="text-red-500 font-semibold">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="number"
                      max="9999"
                      name="registeration_year"
                      id="registeration_year"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.registeration_year}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                  {formErrors.registeration_year && (
                    <div style={{ color: "red" }}>
                      {formErrors.registeration_year}
                    </div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="unit_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ID Number/Unit Number{" "}
                    {VehicleEditForm.values.vehicle_type == "Trailers" && (
                      <span className="text-red-500 font-semibold">*</span>
                    )}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="unit_number"
                      id="unit_number"
                      disabled={
                        VehicleEditForm.values.vehicle_type !== "Trailers" &&
                        true
                      }
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.unit_number}
                      className={`block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm ${
                        VehicleEditForm.values.vehicle_type !== "Trailers" &&
                        "cursor-not-allowed"
                      }`}
                    />
                  </div>
                  {formErrors.unit_number && (
                    <div style={{ color: "red" }}>{formErrors.unit_number}</div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="plate_state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Plate State
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      type="text"
                      name="plate_state"
                      id="plate_state"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.plate_state}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    >
                      <option value="">Select</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="plate_state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Disallowed States
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Select
                      value={initialStates}
                      placeholder="Select"
                      onChange={(e) => handleChange(e)}
                      primaryColor="purple"
                      options={options}
                      isMultiple={true}
                      isClearable={true}
                      className="block w-full flex-1  rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="plate_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Plate Number
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="plate_number"
                      id="plate_number"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.plate_number}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="vin"
                    className="block text-sm font-medium text-gray-700"
                  >
                    VIN <span className="text-red-500 font-semibold">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="vin"
                      id="vin"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.vin}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                  {formErrors.vin && (
                    <div style={{ color: "red" }}>{formErrors.vin}</div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="description"
                      id="description"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.description}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="lift_gate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Lift Gate
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      type="text"
                      name="lift_gate"
                      id="lift_gate"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.lift_gate}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    >
                      <option value="">Select</option>
                      <option value="No Lift Gate">No Lift Gate</option>
                      <option value="Rail Gate">Rail Gate</option>
                      <option value="Truck Under">Truck Under</option>
                    </select>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="rent_per_day"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Rent per day ($){" "}
                    <span className="text-red-500 font-semibold">*</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="rent_per_day"
                      id="rent_per_day"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.rent_per_day}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                  {formErrors.rent_per_day && (
                    <div style={{ color: "red" }}>
                      {formErrors.rent_per_day}
                    </div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="min_rental_days_out"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Minimum Rental Days Out{" "}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="number"
                      name="min_rental_days_out"
                      id="min_rental_days_out"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.min_rental_days_out}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                  </div>
                </div>

                {/* <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="unit_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Unit Number
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="number"
                      name="unit_number"
                      id="unit_number"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.unit_number}
                      min="0"
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                  </div>
                </div> */}

                <div className="col-span-6 sm:col-span-3">
                <div className="flex flex-col items-start">
                        <label
                          htmlFor="equipment_price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Equipment Value ($){" "}
                          <span className="text-red-500 font-semibold">*</span>
                        </label>
                          <span className="text-xs font-semibold">Note: "Trailers are covered for stated value. Trucks/Tractors are covered for Actual Cash Value (ACV) meaning "market value".</span>
                          </div>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="number"
                      name="equipment_price"
                      id="equipment_price"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.equipment_price}
                      min="0"
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                  </div>
                  {formErrors.equipment_price && (
                    <div style={{ color: "red" }}>
                      {formErrors.equipment_price}
                    </div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="usage_restrictions"
                    className="flex gap-1 text-sm font-medium text-gray-700"
                  >
                    <span> Usage Restriction</span>{" "}
                    <Tooltip data="Let potential renters know what they can and can't do with your vehicle." />
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <textarea
                      type="text"
                      name="usage_restrictions"
                      id="usage_restrictions"
                      onChange={VehicleEditForm.handleChange}
                      value={VehicleEditForm.values.usage_restrictions}
                      className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Fuel Type
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="fuel_type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fuel Type
                  </label>
                  <select
                    type="text"
                    name="fuel_type"
                    id="fuel_type"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.fuel_type}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  >
                    <option value="">Select</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Gasoline">Gasoline</option>
                    <option value="Natural Gas">Natural Gas</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="Electric">Electric</option>
                  </select>
                </div>

                {/* <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="fuel_milage"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fuel Milage 
                  </label>
                  <input
                    type="text"
                    name="fuel_milage"
                    id="fuel_milage"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.fuel_milage}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Location
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="pick_up"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Pick Up/Drop Off{" "}
                    <span className="text-red-500 font-semibold">*</span>
                  </label>
                  <select
                    type="text"
                    name="pick_up"
                    id="pick_up"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.pick_up}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  >
                    <option value="">Select</option>
                    {totalLocations?.map((location, index) => {
                      return (
                        <option key={location.name} value={location.name}>
                          {location.location_name}
                        </option>
                      );
                    })}
                  </select>
                  {formErrors.pick_up && (
                    <div style={{ color: "red" }}>{formErrors.pick_up}</div>
                  )}
                </div>

                {/* <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="drop_off"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Drop Off 
                  </label>
                  <select
                    type="text"
                    name="drop_off"
                    id="drop_off"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.drop_off}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  >
                    <option value="">Select</option>
                    {totalLocations?.map((location, index) => {
                      return (
                        <option key={location.name} value={location.name}>
                          {location.location_name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
              </div>
            </div>
          </div>

          {/* <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-2">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Add Any Extra Features
              </h3>
            </div>
            <div className="grid grid-cols-3 gap-6">
              {totalFeatures?.map((feature) => (
                <div
                  key={feature.name}
                  className="flex flex-row gap-1 items-center"
                >
                  <input
                    type="checkbox"
                    name={feature.name}
                    className="block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    checked={VehicleEditForm.values.features?.includes(
                      feature.name
                    )}
                    onChange={() => {
                      if (
                        VehicleEditForm.values.features?.includes(feature.name)
                      ) {
                        VehicleEditForm.setFieldValue(
                          "features",
                          VehicleEditForm.values.features.filter(
                            (f) => f !== feature.name
                          )
                        );
                      } else {
                        VehicleEditForm.setFieldValue("features", [
                          ...VehicleEditForm.values.features,
                          feature.name,
                        ]);
                      }
                    }}
                  />
                  <label
                    htmlFor={feature.name}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {feature.feature_name}
                  </label>
                </div>
              ))}
            </div>
          </div> */}

          {/* <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="flex">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900 mb-1">
                Health Check (Recomended DOT inspection)  
              <span className="text-sm bg-green-100 rounded-full px-3 py-0.5  text-green-800 ml-2">Verify in last 12 months</span>
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="health_inspection"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Health Inspection
                  </label>
                  <input
                    type="text"
                    name="health_inspection"
                    id="health_inspection"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.health_inspection}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="file-input"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Health Inspection Document
                  </label>
                  <input
                    type="file"
                    name="file-input"
                    id="file-input"
                    onChange={(e) => {
                      uploadHealthInspectionHandle(e);
                    }}
                    className="block w-full mb-2 border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                  />
                  {currentVehicle?.health_file && (
                    <a
                      target="_blank"
                      className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                      href={window.$backendURL + currentVehicle?.health_file}
                    >
                      View Uploaded File
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Fault Code (Optional)
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="fault_code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fault Code
                  </label>
                  <input
                    type="text"
                    name="fault_code"
                    id="fault_code"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.fault_code}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  />
                  
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="fault_attach"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fault Code Document
                  </label>
                  <input
                    type="file"
                    name="fault_attach"
                    id="fault_attach"
                    onChange={(e) => {
                      uploadFaultCodeHandle(e);
                    }}
                    className="block mb-2 w-full border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                  />
                  {currentVehicle?.fault_attach && (
                    <a
                      target="_blank"
                      className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                      href={window.$backendURL + currentVehicle?.fault_attach}
                    >
                      View Uploaded File
                    </a>
                  )}
                  
                </div>
              </div>
            </div>
          </div> */}
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Vehicle Documents
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="vehicle_registration_expiration_date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Registration Expiry Date
                  </label>
                  <input
                    type="date"
                    name="vehicle_registration_expiration_date"
                    id="vehicle_registration_expiration_date"
                    onChange={VehicleEditForm.handleChange}
                    value={
                      VehicleEditForm.values
                        .vehicle_registration_expiration_date
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="vehicle_registration_document"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Registration Document
                  </label>
                  <input
                    type="file"
                    name="vehicle_registration_document"
                    id="vehicle_registration_document"
                    onChange={(e) => {
                      uploadRegDocumentHandle(e);
                    }}
                    className="block mb-2 w-full border border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                  />
                  {currentVehicle?.vehicle_registration_document && (
                    <a
                      target="_blank"
                      className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                      href={
                        window.$backendURL +
                        currentVehicle?.vehicle_registration_document
                      }
                    >
                      View Uploaded File
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Vehicle Space (Select all that apply)
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="e_truck"
                      id="e_truck"
                      onChange={() => {
                        if (VehicleEditForm.values.e_truck == true) {
                          VehicleEditForm.setFieldValue("e_truck", false);
                        } else {
                          VehicleEditForm.setFieldValue("e_truck", true);
                        }
                      }}
                      checked={VehicleEditForm.values.e_truck}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="e_truck"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Truck
                    </label>
                  </div>
                </div>

                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="ramp"
                      id="ramp"
                      onChange={() => {
                        if (VehicleEditForm.values.ramp == true) {
                          VehicleEditForm.setFieldValue("ramp", false);
                        } else {
                          VehicleEditForm.setFieldValue("ramp", true);
                        }
                      }}
                      checked={VehicleEditForm.values.ramp}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="ramp"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ramp
                    </label>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="crew_cab"
                      id="crew_cab"
                      checked={VehicleEditForm.values.crew_cab}
                      onChange={() => {
                        if (VehicleEditForm.values.crew_cab == true) {
                          VehicleEditForm.setFieldValue("crew_cab", false);
                        } else {
                          VehicleEditForm.setFieldValue("crew_cab", true);
                        }
                      }}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="crew_cab"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Crew Cab
                    </label>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="dock_height"
                      id="dock_height"
                      onChange={() => {
                        if (VehicleEditForm.values.dock_height == true) {
                          VehicleEditForm.setFieldValue("dock_height", false);
                        } else {
                          VehicleEditForm.setFieldValue("dock_height", true);
                        }
                      }}
                      checked={VehicleEditForm.values.dock_height}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="dock_height"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Dock Height
                    </label>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="multi_temp"
                      id="multi_temp"
                      checked={VehicleEditForm.values.multi_temp}
                      onChange={() => {
                        if (VehicleEditForm.values.multi_temp == true) {
                          VehicleEditForm.setFieldValue("multi_temp", false);
                        } else {
                          VehicleEditForm.setFieldValue("multi_temp", true);
                        }
                      }}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="multi_temp"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Multi - Temp
                    </label>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="apu"
                      id="apu"
                      checked={VehicleEditForm.values.apu}
                      onChange={() => {
                        if (VehicleEditForm.values.apu == true) {
                          VehicleEditForm.setFieldValue("apu", false);
                        } else {
                          VehicleEditForm.setFieldValue("apu", true);
                        }
                      }}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="apu"
                      className="block text-sm font-medium text-gray-700"
                    >
                      APU
                    </label>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="storage_only"
                      id="storage_only"
                      onChange={() => {
                        if (VehicleEditForm.values.storage_only == true) {
                          VehicleEditForm.setFieldValue("storage_only", false);
                        } else {
                          VehicleEditForm.setFieldValue("storage_only", true);
                        }
                      }}
                      checked={VehicleEditForm.values.storage_only}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="storage_only"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Storage Only
                    </label>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="has_ifta"
                      id="has_ifta"
                      checked={VehicleEditForm.values.has_ifta}
                      onChange={() => {
                        if (VehicleEditForm.values.has_ifta == true) {
                          VehicleEditForm.setFieldValue("has_ifta", false);
                        } else {
                          VehicleEditForm.setFieldValue("has_ifta", true);
                        }
                      }}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="has_ifta"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Has IFTA
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Reservation Requirements
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="require_cdl"
                      id="require_cdl"
                      onChange={() => {
                        if (VehicleEditForm.values.require_cdl == true) {
                          VehicleEditForm.setFieldValue("require_cdl", false);
                        } else {
                          VehicleEditForm.setFieldValue("require_cdl", true);
                        }
                      }}
                      checked={VehicleEditForm.values.require_cdl}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="require_cdl"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Required CDL
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="requires_dot"
                      id="requires_dot"
                      onChange={() => {
                        if (VehicleEditForm.values.requires_dot == true) {
                          VehicleEditForm.setFieldValue("requires_dot", false);
                        } else {
                          VehicleEditForm.setFieldValue("requires_dot", true);
                        }
                      }}
                      checked={VehicleEditForm.values.requires_dot}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="requires_dot"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Requires DOT
                    </label>
                  </div>
                </div>

                <div className="col-span-3 sm:col-span-1">
                  <div className="flex flex-row gap-1 items-center">
                    <input
                      type="checkbox"
                      name="can_travel"
                      id="can_travel"
                      onChange={() => {
                        if (VehicleEditForm.values.can_travel == true) {
                          VehicleEditForm.setFieldValue("can_travel", false);
                        } else {
                          VehicleEditForm.setFieldValue("can_travel", true);
                        }
                      }}
                      checked={VehicleEditForm.values.can_travel}
                      className=" block  rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                    />
                    <label
                      htmlFor="can_travel"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Can travel out of state
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Availability Status
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-1">
                  <label
                    htmlFor="availability_status"
                    className="flex gap-1 text-sm font-medium text-gray-700"
                  >
                    <span>Availability</span>{" "}
                    <Tooltip data="Unlisting your vehicle will remove it from the search results" />
                  </label>
                  <select
                    type="text"
                    name="availability_status"
                    id="availability_status"
                    onChange={VehicleEditForm.handleChange}
                    value={VehicleEditForm.values.availability_status}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Unavailable">Unavailable</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* Gallery Of Photos */}
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                Upload Vehicle Photos
              </h3>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="file-input-img"
                  className="flex flex-col justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-36  border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Update 4 Photos of Vehicle</span>
                  <span>{"(Front, Back, Left, Right)"}</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img"
                  id="file-input-img"
                  multiple
                  onChange={handleImageChange}
                  className="block w-full border sr-only border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500
file:bg-transparent file:border-0
file:bg-purple-800 file:text-white file:mr-4
file:py-3 file:px-4"
                />
              </div>

              <section
                aria-labelledby="profile-overview-title"
                className="pt-5"
              >
                <div className=" ">
                  <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {images?.map((image, index) => (
                      <div
                        key={index}
                        className="relative flex justify-center items-center"
                      >
                        <div className="flex justify-center  bg-purple-200 max-h-48 rounded-lg">
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="max-h-64 "
                          />
                        </div>
                        <button
                          onClick={() => handleDeleteImage(index)}
                          className=" absolute -top-2 -right-3 flex justify-center items-center  h-8 w-8 rounded-full bg-red-600 "
                        >
                          <Trash
                            className="h-6 w-6"
                            style={{ color: "white" }}
                          />
                        </button>
                      </div>
                    ))}
                    {attachImages?.map((image, index) => (
                      <div
                        key={index}
                        className="relative flex justify-center items-center"
                      >
                        {/* {image?.image && <> */}
                        <div className="flex justify-center  bg-purple-200 max-h-48 rounded-lg">
                          <img
                            src={window.$backendURL + image.image}
                            alt={`Image ${index + 1}`}
                            className="max-h-64 rounded"
                          />
                        </div>
                        <button
                          onClick={() => handleDeleteImagePre(image)}
                          type="button"
                          className=" absolute -top-2 -right-3 flex justify-center items-center  h-8 w-8 rounded-full bg-red-600 "
                        >
                          <Trash
                            className="h-6 w-6"
                            style={{ color: "white" }}
                          />
                        </button>
                        {/* </>} */}
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <div className="flex justify-end pr-8 pt-4">
                {loading ? (
                  <>
                    <div className="rounded-md flex justify-center items-center gap-4 border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none ">
                      <LoadingIcons.BallTriangle className="h-6 w-6" />
                    </div>
                  </>
                ) : (
                  <>
                    {isSuccess ? (
                      <span className="rounded-md border border-transparent py-2.5 px-5 text-md font-medium text-white shadow-sm bg-green-700">
                        Updated Successfully
                      </span>
                    ) : (
                      <button
                        className="rounded-md border border-transparent button py-2.5 px-5 text-md font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditVehicle;
