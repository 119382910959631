
import React from 'react';


const LoaderSpinner = () => {
  return (
    <div id="loader-overlay" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  );
};

export default LoaderSpinner;
