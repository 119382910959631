import { PencilIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import CheckRole from "./CheckRole";
import { Link } from "react-router-dom";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import userAvatar from "../../../img/user.png";
import { loadStripe } from "@stripe/stripe-js";
import { Toaster, toast } from "sonner";
import LoaderSpinner from "../Skeleton/LoaderSpinner";
const Profile = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [loading, setLoading] = useState(false);
  const [currProfile, setCurrProfile] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeResponse, setStripeResponse] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeObject = await loadStripe(
        "pk_live_51McDWdLUqlbLOiA4OFtWtx9aK2FQVZ3WaGYoRBLjfiVUOFMHaJhr53SqWZlsHsKqxbLHBu2Z6W58L9sUBT1yYKEF00x1WJTl0W"
      );
      setStripe(stripeObject);
    };

    initializeStripe();
  }, []);

  const handleVerification = async (event) => {
    // event.preventDefault();
    setLoader(true);

    if (!stripe) {
      return;
    }

    try {
      // Call your backend to create the VerificationSession.
      const response = await axios.post(
        `${window.$backendURL}/api/method/truckrent.api.get_client_secret`,
        {
          business_profile: currProfile.name,
          type: "Company",
        }
      );
      console.log("stripeResponse", response.data.data.key);
      const session = await response;
      console.log("stripeResponse", session);

      setTimeout(() => {
        setLoader(false);
      }, 2000);
      // Show the verification modal.
      const { error } = await stripe.verifyIdentity(
        session.data.data.key.client_secret
      );

      if (error) {
        toast.error("Verfication Not Completed");
        console.error("[error]", error);
      } else {
        toast.success("Verfication Completed Successfully");
        setStripeResponse("Verification submitted!");
        window.location.reload();
        console.log("Verfication Completed!");
      }
    } catch (error) {
      toast.error("Error creating VerificationSession");
      console.error("Error creating VerificationSession:", error);
    }
  };
  
  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            setCurrProfile(response.data.data);
            console.log(response.data.data);
            setLoading(false);
          });
      } catch (e) {
        console.error(e);
      }
    };

    getProfileOwner();
  }, []);

  return (
    <>
      <Toaster richColors />
      <div className="min-h-screen">
        <h1 className="text-black font-semibold  text-2xl items-center my-4">
          Profile
        </h1>
        <div className="rounded-md bg-white">
          <div
            className="flex flex-row rounded-t-md"
            
          >
            {loading ? (
              <div role="status" className=" animate-pulse">
                <div className="flex justify-center mx-6 my-6 rounded-full items-center mb-4 h-48">
                  <svg
                    className="h-36 w-36  text-purple-400"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 640 512"
                  >
                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                  </svg>
                </div>
              </div>
            ) : (
              <img
                className=" h-36 w-36 rounded-full mx-6 my-6 object-cover"
                src={
                  currProfile?.user?.user_image
                    ? window.$backendURL + currProfile?.user?.user_image
                    : userAvatar
                }
                alt=""
              />
            )}

            <div className=" flex flex-row items-center justify-start">
              <div className="flex flex-col items-left pl-2 ">
                <div className="flex flex-row justify-between gap-4 items-center ">
                  {loading ? (
                    <div
                      role="status"
                      className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                    >
                      <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                    </div>
                  ) : (
                    <>
                      <span className="font-sans text-xl font-semibold pb-1 ">
                        {" "}
                        {currProfile?.user?.first_name +
                          " " +
                          currProfile?.user?.last_name}
                      </span>
                      <div className="flex gap-x-2 items-center">
                        <Link to="/edit-profile">
                          <button className=" flex gap-1 rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2">
                            <PencilIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </button>
                        </Link>
                        {currProfile?.is_verified == 0 && (
                          <>
                            {loader ? (
                              <LoaderSpinner />
                            ) : (
                              <button
                                onClick={handleVerification}
                                disabled={!stripe}
                                className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                              >
                                Verify
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {loading ? (
                  <div
                    role="status"
                    className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                  >
                    <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-36 mb-4"></div>
                  </div>
                ) : (
                  <>
                    <span className="text-sm mb-2">
                      Joined {moment(currProfile?.user.creation).format("ll")}
                    </span>
                    {currProfile?.is_verified == 0 && (
                      <>
                        <span className="text-md tracking-wide font-semibold text-red-600">
                          Not Verified
                        </span>
                      </>
                    )}
                    {currProfile?.is_verified == 1 && (
                      <>
                        <span className="flex items-center gap-x-1 tracking-wide text-md font-semibold text-green-400">
                          Verified{" "}
                          <CheckBadgeIcon className="w-5 h-5 text-green-400" />
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Business Profile Personal Info */}
          <div className="bg-white flex flex-col justify-start p-6 mt-3.5 rounded-md">
            <div className="text-xl font-semibold pb-5 ">Personal Info</div>
            <div className="flex flex-col">
              <div className="flex flex-row gap-4 sm:flex-row items-center">
                <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                  Email Address
                </div>
                {loading ? (
                  <div
                    role="status"
                    className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                  >
                    <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                  </div>
                ) : (
                  <div className=" w-1/2 text-sm text-gray-700">
                    {currProfile?.user?.name}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Business Profile Company Info */}
        <div className=" my-3.5 p-6 bg-white rounded-md">
          <h3 className="text-xl font-semibold pb-5 ">Company information</h3>
          <div className="flex flex-col">
            <div className="flex flex-row gap-4 items-center sm:flex-row ">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Business Name
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.business_name}
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 items-center sm:flex-row ">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Business Address
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.business_address}
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 items-center sm:flex-row ">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Business Number
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.business_number}
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 items-center sm:flex-row">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Employer Identification Number (EIN)
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.employer_identification_number}
                </div>
              )}
            </div>

            <div className="flex flex-row gap-4 items-center sm:flex-row">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Department Transportation Number (Dot){" "}
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.department_transport_number}
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 items-center sm:flex-row">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Motor Carrier Number(MC){" "}
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.motor_carrier_number}
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 items-center sm:flex-row">
              <div className=" w-1/4 list-none font-normal text-sm text-gray-400 py-2">
                Business Hours
              </div>
              {loading ? (
                <div
                  role="status"
                  className=" animate-pulse md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2.5 bg-purple-400 rounded-full dark:bg-purple-400 w-48 mb-4"></div>
                </div>
              ) : (
                <div className=" w-1/2 text-sm text-gray-700">
                  {currProfile?.business_hours}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
