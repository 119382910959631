import axios from 'axios';
import { createContext, useContext, useState, useEffect } from 'react';
const CompanyProfileContext = createContext();
    

export const CompanyProfile = ({ children }) => {
    const [CompanyProfileState, setCompanyProfileState] = useState();
//   const [loadingCompanyProfile, setLoadingCompanyProfile] = useState(true);
var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    
    const fetchCompanyProfile = async () => {
      try{
        await axios
        .get(
          `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
          config
        )
        .then((response) => {
            console.log(response);
            setCompanyProfileState(response.data.data)
        });
    } catch (e) {
      console.error(e);
    }
}

    fetchCompanyProfile();
  }, []);
  return (
    <CompanyProfileContext.Provider value={CompanyProfileState}>{children}</CompanyProfileContext.Provider>
  )
}

export const useCompanyProfile = () => useContext(CompanyProfileContext);