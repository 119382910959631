import axios from "axios";
import { Upload } from "heroicons-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { PlusCircleIcon } from "@heroicons/react/24/outline";
const ReservationPickupImages = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const { reservationID } = useParams();
  const [allReservation, setAllReservation] = useState(null);
  const [vehicleFront, setVehicleFront] = useState(null);
  const [vehicleBack, setVehicleBack] = useState(null);
  const [driverSide, setDriverSide] = useState(null);
  const [pessangerSide, setPessangerSide] = useState(null);
  const [tyres, setTyres] = useState(null);
  const [otherImage, setOtherImage] = useState(null);
  const [loading, setLaoding] = useState(false);

  useEffect(() => {
    try {
      setLaoding(true)
      axios
        .get(
          `${window.$backendURL}/api/method/reservation-contract-details?id=${reservationID}`,
          config
        )
        .then((response) => {
          console.log(response.data?.data);
          setAllReservation(response.data?.data);
          response?.data?.data?.reservation?.renter_pickup_pictures?.map((item) =>{
            console.log(item?.image);
            if(item?.type === "Front"){
                setVehicleFront(item?.image)
            }
            else if(item?.type === "Rear"){
                setVehicleBack(item?.image)
            }
            else if(item?.type === "Driver Side"){
                setDriverSide(item?.image)
            }
            else if(item?.type === "Passenger Side"){
                setPessangerSide(item?.image)
            }
            else if(item?.type === "Fuel"){
                setTyres(item?.image)
            }
            else if(item?.type === "Extra"){
                setOtherImage(item?.image)
            }
          })
          setLaoding(false)
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  console.log(vehicleFront);

  const handleVehicleFrontImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setVehicleFront(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleVehicleBackImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setVehicleBack(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleVehicleDriverSideImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setDriverSide(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleVehiclePessangerSideImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setPessangerSide(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleVehicleTyresImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setTyres(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleVehicleOtherImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const uploadHealthFile = async () => {
      try {
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData,
            config
          )
          .then((response) => {
            setOtherImage(response?.data?.message?.file_url);
          });
      } catch (e) {
        console.log(e);
      }
    };
    await uploadHealthFile();
  };

  const handleUploadAllImages = async() => {
    const JSON = {
      renter_pickup_pictures: [
        { image: vehicleFront, type: "Front" },
        { image: vehicleBack, type: "Rear" },
        { image: driverSide, type: "Driver Side" },
        { image: pessangerSide, type: "Passenger Side" },
        { image: tyres, type: "Fuel" },
        { image: otherImage, type: "Extra" },
      ],
    };
    try {
        await axios
          .put(
            `${window.$backendURL}/api/resource/Reservation Contract/${reservationID}`,
            JSON,
            config
          )
          .then((response) => {
            toast.success("Renter Images Uploaded");
            setAllReservation(response?.data?.data)
          });
      } catch (error) {
        console.log(error);
      }
  };

  return (
    <>
    <Toaster richColors/>
      <div className="flex flex-col  gap-y-4 my-5">
      {loading ?
          <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
            <div className="flex justify-between items-center px-10">
              <Skeleton count={1} height={125} width={225} />
              <Skeleton count={1} height={125} width={225} />
            </div>
            <div className="flex justify-between items-center px-10">
              <Skeleton count={1} height={125} width={225} />
              <Skeleton count={1} height={125} width={225} />
            </div>
            <div className="flex justify-between items-center px-10">
              <Skeleton count={1} height={125} width={225} />
              <Skeleton count={1} height={125} width={225} />
            </div>
          </SkeletonTheme>:
          <>
        <div className="flex justify-between items-center px-10 ">
          <div>
            {vehicleFront ? (
              <div className="relative">
              <img
                src={`${window?.$backendURL}${vehicleFront}`}
                className="w-56 h-32 rounded-md object-center object-cover"
              />
              {/* <span className="absolute -top-1 -right-1 bg-purple-500 rounded-full p-1 cursor-pointer">
                <PlusCircleIcon className="w-5 h-5 text-white"/></span> */}
              </div>
            ) : (
              <>
                <label
                  htmlFor="file-input-img1"
                  className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Upload Vehicle Front Image</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img1"
                  id="file-input-img1"
                  onChange={handleVehicleFrontImageUpload}
                  className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                />
              </>
            )}
          </div>
          <div>
            {vehicleBack ? (
              <img
                src={`${window?.$backendURL}${vehicleBack}`}
                className="w-56 h-32 rounded-md object-center object-cover"
              />
            ) : (
              <>
                <label
                  htmlFor="file-input-img2"
                  className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Upload Vehicle Back Image</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img2"
                  id="file-input-img2"
                  onChange={handleVehicleBackImageUpload}
                  className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center px-10 ">
          <div>
            {driverSide ? (
              <img
                src={`${window?.$backendURL}${driverSide}`}
                className="w-56 h-32 rounded-md object-center object-cover"
              />
            ) : (
              <>
                <label
                  htmlFor="file-input-img"
                  className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Upload Driver Side Image</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img"
                  id="file-input-img"
                  onChange={handleVehicleDriverSideImageUpload}
                  className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                />
              </>
            )}
          </div>
          <div>
            {pessangerSide ? (
              <img
                src={`${window?.$backendURL}${pessangerSide}`}
                className="w-56 h-32 rounded-md object-center object-cover"
              />
            ) : (
              <>
                <label
                  htmlFor="file-input-img2"
                  className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Upload Passenger Side Image</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img2"
                  id="file-input-img2"
                  onChange={handleVehiclePessangerSideImageUpload}
                  className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center px-10 ">
          <div>
            {tyres ? (
              <img
                src={`${window?.$backendURL}${tyres}`}
                className="w-56 h-32 rounded-md object-center object-cover"
              />
            ) : (
              <>
                <label
                  htmlFor="file-input-img"
                  className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Upload Tires Image</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img"
                  id="file-input-img"
                  onChange={handleVehicleTyresImageUpload}
                  className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                />
              </>
            )}
          </div>
          <div>
            {otherImage ? (
              <img
                src={`${window?.$backendURL}${otherImage}`}
                className="w-56 h-32 rounded-md object-center object-cover"
              />
            ) : (
              <>
                <label
                  htmlFor="file-input-img"
                  className="flex flex-col cursor-pointer justify-center gap-y-1 items-center text-sm font-medium text-gray-700 border-gray-500 border-2 rounded h-28 w-60 border-dotted"
                >
                  <Upload className="h-8 w-8" />
                  <span>Upload New Image</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  name="file-input-img"
                  id="file-input-img"
                  onChange={handleVehicleOtherImageUpload}
                  className="block w-full border sr-only  border-gray-300 rounded-md text-sm focus:z-10 focus:border-purple-500 focus:ring-purple-500 file:bg-transparent file:border-0file:bg-purple-800 file:text-white file:mr-4 file:py-3 file:px-4"
                />
              </>
            )}
          </div>
        </div>
        
        <div className="flex items-end justify-end mt-4 px-10">
          <button
            onClick={handleUploadAllImages}
            className="bg-purple-500 h-11 px-5 rounded-md text-white hover:bg-purple-600 font-semibold"
          >
            Upload All Images
          </button>
        </div>
      
        </>
        }
      </div>
    </>
  );
};

export default ReservationPickupImages;
