import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { Link, useNavigate } from "react-router-dom";

import userAvatar from "../../../img/user.png";
const EditProfileBusiness = (props) => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [loading, setLoading] = useState(false);
  const [currProfile, setCurrProfile] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [loaderLogoUpload, setLoaderLogoUpload] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            setCurrProfile(response.data.data);
            setLoading(false);
            setProfilePicture(response.data.data.user.user_image);
            setApiLoading(false);
            console.log(response);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getProfile();
  }, []);

  const handleIndividualProfilePictureUpload = (e) => {
    let filePicture = e.target.files[0];
    const formData = new FormData();
    formData.append("file", filePicture);

    const uploadPictureApi = async () => {
      try {
        setLoaderLogoUpload(true);
        await axios
          .post(
            `${window.$backendURL}/api/method/upload_file`,
            formData
            // config
          )
          .then((responsePicture) => {
            setProfilePicture(responsePicture.data.message.file_url);
            setLoaderLogoUpload(false);
          });
      } catch (e) {
        console.log(e);
      }
    };
    uploadPictureApi();
  };

  const EditProfileForm = useFormik({
    initialValues: {
      first_name: currProfile?.user.first_name,
      last_name: currProfile?.user.last_name,
      phone_no: currProfile?.business_number,
      address: currProfile?.business_address,
      employer_identification_number:
        currProfile?.employer_identification_number,
      license_number: currProfile?.license_number,
      department_transport_number: currProfile?.department_transport_number,
      mc: currProfile?.motor_carrier_number,
      business_hours: currProfile?.business_hours,
      business_name: currProfile?.business_name,
    },
    onSubmit: (values) => {
      var updateProfileJSON = {
        user: {
          id: currProfile.user.name,
          updateData: {
            first_name: values.first_name,
            last_name: values.last_name,
            user_image: profilePicture,
          },
        },
        companyProfile: {
          id: currProfile.name,
          updateData: {
            business_address: values.address,
            employer_identification_number:
              values.employer_identification_number,
            license_number: values.license_number,
            department_transport_number: values.department_transport_number,
            motor_carrier_number: values.mc,
            company_logo: profilePicture,
            business_number: values.phone_no,
            business_hours: values.business_hours,
            business_name: values.business_name,
          },
        },
      };
      const updateProfile = async () => {
        try {
          setLoading(true);
          await axios
            .post(
              `${window.$backendURL}/api/method/update-company`,
              updateProfileJSON,
              config
            )
            .then((response) => {
              setCurrProfile(response.data.data);
              localStorage.setItem(
                "picture",
                `${window.$backendURL}${response.data.data.company_logo}`
              );
              console.log(response.data.data);
              setIsSuccess(true);
              setTimeout(() => {
                setIsSuccess(false);
                navigate("/profile");
              }, 2000);
              setLoading(false);
              props.myFunc(response.data.data);
              console.log(response);
            });
        } catch (e) {
          console.error(e);
        }
      };
      updateProfile();
    },
    enableReinitialize: true,
  });

  return (
    <div className="min-h-screen">
      <Link to="/profile">
        <button className="font-semibold flex flex-row gap-2">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Profile</span>
        </button>
      </Link>
      <form onSubmit={EditProfileForm.handleSubmit}>
        <div className="form px-4 py-5 shadow sm:rounded-lg sm:p-6 mt-4">
          {apiLoading ? (
            <div className="flex justify-center">
              <LoadingIcons.BallTriangle stroke="#4F378B" />
            </div>
          ) : (
            <>
              <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-xl font-semibold leading-6 text-gray-900">
                    Edit Profile
                  </h3>
                </div>

                <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                  <div className="col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Photo
                    </label>
                    <div className="mt-1 flex items-center gap-6">
                      <span className="inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-100">
                        <img
                          src={
                            profilePicture
                              ? window.$backendURL + profilePicture
                              : userAvatar
                          }
                          className="h-full object-cover w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          alt=""
                        />
                      </span>
                      <div className="flex flex-col gap-1">
                        {loaderLogoUpload ? (
                          <label
                            htmlFor="profile_picture"
                            className="w-12 rounded-md border  button py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          >
                            <LoadingIcons.BallTriangle className="h-6 w-6" />
                          </label>
                        ) : (
                          <label
                            htmlFor="profile_picture"
                            className="w-44 rounded-md border  button py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                          >
                            Upload Profile Picture
                          </label>
                        )}

                        <div className="mt-1">
                          <input
                            id="profile_picture"
                            name="profile_picture"
                            type="file"
                            onChange={(e) => {
                              handleIndividualProfilePictureUpload(e);
                            }}
                            className="sr-only"
                          />
                          <p className="font-thin text-sm">
                            Image size should be at least 320px big. Allowed
                            file .png and jpg.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First Name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          onChange={EditProfileForm.handleChange}
                          value={EditProfileForm.values.first_name}
                          className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last Name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="last_name"
                          id="last_name"
                          onChange={EditProfileForm.handleChange}
                          value={EditProfileForm.values.last_name}
                          className="block w-full flex-1 rounded border-gray-300 focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg mt-8 font-medium leading-6 text-gray-900">
                    Profile Info
                  </h3>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="phone_no"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="phone_no"
                        id="phone_no"
                        onChange={EditProfileForm.handleChange}
                        value={EditProfileForm.values.phone_no}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="business_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_name"
                        id="business_name"
                        onChange={EditProfileForm.handleChange}
                        value={EditProfileForm.values.business_name}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business Address <span className="text-red-500">*</span>
                      </label>
                      <textarea
                        type="text"
                        name="address"
                        id="address"
                        onChange={EditProfileForm.handleChange}
                        value={EditProfileForm.values.address}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="employer_identification_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Employer Identification Number{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="employer_identification_number"
                        id="employer_identification_number"
                        onChange={EditProfileForm.handleChange}
                        value={
                          EditProfileForm.values.employer_identification_number
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="license_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        License Number
                      </label>
                      <input
                        type="text"
                        name="license_number"
                        id="license_number"
                        onChange={EditProfileForm.handleChange}
                        value={EditProfileForm.values.license_number}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="department_transport_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Department Transport Number{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="department_transport_number"
                        id="department_transport_number"
                        onChange={EditProfileForm.handleChange}
                        value={
                          EditProfileForm.values.department_transport_number
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="mc"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Motor Carrier Number(MC)
                      </label>
                      <input
                        type="text"
                        name="mc"
                        id="mc"
                        onChange={EditProfileForm.handleChange}
                        value={EditProfileForm.values.mc}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="business_hours"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business Hours <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_hours"
                        id="business_hours"
                        onChange={EditProfileForm.handleChange}
                        value={EditProfileForm.values.business_hours}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end pb-4 pt-6">
          {loading ? (
            <>
              <div className="rounded-md flex justify-center items-center gap-4 border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none ">
                <LoadingIcons.BallTriangle className="h-6 w-6" />
              </div>
            </>
          ) : isSuccess ? (
            <>
              <button className="rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow-sm bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2">
                Updated Successfuly
              </button>
            </>
          ) : (
            <button
              className="rounded-md border border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
              type="submit"
            >
              Update
            </button>
          )}
        </div>
        </div>
        
      </form>
    </div>
  );
};

export default EditProfileBusiness;
