import Logo from "../../img/Logo.png";
import { Fragment, useEffect, useState } from "react";
import { Menu, Popover, Transition, Combobox, Dialog } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { DateRange } from "react-date-range";
import { login, logout, reset } from "../../auth/authSlice";
import {
  DocumentPlusIcon,
  FolderPlusIcon,
  FolderIcon,
  HashtagIcon,
  TagIcon,
  TrashIcon,
  MapPinIcon,
  XMarkIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { SearchOutline } from "heroicons-react";
import { useDispatch } from "react-redux";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

var userEmail;
var JSToken;
var accessToken;
var userPic;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  userPic = localStorage.picture;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const projects = [
  { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
];

const recent = [projects[0]];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const HomePageHeader = ({ sendDataToParent }) => {
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  const [totalLocations, setTotalLocations] = useState(null);
  const [apiFilters, setApiFilters] = useState("");
  const nevigate = useNavigate();
  const dispatch = useDispatch();
  const [acessToken, setAccessToken] = useState(null);
  useEffect(() => {
    setAccessToken(accessToken);
    const GetAllLocations = async () => {
      try {
        await axios
          .get(`${window.$backendURL}/api/resource/Location?fields=["*"]`)
          .then((response) => {
            // setTotalLocations(response.data.data);
            // console.log(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    GetAllLocations();
  }, []);


  const onLogout = () => {
    const logoutFromFrappe = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            "https://api.rentmytruck.co/api/method/truckrent.api.clear_all_sessions_of_user?user=" +
              userEmail,
            config
          )
          .then((response) => {
            window.location.reload();
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    logoutFromFrappe();
    dispatch(logout());
    dispatch(reset());
  };

  const handleFilter = (e) => {
    console.log(e);
    setApiFilters(`["Location","location_name","=","${e}"]`);
  };

  const filteredProjects =
    query === ""
      ? []
      : projects.filter((project) => {
          return project.name.toLowerCase().includes(query.toLowerCase());
        });

  const [address, setAddress] = useState("");
  const [filterJson, setFilterJson] = useState(null);
  const [apiFilter, setApiFilter] = useState([]);
  const [dropDownOpen, setDropdownOpen] = useState(false);

  // useEffect(() => {
    
  // }, [filterJson]);

  useEffect(() => {
    const GetAllLocations = async () => {
      try {
        await axios
          .get(`${window.$backendURL}/api/resource/Location?fields=["*"]`)
          .then((response) => {
            console.log(response.data.data);
          });
      } catch (e) {
        console.error(e);
      }
    };
    GetAllLocations();
  }, []);

  const handleSelect = async (selected) => {
    try {
      const results = await geocodeByAddress(selected);
      const latLng = await getLatLng(results[0]);
      const city = results[0].address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;

      const postalCode = results[0]?.address_components.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;

      const state = results[0]?.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;

      console.log("Selected Address:", selected);
      console.log("Latitude:", latLng.lat);
      console.log("Longitude:", latLng.lng);
      console.log("Postal Code:", postalCode);
      console.log("City:", city);
      console.log("State:", state);

      setFilterJson({
        coordinates: latLng.lat + "," + latLng.lng,
        zip_code: postalCode,
        state: state,
        city: city,
      });
      sendDataToParent({
        coordinates: latLng.lat + "," + latLng.lng,
        zip_code: postalCode,
        state: state,
        city: city,
      })

    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const clearSelect  = () =>{
    setAddress("")
    setFilterJson({
      coordinates: "",
      zip_code: "",
      state: "",
      city: "",
    });
    sendDataToParent({
      coordinates: "",
      zip_code: "",
      state: "",
      city: "",
    })
  }
  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: "us" },
  };

  return (
    <>
      <header className="shrink-0 bg-gray-900">
        <div className="mx-auto flex h-16  items-center justify-between px-4 sm:px-6 lg:px-20">
          <Link to="/">
            <img
              className="h-8 w-auto cursor-pointer"
              src={Logo}
              alt="Your Company"
            />
          </Link>

          <div>
            <div className="relative  w-full justify-center hidden lg:flex items-center">
              <PlacesAutocomplete
                value={address}
                onChange={(value) => setAddress(value)}
                onSelect={handleSelect}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search by City, State, Zip code...",
                        className:
                          "location-search-input w-96 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500",
                        id: "search-placeholder",
                      })}
                    />
                    {address !== "" && <button className="absolute right-2.5 top-2.5" onClick={() => clearSelect()}><XMarkIcon className="w-5 h-5"/></button>}
                    <div className="absolute left-50 mt-1 bg-white rounded-md overflow-y-auto w-96 z-20">
                      {loading && <div className="p-2">Loading...</div>}
                      {suggestions?.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active p-2 cursor-pointer bg-blue-500 text-white"
                          : "suggestion-item p-2 cursor-pointer";
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                      
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </div>

          <div className="flex items-center gap-x-4 ">
            {acessToken == null ? (
              <>
                <Link to="/signin">
                  <button className="bg-gray-100 px-6 py-2 rounded-md font-medium hover:bg-white">
                    Login
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="bg-purple-500 px-10 py-2 rounded-md text-white font-medium hover:bg-purple-600">
                    Register
                  </button>
                </Link>
              </>
            ) : (
              <>
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 ring-2  text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={userPic}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/Dashboard"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Dashboard
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={onLogout}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Sign out
                          </p>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default HomePageHeader;
