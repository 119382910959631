import { Fragment, useEffect, useState } from "react";
import {
  Menu,
  Popover,
  Transition,
  Combobox,
  Dialog,
  Disclosure,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./VehicleListing.css";
import RailGate from "../../../img/cargo-truck_2311484.png";
import NoLiftGate from "../../../img/fast-delivery_9688890.png";
import TruckUnder from "../../../img/delivery-truck_6149226.png";
import { DateRange } from "react-date-range";
import {
  DocumentPlusIcon,
  FolderPlusIcon,
  FolderIcon,
  HashtagIcon,
  TagIcon,
  TrashIcon,
  MapPinIcon,
  XMarkIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import truckIcon from "../../../img/cargo-truck.png";
import axios from "axios";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useFormik } from "formik";
import Tooltip from "../../Global/SmallComponents/Tooltip";
import Skeleton from "react-loading-skeleton";

const projects = [
  { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function VehicleListing() {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 40.7128, // Latitude of New York City
    lng: -74.006, // Longitude of New York City
  };

  const [open, setOpen] = useState(false);

  const [vehicles, setVehicles] = useState(null);
  const [apiFilters, setApiFilters] = useState("");
  const [priceArr, setPriceArray] = useState(null);
  const [typeArr, setTypeArr] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [showFilters, setShowFilters] = useState(null);
  const [detail, setDetails] = useState(null);
  const [arrLoc, setArrLoc] = useState(null);
  const [picker, setPickerShow] = useState(false);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [openFilters, setOpenFilters] = useState(false);

  useEffect(() => {
    const GetAllVehicles = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(`${window.$backendURL}/api/method/get-vehicles`)
          .then((response) => {
            setVehicles(response?.data?.data);
            console.log(response?.data?.data);
            setLoader(false);

            let price = [];
            let type = [];
            let status = [];
            let loc = [];

            for (let index = 0; index < response.data.data.length; index++) {
              if (!price.includes(response.data.data[index].rent_per_day)) {
                price.push(response.data.data[index].rent_per_day);
              }
              if (!type.includes(response.data.data[index].vehicle_type)) {
                type.push(response.data.data[index].vehicle_type);
              }
              if (!status.includes(response.data.data[index].approval_status)) {
                status.push(response.data.data[index].approval_status);
              }
              if (
                !loc.includes(
                  response.data.data[index]?.pick_up[0]?.location_name
                )
              ) {
                loc.push(response.data.data[index]?.pick_up[0]?.location_name);
              }
            }
            setPriceArray(price);
            setTypeArr(type);
            setApprovalStatus(status);
            setArrLoc(loc);
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetAllVehicles();
  }, []);

  const applyFilterForm = useFormik({
    initialValues: {
      door_type: "",
      filter_type: "",
      filter_price: "",
      filter_loc: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      // setApiFilters(`["user","=",""]`);
      // if (values.door_type) {
      //   if (values.door_type.length > 0) {
      //     if (apiFilters.length > 0) {
      //       setApiFilters((prevState) => [
      //         prevState +
      //           `,["door_type","=","${values.door_type}"]`,
      //       ]);
      //     } else {
      //       setApiFilters([
      //         `["door_type","=","${values.door_type}"]`,
      //       ]);
      //     }
      //   }
      // }
      if (values.filter_type) {
        if (values.filter_type.length > 0) {
          if (apiFilters.length > 0) {
            setApiFilters([
              `["vehicle_type","=","${values.filter_type}"]`
            ]);
            const showFilter  = {
              key: "Vehicle Type",
              value: values.filter_type
            }
            setShowFilters(showFilter)
          } else {
            setApiFilters([`["vehicle_type","=","${values.filter_type}"]`]);
            const showFilter  = {
              key: "Vehicle Type",
              value: values.filter_type
            }
            setShowFilters(showFilter)
          }
        }
      }
      // if (values.filter_price) {
      //   if (values.filter_price.length > 0) {
      //     if (apiFilters.length > 0) {
      //       setApiFilters((prevState) => [
      //         prevState + `,["rent_per_day","=","${values.filter_price}"]`,
      //       ]);
      //     } else {
      //       setApiFilters([`["rent_per_day","=","${values.filter_price}"]`]);
      //     }
      //   }
      // }

      console.log(values);
    },
    enableReinitialize: true,
  });
  console.log(apiFilters);

  useEffect(() => {
    const GetAllVehicles = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-vehicles?filters=[${apiFilters}]`
          )
          .then((response) => {
            setVehicles(response.data.data);
            console.log(response.data.data);
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetAllVehicles();
  }, [apiFilters]);

  const [currentVehicle, setCurrentVehicle] = useState();
  const [costing, setcosting] = useState();
  const [totalTypeMiles, setTotalMiles] = useState();
  const [allUnAvailDates, setAllUnAvailDates] = useState([]);
  const [currLocation, setCurrLocation] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [roadside, setRoadSide] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalRent, setTotalrent] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [totalPrePaidMileage, setTotalPrePaidMileage] = useState(0);
  const [globalStatus, setGlobalStatus] = useState(null);
  const [reservationBooking, setReservationBooking] = useState(false);
  const [processingFee, setProcessingFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [vehicleID, setVehicleID] = useState("");

  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
  const [minimumRentalDays, setMinimumRentalDays] = useState(0);
  const [message, setMessage] = useState("");
  const formattedStartDate = formatDate(state[0]?.startDate);

  // Format the endDate
  const formattedEndDate = formatDate(state[0]?.endDate);

  // Create a new object with formatted dates
  const formattedState = {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    key: state.key,
  };

  function formatDate(date) {
    const formattedDate = date?.toISOString()?.split("T")[0];
    return formattedDate;
  }

  const handleSelect = (ranges) => {
    const startDateString = ranges.selection.startDate.toDateString();
    const endDateString = ranges.selection.endDate.toDateString();
    console.log(startDateString, endDateString);
    const minimalRentalDays = calculateTotalDays(
      startDateString,
      endDateString
    );
    if (minimalRentalDays < minimumRentalDays) {
      setMessage(`Minimum rental period is ${minimumRentalDays} days`);
    } else {
      setMessage("");
      setState([ranges.selection]);
    }
  };

  const calculateTotalDays = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const startTimestamp = Date.parse(startDate);
    const endTimestamp = Date.parse(endDate);
    if (!startTimestamp || !endTimestamp) {
      return 0;
    }
    const diffDays = Math.round(
      Math.abs((endTimestamp - startTimestamp) / oneDay)
    );
    return diffDays;
  };

  useEffect(() => {
    const getTypeBasedCosting = async () => {
      try {
        await axios
          .get(
            `${window.$backendURL}/api/method/truckrent.api.get_pricing_breakdown?vehicle=${vehicleID}&start_date=${formattedState?.startDate}&end_date=${formattedState?.endDate}`,
            config
          )
          .then((responseCost) => {
            console.log("responseCost", responseCost?.data?.data);
            setShowPriceBreakdown(true);
            setcosting(responseCost?.data?.data);
            setMinimumRentalDays(
              responseCost?.data?.data?.vehicle?.min_rental_days_out
            );
            // console.log("TypeCostinf>>>",responseCost?.data?.data);
            // // console.log(responseCost.data.data[0].allowed_cost_per_mile*responseCost.data.data[0].per_day_miles_allowed);
            // setTotalMiles(
            //   0.3 * responseCost.data.data[0]?.per_day_miles_allowed
            // );
          });
      } catch (e) {
        console.error(e);
      }
    };
    getTypeBasedCosting();
  }, [state]);

  useEffect(() => {
    var Difference_In_Time =
      state[0].endDate?.getTime() - state[0].startDate?.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
    setTotalDays(Math.floor(Difference_In_Days));
    setTotalrent(Math.floor(Difference_In_Days) * currentVehicle?.rent_per_day);
    console.log(state);
    setTotalPrePaidMileage(Math.floor(totalTypeMiles * 1.5));
    setTax(500);
    setRoadSide(500);
  }, [state, totalPrePaidMileage]);

  console.log("owner", currentVehicle?.vehicle_owner);

  useEffect(() => {
    // handleGrandTotal();
    setServiceFee(totalRent * 0.125);
    setProcessingFee(totalRent * 0.03);
    setTotalFee(serviceFee + processingFee);
    setDeposit(totalRent * 0.3);
    setGrandTotal(totalRent + totalFee + totalPrePaidMileage + deposit);
  }, [totalRent, serviceFee, processingFee, deposit, totalFee]);

  const [items, setItems] = useState([]);
  const [selectedButton, setSelectedButton] = useState(false);
  const [moreFilters, setMoreFilters] = useState([]);
  const [formatedFilters, setFormatedFilters] = useState([]);
  const [priceValue, setPriceValue] = useState([0, 1000]);
  const priceFilter = `["rent_per_day",">", ${priceValue[0]}],["rent_per_day","<", ${priceValue[1]}]`;
  const buttons = [
    { id: 1, value: "No Lift Gate", field: "lift_gate" },
    { id: 2, value: "Rail Gate", field: "lift_gate" },
    { id: 3, value: "Truck Under", field: "lift_gate" },
    { id: 4, value: "1", field: "require_cdl", name: "Required CDL" },
    { id: 5, value: "1", field: "crew_cab", name: "CrewCab" },
    { id: 6, value: "1", field: "e_truck", name: "E-Truck" },
    { id: 7, value: "1", field: "ramp", name: "Ramp" },
    { id: 8, value: "1", field: "multi_temp", name: "Multi-Temp" },
    { id: 9, value: "1", field: "dock_height", name: "Dock Height" },
    { id: 10, value: "1", field: "apu", name: "APU" },
    { id: 11, value: "1", field: "storage_only", name: "Storage Only" },
    { id: 12, value: "1", field: "has_ifta", name: "Has IFTA" },
    { id: 13, value: "1", field: "can_travel_out_of_state", name: "Yes" },
    { id: 14, value: "0", field: "can_travel_out_of_state", name: "No" },
    { id: 15, value: "Swing", field: "door_type", name: "Swing" },
    { id: 16, value: "Roll", field: "door_type", name: "Roll" },
  ];

  const [selectedLiftGate, setSelectedLiftGate] = useState(null);

  const addItem = (id, value, field) => {
    const newItem = { id, value, field };
    const index = items.findIndex((item) => item.id === id);

    if (index === -1) {
      // Item not in the array, so add it
      setItems([...items, newItem]);
      setSelectedButton(true);
      if (field === 'lift_gate') {
        setSelectedLiftGate(id); // Set the selected lift gate button
      }
    } else {
      // Item already in the array, so remove it
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
      setSelectedButton(false); // Deselect the button
      if (field === 'lift_gate') {
        setSelectedLiftGate(null); // Reset the selected lift gate button
      }
    }

    // When a button is clicked, update moreFilters
    updateMoreFilters(id, value, field);
  };

  const updateMoreFilters = (id, value, field) => {
    const filterIndex = moreFilters.findIndex(
      (filter) => filter.id === id && filter.value === value
    );

    console.log(priceFilter);
    if (filterIndex !== -1) {
      // Filter already exists, remove it
      const updatedFilters = [...moreFilters];
      updatedFilters.splice(filterIndex, 1);
      setMoreFilters(updatedFilters);
    } else {
      // Filter does not exist, add it with the specified field
      setMoreFilters([...moreFilters, { id, value, field }]);
    }
  };
  console.log(moreFilters);
  const formatFiltersAsStrings = () => {
    const formattedFilters = moreFilters.map(
      (filter) => `["${filter.field}","=","${filter.value}"]`
    );
    return formattedFilters.join(",");
  };
  console.log("formated", formatFiltersAsStrings());

  const handleApplyFilters = () => {
    setFormatedFilters(formatFiltersAsStrings());
    console.log(formatedFilters);
  };

  useEffect(() => {
    const GetAllVehicles = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-vehicles?filters=[${formatedFilters}]`
          )
          .then((response) => {
            setVehicles(response.data.data);
            console.log(response.data.data);
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetAllVehicles();
  }, [formatedFilters]);

  useEffect(() => {
    const GetAllVehicles = async () => {
      try {
        //   setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-vehicles?filters=[${priceFilter}]`
          )
          .then((response) => {
            setVehicles(response.data.data);
            console.log(response.data.data);
          });
        //   setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    GetAllVehicles();
  }, [priceValue]);

  return (
    <>
      {loader ? (
        <div className="flex flex-col h-full items-start w-full px-8">
          <div className="flex">
            <Skeleton width={1300} height={60} borderRadius="0.4rem" />
          </div>
          <div className="mt-3">
            <Skeleton width={330} height={40} />
          </div>
          <div className="flex justify-between w-full mt-2">
            <div className="flex">
              <Skeleton
                width={330}
                height={400}
                count={2}
                borderRadius="0.4rem"
              />
            </div>
            <div className="flex">
              <Skeleton
                width={330}
                height={400}
                count={2}
                borderRadius="0.4rem"
              />
            </div>
            <div>
              <Skeleton width={600} height={800} borderRadius="0.4rem" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex min-h-full flex-col ">
          <div className="flex items-center bg-white justify-between lg:mb-3 mb-5 border border-gray-200 rounded-xl lg:px-6 px-2">
            <div
              className="lg:hidden flex items-center gap-x-3"
              onClick={() => setOpenFilters(true)}
            >
              <button className="text-sm tracking-wide font-medium rounded-lg button text-white px-8 py-3 items-center">
                Filters
              </button>

              <div className="flex flex-col items-center lg:ml-5">
                <span className="text-sm font-semibold">
                  {vehicles?.length}
                </span>
                <p className="text-sm font-semibold tracking-wide">Results</p>
              </div>
            </div>

            <form onSubmit={applyFilterForm.handleSubmit}>
              <div className="lg:flex hidden items-center my-4 gap-x-4 px-6">
                <div className="rounded-lg ">
                  <div className="text-sm font-medium text-gray-500">
                    <div>
                      <label htmlFor="filter_model" className="sr-only"></label>
                      <select
                        type="text"
                        id="filter_type"
                        name="filter_type"
                        onChange={applyFilterForm.handleChange}
                        value={applyFilterForm.values.filter_type}
                        className="rounded-lg w-40 border-gray-300 pr-8 border-1 focus:outline-none  focus:border-purple-500 focus:ring-purple-500"
                      >
                        <option value="">Vehicle Type</option>
                        <option value="Day Cab">Day Cab</option>
                        <option value="E-Van">E-Van</option>
                        <option value="Tractor Sleeper">Tractor Sleeper</option>
                        <option value="Tractor Day Cab">Tractor Day Cab</option>
                        <option value="Single Tandem">Single Tandem</option>
                        <option value="Box Truck">Box Truck</option>
                        <option value="Van Cargo">Van Cargo</option>
                        <option value="Truck Pickup">Truck Pickup</option>
                        <option value="Trailers">Trailers</option>
                        <option value="Refrigerated Box Truck">
                          Refrigerated Box Truck
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className=" rounded-lg ">
                  <div className=" text-sm font-medium text-gray-500">
                    <div>
                      <label htmlFor="filter_model" className="sr-only"></label>
                      <select
                        type="text"
                        id="filter_price"
                        name="filter_price"
                        onChange={applyFilterForm.handleChange}
                        value={applyFilterForm.values.filter_price}
                        className="rounded-lg w-28 border-gray-300 border-1 focus:outline-none  focus:border-purple-500 focus:ring-purple-500"
                        placeholder="Model"
                      >
                        <option value="">Price</option>
                        {priceArr?.map((opt, index) => {
                          return (
                            <option key={opt} value={opt}>
                              {opt + "$"}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="rounded-lg">
                  <div className="truncate text-sm font-medium text-gray-500">
                    <div className="flex gap-1">
                      <button
                        type="submit"
                        className="rounded-md border w-full h-11 border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2"
                      >
                        Apply Filters
                      </button>
                      <button
                        onClick={() => {
                          setApiFilters("");
                          setShowFilters(null)
                          applyFilterForm.resetForm();
                        }}
                        className="text-purple-800 w-full h-11 px-4 py-2 ml-2 focus:outline-none rounded-lg border border-purple-800 hover:bg-purple-100 hover:text-purple-800"
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center lg:ml-5">
                  <span className="text-sm font-semibold">
                    {vehicles?.length}
                  </span>
                  <p className="text-sm font-semibold tracking-wide">Results</p>
                </div>
              </div>
            </form>

            <div className="flex lg:px-0  py-2 items-center">
              <Popover className="relative">
                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  <div className="flex button text-white px-4 py-3 items-center gap-x-1 rounded-lg">
                    <button className="lg:text-md text-sm tracking-wide  rounded-lg">
                      More Filters
                    </button>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute lg:-left-[4.5rem] -left-[2.3rem] z-10 mt-1 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden h-[40rem] overflow-y-auto rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div className="flex justify-between items-center my-5 px-4 pb-3">
                        <div className="">
                          <h2 className="lg:text-xl text-base font-semibold tracking-wide">
                            More Filters
                          </h2>
                        </div>
                        <div className="flex gap-x-2">
                          <button
                            className="lg:px-8 px-4 py-2 shadow-md rounded-lg text-white font-semibold button hover:shadow-md"
                            onClick={handleApplyFilters}
                          >
                            Apply All
                          </button>
                          <button
                            onClick={() => {
                              setFormatedFilters([]);
                              setMoreFilters([]);
                              setItems([]);
                              setSelectedLiftGate(null)
                            }}
                            className="lg:px-8 px-4 py-2 shadow-sm rounded-lg bg-transparent border font-semibold border-purple-500 text-purple-500 hover:shadow-md"
                          >
                            Clear All
                          </button>
                        </div>
                      </div>
                      <div className="my-3 px-4 ">
                        <div className="">
                          <div className="">
                            <div>
                              <label className="text-[16px] font-semibold px-1">
                                Door Type
                              </label>
                              <div className="flex gap-x-3 mt-2">
                                {buttons?.slice(14, 16).map((button) => (
                                  <div
                                    className="flex items-center gap-x-2 "
                                    key={button.id}
                                  >
                                    {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                    <button
                                      onClick={() =>
                                        addItem(
                                          button.id,
                                          button.value,
                                          button.field
                                        )
                                      }
                                      className={
                                        items.some(
                                          (item) => item.id === button.id
                                        )
                                          ? "bg-purple-400 text-white rounded-full py-3 px-2 shadow-md font-semibold"
                                          : "bg-gray-100 rounded-full py-3 px-2 shadow-sm hover:shadow-md text-gray-500 font-semibold"
                                      }
                                    >
                                      {button.value}
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-3 px-3">
                        <div className="">
                          <div className="">
                            <div>
                              <label
                                htmlFor="filter_liftGate"
                                className="text-[16px] font-semibold px-1"
                              >
                                Lift Gate
                              </label>
                              <div className="flex gap-x-3 mt-2">
                                {buttons?.slice(0, 3).map((button) => (
                                  <div
                                    className="flex items-center gap-x-2 "
                                    key={button.id}
                                  >
                                    {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                    <button
                                      disabled={selectedLiftGate && selectedLiftGate !== button.id}
                                      onClick={() =>
                                        addItem(
                                          button.id,
                                          button.value,
                                          button.field
                                        )
                                      }
                                      className={`rounded-full py-3 px-2 font-semibold ${
                                        items.some((item) => item.id === button.id)
                                          ? "bg-purple-400 text-white shadow-md"
                                          : "bg-gray-100 text-gray-500 shadow-sm hover:shadow-md"
                                      } ${
                                        selectedLiftGate && selectedLiftGate !== button.id
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                    >
                                      {button.value}
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-col gap-y-1 px-4">
                          <div>
                            <label
                              htmlFor="filter_liftGate"
                              className="text-[16px] font-semibold"
                            >
                              Rent Per Day
                            </label>
                          </div>
                          <div className="flex justify-between items-center font-semibold">
                            <p>{"$" + priceValue[0]}</p>
                            <p>{"$" + priceValue[1]}</p>
                          </div>
                          <div id="range-slider" className="py-1">
                            <RangeSlider
                              value={priceValue}
                              onInput={setPriceValue}
                              min={0}
                              max={1000}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="my-4 px-4 ">
                        <div className="mt-5">
                          <div className="">
                            <div>
                              <label className="text-[16px] font-semibold px-1">
                                Specifications
                              </label>
                              <div className="mt-2 ">
                                {buttons?.slice(3, 4).map((button) => (
                                  <div
                                    className="flex items-center gap-x-2 "
                                    key={button.id}
                                  >
                                    {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                    <button
                                      onClick={() =>
                                        addItem(
                                          button.id,
                                          button.value,
                                          button.field
                                        )
                                      }
                                      className={
                                        items.some(
                                          (item) => item.id === button.id
                                        )
                                          ? "bg-purple-400 text-white rounded-full py-3 px-2 shadow-md font-semibold"
                                          : "bg-gray-100 rounded-full py-3 px-2 shadow-sm hover:shadow-md text-gray-500 font-semibold"
                                      }
                                    >
                                      {button.name}
                                    </button>
                                  </div>
                                ))}
                                <div className="flex gap-x-3 mt-2">
                                  {buttons?.slice(4, 6).map((button) => (
                                    <div
                                      className="flex items-center gap-x-2 "
                                      key={button.id}
                                    >
                                      {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                      <button
                                        onClick={() =>
                                          addItem(
                                            button.id,
                                            button.value,
                                            button.field
                                          )
                                        }
                                        className={
                                          items.some(
                                            (item) => item.id === button.id
                                          )
                                            ? "bg-purple-400 text-white rounded-full py-3 px-2 shadow-md font-semibold"
                                            : "bg-gray-100 rounded-full py-3 px-2 shadow-sm hover:shadow-md text-gray-500 font-semibold"
                                        }
                                      >
                                        {button.name}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex gap-x-3 mt-2">
                                  {buttons?.slice(6, 9).map((button) => (
                                    <div
                                      className="flex items-center gap-x-2 "
                                      key={button.id}
                                    >
                                      {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                      <button
                                        onClick={() =>
                                          addItem(
                                            button.id,
                                            button.value,
                                            button.field
                                          )
                                        }
                                        className={
                                          items.some(
                                            (item) => item.id === button.id
                                          )
                                            ? "bg-purple-400 text-white rounded-full py-3 px-2 shadow-md font-semibold"
                                            : "bg-gray-100 rounded-full py-3 px-2 shadow-sm hover:shadow-md text-gray-500 font-semibold"
                                        }
                                      >
                                        {button.name}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex gap-x-3 mt-2">
                                  {buttons?.slice(9, 12).map((button) => (
                                    <div
                                      className="flex items-center gap-x-2 "
                                      key={button.id}
                                    >
                                      {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                      <button
                                        onClick={() =>
                                          addItem(
                                            button.id,
                                            button.value,
                                            button.field
                                          )
                                        }
                                        className={
                                          items.some(
                                            (item) => item.id === button.id
                                          )
                                            ? "bg-purple-400 text-white rounded-full py-3 px-2 shadow-md font-semibold"
                                            : "bg-gray-100 rounded-full py-3 px-2 shadow-sm hover:shadow-md text-gray-500 font-semibold"
                                        }
                                      >
                                        {button.name}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-7 mb-5 px-4">
                        <h1 className="text-xl font-semibold tracking-wide py-2">
                          Reservation Requirements
                        </h1>
                        <div className="">
                          <div className="">
                            <div>
                              <label
                                htmlFor="filter_liftGate"
                                className="text-[14px] font-semibold px-1"
                              >
                                Out of State Travel?
                              </label>
                              <div className="flex gap-x-3 mt-2">
                                {buttons?.slice(12, 14).map((button) => (
                                  <div
                                    className="flex items-center gap-x-2 "
                                    key={button.id}
                                  >
                                    {/* <img src={NoLiftGate} className="w-7 h-7" /> */}
                                    <button
                                      onClick={() =>
                                        addItem(
                                          button.id,
                                          button.value,
                                          button.field
                                        )
                                      }
                                      className={
                                        items.some(
                                          (item) => item.id === button.id
                                        )
                                          ? "bg-purple-400 text-white rounded-full py-3 px-2 shadow-md font-semibold"
                                          : "bg-gray-100 rounded-full py-3 px-2 shadow-sm hover:shadow-md text-gray-500 font-semibold"
                                      }
                                    >
                                      {button.name}
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
          </div>
          <div className="bg-gray-200 p-4 flex flex-wrap rounded-lg mb-5 border border-gray-200 mx-5">
            {moreFilters?.length > 0 || showFilters !== null? (<>
             { moreFilters?.map((filter, index) => (
                <div
                  key={index}
                  className="bg-gray-100 text-sm px-2.5 py-1 m-1 capitalize rounded-full text-gray-600 flex items-center gap-x-1.5"
                >
                  <div>
                    {filter?.value === "1"
                      ? filter?.field.replace("_", " ")
                      : filter?.field.replace("_", " ") + ": " + filter?.value}
                  </div>
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      addItem(filter.id, filter.value, filter.field)
                    }
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </span>
                </div>
              ))}
              { showFilters !== null &&
               <div
               className="bg-gray-100 text-sm px-2.5 py-1 m-1 capitalize rounded-full text-gray-600 flex items-center gap-x-1.5"
             >
               <div>
               {showFilters?.key + ":"} {showFilters?.value}
               </div>
               <span
                 className="cursor-pointer"
                 onClick={() => {
                  setApiFilters("");
                  setShowFilters(null)
                 }}
               >
                 <XMarkIcon className="w-4 h-4" />
               </span>
             </div>
              
              } 
            </>):(<p className="text-sm text-gray-600 ml-5">No filters selected</p>) }

           {/* { showFilters !== null && <span className="bg-gray-100 text-sm px-2.5 py-1 m-1 capitalize rounded-full text-gray-600 flex items-center gap-x-1.5">{showFilters?.key + ":"} {showFilters?.value}</span>} */}
            
            
            
          </div>
          <div className="flex px-2 lg:px-4 pb-2">
            <h2 className="text-2xl font-semibold">Vehicle Listing</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="w-full overflow-y-auto h-screen">
              {/* Left Column */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 justify-center lg:px-2 pb-2">
                {vehicles?.map((vehicle, index) => (
                  <div
                    key={vehicle.name}
                    className="w-full flex flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white hover:bg-gray-100"
                  >
                    <div className="aspect-w-3 aspect-h-3 bg-gray-200 hover:opacity-75 sm:aspect-none h-64">
                      <Link to={"/vehicle-detail/" + vehicle.name}>
                        <img
                          src={
                            vehicle?.attach_images[0]?.image === undefined
                              ? "https://i.postimg.cc/W1VykQvr/Untitled-design-Edited.png"
                              : window.$backendURL +
                                vehicle?.attach_images[0]?.image
                          }
                          alt="No Image Attached"
                          className="min-h-64 w-full object-cover object-center h-full sm:h-full sm:w-full"
                        />
                      </Link>
                    </div>
                    <div className="flex flex-1 flex-col justify-between space-y-2 p-4">
                      <div className="flex items-start flex-row justify-between ">
                        <span className=" font-normal text-xl">
                          {" "}
                          {vehicle?.make +
                            " " +
                            vehicle?.model +
                            " " +
                            vehicle?.year}
                        </span>
                        <span
                          className={
                            "inline-flex  rounded-full bg-red-100 px-4 text-xs font-semibold leading-5 text-red-800  sm:ml-2 items-center"
                          }
                        >
                          {vehicle?.vehicle_type}
                        </span>
                      </div>
                      <div className="flex flex-row gap-1 items-center mt-4">
                        <span className=" font-semibold text-gray-700 items-center">
                          ${vehicle?.rent_per_day} / Day
                        </span>
                      </div>
                      <div className="flex flex-row gap-1 items-center mt-4">
                        <MapPinIcon className="w-5 text-gray-700" />
                        <span className="font-normal text-gray-700 items-center truncate">
                          {vehicle?.pick_up[0]?.address}
                        </span>
                      </div>

                      <div className=" pt-4 pb-2  ">
                        {/* <div className="flex items-center  ">
                          <div className="flex justify-between gap-2">
                            <span className=" font-light">4.5</span>
                            <StarIcon className="h-5 w-5" />
                          </div>
                          <span className="ml-2 inline-flex rounded-full bg-purple-100 px-2  text-sm leading-5 text-purple-800 font-light items-center">
                            23 Trips
                          </span>
                          <span className={vehicle?.availability_status=="Available" ? "ml-2 inline-flex rounded-full bg-blue-100 px-2  text-sm leading-5 text-blue-800 font-light items-center":"ml-2 inline-flex rounded-full bg-red-100 px-2  text-sm leading-5 text-red-800 font-light items-center"}>
                            {vehicle?.availability_status}
                          </span>
                        </div> */}

                        <div className="flex items-center gap-2 lg:gap-2">
                          <div className="flex gap-1 px-2 py-1 bg-purple-100 rounded-md">
                            {" "}
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              id="Layer_1"
                              dataname="Layer 1"
                              viewBox="0 0 28 28"
                            >
                              <path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" />
                            </svg> */}
                            <span className=" inline-flex text-purple-500  px-0.5 leading-5 text-base text-center font-light  items-center">
                              {vehicle?.fuel_type}
                            </span>
                          </div>
                          <div className="flex gap-1 px-2 py-1 bg-purple-100 rounded-md">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              dataname="Layer 1"
                              viewBox="0 0 26 26"
                              width="22"
                            >
                              <path d="M23.5,23h-3.5V4.5c0-2.481-2.019-4.5-4.5-4.5h-7c-2.481,0-4.5,2.019-4.5,4.5V23H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5H23.5c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5ZM5,4.5c0-1.93,1.57-3.5,3.5-3.5h7c1.93,0,3.5,1.57,3.5,3.5V23H5V4.5Zm12,7.5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z" />
                            </svg> */}
                            <span className=" inline-flex text-purple-500  px-0.5 text-base leading-5 text-center font-light items-center">
                              {vehicle?.lift_gate}
                            </span>
                          </div>
                        </div>
                        <Link to={"/vehicle-detail/" + vehicle.name}>
                          <div className="flex mt-4  items-center justify-center">
                            <button className="bg-purple-500 px-5 py-2 w-full rounded-md text-white hover:bg-purple-600">
                              See Details
                            </button>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full ">
              <div className="h-screen rounded-lg pb-2 lg:pr-5">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={3}
                >
                  {vehicles?.map((c, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: Number(c?.pick_up[0]?.coordinates?.split(",")[0]),
                        lng: Number(c?.pick_up[0]?.coordinates?.split(",")[1]),
                      }}
                      title={c.make + " " + c.model}
                      onClick={() => navigate(`/vehicle-detail/${c.name}`)}
                    />
                  ))}
                </GoogleMap>
              </div>
            </div>
          </div>

          {/* <div className="flex items-center mt-5 ">
            <h1 className="text-2xl px-[3.3rem] font-semibold">
              Vehicles Listing
            </h1>
          </div>
          <div className="flex my-6 rounded-xl">
            <div className="flex w-[50%] flex-col h-screen overflow-y-auto">
              <div className="flex flex-wrap gap-x-5 justify-center gap-y-10 md:px-2 px-2  items-center ">
                {vehicles?.map((vehicle, index) => (
                  <div
                    key={vehicle.name}
                    className="w-96 flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white hover:bg-gray-100"
                  >
                    
                  </div>
                ))}
              </div>
            </div>
            {!visible ? (
              <div className="w-[50%] mr-10">
                
              </div>
            ) : (
              <div className="w-[50%] bg-white mr-3 rounded-lg py-4">
               
              </div>
            )}
          </div> */}
        </div>
      )}

      <Transition.Root show={openFilters} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 sm:hidden"
          onClose={setOpenFilters}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setOpenFilters(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Form */}
                <form
                  onSubmit={applyFilterForm.handleSubmit}
                  className="mt-4 px-4"
                >
                  {/* Vehicle Type */}
                  <div className="mb-4">
                    <label
                      htmlFor="filter_type"
                      className="text-sm font-medium text-gray-500 block"
                    >
                      Vehicle Type
                    </label>
                    <select
                      id="filter_type"
                      name="filter_type"
                      onChange={applyFilterForm.handleChange}
                      value={applyFilterForm.values.filter_type}
                      className="rounded-lg w-full border-gray-300 pr-8 border-1 focus:outline-none focus:border-purple-500 focus:ring-purple-500"
                    >
                      <option value="">Vehicle Type</option>
                      <option value="Day Cab">Day Cab</option>
                      <option value="E-Van">E-Van</option>
                      <option value="Tractor Sleeper">Tractor Sleeper</option>
                      <option value="Tractor Day Cab">Tractor Day Cab</option>
                      <option value="Single Tandem">Single Tandem</option>
                      <option value="Box Truck">Box Truck</option>
                      <option value="Van Cargo">Van Cargo</option>
                      <option value="Truck Pickup">Truck Pickup</option>
                      <option value="Trailers">Trailers</option>
                      <option value="Refrigerated Box Truck">
                        Refrigerated Box Truck
                      </option>
                    </select>
                  </div>

                  {/* Price */}
                  <div className="mb-4">
                    <label
                      htmlFor="filter_price"
                      className="text-sm font-medium text-gray-500 block"
                    >
                      Price
                    </label>
                    <select
                      id="filter_price"
                      name="filter_price"
                      onChange={applyFilterForm.handleChange}
                      value={applyFilterForm.values.filter_price}
                      className="rounded-lg w-full border-gray-300 border-1 focus:outline-none focus:border-purple-500 focus:ring-purple-500"
                    >
                      <option value="">Price</option>
                      <option value="">Price</option>
                      {priceArr?.map((opt, index) => {
                        return (
                          <option key={opt} value={opt}>
                            {opt + "$"}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* Apply and Clear Filters */}
                  <div className="flex flex-col items-center mb-4">
                    <button
                      type="submit"
                      className="rounded-md border w-full h-11 border-transparent button py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:ring-offset-2 mb-2"
                    >
                      Apply Filters
                    </button>
                    <button
                      onClick={() => {
                        setApiFilters("");
                        applyFilterForm.resetForm();
                      }}
                      className="text-purple-800 w-full h-11 px-4 py-2 focus:outline-none rounded-lg border border-purple-800 hover:bg-purple-100 hover:text-purple-800"
                    >
                      Clear Filters
                    </button>
                  </div>

                  {/* Results */}
                  <div className="text-sm font-semibold text-center">
                    {vehicles?.length} Results
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
