import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CreditCardIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from "sonner";
import PaymentForm from "./PaymentForm";

const SettingRenter = () => {
  const stripePromise = loadStripe(
    "pk_live_51McDWdLUqlbLOiA4OFtWtx9aK2FQVZ3WaGYoRBLjfiVUOFMHaJhr53SqWZlsHsKqxbLHBu2Z6W58L9sUBT1yYKEF00x1WJTl0W"
  );
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const [deletedPaymentMethod, setDeletedPaymentMethod] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState();
  const [newAdded, setNewAdded] = useState(false);

  function getNewAdded(){
    setNewAdded(!newAdded)
  }
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const getProfileOwner = async () => {
      try {
        setLoading(true);
        await axios
          .get(
            `${window.$backendURL}/api/method/get-owner?email=${localStorage.user_email}&role=Owner Business`,
            config
          )
          .then((response) => {
            const getPayemntsList = async () => {
              var json = {
                customer_stripe: response.data.data?.customer_stripe,
              };
              try {
                setLoading(true);
                await axios
                  .post(
                    `${window.$backendURL}/api/method/truckrent.api.list_payment_method`,
                    json
                  )
                  .then((responsePayments) => {
                    setPaymentMethods(responsePayments.data.data.data);
                    console.log(">>>>", responsePayments);
                    setLoading(false);
                  });
              } catch (e) {
                console.error(e);
              }
            };

            getPayemntsList();
          });
      } catch (e) {
        console.error(e);
      }
    };

    getProfileOwner();
  }, [deletedPaymentMethod,newAdded]);

  const handleDeletePaymentMethod = async (id) => {
    var json = {
      payment_id: id,
    };

    try {
      await axios
        .post(
          `${window.$backendURL}/api/method/truckrent.api.delete_payment_method`,
          json
        )
        .then((response) => {
          toast.success("Payment Method Deleted Successfully");
          setDeletedPaymentMethod(!deletedPaymentMethod);
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <h1 className="text-xl flex justify-start items-center font-bold">
        Renter Settings
      </h1>
      <h2 className="text-md mt-4 flex justify-start items-center font-bold">
        Payment Methods
      </h2>
      {/* Cards */}

      {paymentMethods?.map((card) => (
        <div className="relative sm:w-1/3 md:2/3 mt-2 border border-1 border-gray-600 rounded isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>

          <div className="text-sm leading-6 text-gray-900">
            <div className="flex items-center justify-center">
              <div className="flex  justify-center items-center mr-1">
                <CreditCardIcon className="h-8 w-8 text-gray-900 " />
              </div>
              <div className="flex">
                <span className="flex justify-center mr-2 items-center text-lg ">
                  {card.card.brand}
                </span>
                <span className="pt-1.5">**** **** ****</span>
                <span className="pl-1 pt-1">{card.card.last4}</span>
              </div>
              <div className="ml-4">
                {card.card.exp_month + "/" + card.card.exp_year}
              </div>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <TrashIcon
                onClick={() => handleDeletePaymentMethod(card.id)}
                className="h-5 w-5 text-gray-900 hover:text-red-500"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      ))}
      <button onClick={()=>{setOpen(true)}} className="relative w-full sm:w-1/3 md:2/3 mt-2 border border-1 border-gray-600 hover:bg-gray-100 rounded  flex  justify-center items-center py-2">
<span className="flex justify-center items-center"> <PlusIcon className="w-4 h-4"/> Add Payment Method</span>
</button>

      {/* Add PM Modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center w-full sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Add Payment Method
                      </Dialog.Title>
                      <div className="mt-2">
                        <Elements stripe={stripePromise}>
                          <PaymentForm func={getNewAdded}/>
                          {/* Other components */}
                        </Elements>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SettingRenter;
